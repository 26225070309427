.breadcrumb {
    background-color: $breadcrumb-bg;
}

.breadcrumb-item {
    margin: 0;

    a {
        color: $breadcrumb-before-color;
    }

    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item::before {
        content: "|";
        color: $breadcrumb-before-color;
        font-weight: $font-weight-light;
    }

    &.active {
        color: $breadcrumb-active-color;
        font-weight: $font-weight-semi-bold;
    }
}
