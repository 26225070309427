.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
}

.modal-open {
    @include media-breakpoint-down(md) {
        position: fixed;
    }
}

input[placeholder] {
    text-overflow: ellipsis;
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
    color: $blue;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.hidden {
    display: none;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    // TODO: commented out if client wants to provide hard coded image.
    // background-image: url('../images/storelocator.jpg');
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin: 0 auto;
}

.error-page {
    h4 {
        a {
            @include type-h4();
        }
    }

    h2 {
        a {
            @include type-h2();
        }
    }
}

.error-unassigned-category {
    color: $red;
}

.skip {
    position: absolute;
    left: 0;
    top: -4.2em;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
    transition: all 0.2s ease-in-out;
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.svg {
    position: relative;
    display: block;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
