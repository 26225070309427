.checkout-global {
    .btn {
        height: 44px;
    }

    &.container {
        @include media-breakpoint-up(md) {
            max-width: 1170px;
        }

        @include media-breakpoint-between(lg,th) {
            padding: 0 30px;
        }

        h1.page-title {
            color: $nero;
            left: 0;
            font-size: 1.75rem;
            margin-top: 2.5rem;
            margin-bottom: 1.75rem;

            @include media-breakpoint-up(lg) {
                font-size: 2.25rem;
                margin-top: 3.125rem;
            }
        }

        .customer-summary {
            margin-bottom: 1.875rem;
        }

        .custom-login {
            @include media-breakpoint-up(lg) {
                max-width: 670px;
            }

            .shipping-section {
                margin-bottom: 0;
            }

            .btn {
                font-size: $font-size-body;
                font-weight: 600;
            }

            .form-control-label {
                font-family: $font-family-third;
                font-weight: 600;
            }

            .customer-summary {
                .summary-section-label,
                .summary-details {
                    font-family: $font-family-third;
                    font-weight: 700;
                }

                .summary-details {
                    @include media-breakpoint-up(lg) {
                        padding-left: 5px;
                    }

                    .customer-summary-email,
                    .customer-summary-fullname {
                        font-weight: 600;
                        color: $shadow-color;
                        font-size: $font-size-body;
                    }
                }
            }
        }

        .summary-wrapper {
            padding-left: 1.25rem;
            padding-right: 1.25rem;

            @include media-breakpoint-between(lg,xl) {
                max-width: 500px;
                padding-left: 30px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 470px;
                padding-left: 0;
            }

            .summary-block {
                border: 1px solid $gray-102;
            }

            .order-total-block {
                border: 1px solid $gray-300;
            }
        }
    }

    .card {
        .card-header {
            padding-left: 0;
            padding-right: 0;

            .order-receipt-header {
                line-height: 1.375rem;
            }

            .card-header-custom {
                font-size: 22px;
                color: $shadow-color;
            }
        }

        .edit-button {
            font-family: $font-family-third;
            color: $primary;
            font-size: $font-size-body;
            text-transform: capitalize;
            font-weight: 600;
        }

        .card-body {
            padding: 20px 0 0;

            .customer-information-block {
                .submit-customer-login {
                    margin-top: 6px;
                }

                .forgot-password {
                    margin-top: -5px;
                }
            }
        }

        &.order-product-summary {
            margin-bottom: 0;

            .card-body {
                padding: 0;

                .leading-lines {
                    padding: 16px;
                }

                .product-line-item-details {
                    .item-image {
                        max-width: 100px;

                        img {
                            width: 100px;
                            max-width: unset;
                            margin-right: 0;
                        }
                    }

                    .line-item-name {
                        margin-bottom: 5px;
                        overflow: unset;

                        a {
                            white-space: normal;
                            font-family: $font-family-third;
                            font-weight: 700;
                            letter-spacing: 0;
                        }

                        span {
                            overflow: unset;
                        }

                        &.no-link {
                            span {
                                white-space: normal;
                                font-family: $font-family-third;
                                font-weight: 700;
                                font-size: $font-size-body;
                                color: $primary;
                            }
                        }
                    }

                    .line-item-attributes {
                        font-family: $font-family-third;
                        font-weight: 600;
                        text-transform: capitalize;

                        span {
                            text-transform: inherit;
                            font-weight: 400;
                            margin-left: 9px;
                        }

                        &.line-item-sku {
                            margin-bottom: 2px;
                        }
                    }

                    .item-attributes {
                        padding-left: 12px;
                        flex: auto;

                        .price-wrapper {
                            margin-top: 10px;
                        }
                    }
                }

                h2 {
                    color: $gray-600;
                    line-height: 1;
                    font-size: 18px;
                }

                .line-item-quantity {
                    margin-top: 4px;

                    .line-item-pricing-info {
                        span {
                            font-size: $font-size-body;
                            font-family: $font-family-third;
                        }

                        .qty-card-quantity-count {
                            font-weight: 400;
                            margin-left: 9px;
                        }
                    }
                }

                .line-item-availability {
                    margin-top: 10px;
                    margin-bottom: 10px;

                    .line-item-attributes {
                        line-height: 1.5;

                        &.expected-availability,
                        &.line-item-instock-date,
                        &.availability-label {
                            line-height: 1.371;
                        }
                    }
                }

                .pc-promotion-message {
                    font-weight: 400;
                    color: $fire-engine-red;
                    line-height: 1.375;
                }

                .edit-button {
                    color: $gray-600;
                }
            }
        }

        &.order-total-summary-wrapper {
            .card-header {
                h2 {
                    &.card-header-custom {
                        color: $gray-600;
                        font-size: $font-size-xl;
                    }
                }

                .order-receipt-label {
                    font-family: $font-family-third;
                    margin-left: 10px;
                    line-height: 1.371;
                    padding-top: 3px;
                }
            }

            .order-total-summary {
                .leading-lines.order-summary-title {
                    div p span {
                        font-family: $font-family-third;
                        font-weight: 400;
                        font-size: $font-size-body;
                    }
                }
            }
        }

        .product-summary-block {
            margin: 0;

            .groupedProducts {
                margin: 0;
                border: unset;
            }

            .product-line-item {
                padding: 0 20px 20px;

                .line-item-unit-price,
                .line-item-total-price {
                    display: flex;

                    .line-item-pricing-info {
                        line-height: 1.5;

                        span {
                            font-size: $font-size-xl;
                            font-weight: 600;
                            text-transform: uppercase;
                        }
                    }

                    .price {
                        margin-left: 6px;
                        // line-height: 1.067;
                        margin-top: 2.7px;

                        del {
                            color: $gray-104;
                        }

                        del + span span {
                            margin-top: 5px;
                            font-weight: 600;
                            color: $fire-red;
                        }

                        span,
                        .line-item-total-price-amount {
                            font-size: $font-size-base;
                            font-family: $font-family-third;
                            font-weight: 400;
                        }

                        .line-item-total-price-amount {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        &.customer-section {
            .card-header {
                background: $white;

                .guest-description,
                .registered-customer-message {
                    margin: 0.875rem 0 1rem;

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 1.25rem;
                    }
                }
            }

            .customer-information-guest {
                border-bottom: 1px solid #979797;
            }

            #registered-customer {
                .remember-me-wrapper {
                    margin-top: 2px;
                }

                .remember-me {
                    margin-bottom: 4px;

                    label {
                        line-height: 1.371;
                        margin-bottom: 0;
                    }
                }

                .privacy-policy {
                    line-height: 1.317;

                    p {
                        margin-bottom: 0;

                        a {
                            font-family: $font-family-third;
                        }
                    }
                }

                .forgot-password {
                    a {
                        font-family: $font-family-third;
                    }
                }
            }
        }

        &.order-total-summary-wrapper {
            margin-bottom: 0;

            .card-header {
                padding: 14px 20px;
            }

            .order-total-summary {
                padding: 15px 20px 0;
            }

            .shipping-discount,
            .order-discount {
                color: $callout-message;
            }

            .order-total-summary {
                .grand-total {
                    padding-bottom: 16px;
                    line-height: 1.067;

                    &.leading-lines {
                        border-top: 1px solid #979797;
                        padding-top: 13px;
                        margin-top: 8px;
                    }

                    p {
                        margin-bottom: 0;
                    }

                    span {
                        font-family: $font-family-second;
                        font-size: $font-size-xl;
                        line-height: 1.067;
                    }

                    .grand-total-sum {
                        font-size: $font-size-base;
                    }
                }
            }
        }

        &.ghost {
            opacity: 1;
            background-color: $white-smoke;
        }

        .shipping-content-wrapper {
            flex: 100%;
        }

        .shipping-content {
            padding-top: 1rem;

            .phone-Ex {
                font-size: $font-size-xs;
            }

            .shipping-form {
                .restricted-products,
                .restricted-shipping {
                    margin-top: 1.313rem;

                    .alert {
                        margin-bottom: 0;
                    }
                }
            }

            .custom-checkbox {
                label {
                    font-family: $font-family-third;
                    font-weight: 400;
                }
            }

            .form-field {
                .col-lg-6 {
                    @include media-breakpoint-up(lg) {
                        &:first-child {
                            padding-right: 5px;
                        }

                        &:last-child {
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
    }

    .custom-select {
        background: $checkout-svg-checkbox-selectArrow no-repeat right 0.8125rem center/22px 22px;
    }

    .custom-control-input {
        + .custom-control-label::before {
            content: $checkout-svg-checkbox-unselected;
        }

        &:checked + .custom-control-label {
            &::before {
                content: $checkout-svg-checkbox-selected;
                width: 15px;
                height: 15px;
            }

            &::after {
                content: "";
                background: none;
                top: 0;
                width: 15px;
                height: 15px;
            }
        }
    }

    &.required {
        label {
            &::after {
                content: "";
                display: none;
            }
        }
    }

    .custom-login {
        .edit-button {
            font-family: $font-family-base;
            color: #755836;
            line-height: 1.5;
            font-weight: 400;
        }

        .form-group:not(.custom-control) {
            margin-bottom: 21px;
        }
    }

    .shipping,
    .payment,
    .shipping-summary,
    .payment-summary,
    .order-review,
    .payment-form {
        .card-header {
            .card-header-custom {
                color: $primary;
                font-weight: 600;
                font-size: 22px;
                text-transform: uppercase;
            }
        }
    }

    .payment-summary {
        .content-asset {
            .custom-control-label,
            ul > li {
                font-family: $font-family-third;
                font-weight: 400;
            }
        }
    }

    .order-review-container {
        padding-top: 1.375rem;
    }

    .checkout-stage {
        width: 30px;
        height: 53px;
        text-align: center;
        background-color: #dfdfdf;
        margin-bottom: 0;
        padding: 0.75rem 0;
        color: $gray-600;
        font-size: 1.375rem;
        font-weight: 600;
    }

    &[data-checkout-stage=customer],
    &[data-checkout-stage=shipping] {
        .estimated-sales-tax {
            display: block;
        }

        .actual-sales-tax {
            display: none;
        }

        @include media-breakpoint-down(md) {
            .card-header {
                align-items: center;
            }
        }
    }

    &:not([data-checkout-stage="customer"], [data-checkout-stage="shipping"]) {
        .estimated-sales-tax {
            display: none;
        }

        .actual-sales-tax {
            display: block;
        }
    }

    .form-header {
        background-color: $gray-200;
    }

    &.data-checkout-stage {
        &[data-checkout-stage=shipping],
        &[data-checkout-stage=payment],
        &[data-checkout-stage=placeOrder] {
            .card {
                &.ghost {
                    &.payment,
                    &.order-review {
                        display: block;

                        @include media-breakpoint-down(md) {
                            .card-header {
                                align-items: center;
                            }
                        }
                    }

                    .checkout-stage {
                        color: $gray-600;
                        background-color: #dfdfdf;
                    }
                }

                &.shipping-summary {
                    margin-bottom: 0;
                }
            }

            .payment-summary,
            .payment-form {
                margin-bottom: 0;

                .card-header {
                    margin-top: 2.375rem;
                }

                .content-asset {
                    ul {
                        margin-bottom: 0;
                    }
                }
            }

            .checkout-stage {
                background-color: $shadow-color;
                color: $white;
            }

            .summary-details {
                &.shipping {
                    margin-bottom: 0;
                }
            }

            .address-summary {
                margin-bottom: 0;

                .billing-addr-label {
                    display: none;
                }
            }

            .single-shipping {
                @include media-breakpoint-up(md) {
                    display: flex;
                }

                .card-header {
                    background-color: $white-smoke;
                }

                .single-shipping-details {
                    width: 50%;

                    @include media-breakpoint-down(md) {
                        &:first-child {
                            margin-bottom: 1.375rem;
                        }
                    }

                    .start-lines {
                        padding: 0;
                    }
                }
            }
        }

        .checkout-stage-bottom {
            h2.card-header-custom {
                color: $gray-600;
            }
        }

        &[data-checkout-stage=placeOrder],
        &[data-checkout-stage=payment] {
            .card {
                &.ghost.payment {
                    display: none;
                }
            }

            .payment-information {
                .summary-details {
                    &.billing {
                        display: none;
                    }
                }
            }
        }

        &[data-checkout-stage=placeOrder] {
            .card {
                &.ghost.order-review {
                    display: none;
                }
            }
        }
    }

    .shipment-selector-block {
        a {
            margin-top: 10px;
            margin-bottom: 0;
            font-family: $font-family-third;
            text-decoration: underline;

            @include media-breakpoint-up(lg) {
                margin-bottom: 6px;
            }
        }
    }

    .shipping-method-block {
        h5 {
            margin-bottom: 1rem;
            font-weight: 600;
            font-size: $font-size-xl;
        }

        .form-check,
        .shipping-method-pricing {
            padding-left: 0;

            label,
            span {
                font-family: $font-family-third;
                font-weight: 400;
                color: $gray-600;

                &.arrival-time {
                    padding-left: 8px;
                    color: $gray-600 !important;
                }

                &.display-name {
                    font-weight: 600;
                }

                &.shipping-cost {
                    font-size: 1rem;
                }
            }

            .form-check-input {
                opacity: 0;

                + .shipping-method-option {
                    display: flex;
                    align-items: center;

                    &::before {
                        content: $checkout-svg-radio-button-unselected;
                        border: none;
                        line-height: 1;
                        width: 14px;
                        height: 14px;
                    }

                    .display-name {
                        padding-left: 12px;
                    }
                }

                &:checked + .shipping-method-option {
                    &::before {
                        content: $checkout-svg-radio-button-selected;
                        border: none;
                        background: none;
                        line-height: 1;
                        width: 15px;
                        height: 15px;
                    }

                    &::after {
                        content: "";
                        background: none;
                    }
                }
            }
        }

        .form-check,
        .shipping-method-pricing {
            padding: 0;
            margin-bottom: 0;
            line-height: 1.371;

            .form-check-label {
                margin-bottom: 4px;
            }

            .shipping-description {
                padding-left: 26px;
            }
        }
    }

    .shipping-summary,
    .payment-information {
        .single-shipping {
            .summary-section-label {
                font-family: $font-family-second;
                font-weight: 600;
                margin-bottom: 5px;
                font-size: $font-size-body;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-xl;
                }
            }
        }

        .summary-details,
        .address-summary,
        .address-summary span,
        .payment-details span,
        .order-history-info span {
            font-size: $font-size-body;
            font-weight: 400;
            font-family: $font-family-third;
        }
    }

    .payment-form-label {
        p {
            font-family: $font-family-second;
            font-weight: 600;
            margin-bottom: 5px;
            font-size: $font-size-body;

            @include media-breakpoint-up(md) {
                font-size: $font-size-xl;
            }
        }
    }

    .payment-form {
        margin-bottom: 0;

        .payment-options-block {
            .credit-card-form {
                .row {
                    .col-6 {
                        &:first-child {
                            padding-right: 5px;
                        }

                        &:last-child {
                            padding-left: 5px;
                        }
                    }
                }
            }
        }

        .card-number-wrapper {
            &::after {
                background-image: $checkout-credit-front;
                transform: translateY(-50%);
                top: 50%;
            }

            &[data-type="visa"]::after {
                background-image: url('../../images/payment-types.png');
                background-size: auto;
                background-position: -162px -110px;
            }

            &[data-type="mastercard"]::after {
                background-image: url('../../images/payment-types.png');
                background-size: auto;
                background-position: -295px -110px;
            }

            &[data-type="amex"]::after {
                background-image: url('../../images/payment-types.png');
                background-size: auto;
                background-position: -230px -15px;
            }

            &[data-type="discover"]::after {
                background-image: url('../../images/payment-types.png');
                background-size: auto;
                background-position: -95px -110px;
            }
        }

        .securityCode-wrapper {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: 16px;
                background-repeat: no-repeat;
                background-image: $checkout-credit-back;
                background-size: contain;
                width: 40px;
                height: 24px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .apply-top-spacing {
            margin-top: 1rem;
        }

        .address-selector-block {
            .address-selector-ctas {
                a {
                    margin-top: 10px;
                    margin-bottom: 0;
                    font-family: $font-family-third;
                }
            }

            .address-selector-label {
                font-size: $font-size-xl;
                font-family: $font-family-second;
                font-weight: 600;
            }

            .addressSelector {
                margin-top: 14px;
            }

            .subscription-checkbox {
                .custom-control-label {
                    &::before {
                        border: 0;
                        background-color: unset;
                    }

                    &::after {
                        content: '';
                    }
                }
            }

            .content-asset {
                margin-top: 00.875rem;

                ul {
                    padding-inline-start: 1.125rem;

                    li {
                        font-family: $font-family-third;
                        color: $black;

                        a {
                            font-family: $font-family-third;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .card-body-label {
        font-size: $font-size-base;
        font-family: $font-family-second;
        font-weight: 500;
    }

    .shipping-address-heading {
        font-family: $font-family-second;
        font-size: $font-size-xl;
        font-weight: 600;
        color: $gray-600;
        letter-spacing: 0;
        line-height: 1.067;

        &.login-customer-heading {
            margin-bottom: 0.875rem;
        }
    }

    .next-step-button {
        border-bottom: 1px solid #979797;
        margin-top: 1.25rem;
    }

    &[data-checkout-stage=placeOrder] {
        .next-step-button {
            border-bottom: 0;

            .place-order {
                margin-top: 10px;
            }
        }
    }

    #requestPasswordResetModal {
        .modal-dialog {
            .modal-content {
                padding: 38px 40px 40px;

                .modal-header {
                    padding: 0 0 20px;
                    align-items: center;
                    margin-bottom: 12px;
                    border-bottom: 1px solid #767676;

                    .close {
                        margin: 0;

                        &::before {
                            position: unset;
                        }
                    }

                    .request-password-title {
                        font-size: $font-size-lg;
                    }
                }

                .modal-body {
                    padding: 0;

                    .request-password-body {
                        p {
                            font-family: $font-family-third;
                        }

                        .form-control-label {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .form-control {
        height: 44px;
    }
}

.main-menu.no-menu {
    .continue-shopping.nav-link {
        color: $white !important;
        text-decoration: none;
    }
}

.checkout-global {
    &.receipt {
        padding: 0 1.25rem;

        @include media-breakpoint-up(lg) {
            max-width: 970px;
        }

        .order-product-summary {
            .end-lines {
                display: none;
            }

            .item-attributes {
                .line-item-attributes span {
                    font-size: $font-size-body;
                }
            }
        }

        .order-thank-you-msg {
            color: $gray-600;
            margin-top: 1.25rem;
            letter-spacing: inherit;
            line-height: 1.167;

            @include media-breakpoint-up(lg) {
                margin-top: 3.125rem;
                margin-bottom: 1.125rem;
            }
        }

        .order-thank-you-email-msg,
        span {
            font-size: $font-size-base;
            font-family: $font-family-third;

            span {
                color: $brand-main;
            }
        }

        .order-thank-you-email-msg {
            span {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .account-confirmation-wrapper {
            background-color: $gray-103;
            border: 1px solid $gray-300;

            .order-confirm-create-account {
                max-width: 770px;
                background: unset;

                .card-header {
                    background-color: unset;

                    .card-header-custom {
                        font-size: 22px;
                    }

                    .confirmation-desc {
                        margin-top: 16px;
                        font-family: $font-family-third;

                        @include media-breakpoint-up(lg) {
                            padding: 0 9rem;
                        }
                    }
                }

                .card-body {
                    .col-md-6 {
                        .form-control-label {
                            font-family: $font-family-third;
                        }

                        @include media-breakpoint-up(md) {
                            &:first-child {
                                padding-right: 1rem;
                            }

                            &:last-child {
                                padding-left: 1rem;
                            }
                        }
                    }

                    .btn {
                        font-size: $font-size-body;
                    }
                }
            }
        }

        .confirm-details {
            background: $light-white;
            border: 1px solid $gray-102;
            padding: 2.188rem 0;

            .confirmation-page-summary {
                .confirmation-page-shippingSummary {
                    padding: 0 1.875rem;

                    @include media-breakpoint-up(lg) {
                        flex: 0 0 33.33%;
                        padding: 0 4.063rem;
                    }

                    .single-shipping-details {
                        .billing-addr-label {
                            display: none;
                        }
                    }

                    .summary-details {
                        margin-bottom: 1.563rem;

                        div,
                        span {
                            font-size: $font-size-body;
                            font-family: $font-family-third;
                            font-weight: 400;
                            line-height: 1.371;
                            flex: auto;
                            max-width: inherit;
                        }

                        .order-history-info {
                            margin-bottom: 0;
                        }
                    }

                    .start-lines {
                        span {
                            background: unset;
                        }
                    }
                }

                .payment-form-label p,
                .summary-section-label {
                    font-family: $font-family-second;
                    font-weight: 600;
                    margin-bottom: 0.813rem;
                    font-size: $font-size-body;

                    @include media-breakpoint-up(lg) {
                        font-size: $font-size-xl;
                    }
                }

                .confirmation-page-billingSummary {
                    @include media-breakpoint-down(lg) {
                        padding: 0 1.875rem;
                    }

                    .payment-information {
                        margin-bottom: 0;

                        @include media-breakpoint-up(lg) {
                            display: flex;
                            height: 100%;
                        }

                        .payment-details {
                            margin-bottom: 1.563rem;
                        }

                        .summary-details {
                            @include media-breakpoint-up(lg) {
                                padding: 0 4.063rem;
                                flex: 0 0 50%;
                            }

                            .summary-section-label {
                                font-family: $font-family-second;
                                font-weight: 600;
                                margin-bottom: 0.813rem;
                                font-size: $font-size-body;

                                @include media-breakpoint-up(lg) {
                                    font-size: $font-size-xl;
                                }
                            }

                            div,
                            span {
                                font-size: $font-size-body;
                                font-family: $font-family-third;
                                font-weight: 400;
                                line-height: 1.371;
                            }

                            &.payment {
                                @include media-breakpoint-up(lg) {
                                    border-left: 1px solid $gray-101;
                                    border-right: 1px solid $gray-101;
                                }
                            }
                        }
                    }
                }
            }
        }

        .order-product-summary,
        .checkout-order-total-summary {
            border: 1px solid $gray-101;

            .order-total-summary {
                padding-bottom: 1rem;

                .grand-total {
                    padding-bottom: 0;
                }
            }

            .card-header {
                .card-header-custom {
                    font-size: $font-size-xl;
                    font-family: $font-family-second;
                    color: $gray-600;
                }
            }

            .pc-promotion-message {
                margin-top: 10px;
            }

            .product-summary-block {
                @include media-breakpoint-up(lg) {
                    max-width: 430px;
                }

                .line-item-pricing-info {
                    span {
                        font-family: $font-family-second;
                    }
                }

                .price-wrapper {
                    .line-item-unit-price {
                        .price {
                            span {
                                color: $gray-600;
                                font-weight: 400;
                            }

                            del span {
                                color: $gray-104;
                            }

                            del + span span {
                                color: $fire-red;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }

    .age-verification {
        @include media-breakpoint-down(sm) {
            .custom-control-input {
                left: 2px;
                z-index: 0;
            }
        }
    }
}

#address-verification-modal {
    .modal-dialog {
        @include media-breakpoint-up(lg) {
            max-width: 616px;
            width: 100%;
        }
    }

    .modal-content {
        padding: 38px 30px 30px;

        @include media-breakpoint-up(lg) {
            padding: 38px 41px 47px;
        }

        .modal-header {
            padding: 0 0 20px;
            border-bottom: 1px solid #767676;
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 0 20px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 12px;
            }

            .modal-title {
                line-height: 1.067;
                font-weight: 600;
            }

            @include media-breakpoint-up(md) {
                margin: 0 0 12px;
            }

            .close {
                margin: 0 0 0 auto;
                opacity: 1;
                padding: 0;

                &::before {
                    height: 14px;
                    width: 14px;
                    right: unset;
                    top: unset;
                    position: relative;
                    background-size: 14px;
                    background-repeat: no-repeat;
                    background-image: $close-icon-x;
                }
            }
        }

        .multiline-address span {
            font-size: 14px;
        }

        .modal-body.small-font {
            display: flex;
            flex-flow: column wrap;
            padding: 0;

            @include media-breakpoint-up(md) {
                padding: 0;
                flex-flow: row wrap;
            }

            p {
                width: 100%;
                font-size: 14px;
                font-family: $font-family-third;
            }

            .form-check-label {
                margin-bottom: 10px;

                h5 {
                    font-size: 14px;
                    font-family: $font-family-third;
                }
            }

            .address-block {
                width: 100%;
                grid-template-rows: unset;
                margin-top: 24px;
                margin-bottom: 0;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 18px;
                }

                @include media-breakpoint-up(md) {
                    width: 50%;
                }

                @include media-breakpoint-down(lg) {
                    padding-right: 0;
                }

                &.suggestion-column {
                    border-left: none;

                    @include media-breakpoint-down(lg) {
                        margin-top: 30px;
                    }
                }

                .form-check {
                    padding-left: 0;

                    .form-check-input {
                        opacity: 0;

                        label {
                            font-family: $font-family-third;
                            line-height: 1.371;
                        }

                        + label {
                            display: flex;
                            align-items: center;

                            &::before {
                                content: $checkout-svg-radio-button-unselected;
                                border: none;
                                line-height: 1;
                                padding-right: 9px;
                            }

                            .display-name {
                                padding-left: 12px;
                            }
                        }

                        &:checked + label {
                            &::before {
                                content: $checkout-svg-radio-button-selected;
                                border: none;
                                background: none;
                                line-height: 1;
                                padding-right: 9px;
                            }

                            &::after {
                                content: "";
                                background: none;
                            }
                        }
                    }
                }

                &.suggestion-column {
                    padding: 0;

                    @include media-breakpoint-up(md) {
                        padding: 0;
                    }
                }
            }

            .address-verification-content {
                margin-top: 0;
                font-size: 14px;
                font-family: $font-family-third;
                line-height: 1.371;

                span {
                    font-family: $font-family-third;
                }
            }

            .vertexOffLine {
                margin-top: 10px;
            }

            .vertexOffLine,
            .vertexOffLine a {
                width: 100%;
            }
        }
    }

    .alert-warning {
        display: none;
    }

    .modal-title {
        font-size: 18px;
        font-weight: 500;
    }

    .address-bypass-row {
        width: 100%;
        padding: 0;
        text-align: center;

        button {
            margin-top: 0;
            min-width: 45%;
        }
    }
}
