@include media-breakpoint-down(md) {
    [data-action*='Product-Show'] .primary-images .product-carousel {
        .slide-link {
            height: 100%;

            img {
                width: auto;
                margin: auto;
                height: auto;
                max-height: 100%;
                max-width: 336px;
            }
        }
    }
}

.attributes {
    .promotions {
        padding-bottom: 0 !important;
    }

    .promotions:empty {
        border-bottom: 0 !important;
    }
}

.stock-notify-me {
    @include media-breakpoint-up(md) {
        .form-group {
            position: relative;
        }

        .invalid-feedback {
            position: absolute;
        }
    }
}

.product-notification-message {
    @include toast-message();
}

.product-notification-alert {
    @include toast-alert();
}

.expertvoice {
    margin-top: rem(50px);
}

.cls-slide-pdp {
    min-width: "400px";
    min-height: "183px";
}
