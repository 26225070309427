
// Checkbox & Radio buttons
.custom-control-label {
    font-weight: $font-weight-regular;

    &::before {
        top: 0;
    }
}

.custom-checkbox,
.custom-radio {
    .custom-control-input:checked + .custom-control-label {
        font-weight: $font-weight-semi-bold;
    }
}

.custom-radio {
    .custom-control-input {
        + .custom-control-label::before {
            content: $svg-radio-button-unselected;
            border: none;
        }

        &:checked + .custom-control-label {
            &::before {
                content: $svg-radio-button-selected;
                border: none;
                background: none;
            }

            &::after {
                content: "";
                background: none;
            }
        }
    }
}

.custom-checkbox {
    .custom-control-input {
        + .custom-control-label::before {
            content: $svg-checkbox-unselected;
            border: none;
        }

        &:checked + .custom-control-label {
            &::before {
                content: $svg-checkbox-selected;
                border: none;
                background: none;
            }

            &::after {
                content: "";
                background: none;
            }
        }
    }

    &.required {
        label {
            &::after {
                content: "";
                display: none;
            }
        }
    }
}

.removedItem {
    display: none;
}

option[disabled="disabled"] {
    display: none;
}

