@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts";

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/";

//--------------------------------
//  COLORS
//--------------------------------

// Theme colors documentation: https://getbootstrap.com/docs/4.1/getting-started/theming/#theme-colors

// Color Palette

/* Variable names from http://chir.ag/projects/name-that-color */

//Brand Colors
$shadow-color: #85704d;
$aubergine: #231f20;
$barley-corn: #b39659;
$single-fawn: #6e5a3b;

//Brand Accents
$mariner: #355ba8;
$fire-engine-red: #d2232a;
$fire-red: #d5232a;
$pumpkin: #f47521;
$pewter: #9e9f9e;
$nero: #222;
$night-rider: #353535;
$hurricane: #888380;
$mercury: #d7d3d1;
$white-smoke: #f6f6f6;
$snow: #fafafa;
$white: #fff;
$black: #000;
$trans: transparent;
$green: #056808;
$astral: #31708f;
$saffron: #fbc02d;
$falu-red: #92110f;
$charcoal: #4a4a4a;
$wild-sand: #e6e2db;
$hawkes-blue: #d2d7e1;
$tapa: #76716e;
$burnt-sienna: #e27252;
$alert-yellow: #faedd6;

// Theme Mappings
$colors: () !default;
$colors: map-merge(
    (
        "shadow": $shadow-color,
        "aubergine": $aubergine,
        "barley-corn": $barley-corn,
        "single-fawn": $single-fawn,
        "mariner": $mariner,
        "fire-engine-red": $fire-engine-red,
        "pumpkin": $pumpkin,
        "pewter": $pewter,
        "nero": $nero,
        "night-rider": $night-rider,
        "hurricane": $hurricane,
        "mercury": $mercury,
        "white-smoke": $white-smoke,
        "snow": $snow,
        "trans": $trans,
        "white": $white,
        "black": $black,
        "green": $green,
        "astral": $astral,
        "saffron": $saffron,
        "falu-red": $falu-red,
        "charcoal": $charcoal,
        "wild-sand": $wild-sand,
        "hawkes-blue": $hawkes-blue,
        "tapa": $tapa
    ),
    $colors
);

// Gray Colors - Bootstrap overrides
$gray: #bebebe;
$gray-600: $nero;
$gray-500: $night-rider;
$gray-400: $hurricane;
$gray-300: $mercury;
$gray-200: $white-smoke;
$gray-100: $snow;
$gray-700: $nero;
$gray-800: $nero;
$gray-900: $nero;
$light-white: #e7e7e7;
$gray-101: #979797;
$gray-102: #a5a5a5;
$gray-103: #f9f9f9;
$gray-104: #656565;

// Brand Variables
$brand-main: $shadow-color;
$brand-second: $aubergine;
$brand-light: $barley-corn;
$brand-dark: $single-fawn;
$primary: $brand-main;
$secondary: $brand-second;
$dark: $brand-dark;
$light: $brand-light;
$success: $green;
$info: $astral;
$warning: $saffron;
$danger: $falu-red;
$accent-1: $mariner;
$accent-2: $fire-engine-red;
$accent-3: $pumpkin;
$accent-4: $pewter;
$accent-5: $tapa;
$callout-message: $fire-red;

$brand-primary: $brand-main !default;
$brand-secondary: $brand-second !default;
$brand-tertiary: $brand-light !default;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "dark": $dark,
        "light": $light,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "accent-1": $accent-1,
        "accent-2": $accent-2,
        "accent-3": $accent-3,
        "accent-4": $accent-4,
        "accent-5": $accent-5
    ),
    $theme-colors
);

//--------------------------------
//  OTHER THEME COLORS
//--------------------------------

$brand-success: $success;
$brand-danger: $danger;
$brand-primary: $shadow-color;
$light-gray: $gray-200;
$slightly-darker-gray: $gray-300;
$dark-gray: $gray-400;
$darker-gray: $gray-500;
$state-danger-text: $white;

$sale: $fire-engine-red !default;

//--------------------------------
//  CHART COLORS
//-------------------------------
$chart-light-green: #70a730;
$chart-blue: #4a90e2;
$chart-purple: #6e3f98;
$chart-orange: #f5a623;

$chart-colors: (
    'blue': $chart-blue,
    'orange': $chart-orange,
    'purple': $chart-purple,
    'light-green': $chart-light-green
);

//--------------------------------
//  Breakpoints
//--------------------------------

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    th: 1025px,
    xl: 1200px
);

//--------------------------------
//  Max widths
//--------------------------------

$container-max-widths: (
    md: 720px,
    lg: 940px,
    th: 1000px,
    xl: 1200px
);

//--------------------------------
//  ALERTS
//--------------------------------

$alert-bg-level: -10; // bootstrap override
$alert-border-level: -9; // bootstrap override
$alert-color-level: 4; // bootstrap override
$alert-danger-bg: $brand-danger;
$alert-success-bg: $brand-success;

//--------------------------------
//  BACKGROUNDS
//--------------------------------

// BG utility class documentation: https://getbootstrap.com/docs/4.0/utilities/colors/#background-color

// Gradients
// Gradients utility class documentation: https://getbootstrap.com/docs/4.0/utilities/colors/#background-gradient

$enable-gradients: false; // bootstrap override -> must be set to true for gradients to be visible on the site

//--------------------------------
//  BORDERS / DIVIDERS
//--------------------------------

// Borders utility class documentation: https://getbootstrap.com/docs/4.0/utilities/borders/

$border-radius: 0;
$border-success: 1px solid $success;
$border-warning: 1px solid $warning;
$border-danger: 1px solid $danger;
$border-width: 1px; // could be bootstrap override
$border-color: $gray-300; // default color - could be bootstrap override
$border: 1px solid $border-color;
$border2-color: $primary;
$border2: 2px solid $border2-color;
$border3-color: $gray-400;
$border3: 2px solid $border3-color;
$border4-color: $gray-500;
$border4: 1px solid $border4-color;
$border5-color: $gray-200;
$border5: 1px solid $border5-color;
$border6-color: $white;
$border6: 1px solid $border6-color;
$border7-color: $gray-400;
$border7: 1px solid $border6-color;
$horizontal-border-grey: $gray-300;
$hr-border-color: $gray-300;
$horizontal-rule-grey: $gray-300;
$horizontal-border-width: 1px;
$crossed-out-border: 0.5px solid $white;

//--------------------------------
//  BOX SHADOWS
//--------------------------------

$enable-shadows: true; // bootstrap override
$shadow-shade: rgba($hawkes-blue, 0.5);
$shadow: 2px 2px 4px 2px $shadow-shade;

//--------------------------------
//  TYPOGRAPHY
//--------------------------------

$font-family-base: 'Nunito Sans', sans-serif;
$font-family-second: 'Oswald', sans-serif;
$font-family-third: 'Open Sans', sans-serif;
$font-family-fourth: 'FFDINWebProMedium', sans-serif;
$font-size-base: 1rem; // sets base font size applied to html element
$font-size-body: $font-size-base * 0.875; // 14px
$font-size-xs: $font-size-base * 0.75; // 12px
$font-size-xl: $font-size-base * 1.125; // 18px
$font-size-price: $font-size-base * 1.625; //26px
$body-color: $gray-600; //bootstrap override
$enable-font-px-fallback: true;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Fonts

// Displays
$enable-responsive-font-sizes: true; // need this for scaling font
$display1-size: $font-size-base * 5; //bootstrap override  //80px
$display1-size-mobile: $font-size-base * 3.4375; //55px
$display2-size: $font-size-base * 3; //bootstrap override
$display2-size-mobile: $font-size-base * 2.125; //34px
$display3-size: $font-size-base * 1.125;
$display3-size-mobile: $font-size-base * 0.875; //14px

// Headings

$h1-font-size: $font-size-base * 3; //bootstrap override 48px
$h1-font-size-mobile: $font-size-base * 2.125; //34px
$h2-font-size: $font-size-base * 2.25; //bootstrap override//36px
$h2-font-size-mobile: $font-size-base * 1.625; //26px
$h3-font-size: $font-size-base * 1.75; //bootstrap override //28px
$h3-font-size-mobile: $font-size-base * 1.25; //20px
$h4-font-size: $font-size-base * 1.375; //bootstrap override //22px
$h4-font-size-mobile: $font-size-base * 1.125; //18px
$h5-font-size: $font-size-base; //bootstrap override //16px
$h6-font-size: $font-size-base * 0.875; //bootstrap override //14 px

// Weights
$checkout-font-weight: 600;

// Labels

$label-size: $font-size-base * 0.875; //14px
$label-size-mobile: $font-size-base * 0.75; //12px

//--------------------------------
//  LINKS
//--------------------------------

$link-color: $primary; //bootstrap override
$link-hover-color: $primary; //bootstrap override
$link-color-hover: $primary; //bootstrap override
$link-decoration-hover: underline;
$link-color-alt: $primary;
$link-color-hover-alt: $dark;
$link-decoration-alt: underline;

//--------------------------------
//  BUTTONS
//--------------------------------

$btn-link-color: $primary; //bootstrap override
$btn-link-text-decoration-hover: underline;
$btn-disabled-opacity: 1;
$btn-disabled-main-color: $charcoal;
$btn-disabled-main-bg: $gray-300;
$btn-font-size: $font-size-xs; //bootstrap override
$font-size-lg: $font-size-base * 1.25; //bootstrap override
$btn-padding-y-lg: 0.9rem; //bootstrap override
$btn-outline-primary-hover-color: $wild-sand;
$btn-secondary-hover-color: $gray-200;

//--------------------------------
//  FORMS
//--------------------------------
// all bootstrap overrides
$input-border-color: $gray-300;
$input-color: $charcoal;
$input-border-radius: 0;
$input-box-shadow: none;
$input-bg: $white;
$input-placeholder-color: $gray-400;
$custom-select-bg: $white;
$custom-select-border-radius: 0;
$custom-select-focus-box-shadow: none;
$input-padding-x: 0.8125rem;
$input-padding-y: 0.625rem;
$custom-select-padding-y: 0.625rem;
$custom-select-bg-size: 22px 22px;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-bg: $white;
$text-muted: $gray-400;

//--------------------------------
//  BUTTONS & FORMS
//--------------------------------

$input-btn-padding-y: 0.75rem; //bootstrap override
$input-btn-padding-x: 1rem; //bootstrap override
$btn-box-shadow: 0 0 0 transparent; //bootstrap override
$btn-active-box-shadow: 0 0 0 transparent; //bootstrap override

//--------------------------------
//  DROPDOWN MENU
//--------------------------------

$menu-link: $gray-200;
$close-menu-bg: $gray-200;
$dropdown-link-hover-bg: $gray-200; //bootstrap override
$dropdown-link-hover-color: $gray-200; //bootstrap override

//--------------------------------
// BREADCRUMBS
//--------------------------------

$breadcrumb-bg: $white;
$breadcrumb-before-color: $gray-600;
$breadcrumb-active-color: $primary; //bootstrap override
$breadcrumb-divider-color: $gray-600; //bootstrap override
$breadcrumb-item-padding: 11px;

//--------------------------------
// CONTENT SWITCHER
//--------------------------------

$contentswitcher-active-color: $white;
$contentswitcher-active-bg: $primary;

//--------------------------------
// Product Tile
//--------------------------------

$product-tile-promo: $accent-3;
$product-number-grey: $gray-500;

//--------------------------------
//  SPACING
//--------------------------------

$spacer-px: 16px;
$spacer: 1rem; // 16px
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        // 12px
        1: ($spacer * 0.75),
        // 20px
        2: ($spacer * 1.25),
        // 30px
        3: ($spacer * 1.875),
        // 40px
        4: ($spacer * 2.5),
        // 60px
        5: ($spacer * 3.75),
        // 80px
        6: ($spacer * 5)
    ),
    $spacers
);

//This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
    (
        25: 25%,
        33: 33.3333%,
        50: 50%,
        66: 66.6666%,
        75: 75%,
        100: 100%
    ),
    $sizes
);

// Grid columns

// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 24px; // How do we want to update this to be reflective of the 8px spacing for mobile?

//--------------------------------
//  CARDS & ACCORDIONS
//--------------------------------

$accordion-spacer-y: 17px;
$accordion-spacer-x: 0;
$accordion-btn-y: 0.5rem;
$accordion-btn-x: 1.5rem;
$accordion-paragraph-margin: 0;

//--------------------------------
//  MODALS
//--------------------------------

$modal-border-top: none;
$modal-header-border-width: 0;
$modal-header-border-color: transparent;

//--------------------------------
//  TOOLTIPS
//--------------------------------
$tooltip-color: $body-color;
$tooltip-bg: $white;
$tooltip-max-width: 300px;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 22px;
$tooltip-opacity: 1;
$tooltip-arrow-width: 35px;
$tooltip-arrow-height: 19px;

//--------------------------------
//  TABS
//--------------------------------

$nav-tabs-link-color: $gray-600;
$nav-tabs-link-active-color: $nav-tabs-link-color;
$nav-tabs-link-hover-border-color: $white;
$nav-link-disabled-color: $gray-400;
$nav-tabs-border-color: $primary;
$nav-tabs-border-bottom-width: 2px;

//--------------------------------
//  TABLES
//--------------------------------

$table-th-border-color: $primary;
$table-border-color: $gray-300;

//--------------------------------
//  QUICKVIEW COMPONENT
//--------------------------------

$quickview-font-size1: $font-size-base * 0.625;
$quickview-font-size2: $font-size-base * 1.5;
$quickview-font-size3: $font-size-price;
$quickview-font-size4: $h3-font-size;

//--------------------------------
//  ICONOGRAPHY
//--------------------------------

// Any icons without url() need to be used with the imagePathUtils function.

$custom-select-indicator: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIwIDE4Ij4gICAgPHBhdGggZmlsbD0iIzRBNEE0QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42MDUgNkw0IDYuNTUgMTAgMTJsNi01LjQ1LS42MDUtLjU1LTUuMzk4IDQuOTA1eiIvPjwvc3ZnPg==);
$svg-logo-desktop: url("../images/svg-icons/icon-logo-desktop.svg");
$svg-logo-tablet: url("../images/svg-icons/icon-logo-tablet.svg");
$svg-logo-mobile: url("../images/svg-icons/icon-logo-mobile.svg");
$svg-close: url("../images/svg-icons/icon-close.svg");
$svg-arrow-down: url("../images/svg-icons/icon-arrow-down.svg");
$svg-arrow-left: url("../images/svg-icons/icon-arrow-left.svg");
$svg-arrow-right: url("../images/svg-icons/icon-arrow-right.svg");
$svg-arrow-up: url("../images/svg-icons/icon-arrow-up.svg");
$svg-arrow-up-gold: url("../images/svg-icons/icon-arrow-up-gold.svg");
$svg-menu: url("../images/svg-icons/icon-menu.svg");
$svg-minus: url("../images/svg-icons/icon-minus.svg");
$svg-plus: url("../images/svg-icons/icon-plus.svg");
$svg-minus-white: url("../images/svg-icons/icon-minus-white.svg");
$svg-plus-white: url("../images/svg-icons/icon-plus-white.svg");
$svg-tooltip: url("../images/svg-icons/icon-tooltip.svg");
$svg-radio-button-unselected: url("../images/svg-icons/icon-radio-unselected.svg");
$svg-radio-button-selected: url("../images/svg-icons/icon-radio-selected.svg");
$svg-checkbox-unselected: url("../images/svg-icons/icon-checkbox-unselected.svg");
$svg-checkbox-selected: url("../images/svg-icons/icon-checkbox-selected.svg");
$svg-alert-success: url("../images/svg-icons/icon-alert-success.svg");
$svg-alert-info: url("../images/svg-icons/icon-alert-info.svg");
$svg-alert-warning: url("../images/svg-icons/icon-alert-warning.svg");
$svg-alert-danger: url("../images/svg-icons/icon-alert-danger.svg");
$svg-cart-white : url("../images/svg-icons/icon-cart-white.svg");
$svg-cart-default : url("../images/svg-icons/icon-cart-default.svg");
$svg-search-white : url("../images/svg-icons/icon-search-white.svg");
$svg-search-default : url("../images/svg-icons/icon-search-default.svg");
$svg-email : url("../images/svg-icons/icon-email.svg");
$svg-phone : url("../images/svg-icons/icon-phone.svg");
$svg-pin : url("../images/svg-icons/icon-pin.svg");
$svg-preferred-dealer : url("../images/svg-icons/icon-preferred-dealer.svg");
$svg-prop-warning : "../images/svg-icons/icon-prop-warning.svg";
$svg-video : "../images/svg-icons/icon-video.svg";
$close-icon-x: url("../images/svg-icons/icn-close.svg");
$checkout-svg-radio-button-unselected: url("../images/svg-icons/checkout-radio-btn-unchecked.svg");
$checkout-svg-radio-button-selected: url("../images/svg-icons/checkout-radio-btn-checked.svg");
$checkout-svg-checkbox-unselected: url("../images/svg-icons/checkout-check-box-unchecked.svg");
$checkout-svg-checkbox-selected: url("../images/svg-icons/checkout-check-box-checked.svg");
$checkout-svg-checkbox-selectArrow: url("../images/svg-icons/arrow-down-24.svg");
$checkout-credit-front: url("../images/svg-icons/checkout-cc-front.svg");
$checkout-credit-back: url("../images/svg-icons/checkout-cc-back.svg");

//--------------------------------
//  SLICK CAROUSEL
//--------------------------------

$slick-dot-color: $gray-200;
$slick-dot-color-active: $gray-500;
$slick-opacity-default: 1;
$slick-opacity-not-active: 1;
$slick-arrow-bg-color: $trans;

//--------------------------------
//  TRANSITIONS
//--------------------------------

$slick-arrow-transition: all 0.1s ease;
$btn-primary-transition: background 0.2s ease;
$btn-transition: all 0.2s ease;
$nav-link-transition: width 0.3s ease;
$tr-transition: background-color 0.1s ease;

//--------------------------------
//  Z-INDEX
//--------------------------------
$zindex-alert: 1080;
$z-index-toast: 1100 !default;
$z-index-modal: 1050 !default;
$z-index-modal-backdrop: 1000 !default;
$z-index-header-fixed: 400 !default;
$z-index-header-promo-banner: 350 !default;
$z-index-header: 300 !default;
$z-index-refinement-drawer: 250 !default;
$z-index-pdp-sticky-add-to-cart: 200 !default;
$z-index-sticky-footer: 100 !default;
$z-index-wishlist-icon: 50 !default;
$z-index-consent-drawer: 2147483601 !default; //This is set so high due to integrations like FreshChat that use z-index of 2147483600

//--------------------------------
// ASPECT RATIOS
//--------------------------------
$aspect-ratio-square: calc(1 / 1 * 100%) !default;
$aspect-ratio-landscape: calc(1 / 1.5 * 100%) !default;
$aspect-ratio-portrait: calc(1.5 / 1 * 100%) !default;
$aspect-ratio-one-one: calc(1 / 1 * 100%) !default;
$aspect-ratio-sixteen-nine: calc(9 / 16 * 100%) !default;
$aspect-ratio-nine-sixteen: calc(16 / 9 * 100%) !default;
$aspect-ratio-four-three: calc(3 / 4 * 100%) !default;
$aspect-ratio-three-four: calc(4 / 3 * 100%) !default;
// horizontal versions used for enforcing horizontal width in hero videos
$aspect-ratio-one-one-horizontal: calc(1 / 1 * 100vh) !default;
$aspect-ratio-sixteen-nine-horizontal: calc(16 / 9 * 100vh) !default;
$aspect-ratio-nine-sixteen-horizontal: calc(9 / 16 * 100vh) !default;
$aspect-ratio-four-three-horizontal: calc(4 / 3 * 100vh) !default;
$aspect-ratio-three-four-horizontal: calc(3 / 4 * 100vh) !default;
$aspect-ratio-five-twelve-horizontal: calc(5 / 12 * 100%) !default;

//--------------------------------
// MISC
//--------------------------------
$transition-speed: 200ms !default; // This value should match the TransitionSpeed value in scripts/constants/SiteConstants.js
$hero-transition-speed-long: 1000ms !default;
$hero-transition-speed-medium: 600ms !default;
$hero-transition-speed-short: 200ms !default;
$base-font-size: 16px !default;
$base-line-height: 1.43 !default;
$base-font-color: $black !default;
$border-radius: 0 !default;
$border-color: $light-gray !default;
$page-background-color: $white !default;
$leading-lines-border-color: transparent !default;
$leading-lines-border-width: 0 !default;

//--------------------------------
// FONTS (setup is handled in utilities/_fonts.scss)
//--------------------------------
$primary-font: 'Roboto', sans-serif !default;
$secondary-font: Georgia, Times, 'Times New Roman', serif !default;
$tertiary-font: $primary-font !default;
$header-font: $primary-font !default;
$body-font: $primary-font !default;

$fonts: (
    primary: $primary-font,
    secondary: $secondary-font,
    tertiary: $tertiary-font,
    header: $header-font,
    body: $body-font
) !default;

//--------------------------------
// TEXT
//--------------------------------
// Stand Alone Text Defaults
$default-text-font: $primary-font !default;
$default-text-font-size: 13px !default;
$default-text-line-height: 1.4 !default;
$default-text-font-weight: normal !default;
$default-text-letter-spacing: 0.1em !default;

// Paragraphs
$paragraph-font-size: $default-text-font-size !default; // paragraph size on mobile
$paragraph-line-height: $default-text-line-height !default;
$paragraph-lg-font-size: $default-text-font-size !default; // paragraph size on desktop
$paragraph-lg-line-height: $default-text-line-height !default;
$paragraph-font-size-large: 16px !default; // large paragraph size on mobile
$paragraph-line-height-large: $default-text-line-height !default;
$paragraph-lg-font-size-large: $paragraph-font-size-large !default;  // large paragraph size on desktop
$paragraph-lg-line-height-large: $default-text-line-height !default;
$paragraph-font-size-small: $default-text-font-size !default; // small paragraph size on mobile
$paragraph-line-height-small: $default-text-line-height !default;
$paragraph-lg-font-size-small: $default-text-font-size !default; // small paragraph size on desktop
$paragraph-lg-line-height-small: $default-text-line-height !default;
$paragraph-letter-spacing: $default-text-letter-spacing !default;
$paragraph-bottom-margin: $spacer !default;

//--------------------------------
// MODALS
//--------------------------------
$modal-backdrop-background-color: rgba($black, 0.6) !default;
$modal-background-color: transparent !default;
$modal-border: none !default;
$modal-border-radius: $border-radius !default;
$modal-box-shadow: 0 2px 6px rgba($black, 0.1) !default;
$modal-max-width: 500px !default;
$modal-sm-max-width: 300px !default;
$modal-lg-max-width: 900px !default;
$modal-xl-max-width: 1140px !default;
$modal-margins-mobile: 8px !default;
$modal-margins-desktop: $spacer*2 auto !default;
// Header
$modal-header-padding: 10px 20px 9px !default;
$modal-header-background-color: $brand-secondary !default;
$modal-header-text-color: $white !default;
$modal-header-border-color: transparent !default;
$modal-header-title-size: 4 !default; // This number should be 1-6, corresponding with h1-h6 headers
$modal-header-close-icon-size: 14px !default;
// Body
$modal-body-padding: 20px !default;
$modal-body-background-color: $white !default;
$modal-body-text-color: $base-font-color !default;
// Footer
$modal-footer-padding: 0 20px 20px !default;
$modal-footer-background-color: $white !default;
$modal-footer-text-color: $base-font-color !default;
$modal-footer-border-color: transparent !default;

//--------------------------------
// HEADERS
//--------------------------------
// Mobile header values
$h1-font-family: $header-font !default;
$h1-font-size: 28px !default;
$h1-line-height: 1.14 !default;
$h1-font-weight: 700 !default;
$h1-letter-spacing: 0.01em !default;
$h1-text-transform: none !default;
$h1-font-style: normal !default;

$h2-font-family: $header-font !default;
$h2-font-size: 24px !default;
$h2-line-height: 1.17 !default;
$h2-font-weight: 700 !default;
$h2-letter-spacing: 0.01em !default;
$h2-text-transform: none !default;
$h2-font-style: normal !default;

$h3-font-family: $header-font !default;
$h3-font-size: 20px !default;
$h3-line-height: 1.2 !default;
$h3-font-weight: 400 !default;
$h3-letter-spacing: 0.01em !default;
$h3-text-transform: none !default;
$h3-font-style: normal !default;

$h4-font-family: $header-font !default;
$h4-font-size: 16px !default;
$h4-line-height: 1.25 !default;
$h4-font-weight: 400 !default;
$h4-letter-spacing: 0.01em !default;
$h4-text-transform: none !default;
$h4-font-style: normal !default;

$h5-font-family: $header-font !default;
$h5-font-size: 14px !default;
$h5-line-height: 1.25 !default;
$h5-font-weight: 400 !default;
$h5-letter-spacing: 0.01em !default;
$h5-text-transform: none !default;
$h5-font-style: normal !default;

$h6-font-family: $default-text-font !default;
$h6-font-size: $default-text-font-size !default;
$h6-line-height: $default-text-line-height !default;
$h6-font-weight: $default-text-font-weight !default;
$h6-letter-spacing: $default-text-letter-spacing !default;
$h6-text-transform: none !default;
$h6-font-style: normal !default;

// Medium desktop header values
$h1-md-font-size: $h1-font-size !default;
$h1-md-line-height: $h1-line-height !default;
$h1-md-font-weight: $h1-font-weight !default;
$h1-md-letter-spacing: $h1-letter-spacing !default;
$h1-md-text-transform: $h1-text-transform !default;
$h1-md-font-style: $h1-font-style !default;

$h2-md-font-size: $h2-font-size !default;
$h2-md-line-height: $h2-line-height !default;
$h2-md-font-weight: $h2-font-weight !default;
$h2-md-letter-spacing: $h2-letter-spacing !default;
$h2-md-text-transform: $h2-text-transform !default;
$h2-md-font-style: $h2-font-style !default;

$h3-md-font-size: $h3-font-size !default;
$h3-md-line-height: $h3-line-height !default;
$h3-md-font-weight: $h3-font-weight !default;
$h3-md-letter-spacing: $h3-letter-spacing !default;
$h3-md-text-transform: $h3-text-transform !default;
$h3-md-font-style: $h3-font-style !default;

$h4-md-font-size: $h4-font-size !default;
$h4-md-line-height: $h4-line-height !default;
$h4-md-font-weight: $h4-font-weight !default;
$h4-md-letter-spacing: $h4-letter-spacing !default;
$h4-md-text-transform: $h4-text-transform !default;
$h4-md-font-style: $h4-font-style !default;

$h5-md-font-size: $h5-font-size !default;
$h5-md-line-height: $h5-line-height !default;
$h5-md-font-weight: $h5-font-weight !default;
$h5-md-letter-spacing: $h5-letter-spacing !default;
$h5-md-text-transform: $h5-text-transform !default;
$h5-md-font-style: $h5-font-style !default;

$h6-md-font-size: $h6-font-size !default;
$h6-md-line-height: $h6-line-height !default;
$h6-md-font-weight: $h6-font-weight !default;
$h6-md-letter-spacing: $h6-letter-spacing !default;
$h6-md-text-transform: $h6-text-transform !default;
$h6-md-font-style: $h6-font-style !default;

// Large desktop header values
$h1-lg-font-size: 54px !default;
$h1-lg-line-height: 1.11 !default;
$h1-lg-font-weight: $h1-font-weight !default;
$h1-lg-letter-spacing: $h1-letter-spacing !default;
$h1-lg-text-transform: $h1-text-transform !default;
$h1-lg-font-style: $h1-font-style !default;

$h2-lg-font-size: 40px !default;
$h2-lg-line-height: 1.15 !default;
$h2-lg-font-weight: $h2-font-weight !default;
$h2-lg-letter-spacing: $h2-letter-spacing !default;
$h2-lg-text-transform: $h2-text-transform !default;
$h2-lg-font-style: $h2-font-style !default;

$h3-lg-font-size: 24px !default;
$h3-lg-line-height: 1.17 !default;
$h3-lg-font-weight: $h3-font-weight !default;
$h3-lg-letter-spacing: $h3-letter-spacing !default;
$h3-lg-text-transform: $h3-text-transform !default;
$h3-lg-font-style: $h3-font-style !default;

$h4-lg-font-size: 20px !default;
$h4-lg-line-height: 1.2 !default;
$h4-lg-font-weight: $h4-font-weight !default;
$h4-lg-letter-spacing: $h4-letter-spacing !default;
$h4-lg-text-transform: $h4-text-transform !default;
$h4-lg-font-style: $h4-font-style !default;

$h5-lg-font-size: 16px !default;
$h5-lg-line-height: 1.2 !default;
$h5-lg-font-weight: $h5-font-weight !default;
$h5-lg-letter-spacing: $h5-letter-spacing !default;
$h5-lg-text-transform: $h5-text-transform !default;
$h5-lg-font-style: $h5-font-style !default;

$h6-lg-font-size: $h6-font-size !default;
$h6-lg-line-height: $h6-line-height !default;
$h6-lg-font-weight: $h6-font-weight !default;
$h6-lg-letter-spacing: $h6-letter-spacing !default;
$h6-lg-text-transform: $h6-text-transform !default;
$h6-lg-font-style: $h6-font-style !default;

$headers: (
    h1 $h1-font-family $h1-font-size $h1-line-height $h1-font-weight $h1-letter-spacing $h1-text-transform $h1-font-style $h1-md-font-size $h1-md-line-height
    $h1-md-font-weight $h1-md-letter-spacing $h1-md-text-transform $h1-md-font-style $h1-lg-font-size $h1-lg-line-height
    $h1-lg-font-weight $h1-lg-letter-spacing $h1-lg-text-transform $h1-lg-font-style,
    h2 $h2-font-family $h2-font-size $h1-line-height $h2-font-weight $h2-letter-spacing $h2-text-transform $h2-font-style $h2-md-font-size $h2-md-line-height
    $h2-md-font-weight $h2-md-letter-spacing $h2-md-text-transform $h2-md-font-style $h2-lg-font-size $h2-lg-line-height
    $h2-lg-font-weight $h2-lg-letter-spacing $h2-lg-text-transform $h2-lg-font-style,
    h3 $h3-font-family $h3-font-size $h3-line-height $h3-font-weight $h3-letter-spacing $h3-text-transform $h3-font-style $h3-md-font-size $h3-md-line-height
    $h3-md-font-weight $h3-md-letter-spacing $h3-md-text-transform $h3-md-font-style $h3-lg-font-size $h3-lg-line-height
    $h3-lg-font-weight $h3-lg-letter-spacing $h3-lg-text-transform $h3-lg-font-style,
    h4 $h4-font-family $h4-font-size $h4-line-height $h4-font-weight $h4-letter-spacing $h4-text-transform $h4-font-style $h4-md-font-size $h4-md-line-height
    $h4-md-font-weight $h4-md-letter-spacing $h4-md-text-transform $h4-md-font-style $h4-lg-font-size $h4-lg-line-height
    $h4-lg-font-weight $h4-lg-letter-spacing $h4-lg-text-transform $h4-lg-font-style,
    h5 $h5-font-family $h5-font-size $h5-line-height $h5-font-weight $h5-letter-spacing $h5-text-transform $h5-font-style $h5-md-font-size $h5-md-line-height
    $h5-md-font-weight $h5-md-letter-spacing $h5-md-text-transform $h5-md-font-style $h5-lg-font-size $h5-lg-line-height
    $h5-lg-font-weight $h5-lg-letter-spacing $h5-lg-text-transform $h5-lg-font-style,
    h6 $h6-font-family $h6-font-size $h6-line-height $h6-font-weight $h6-letter-spacing $h6-text-transform $h6-font-style $h6-md-font-size $h6-md-line-height
    $h6-md-font-weight $h6-md-letter-spacing $h6-md-text-transform $h6-md-font-style $h6-lg-font-size $h6-lg-line-height
    $h6-lg-font-weight $h6-lg-letter-spacing $h6-lg-text-transform $h6-lg-font-style
) !default;

//--------------------------------
// TABS
//--------------------------------
$tab-font: 14px $primary-font !default;
$tab-text-transform: none !default;
$tab-font-weight: bold !default;
$tab-letter-spacing: 0.05em !default;
$tab-border-color: $light-gray !default;
$tab-border-width: 1px !default;
$tab-border-radius-top-left: $border-radius !default;
$tab-border-radius-top-right: $border-radius !default;
$tab-border-radius-bottom-left: 0 !default;
$tab-border-radius-bottom-right: 0 !default;
$tab-padding-top: 15px !default;
$tab-padding-right: $spacer !default;
$tab-padding-bottom: 11px !default;
$tab-padding-left: $spacer !default;
$tab-text-decoration: none !default;
$tab-background: transparent !default;
$tab-color: $dark-gray !default;
$tab-border-top: none !default;
$tab-border-right: none !default;
$tab-border-bottom: $tab-border-width solid $tab-border-color !default;
$tab-border-left: none !default;
// hover state
$tab-text-decoration-hover: none !default;
$tab-background-hover: transparent !default;
$tab-color-hover: $brand-primary !default;
$tab-border-top-hover: none !default;
$tab-border-right-hover: none !default;
$tab-border-bottom-hover: $tab-border-width solid $tab-border-color !default;
$tab-border-left-hover: none !default;
// active state
$tab-text-decoration-active: none !default;
$tab-background-active: transparent !default;
$tab-color-active: $brand-primary !default;
$tab-border-top-active: $tab-border-width solid $tab-border-color !default;
$tab-border-right-active: $tab-border-width solid $tab-border-color !default;
$tab-border-bottom-active: none !default;
$tab-border-left-active: $tab-border-width solid $tab-border-color !default;

// Link style tabs
$link-tab-font: $tab-font !default;
$link-tab-text-transform: $tab-text-transform !default;
$link-tab-text-decoration: underline !default;
$link-tab-font-weight: normal !default;
$link-tab-letter-spacing: $tab-letter-spacing !default;
$link-tab-color: $link-color !default;
$link-tab-padding-top: $spacer/2 !default;
$link-tab-padding-right: $spacer !default;
$link-tab-padding-bottom: $spacer/2 !default;
$link-tab-padding-left: $spacer !default;
// hover state
$link-tab-text-transform-hover: $link-tab-text-transform !default;
$link-tab-text-decoration-hover: underline !default;
$link-tab-font-weight-hover: $link-tab-font-weight !default;
$link-tab-color-hover: $link-color-hover !default;
// active state
$link-tab-text-transform-active: $link-tab-text-transform !default;
$link-tab-text-decoration-active: none !default;
$link-tab-font-weight-active: bold !default;
$link-tab-color-active: $link-color !default;

// Pills style tabs
$pills-tab-font: $tab-font !default;
$pills-tab-text-transform: $tab-text-transform !default;
$pills-tab-text-decoration: $tab-text-decoration !default;
$pills-tab-font-weight: $tab-font-weight !default;
$pills-tab-letter-spacing: $tab-letter-spacing !default;
$pills-tab-color: $tab-color !default;
$pills-tab-background: transparent !default;
$pills-tab-padding-top: $spacer/2 !default;
$pills-tab-padding-right: $spacer !default;
$pills-tab-padding-bottom: $spacer/2 !default;
$pills-tab-padding-left: $spacer !default;
$pills-tab-border-radius: 6px;
// hover state
$pills-tab-text-transform-hover: $pills-tab-text-transform !default;
$pills-tab-text-decoration-hover: $pills-tab-text-decoration !default;
$pills-tab-font-weight-hover: $pills-tab-font-weight !default;
$pills-tab-color-hover: $tab-color-hover !default;
$pills-tab-background-hover: $pills-tab-background !default;
// active state
$pills-tab-text-transform-active: $pills-tab-text-transform !default;
$pills-tab-text-decoration-active: $pills-tab-text-decoration !default;
$pills-tab-font-weight-active: bold !default;
$pills-tab-color-active: $white !default;
$pills-tab-background-active: #3091E7 !default;

//--------------------------------
// DROPDOWNS
//--------------------------------
$dropdown-menu-border: 1px solid $light-gray !default;
$dropdown-menu-border-radius: $border-radius !default;
$dropdown-menu-shadow: 0 2px 2px rgba($black, 0.2) !default;
$dropdown-megamenu-border: none !default;
$dropdown-icon: ''; // $icon-expand !default;
$dropdown-icon-size: 10px !default;
$dropdown-item-color: $link-color !default;
$dropdown-item-border-color: $light-gray !default;
$dropdown-item-hover-background: $light-gray !default;
$dropdown-item-hover-color: $link-color-hover !default;
$dropdown-item-padding: $spacer !default;
$dropdown-fadein-speed: $transition-speed !default; // set to zero to disable fade effect
$dropdown-font-size: 14px !default;

/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
//--------------------------------
// NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
//--------------------------------
$nav-link-font-family: $primary-font !default;
$nav-link-font-size: 14px !default;
$nav-link-font-weight: 500 !default;
$nav-link-line-height: 20px !default;
$nav-link-letter-spacing: 0.03em !default;
$nav-link-text-transform: none !default;
$nav-link-color: $brand-primary !default;
$nav-link-text-decoration: none !default;
$nav-link-text-decoration-hover: none !default;
$nav-sub-link-font-size: 14px !default;

//--------------------------------
// HEADER
//--------------------------------
// Promo bar
$header-promo-bar-background: $black !default;
$header-promo-bar-color: $white !default;
$header-promo-bar-padding-top: 10px !default;
$header-promo-bar-padding-bottom: 10px !default;
$header-promo-bar-font-size: 11px !default;
$header-promo-bar-link-font-size: 11px !default;
$header-promo-bar-line-height: 20px !default;

// Main header
$header-background: $white !default;
$header-fixed-shadow: $dropdown-menu-shadow !default;
$header-height: 50px !default;
$header-height-md: 70px !default;
$header-height-lg: 64px !default;
$header-logo-image: '../images/logo.png' !default;
$header-logo-image-fixed: $header-logo-image !default;
$header-logo-image-md: $header-logo-image !default;
$header-logo-image-md-fixed: $header-logo-image !default;
$header-logo-image-lg: $header-logo-image !default;
$header-logo-image-lg-fixed: $header-logo-image !default;
$header-logo-height: 100% !default;
$header-logo-width: 160px !default;
$header-logo-width-md: 212px !default;
$header-logo-width-lg: 212px !default;
$header-logo-transparent-nav-brightness: 0 !default;
$header-logo-transparent-nav-invert: 1 !default;
$header-transition-speed: 400ms !default;
$header-link-color: $nav-link-color !default;
$header-link-color-hover: $black !default;
$header-link-highlight-color: $fire-red !default;
$header-link-highlight-color-hover: $brand-secondary !default;
$header-link-border-color: transparent !default;
$header-link-font: $nav-link-font-family !default;
$header-link-font-size: 14px !default;
$header-link-top-level-font-size: $header-link-font-size !default;
$header-link-font-weight: $nav-link-font-weight !default;
$header-link-line-height: $nav-link-line-height !default;
$header-link-letter-spacing: 0.05em !default;
$header-link-underline-position-top: 100% !default;
$header-link-underline-color: $brand-primary !default; // hover state for left-align logo desktop view only, set to transparent to disable
$header-link-underline-height: 1px !default;
$header-link-text-transform: $nav-link-text-transform !default;
$header-link-font-size-logo-center: 14px !default;
$header-search-desktop-width: 125px !default;
$header-search-desktop-width-focus: 200px !default;
$header-search-desktop-expanded-border-color: $light-gray !default;
$header-search-desktop-border-width: 0 1px 0 1px !default;
$header-search-icon-font-size: 22px !default;
$header-search-icon-color: $header-link-color !default;
$header-search-icon-color-hover: $brand-secondary !default;
$header-search-text-font-size: 16px !default; //minimum of 16px required to prevent zooming on iphones
$header-minicart-count-background: $brand-light !default;
$header-minicart-count-color: $white !default;
$header-link-dropdown-text-align: center !default; // used for account + support
$header-link-dropdown-menu-border: none !default;
$header-link-dropdown-menu-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08) !default;
$header-link-dropdown-menu-padding: 16px 0px !default;
$header-link-dropdown-menu-font-size: 11px !default;
$header-link-dropdown-menu-li-padding: 8px !default;

$header-transparent-background: transparent !default;
$header-transparent-border-bottom: transparent !default;
$header-transparent-link-color: transparent !default;
$header-transparent-link-color-hover: transparent !default;
$header-transparent-search-desktop-expanded-border-color: transparent !default;

$header-transparent-text-color-light: $white !default;
$header-transparent-text-color-dark: $black !default;
$header-transparent-minicart-count-background-color-light: $black !default;
$header-transparent-minicart-count-background-color-dark: $fire-red !default;

// Checkout header
$header-checkout-locale-display: block !default;
$header-checkout-account-display: block !default;
$header-checkout-support-display: block !default;
$header-checkout-minicart-display: block !default;
// Main navbar
$header-navbar-border-bottom-width: 1px !default;
$header-navbar-border-bottom-color: $border-color !default;
$header-navbar-link-spacing: 36px !default; // space between top-level menu items
$header-navbar-dropdown-icon: none !default; // desktop only - mobile falls back to standard dropdown icon ($dropdown-icon)
$header-navbar-dropdown-icon-size: 10px !default;
$header-navbar-account-icon: ''; // $icon-account !default;
$header-navbar-account-icon-size: 22px !default;
$header-navbar-account-font-size: 0px !default; // set to 0 if you only want an icon to show for account desktop only
$header-navbar-account-margin: 8px 0 0 !default;
$header-navbar-background: $brand-secondary !default; // center-logo desktop layout only
$header-navbar-color: $white !default; // center-logo desktop layout only
$header-navbar-background-hover: $white !default; // center-logo desktop layout only
$header-navbar-color-hover: $brand-secondary !default; // center-logo desktop layout only
$header-navbar-toggler-icon-size: 18px !default; // hamburger menu icon size, mobile only
$header-navbar-highlight-color: $brand-primary !default; // center-logo desktop layout only
$header-navbar-highlight-background-hover: $white !default; // center-logo desktop layout only
$header-navbar-highlight-color-hover: $brand-secondary !default; // center-logo desktop layout only
// Megamenu
$megamenu-background: $white !default;
$megamenu-box-shadow: $header-fixed-shadow !default;
$megamenu-padding-top-bottom: 30px !default;
$megamenu-padding: $megamenu-padding-top-bottom $spacer !default;
$megamenu-header-font: $header-link-font !default;
$megamenu-header-font-size: $nav-link-font-size !default;
$megamenu-header-font-weight: bold !default;
$megamenu-header-line-height: $nav-link-line-height !default;
$megamenu-header-text-transform: none !default;
$megamenu-header-color: $brand-primary !default;
$megamenu-header-color-hover: $brand-primary !default;
$megamenu-title-margin-bottom: 30px !default;
$megamenu-title-text-decoration: none !default;
$megamenu-secondary-color: $brand-secondary !default;
$megamenu-secondary-color-hover: $brand-primary !default;
$megamenu-secondary-font: $body-font !default;
$megamenu-secondary-font-size: $nav-sub-link-font-size !default;
$megamenu-secondary-font-weight: 300 !default;
$megamenu-secondary-text-transform: none !default;
$megamenu-secondary-indentation: 2px !default;
$megamenu-link-padding-top: 10px !default;
$megamenu-link-padding-bottom: $megamenu-link-padding-top !default;
$megamenu-link-padding-left: $spacer !default;
$megamenu-link-padding-right: $megamenu-link-padding-left !default;
$megamenu-asset-link-list-font-family: $megamenu-header-font !default;
$megamenu-asset-link-list-font-size: 18px !default;
$megamenu-asset-link-list-text-transform: none !default;
$megamenu-asset-link-list-padding: $megamenu-link-padding-top $megamenu-link-padding-right $megamenu-link-padding-bottom $megamenu-link-padding-left !default;
$megamenu-asset-link-list-text-decoration: none !default;
//Megamenu Left nav — Styles for mega-left megamenu only
$megamenu-left-nav-width: 15% !default;
$megamenu-left-nav-border-width: 2px !default;
$megamenu-left-nav-border-color: $white-smoke !default;
$megamenu-left-nav-border-color-hover: $brand-secondary !default;
$megamenu-left-nav-font-size: $megamenu-asset-link-list-font-size !default;
$megamenu-left-nav-text-transform: uppercase !default;
$megamenu-left-nav-tertiary-title-font-size: $megamenu-header-font-size !default;
$megamenu-left-nav-tertiary-title-font-weight: $megamenu-header-font-weight !default;
$megamenu-left-nav-tertiary-title-text-transform: $megamenu-header-text-transform !default;

//Subcategory styles
$megamenu-subcategory-max-height: 500px;

//Megamenu Offset View All Link Styles - applies to different level on mega-left vs mega-standard, but refers to the "view all link" that is positioned at the bottom of the dropdown (level 2 or level 3)
$view-all-link-font-weight: bold;
$view-all-link-text-transform: uppercase;

// Search suggestions
$header-suggestions-results-width: 350px !default;
$header-suggestions-image-size: 30px !default;
$header-suggestions-header-font: $header-font !default;
$header-suggestions-header-font-size: 15px !default;
$header-suggestions-header-color: $black !default;
$header-suggestions-header-text-transform: none !default;
$header-suggestions-border: $dropdown-menu-border !default;
$header-suggestions-item-font: $body-font !default;
$header-suggestions-item-font-size: $default-text-font-size !default;
$header-suggestions-item-category-parent-color: $gray !default;

//--------------------------------
//  PAGE-LEVEL SETTINGS
//--------------------------------
$page-header-padding-top: $spacer !default;
$page-header-padding-bottom: $spacer !default;
$page-header-padding-top-md: 44px !default;
$page-header-padding-bottom-md: 44px !default;

//--------------------------------
//  PRODUCT
//--------------------------------
// Price
$product-strike-through-price-color: $dark-gray !default;

// Ratings
$product-rating-star-icon-font: ''; // $icomoon-font-family !default;
$product-rating-star-color: $black !default;

// PRODUCT TILES (product grid, recommended sliders)
$product-tile-grid-margin-bottom: 45px !default;
$product-tile-image-hover-shadow-color: $black !default;
$product-tile-image-hover-shadow-size: 2px !default;
$product-tile-image-hover-shadow: inset 0px -#{$product-tile-image-hover-shadow-size} 0px $product-tile-image-hover-shadow-color !default;
$product-tile-image-margin-bottom: 10px !default;
$product-tile-swatch-size: 18px !default;
$product-tile-swatch-spacing: 8px !default;
$product-tile-swatch-thumbnail-size: 30px !default;
$product-tile-swatch-thumbnail-spacing: 1px !default;
$product-tile-swatch-margin-bottom: 10px !default;
$product-tile-swatch-color-count-color: $brand-secondary !default;
$product-tile-title-font: $header-font !default;
$product-tile-title-font-size: 13px !default;
$product-tile-title-font-weight: normal !default;
$product-tile-title-text-decoration: none !default;
$product-tile-title-text-decoration-hover: none !default;
$product-tile-title-color: $base-font-color !default;
$product-tile-title-color-hover: $base-font-color !default;
$product-tile-title-margin-bottom: 2px !default;
$product-tile-price-font-size: 13px !default;
$product-tile-price-color: $base-font-color !default;
$product-tile-price-strike-through-color: $product-strike-through-price-color !default;
$product-tile-price-margin-bottom: 10px !default;
$product-tile-sale-color: $sale !default;
$product-tile-rating-margin-bottom: 20px !default;
$product-tile-rating-star-color: $product-rating-star-color !default;
$product-tile-rating-star-size: 11px !default;
$product-tile-reviews-link-display: none !default;
$product-tile-reviews-link-font-size: 11px !default;
$product-tile-reviews-link-color: $light-gray !default;
$product-tile-reviews-link-color-hover: $dark-gray !default;
$product-tile-quickview-link-icon: ''; // $icon-plus !default;
$product-tile-quickview-link-size: 30px !default; // Quickview link appears on desktop only
$product-tile-quickview-link-icon-size: 14px !default;
$product-tile-quickview-link-color: rgba($black, 0.7) !default;
$product-tile-quickview-link-background-color: rgba($white, 0.7) !default;
$product-tile-quickview-link-box-shadow: none !default;
$product-tile-wishlist-link-icon: ''; // $icon-favorite !default;
$product-tile-wishlist-link-icon-selected: ''; // $icon-favorite-selected !default;
$product-tile-wishlist-link-size: 30px !default;
$product-tile-wishlist-link-icon-size: 14px !default;
$product-tile-wishlist-link-color: rgba($black, 0.7) !default;
$product-tile-wishlist-link-background-color: rgba($white, 0.7) !default;
$product-tile-wishlist-link-box-shadow: none !default;
$product-tile-wishlist-link-border-radius: 50% !default;

// PRODUCT TILES (badges)
$product-tile-badges-margin: 1px !default;
$product-tile-badge-padding: 2px 3px !default;
$product-tile-badge-padding-lg: 5px 6px !default;
$product-tile-badge-margin-bottom: 1px !default;
$product-tile-badge-font-weight: bold !default;
$product-tile-badge-font-size: 10px !default;
$product-tile-badge-font-size-lg: $default-text-font-size !default;
$product-tile-badge-text-align: left !default;
$product-tile-badge-line-height: $default-text-line-height !default;
$product-tile-badge-font-family: $body-font !default;
$product-tile-badge-background: $white !default;
$product-tile-badge-color: $base-font-color !default;
$product-tile-badge-border-radius: $border-radius !default;
$product-tile-badge-border-width: 1px !default;
$product-tile-badge-border-style: solid !default;
$product-tile-badge-border-color: transparent !default;

//--------------------------------
//  HERO ASSETS
//--------------------------------
$hero-title-size: 1 !default; // This number should be 1-6, corresponding with h1-h6 headers
$hero-subtitle-font: $header-font !default;
$hero-subtitle-font-size: $paragraph-font-size-small !default;
$hero-subtitle-text-transform: none !default;
$hero-textblock-outer-padding: $spacer !default;
$hero-textblock-outer-padding-md: $spacer !default;
$hero-textblock-outer-padding-lg: 50px !default;
$hero-textblock-inner-padding: $spacer !default;
$hero-textblock-inner-padding-md: $spacer !default;
$hero-textblock-inner-padding-lg: $spacer !default;
$hero-textblock-background-opacity: 0.7 !default;
$hero-textblock-border-width: 1px !default;
$hero-textblock-items-vertical-spacing: $spacer !default;
$hero-min-height-short: 150px !default;
$hero-min-height-short-md: 80px !default;
$hero-min-height-short-lg: 80px !default;
$hero-min-height-medium: 250px !default;
$hero-min-height-medium-md: 300px !default;
$hero-min-height-medium-lg: 350px !default;
$hero-min-height-tall: 600px !default;
$hero-min-height-tall-md: 650px !default;
$hero-min-height-tall-lg: 700px !default;
$hero-colortheme-dark: $black !default;
$hero-colortheme-light: $white !default;
$hero-video-aspect-ratio: $aspect-ratio-sixteen-nine !default;
$hero-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal !default;
$hero-animation-delay: 0ms !default; // This value should match the ComponentAnimationDelay value in scripts/constants/SiteConstants.js

//--------------------------------
//  CATEGORY TILE ASSETS
//--------------------------------
$category-tile-border-radius: $border-radius !default;
$category-tile-primary-font-size: $paragraph-font-size-large !default;
$category-tile-secondary-font-size: $paragraph-lg-font-size !default;
$category-tile-text-shadow: 0 0 2px rgba($black, 0.6) !default; // When text is in image area only
$category-tile-text-background: rgba($black, 0.3) !default; // When text is in image area only
$category-tile-text-inside-color: $white !default; // When text is in image area only
$category-tile-hover-zoom-amount: 1.2 !default; // Only applicable if zoom is enabled
$category-tile-aspect-ratio-square: $aspect-ratio-square !default;
$category-tile-aspect-ratio-landscape: $aspect-ratio-landscape !default;
$category-tile-aspect-ratio-portrait: $aspect-ratio-portrait !default;
$category-tile-video-aspect-ratio: $aspect-ratio-sixteen-nine !default;
$category-tile-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal !default;

//--------------------------------
//  IMAGE TEXT BLOCK ASSETS
//--------------------------------
$image-text-block-border-radius: $category-tile-border-radius !default;
$image-text-block-subtitle-font-size: $paragraph-font-size-small !default;
$image-text-block-primary-font-size: $category-tile-primary-font-size !default;
$image-text-block-secondary-font-size: $category-tile-secondary-font-size !default;
$image-text-block-text-shadow: $category-tile-text-shadow !default; // When text is in image area only
$image-text-block-text-background: $category-tile-text-background !default; // When text is in image area only
$image-text-block-text-inside-color: $category-tile-text-inside-color !default; // When text is in image area only
$image-text-block-hover-zoom-amount: $category-tile-hover-zoom-amount !default; // Only applicable if zoom is enabled
$image-text-block-aspect-ratio-square: $category-tile-aspect-ratio-square !default;
$image-text-block-aspect-ratio-landscape: $category-tile-aspect-ratio-landscape !default;
$image-text-block-aspect-ratio-portrait: $category-tile-aspect-ratio-portrait !default;
$image-text-block-video-aspect-ratio: $aspect-ratio-sixteen-nine !default;
$image-text-block-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal !default;

//--------------------------------
//  PHOTO HOTSPOTS ASSETS
//--------------------------------
$photo-hotspot-size: 20px !default;
$photo-hotspot-background: rgba($white, 0.6) !default;
$photo-hotspot-background-dark: rgba($black, 0.3) !default;
$photo-hotspot-border-color-inner: transparent !default;
$photo-hotspot-border-color-inner-dark: transparent !default;
$photo-hotspot-border-size-inner: 3px !default;
$photo-hotspot-border-color-outer: $white !default;
$photo-hotspot-border-color-outer-dark: $black !default;
$photo-hotspot-border-size-outer: 3px !default;
$photo-hotspot-border-radius: 50% !default;
$photo-hotspot-icon: ''; // $icon-plus !default;
$photo-hotspot-icon-color: $white !default;
$photo-hotspot-icon-color-dark: $black !default;
$photo-hotspot-icon-size: 10px !default;
$photo-hotspot-shadow: none !default;
$photo-hotspot-size-hover: 40px !default;
$photo-hotspot-background-hover: rgba($white, 0.8) !default;
$photo-hotspot-background-hover-dark: rgba($black, 0.5) !default;
$photo-hotspot-border-color-inner-hover: transparent !default;
$photo-hotspot-border-color-inner-hover-dark: transparent !default;
$photo-hotspot-border-size-inner-hover: 3px !default;
$photo-hotspot-border-color-outer-hover: $white !default;
$photo-hotspot-border-color-outer-hover-dark: $black !default;
$photo-hotspot-border-size-outer-hover: 3px !default;
$photo-hotspot-hover-icon: ''; // plus !default;
$photo-hotspot-tooltip-max-width: 300px !default;
$photo-hotspot-text-spacing: 8px !default;
$photo-hotspot-text-product-divider: 1px solid $border-color !default;
$photo-hotspot-attribute-spacing: 8px !default;
$photo-hotspot-tooltip-image-size: 75px !default;
$photo-hotspot-tooltip-product-name-font-size: $default-text-font-size !default;
$photo-hotspot-tooltip-product-name-color: $black !default;
$photo-hotspot-tooltip-product-name-text-overflow: ellipsis !default;
$photo-hotspot-tooltip-product-name-whitespace: nowrap !default;
$photo-hotspot-tooltip-price-font-size: $default-text-font-size !default;
$photo-hotspot-tooltip-price-color: $dark-gray !default;
$photo-hotspot-tooltip-message-display: block !default;
$photo-hotspot-tooltip-message-font-size: 10px !default;
$photo-hotspot-tooltip-message-text-transform: uppercase !default;
$photo-hotspot-tooltip-message-color: $dark-gray !default;
$photo-hotspot-tooltip-sale-price-color: $sale !default;

//--------------------------------
//  VIDEO ASSETS
//--------------------------------
$video-play-icon: ''; // play-hollow !default;
$video-play-icon-color: $white !default;
$video-play-icon-size: 50px !default;
$video-pause-icon: ''; // pause !default;
$video-pause-icon-color: $white !default;
$video-pause-icon-color-hover: $brand-secondary !default;
$video-pause-icon-size: 50px !default;
$video-modal-size: 'xl' !default; // sm, lg, or xl

//--------------------------------
//  BLOG LANDING
//--------------------------------
$blog-nav-background-color: $single-fawn !default;
$blog-nav-border-color: $border-color !default;
$blog-nav-item-text-decoration: none !default;
$blog-nav-item-active-font-weight: bold !default;

//--------------------------------
//  BLOG DETAIL
//--------------------------------
$blog-detail-category-color: $dark-gray !default;
$blog-detail-date-color: $dark-gray !default;
$blog-detail-category-date-separator-color: $dark-gray !default;
$blog-detail-category-date-separator: '|' !default;
$blog-detail-category-date-separator-spacer-left: 10px !default;
$blog-detail-category-date-separator-spacer-right: 10px !default;
$blog-detail-title-size: 2 !default; // This number should be 1-6, corresponding with h1-h6 headers
$blog-detail-title-margin-top: 10px !default;
$blog-detail-author-color: $dark-gray !default;
$blog-detail-author-margin-top: 10px !default;
$blog-detail-social-icons-border-top: none !default;
$blog-detail-social-icons-margin-top: 80px !default;
$blog-detail-social-icons-padding-top: 0 !default;
$blog-detail-social-icon-color: $link-color !default;
$blog-detail-social-icon-color-hover: $link-color-hover !default;
$blog-detail-social-icon-size: 30px !default;
$blog-detail-social-icon-spacing: 30px !default;
$blog-detail-footer-text-align: center !default;
$blog-detail-search-words-border-top: 1px solid $border-color !default;
$blog-detail-search-words-margin-top: 20px !default;
$blog-detail-search-words-padding-top: 20px !default;
$blog-detail-search-words-separator: ',' !default;
$blog-detail-search-words-spacer-left: 0 !default;
$blog-detail-search-words-spacer-right: 7px !default;

//--------------------------------
//  CONTENT TILES
//--------------------------------
$content-tile-image-aspect-ratio: $aspect-ratio-square !default;
$content-tile-image-zoom-amount: 1.2 !default; // set to 1 to turn zooming off
$content-tile-grid-margin-bottom: $product-tile-grid-margin-bottom !default;
$content-tile-image-hover-shadow-color: $product-tile-image-hover-shadow-color !default;
$content-tile-image-hover-shadow-size: $product-tile-image-hover-shadow-size !default;
$content-tile-image-hover-shadow: $product-tile-image-hover-shadow !default;
$content-tile-image-margin-bottom: $product-tile-image-margin-bottom !default;
$content-tile-category-font: $default-text-font !default;
$content-tile-category-font-size: $paragraph-font-size-small !default;
$content-tile-category-font-weight: $product-tile-title-font-weight !default;
$content-tile-category-text-decoration: $product-tile-title-text-decoration !default;
$content-tile-category-text-decoration-hover: $product-tile-title-text-decoration-hover !default;
$content-tile-category-color: $dark-gray !default;
$content-tile-category-color-hover: $dark-gray !default;
$content-tile-category-margin-bottom: 7px !default;
$content-tile-name-font: $header-font !default;
$content-tile-name-font-size: 18px !default;
$content-tile-name-font-weight: $product-tile-title-font-weight !default;
$content-tile-name-text-decoration: $product-tile-title-text-decoration !default;
$content-tile-name-text-decoration-hover: $product-tile-title-text-decoration-hover !default;
$content-tile-name-color: $product-tile-title-color !default;
$content-tile-name-color-hover: $product-tile-title-color-hover !default;
$content-tile-name-margin-bottom: 10px !default;
$content-tile-date-font: $header-font !default;
$content-tile-date-font-size: $paragraph-font-size-small !default;
$content-tile-date-font-weight: $product-tile-title-font-weight !default;
$content-tile-date-text-decoration: $product-tile-title-text-decoration !default;
$content-tile-date-text-decoration-hover: $product-tile-title-text-decoration-hover !default;
$content-tile-date-text-transform: uppercase !default;
$content-tile-date-color: $product-tile-title-color !default;
$content-tile-date-color-hover: $product-tile-title-color-hover !default;
$content-tile-date-margin-bottom: 7px !default;
$content-tile-description-font: $default-text-font !default;
$content-tile-description-font-size: $default-text-font-size !default;
$content-tile-description-font-weight: $default-text-font-weight !default;
$content-tile-description-text-decoration: none !default;
$content-tile-description-text-decoration-hover: none !default;
$content-tile-description-color: $base-font-color !default;
$content-tile-description-color-hover: $base-font-color !default;
