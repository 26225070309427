@import "../utilities/swatch";
@import "productCommon";
@import "quickView";
@import "./badges";
@import "../utilities/nth_of_type";

.Federal_review {
    .yotpo {
        .yotpo-bottomline .yotpo-icon-star,
        .yotpo-bottomline .yotpo-icon-half-star,
        .yotpo-bottomline .yotpo-icon-empty-star {
            color: #876841 !important;
        }

        .text-m {
            padding-left: 10px !important;
            color: #020202 !important;
        }

        .text-m:hover {
            color: #020202 !important;
        }

        .text-m {
            span {
                font-size: 13px;
                color: #020202;
                letter-spacing: 1px;
                font-weight: 600;
            }
        }
    }
}

.product-carousel {
    img {
        width: 100%;
        height: 100%;
    }
}

.grid-tile {
    height: 100%;
}

.product-tile {
    padding: 0 7px;
    height: 100%;
    margin-bottom: 0;

    .tile-body {
        padding-top: 7px;

        // swatches
        .color-swatches {
            margin-bottom: 11px;
        }

        // pricing
        .price {
            margin-bottom: 0;
            white-space: nowrap;

            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pc-promotion-message {
            color: $accent-2;
            margin-bottom: 4px;
        }

        .availability {
            margin-top: 0;
            text-align: right;
        }

        // links
        .pdp-link {
            margin-bottom: 5px;

            a {
                color: $body-color;
            }
        }

        .pid {
            color: $gray-500;
            margin-bottom: 17px;
        }

        .tile-info {
            margin-bottom: 12px;
        }

        // attributes
        .tile-attributes {
            padding-left: 0;
            margin: 0;

            li {
                list-style: none;
                margin-top: 0;

                span:first-child {
                    margin-right: 7px;
                }
            }
        }

        // Compare
        .compare label {
            margin-top: 5px;

            @include type-body();

            &::before {
                top: 1px;
            }
        }
    }

    .image-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 245px;
        padding: 0 10px;

        .quickview {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: auto;
            max-width: 130px;
            display: none;
            text-transform: uppercase;
        }

        &:hover {
            .quickview {
                display: block;
                text-decoration: none;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            width: 100%;
            text-align: center;

            .tile-image { // below fixes issue with squished images
                max-height: 100%;
                max-width: 100%;
                height: auto;
                width: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .swatches {
        a {
            text-decoration: none;
        }
    }

    span[data-attr-value="White"] .swatch-circle,
    span[data-attr-value="white"] .swatch-circle {
        border: $border;
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(0, $white);
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: $border;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}
