.shipping-discount,
.order-discount {
    color: $callout-message;
}

// .line-item-availability {
//     &.preorder-msg {
//         p {
//             font-family: $font-family-third;
//             font-size: $font-size-body;
//             letter-spacing: 0.5px;
//         }

//         .availability-label {
//             font-weight: $font-weight-bold;
//             line-height: 1;
//             margin-top: 10px;
//         }

//         .instock-expected-date {
//             margin: 10px 0;

//             .expected-availability,
//             .line-item-instock-date {
//                 font-weight: $font-weight-semi-bold;
//             }
//         }
//     }
// }

// .pre-order-description {
//     &.cart-preorder-description {
//         margin-left: 90px;
//         margin-top: 10px;
//     }

//     p {
//         font-family: $font-family-fourth;
//         font-size: $font-size-base;
//         color: $accent-2;
//     }
// }

.line-item-availability {
    &.preorder-msg {
        p {
            white-space: nowrap;
        }
    }
}

.saved-products-list {
    .line-item-price,
    .line-item-total-price {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }

    .page-title {
        margin-bottom: 1.25rem;

        @include media-breakpoint-down(md) {
            font-size: 1.75rem;
            margin-bottom: 1.75rem;
        }
    }

    .saved-items-count {
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
        }
    }

    .product-card-footer {
        .product-line-item-options {
            display: none;
        }
    }
}

.product-line-item-options {
    a {
        font-weight: 700;
    }
}

.login-require-error {
    all: unset;
    font-size: 0.875rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #e27252;
    cursor: default;
    padding: 1.25rem 0;
    height: auto !important;

    @include media-breakpoint-down (md) {
        text-align: center;
    }

    p {
        font-family: $font-family-third;

        a {
            font-family: $font-family-third;
            text-decoration: none;
        }

        span {
            @include media-breakpoint-down (md) {
                &::after {
                    display: block;
                    clear: both;
                    content: "";
                }
            }
        }
    }
}

.login-require-error {
    height: auto !important;
    background-color: $alert-yellow;

    p {
        a {
            color: $black;
        }
    }
}
