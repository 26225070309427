$spacing-values: (
    0, 2, 4, 5, 8, 10, 12, 14, 16, 18, 19, 20, 24, 26, 28, 30, 32, 36, 40, 43, 48, 55, 58, 60, 68, 70, 72, 80, 86, 100, 110, 120, 140, 150, 200, 250, 265
);

@each $value in $spacing-values {
    .height-#{$value} {
        height: #{$value}px;
    }

    .width-#{$value} {
        width: #{$value}px;
    }

    .padding-#{$value} {
        padding: #{$value}px;
    }

    .padding-y-#{$value} {
        padding: #{$value}px 0;
    }

    .padding-x-#{$value} {
        padding: 0 #{$value}px;
    }

    .padding-t-#{$value} {
        padding-top: #{$value}px;
    }

    .padding-r-#{$value} {
        padding-right: #{$value}px;
    }

    .padding-b-#{$value} {
        padding-bottom: #{$value}px;
    }

    .padding-l-#{$value} {
        padding-left: #{$value}px;
    }

    .margin-#{$value} {
        margin: #{$value}px;
    }

    .margin-y-#{$value} {
        margin: #{$value}px 0;
    }

    .margin-x-#{$value} {
        margin: 0 #{$value}px;
    }

    .margin-t-#{$value} {
        margin-top: #{$value}px;
    }

    .margin-r-#{$value} {
        margin-right: #{$value}px;
    }

    .margin-b-#{$value} {
        margin-bottom: #{$value}px;
    }

    .margin-l-#{$value} {
        margin-left: #{$value}px;
    }
}

@each $value in $spacing-values {
    @include media-breakpoint-down(sm) {
        .height-m-#{$value} {
            height: #{$value}px;
        }

        .width-m-#{$value} {
            width: #{$value}px;
        }

        .padding-m-#{$value} {
            padding: #{$value}px;
        }

        .padding-m-y-#{$value} {
            padding: #{$value}px 0;
        }

        .padding-m-x-#{$value} {
            padding: 0 #{$value}px;
        }

        .padding-m-t-#{$value} {
            padding-top: #{$value}px;
        }

        .padding-m-r-#{$value} {
            padding-right: #{$value}px;
        }

        .padding-m-b-#{$value} {
            padding-bottom: #{$value}px;
        }

        .padding-m-l-#{$value} {
            padding-left: #{$value}px;
        }

        .margin-m-#{$value} {
            margin: #{$value}px;
        }

        .margin-m-y-#{$value} {
            margin: #{$value}px 0;
        }

        .margin-m-x-#{$value} {
            margin: 0 #{$value}px;
        }

        .margin-m-t-#{$value} {
            margin-top: #{$value}px;
        }

        .margin-m-r-#{$value} {
            margin-right: #{$value}px;
        }

        .margin-m-b-#{$value} {
            margin-bottom: #{$value}px;
        }

        .margin-m-l-#{$value} {
            margin-left: #{$value}px;
        }
    }

    @include media-breakpoint-down(md) {
        .padding-lg-t-#{$value} {
            padding-top: #{$value}px;
        }

        .padding-lg-r-#{$value} {
            padding-right: #{$value}px;
        }

        .padding-lg-b-#{$value} {
            padding-bottom: #{$value}px;
        }

        .padding-lg-l-#{$value} {
            padding-left: #{$value}px;
        }

        .margin-lg-t-#{$value} {
            margin-top: #{$value}px;
        }

        .margin-lg-r-#{$value} {
            margin-right: #{$value}px;
        }

        .margin-lg-b-#{$value} {
            margin-bottom: #{$value}px;
        }

        .margin-lg-l-#{$value} {
            margin-left: #{$value}px;
        }
    }
}
