.table {
    thead th {
        border-top: none;
        border-bottom: (2 * $table-border-width) solid $table-th-border-color;
        padding: 9px 12px 9px 0;
        text-transform: uppercase;

        @include type-h5();
    }

    tbody td {
        padding-left: 0;
    }

    tr {
        transition: $tr-transition;

        &:hover {
            background-color: $gray-200;
        }
    }
}
