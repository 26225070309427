@charset "UTF-8";

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .position-#{$size}-static {
            position: static;
        }
    }
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
    margin-right: 0.255em;
}

.main-menu {
    position: relative;
}

.navbar {
    position: static;
}

.navbar.bg-inverse {
    padding: 0;

    @include media-breakpoint-up(th) {
        .navbar-nav {
            display: inline-block;
            width: 100%;

            .nav-item,
            .dropdown {
                display: inline-block;
            }

            .nav-item.link-thisisfederal {
                float: right;
                background: $accent-5;
                margin-top: 0;

                a {
                    display: block;
                    padding: 10px 1rem 9px;
                    margin-right: 0;
                }
            }
        }
    }
}

.menu-toggleable-left {
    &.in {
        .has-sub-cat {
            &::after {
                @include caret-right();
            }
        }

        @include media-breakpoint-down(lg) {
            .menu-column {
                display: block;
                position: static;
            }

            .show > .menu-list {
                left: 0 !important;
            }

            .menu-column > .menu-list > .dropdown-item > .menu-list {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
                min-width: $dropdown-min-width;
                padding: $dropdown-padding-y 0;
                margin: $dropdown-spacer 0 0; // override default ul
                font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
                color: $body-color;
                text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
                list-style: none;
                background-color: $dropdown-bg;
                background-clip: padding-box;
                z-index: 1;
            }

            .dropdown-menu,
            .menu-list {
                @include box-shadow(none);
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-item {
        &:hover,
        &:focus {
            background-color: transparent;
        }

        &.top-category {
            .nav-link {
                padding-left: 0;
            }

            .caret-icon {
                display: none;
            }
        }

        @include media-breakpoint-up(th) {
            padding: 0.425em 0;

            + .dropdown-item {
                border: none;
            }

            .dropdown-link {
                padding: 0;
                line-height: normal;
            }
        }
    }

    .nav-item,
    .dropdown-item {
        img {
            max-width: 100%;
        }

        @include media-breakpoint-down(sm) {
            border-top: none;
        }
    }

    .dropdown-menu,
    .menu-list {
        @include media-breakpoint-up(th) {
            width: auto;
        }
    }

    .dropdown-toggle {
        @include media-breakpoint-up(th) {
            &::after {
                display: none;
            }
        }
    }
}

.menu-row {
    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    @include media-breakpoint-up(th) {
        display: flex;

        // Commenting this out because the will need to be uncommented once CR is implemented after launch
        //@include make-row();

        //margin: 25px 0 0;

        .dropdown-item {
            &.active,
            &:active,
            &:hover {
                color: inherit;
                background-color: inherit;
            }
        }
    }
}

.menu-column {
    @include media-breakpoint-up(th) {

        // Commenting this out because the will need to be uncommented once CR is implemented after launch
        //@include make-col-ready();
        //@include make-col(3);

        padding: 0.25rem 12px;
        margin-bottom: 10px;
    }
}

.menu-list {
    padding-left: 0;

    .caret-icon {
        &.caret-right {
            @include caret-right();
        }
    }

    @include media-breakpoint-up(th) {
        .has-sub-cat {
            font-weight: bold;
        }
    }
}

.navbar .nav-item.dropdown .dropdown-menu.show {
    background-color: $dropdown-bg !important; // overrides the SFRA skin.scss
}
