@import '../utilities/nth_of_type';

//
// Base styles
//

.card {
    margin-bottom: 1em;

    .list-group {
        margin-left: -($card-spacer-x);
        margin-right: -($card-spacer-x);
    }

    .card-title {
        margin-bottom: 15px;
    }

    .card-text {
        margin-bottom: 20px;
    }

    .card-body {
        padding: 20px 20px 15px;

        .btn {
            width: 100%;
        }

        ul {
            // Cards with lists overwrite padding bottom
            margin-bottom: -15px;

            li {
                margin: 0;
                border: none;
                border-top: $border;
            }

            &.payment-options {
                margin-bottom: 0;
            }
        }

        .card-link {
            @include link-primary();
        }
    }

    #accordion & {
        border: none;
        border-bottom: $border;
        border-radius: none;
        margin-bottom: 0;

        @include first() {
            border-top: $border;
        }

        .card-header {
            padding: $accordion-spacer-y $accordion-spacer-x;
            border: none;
            background-color: $white;

            .btn {
                width: 100%;
                text-align: left;
                padding: 0;

                &.btn-link {
                    color: $body-color;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }

                    &::after {
                        content: "";
                        float: right;
                        width: 14px;
                        height: 14px;
                        margin: 0 13px 0 0;
                        background: $svg-minus no-repeat 0 0;
                    }

                    &.collapsed {
                        &::after {
                            content: "";
                            background: $svg-plus no-repeat 0 0;
                        }
                    }
                }
            }
        }

        .card-body {
            padding: 0 0 21px;
            margin-left: $accordion-paragraph-margin;
            margin-top: -5px;
            width: 90%;
        }
    }

    .account-global &,
    .login-page &,
    .checkout-global & {
        border: none;
        min-width: 0;

        .card-header {
            border: none;

            h2 {
                color: $dark;
            }
        }

        .card-body {
            ul {
                li {
                    border-top: none;
                    padding: 0;

                    .nav-link {
                        display: block;
                        color: $primary !important;

                        &:hover,
                        &.active {
                            background-color: $trans;
                            color: $dark !important;
                        }
                    }
                }
            }
        }
    }
}

.card-link {
    @include hover {
        text-decoration: underline;
    }
}
