// The styles defined here are inteded to be used by Vista Federal in content/blog
.hero-content {
    position: relative;
}

.center-vertically {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.top-vertical {
    position: absolute;
    top: 0;
}

.bottom-vertical {
    position: absolute;
    bottom: 0;
}

.full-width {
    width: 100%;
}

.fill-height {
    height: 100%;
}
