.nav-link {
    color: $aubergine !important; // Overrides the skin.scss file
}

.main-menu {
    &.no-menu {
        .continue-shopping {
            color: $white !important;
        }
    }
}
