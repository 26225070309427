.nav-link {
    @include main-nav();

    color: $secondary;
    padding: 0.8em 0 0.6em;
    display: inline-block;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(th) {
        margin-right: $spacer;
        padding: 0;
        color: $white;
        text-transform: uppercase;
        width: auto;

        &::after {
            content: "";
            width: 0;
            height: 3px;
            background: $white;
            position: absolute;
            right: 0;
            bottom: -5px;
            transition: $nav-link-transition;
        }

        &:hover {
            color: $white;

            &::after {
                content: "";
                width: 100%;
                left: 0;
            }
        }
    }
}

//
// Tabs
//

.nav-tabs {
    justify-content: space-between;
    flex-direction: column;

    .nav-link {
        width: 100%;
        color: $nav-tabs-link-color;
        white-space: nowrap;
        padding: $nav-link-padding-y 0;
        text-transform: none;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $nav-tabs-link-active-color;
        border: none;
        border-bottom: $nav-tabs-border-bottom-width solid $nav-tabs-border-color;
    }

    .nav-item {
        margin: 0;

        > .nav-link-override {
            color: $nav-tabs-link-color !important;
        }

        > .pdp-chart-header {
            color: $nav-tabs-link-color !important;
        }

        > .nav-link {
            color: $nav-tabs-link-color;
        }
    }

    @include media-breakpoint-up(th) {
        border: none;
        flex-direction: row;
    }
}

// page designer tabs
.experience-component {
    .nav-tabs {
        justify-content: normal;
    }
}
