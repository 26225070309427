@import "../variables";
@import "../utilities/searchClearRemove";

.site-search {
    position: relative;
    height: auto; /* 40/16 */
    padding: 10px 0;
    border-bottom: $border;

    @include media-breakpoint-up(th) {
        padding: 10px;
        height: 32px;
    }

    .search {
        position: absolute;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        padding: 0;
        background: $svg-search-default;
        width: 20px;
        height: 20px;

        @include media-breakpoint-up(th) {
            background: $svg-search-white;
            right: 0;
            bottom: 8px;
            top: 0;
            transform: translateY(0);
        }
    }

    // remove clear button from search inputs
    @include searchClearRemove();

    .search-field {
        background: $trans;

        @include media-breakpoint-up(th) {
            border: none;
            border-bottom: $border6;
            padding: 3px;
            font-family: $font-family-second;
            height: 30px;
            color: $white;
        }

        &:focus {
            // standard outline for focus
            outline: 6px auto -webkit-focus-ring-color;
        }
    }

    .fa-search:hover {
        cursor: pointer;
    }

    input {
        padding-right: 2rem;
        height: 100%;

        &::placeholder {
            color: $secondary;
        }

        &::-webkit-input-placeholder {
            color: $secondary;
        }

        &::-moz-placeholder {
            color: $secondary;
        }

        &:-ms-input-placeholder {
            color: $secondary;
        }

        @include media-breakpoint-up(th) {
            color: $white;

            &::placeholder {
                color: $white;
            }

            &::-webkit-input-placeholder {
                color: $white;
            }

            &::-moz-placeholder {
                color: $white;
            }

            &:-ms-input-placeholder {
                color: $white;
            }
        }
    }

    @include media-breakpoint-up(th) {
        border-bottom: none;
        padding: 0;
        margin-right: 40px;
    }
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    display: flex;
    position: fixed;
    width: 100%;
    border: 1px solid $gray-300;
    background-color: $white;
    top: 0;
    right: 0;
    z-index: 3;
    padding-bottom: 20px;

    @include media-breakpoint-up(th) {
        display: block;
        position: absolute;
        width: 21.875rem;
        padding-bottom: 0;
    }

    .suggestion-img {
        height: 50px;
    }

    .header {
        padding: map-get($spacers, 1) 0 0;
        min-height: 38px; // Workaround to get IE to apply layout and stop padding collapse

        h4 {
            margin-bottom: 0;
        }

        &:not(:first-child) {
            border-top: $border;
        }
    }

    ul {
        // putting a smaller heigh so can scroll on overflow with fixed position
        height: 80%;
        overflow-y: scroll;

        li {
            margin: 0;
        }
    }

    .items {
        padding: map-get($spacers, 2) 0;
    }

    .item {
        padding-bottom: map-get($spacers, 1);

        .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .category-parent {
        color: $gray-500;
        font-size: $font-size-xs;
    }

    .selected {
        background-color: $gray-300;
    }

    .container {
        list-style-type: none;
    }
}

.more-below {
    -moz-border-radius: 1.25em;
    background: $gray-300;
    border: $border;
    border-radius: 0;
    bottom: 1.875em;
    box-shadow: $shadow;
    display: none;
    height: 2.5em;
    position: fixed;
    right: 1.875em;
    width: 2.5em;
    // height of container to center icon
    padding-top: 7.5px;

    .icon {
        display: block;
        height: 20px;
        width: 20px;
        margin: 0 auto;
    }
}
