@include media-breakpoint-up('md') {
    [data-action*='Product-Show'] .primary-images .product-carousel img {
        width: auto;
        margin: auto;
    }
}

// Einstein Carousel Styles
.homepage-wrapper {
    .slider-wrapper {
        margin: 0 auto;
        max-width: 1200px;
    }
}

[id^="cq_recomm_slot"] {
    .slider-wrapper {
        position: relative;
        margin: map-get($spacers, 4) auto;
    }

    .product-tile {
        height: 100%;
    }

    .product-carousel-arrows {
        .slick-prev {
            left: -48px;
        }

        .slick-next {
            right: -48px;
        }
    }
}
