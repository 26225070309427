footer {
    .footer-content-header {
        text-transform: uppercase;
        margin-bottom: 0;
        position: relative;
        font-size: 0.875rem;
        letter-spacing: 1px;
        font-family: "Oswald", sans-serif;
        font-weight: 600;
        line-height: 1;
    }
}
