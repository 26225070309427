@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";
@import "./components/quantityInput";

.minicart {
    position: relative;

    h4 {
        white-space: nowrap;
    }

    .cart {
        padding-top: 0.625em;
        padding-bottom: 0.625em;
        background-color: $body-bg;
    }

    .btn.remove-product {
        color: $slightly-darker-gray;
        background-color: white;
        border: none;
        margin-top: -0.313em;
        padding: 0 5px;
        height: 20px;

        .icon {
            height: 10px;
            width: 10px;
            top: 0;
        }
    }

    .product-summary {
        margin-right: -0.938em;
        max-height: 21.875em;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0.938em;
    }

    .card-body {
        padding: 0.625em;
    }

    .quantity-label {
        font-size: 0.813em;
    }

    .quantity {
        width: 100%;
    }

    .popover {
        top: 100%;
        left: auto;
        right: 0;
        min-width: 23.44rem;
        max-width: 23.44rem;
        min-height: 6.25rem;
        display: none;

        &::before {
            left: auto;
            right: 15px;
        }

        &::after {
            left: auto;
            right: 16px;
        }

        &.show {
            display: block;
        }
    }

    .minicart-footer {
        border-top: 1px solid $gray-300;
    }

    .estimated-total {
        margin-top: 0.625em;
    }

    .sub-total-label {
        font-size: 1em;
        font-weight: 600;
    }

    .sub-total {
        font-size: 1em;
        font-weight: 600;
    }

    .line-item-divider {
        margin: 0.625em -0.625em;
        width: calc(100% + 0.625em + 0.625em);
    }

    .line-item-name {
        width: 90%;
    }

    .line-item-unit-price {
        .price .strike-through,
        .price del {
            margin-right: 0;
        }
    }

    .checkout-btn {
        margin: 0 auto;
    }

    .product-summary-info {
        border-top: $border;
        margin-top: map-get($spacers, 1);
        padding-top: map-get($spacers, 1);
        // keep border within card
        margin-right: -10px;
        margin-left: -10px;
    }

    .minicart-header {
        a {
            // center vertically
            line-height: 34px;
        }
    }

    .bundled-line-item {
        padding-left: map-get($spacers, 1);
    }

    .quantity-form input[type="text"]:read-only {
        background: $white;
    }

    .minicart-link {
        color: $white;
    }
}

.hide-link-med {

    @include media-breakpoint-only(sm) {
        display: none;
    }
}

.hide-no-link {

    @include media-breakpoint-up(md) {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}
