html {
    font-size: $font-size-base;
}

body {
    font-size: $font-size-body;
}

form {
    font-size: $font-size-body;
}

label {
    font-weight: $font-weight-bold;
}

strong {
    font-weight: $font-weight-bold;
}

ul {
    ul > li {
        list-style-type: disc;
    }
}

ol {
    ol > li {
        list-style-type: upper-alpha;
    }
}

ul,
ol {
    > li {
        + li {
            margin-top: 10px;
        }

        + ul,
        + ol {
            margin-top: 9px;
        }
    }

    ol,
    ul {
        > li + li {
            margin-top: 5px;
        }

        + li {
            margin-top: 18px;
        }
    }
}

hr {
    width: 100%;
}
