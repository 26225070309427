.order-status-btn {
    color: $white !important;
    background-color: #85704d;
    text-align: center;
    padding: 5px 0.5rem;
    line-height: 1.5;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: #85704d;
    border-radius: 0;
    box-shadow: 0 0 0 transparent;
}

nav.menu-container {
    @include media-breakpoint-down(th) {
        .suggestions ul {
            overflow-y: scroll;
        }
    }
}

.navbar-header .header-utility-links .suggestions a span,
.navbar-header .header-utility-links .suggestions a {
    font-size: 0.875rem;
    color: $primary;
}

.breadcrumb-container {
    .breadcrumb-item a {
        color: #222;
    }

    .breadcrumb-item:last-child a {
        color: #85704d;
    }
}

nav.menu-container {
    @include media-breakpoint-up(th) {
        .header.menu {
            padding: 24px 12px 29px;
        }
    }

    .navbar-header .navbar-toggler {
        background-size: 22px 20px;
        background-repeat: no-repeat;
        height: 20px;
    }

    .mobile-search {
        background: #222;
    }

    .minicart-quantity {
        font-weight: 600;
        font-size: 16px;
        position: relative;
        top: 4px;
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .site-search {
        border-bottom: 0;
    }

    .search-field::placeholder {
        font-weight: 400;
    }

    @include media-breakpoint-down(sm) {
        .brand img {
            width: auto;
        }
    }
}

.navbar-header {
    .header-utility-links {
        a {
            margin-right: 30px;
            color: $shadow-color;

            &:nth-of-type(2) {
                margin-right: 30px;
            }
        }

        .minicart-footer {
            a {
                color: $white;
            }
        }
    }
}

.prices-add-to-cart-actions,
.line-item-availability,
.account-global,
.checkout-global,
.quick-view-dialog {
    .preorder-msg,
    &.preorder-msg {
        .availability-label {
            font-family: $font-family-third;
            font-weight: $font-weight-bold;
            font-size: $font-size-body;
            letter-spacing: 0.5px;
            line-height: 1.371;
            margin: 10px 0;
        }
    }

    .expected-instock-date,
    .line-item-instock-date {
        flex-wrap: wrap;

        p {
            font-family: $font-family-third;
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-body;
            letter-spacing: 0.5px;
            line-height: 1.371;

            &.expected-availability {
                margin-right: 3px;
            }
        }
    }

    .cart-and-ipay {
        .add-to-cart {
            font-size: $font-size-body;
            font-weight: $font-weight-semi-bold;
        }
    }
}

.pre-order-description {
    &.cart-preorder-description {
        margin-top: 10px;

        @include media-breakpoint-up(md) {
            margin-left: 90px;
        }
    }

    p {
        font-family: $font-family-fourth;
        font-weight: 500;
        font-size: $font-size-base;
        color: $accent-2;
    }
}

.line-item-availability {
    .instock-expected-date {
        margin: 10px 0;
    }
}

.quick-view-dialog {
    .modal-footer {
        .availability {
            ul.preorder-msg {
                .availability-label {
                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }
            }
        }
    }
}
