// Buttons
button,
.btn {
    border-radius: 0;
    text-transform: uppercase;
    border: none;
    padding-top: 11px;
    padding-bottom: 11px;
    @include type-button-text();

    &.disabled,
    &.disabled:hover,
    &:disabled:hover,
    &:disabled {
        background: $btn-disabled-main-bg;
        color: $btn-disabled-main-color;
        border: none;
    }
}

// Custom disabled states for buttons
.btn-primary {
    border-color: $primary;
    transition: $btn-primary-transition;

    &:active,
    &:hover,
    &:focus {
        background: $dark;
    }
}

.btn-outline-primary {
    border: $border2;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: $white;
    transition: $btn-transition;

    &:hover,
    &:active,
    &:focus {
        background-color: $btn-outline-primary-hover-color;
        color: $primary;
    }
}

.btn-secondary {
    border: $border3;
    color: $primary;
    padding-top: 9px;
    padding-bottom: 9px;
    background: $white;
    transition: $btn-transition;

    &:hover,
    &:active,
    &:focus {
        background: $btn-secondary-hover-color;
        border: $border3;
        color: $primary;
    }
}

// Button text link
.btn-text {
    color: $btn-link-color;
    transition: $btn-transition;

    &:hover {
        color: $btn-link-color;
        text-decoration: $btn-link-text-decoration-hover;
    }
}

.product-grid .grid-footer .pagination-links {
    .pagination-button {
        margin: 0 3px;
        padding: 5px 10px;
        border: none;
        background-color: transparent;
        color: $gray-400;
        text-transform: lowercase;
        @include media-breakpoint-up(sm) {
            margin: 0 8px;
            padding: 5px 12px;
        }
        @include media-breakpoint-up(m) {
            padding: 5px 16px;
        }
    }

    .pagination-button.active {
        color: $brand-main;
        font-weight: 900;
    }
}
