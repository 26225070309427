@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.item-attributes {
    vertical-align: top;
    padding-left: 0;
}

.line-item-attributes,
.line-item-option {
    @include type-label-3();

    margin: 0;
}

.line-item-attributes-value {
    @include type-label-2();

    white-space: pre-wrap;
}

.line-item-name,
.line-item-name span {
    @include type-line-item-name();

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $darker-gray;
    margin-bottom: 0.313em;
}

.line-item-price-info {
    @include type-form-label();

    margin-bottom: 0;
}

.line-item-price {
    .price,
    .sales {
        font-size: $font-size-base;
    }
}

.line-item-pricing-info,
.line-item-pricing-info span {
    @include type-form-label();

    margin-bottom: 0;

    + .price {
        font-size: $font-size-base;
    }
}

.line-item-price,
.line-item-total-price,
.line-item-unit-price {
    .price span,
    .price span .list,
    .price span .sales {
        @include type-product-price();

        font-size: $font-size-base;
        display: block;
    }

    .price span del {
        margin: 0;
        font-size: $font-size-base;
    }

    .price span .strike-through span,
    .price .strike-through {
        @include type-product-price-crossed-out();

        font-size: $font-size-base;
    }
}

.line-item-total-price-amount {
    @include type-tile-price();
}

.line-item-price-quantity-info {
    margin-top: 0.625em;
    border-top: 1px solid $horizontal-rule-grey;
}

.flex {
    display: flex;
}

.line-item-total-text {
    font-size: $font-size-base;
}

.product-image {
    height: auto;
    width: 5.625em;
    margin-right: 0.938em;
    flex-grow: 0;
    flex-shrink: 0;
}

.non-adjusted-price {
    display: none;
}

.line-item-promo {
    @include type-label-3();

    color: $accent-2;
}

.line-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    .remove-line-item {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.bundled-line-item + .bundled-line-item {
    margin-top: 0.625em;
}

.bundle-includes {
    font-size: $font-size-base;
    margin-bottom: 0.625em;
}

.line-item-divider {
    margin: 0.625em -1.225em 0.625em -1.325em;
}

.line-item-availability {
    @include type-label-1();
}

.preorder-shipdate {
    margin-right: initial;
    margin-left: initial;
    width: 75%;
}

.preorder-content {
    margin-left: initial;
}

.minicart {
    .product-line-item-details {
        overflow-y: hidden;
    }
}

.cart-product-card-price {
    @include media-breakpoint-down(sm) {
        display: none;
    }
    @include media-breakpoint-up(md) {
        display: contents;
    }
}
