@import "~org/utilities/swatch";
@import "productCommon";
@import "~org/components/quantityInput";
@import "pricing";

.quick-view-dialog,
.choose-bonus-product-dialog {
    max-width: 56.25em;

    .product-name {
        padding-top: 0;
    }

    .selectable-bonus-product-line-item {
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .beenSelected {
        background-color: $gray-400;
    }

    .primary-images,
    .slick-list {
        // max-height: calc(390px - 36px);
        height: auto;

        .slick-initialized .slick-slide {
            justify-content: start;
        }

        .slick-next,
        .slick-prev {
            top: 25%;
        }
    }

    @include media-breakpoint-up('md') {
        .primary-images {
            .slick-initialized .slick-slide {
                display: block;
            }

            .product-carousel img {
                max-width: 100%;
            }
        }
    }

    // prevent broken carousel vertical scroll
    .product-carousel:not(.slick-initialized) {
        .slide {
            display: none;
        }
    }

    .product-number {
        width: 100%;
        border-top: $border;
    }

    .product-number,
    .attributes ul,
    .attributes .last-swatch .attribute {
        padding: map-get($spacers, 1) 0;
        border-bottom: $border;
    }

    .product-name {
        font-size: $quickview-font-size4;
    }

    .attributes {
        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                list-style-type: none;

                .label-2 {
                    white-space: nowrap;
                }
            }
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(2.5em, $white);
    }

    a[disabled] .swatch-circle {
        cursor: not-allowed;

        &.color-value.selected::after {
            background-color: $gray-100;
        }
    }

    .tiered th {
        font-size: $font-size-body;
        white-space: nowrap;
    }

    .modal-header {
        padding-top: 20px;
    }

    .modal-body {
        max-height: 390px;
        overflow-y: auto;
        padding: 0 20px;
    }

    .modal-footer {
        background-color: $white;
        border: none;
        flex-direction: column;
        align-items: normal;
        padding: 0 20px 20px;

        @include media-breakpoint-up(md) {
            padding: 20px 15px;
        }

        .prices .price,
        .prices .price span {
            font-size: $quickview-font-size3;
        }

        button {
            max-width: none;
            width: 100%;
        }

        .row {
            margin: 0;
            width: 100%;
        }

        .availability {
            width: 100%;

            ul {
                margin-bottom: 5px;

                .label-1 {
                    @include media-breakpoint-up(md) {
                        text-align: right;
                    }
                }
            }
        }

        .global-availability {
            .prices {
                padding: 0 12px;
            }
        }
    }

    .quantity-form,
    .quantity-form-SFL {
        margin-top: 0;

        @include media-breakpoint-up(md) {
            margin-top: map-get($spacers, 2);
        }
    }

    .cart-and-ipay {
        padding: 0;
    }

    .promotions {
        text-align: left;
        color: $accent-2;
        padding-top: map-get($spacers, 1);
    }

    .bonus-summary {
        @include media-breakpoint-down(sm) {
            font-size: $quickview-font-size1;
        }

        .bonus-product-name {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
    }

    .selected-pid {
        .close::before {
            content: "";
            display: block;
            background: $svg-close;
            background-size: 10px 10px;
            height: 10px;
            width: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .bonus-product-name + div {
            padding: 0;
            position: relative;
        }
    }

    .pre-cart-products {
        margin-right: 0.125em;
    }

    .color-attribute {
        border: none;
        padding: 0;
        background: none;
    }

    .non-input-label {
        display: block;
        margin-bottom: 0.5rem;
    }

    .bundle-items-label {
        display: none;
    }

    .product-options .quantity {
        position: static;
    }

    .product-availability {
        text-align: left;
    }

    .tiered table {
        background: $gray-200;
    }

    .update-cart-product-global {
        margin-top: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}

.choose-bonus-product-dialog {
    .modal-body {
        max-height: 70vh;
        overflow-y: scroll;
    }

    .choice-of-bonus-product:first-of-type {
        .product-name {
            padding-top: 0;
        }
    }
}

.prices .sales {
    .value {
        display: inline-block !important;
    }
}
