.age-gate {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9999999999 !important; // set above all other els, especially promo stuff
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &[data-theme="ammo--remington"] {
        background-color: $white;
        color: $charcoal;
    }

    &[data-theme="ammo--VistaFederal"] {
        background-color: $aubergine;
    }

    &[data-theme="ammo--hevishot"] {
        background-color: $secondary;
    }

    &[data-theme="ammo--VistaCCI"] {
        background-color: $mariner;
        color: $white;
    }

    &[data-theme="ammo--VistaSpeer"] {
        background-color: $white;
        color: $secondary;
    }

    &[data-theme="ammo--le"] {
        background-color: $white;
        color: $secondary;
    }

    input[type="checkbox"] {
        margin-right: 0.5rem;
    }

    &__form {
        width: 100%;
        padding: 5vh 5vw;
        @include media-breakpoint-up('th') {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto 0;
        }

        .content-asset {
            max-width: 75ch;
        }
    }

    &__image {
        position: relative;
        object-fit: cover;
        height: 66vh;
        width: 100%;

        @include media-breakpoint-up('th') {
            position: absolute;
            left: unset;
            right: 0;
            top: 0;
            width: 50%;
            height: 100vh;
        }
    }
}

// body mods for mobile
body {
    &.age-gate-active {
        overflow-y: hidden;
        height: 100vh;
    }
}
