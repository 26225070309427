@import "menu";
@import "~nav/components/navigation";
@import "../utilities/nth_of_type";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.header-wrapper {
    background: $gray-600;
}

.header {
    position: relative;

    @include media-breakpoint-up(th) {
        padding: 27px 12px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.navbar-header {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user,
    .country-selector,
    .minicart {
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    .company-user-name-header {
        color: $black !important;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        line-height: 2.25em; /* 36/16 */
        height: auto;
    }

    .navbar-toggler {
        height: 24px;
        width: auto;
        background: $svg-menu;
    }

    .header-utility-links {
        a {
            color: $white;
            margin-right: 40px;

            span {
                color: $white;
            }

            @include nth(2) {
                margin-right: 0;
            }
        }
    }

    .user {
        position: relative;

        .account-dropdown-spacing {
            margin-top: 10px;
        }

        .popover {
            position: absolute;
            display: none;
            padding: 1em;
            top: 85%;
            left: 0;
            background: $gray-600;

            a {
                white-space: nowrap;
                margin-bottom: 0.5em;
            }

            ul {
                margin-bottom: 0;
                padding-left: 0;
            }

            &.show {
                display: block;
            }
        }
    }

    .search {
        display: inline-block;
        width: 260px;
    }

    @include media-breakpoint-up(th) {
        height: 100%;
        display: block;
    }
}

.brand {
    img {
        width: 100%;
    }

    @include media-breakpoint-up(th) {
        width: 288px;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.no-menu-header .brand {
    justify-content: center;

    @include media-breakpoint-up(th) {
        display: flex;
        flex-direction: column;
    }
}

.main-menu {
    background-color: $gray-500;

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }

    &.no-menu {
        height: 40px;

        .continue-shopping-wrapper {
            text-align: center;
            padding-top: 9px;
        }

        .continue-shopping {
            @include main-nav();

            margin-right: $spacer;
            padding: 0;
            color: $white;
            text-transform: uppercase;
            width: auto;

            &::after {
                content: "";
                width: 0;
                height: 3px;
                background: $white;
                position: absolute;
                right: 0;
                bottom: -5px;
                transition: $nav-link-transition;
            }

            &:hover {
                color: $white;

                &::after {
                    content: "";
                    width: 100%;
                    left: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(th) {
        height: 40px;
    }
}

.header-banner {
    background-color: $primary;
    text-align: center;
    color: $white;

    a {
        color: $white;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    .content {
        width: 100%;
        padding-top: $banner-padding;
        padding-bottom: $banner-padding;
    }
}

.minicart {
    a.minicart-link {
        &:hover {
            text-decoration: none;
        }
    }

    .minicart-quantity {
        display: inline-block;
        text-align: center;
        position: relative;
        top: 1px;
        left: 5px;
        color: $white;
    }

    .icon {
        height: 16px;
        width: 20px;
        position: relative;
        top: 4px;
        pointer-events: none;
    }

    @include media-breakpoint-up(th) {
        position: relative;
        bottom: 4px;
    }
}

a.normal {
    color: $primary;
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: $blue;
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

nav.menu-container {
    .navbar-header {
        display: flex;
        justify-content: space-between;
    }

    .minicart-total.hide-no-link {
        margin-right: 25px;
    }

    .search-mobile {
        display: none;
    }

    .brand {
        position: relative;
        margin: 0;
        bottom: 0;
    }

    .searchmenu.active {
        .search-content {
            right: 0;
        }
    }

    .utility,
    .minicart {
        flex-shrink: 0;
    }

    .search-icon.active {
        z-index: 1;
    }

    #myaccount {
        text-decoration: none;

        .user-message {
            position: relative;
            top: 2px;
            margin-left: 6px;
        }
    }

    .suggestions {
        width: 100%;
    }

    .searchmenu {
        position: relative;
        overflow: hidden;
        height: 45px;
        width: 650px;
        left: 25px;
        z-index: 2;

        .search-content {
            position: absolute;
            width: 100%;
            right: -101%;
            top: 4px;
            transition: all 0.5s ease;
        }
    }

    .site-search {
        margin-right: 0;

        .search {
            background: $svg-close;
            width: 24px;
            height: 24px;
            cursor: pointer;
            top: 50%;
            transform: translate(0, -50%);
            right: 15px;
            background-repeat: no-repeat;
        }
    }

    .search-icon {
        position: relative;
        top: 2px;
    }

    .search-icon,
    .header-utility-links a {
        margin-right: 25px;
        cursor: pointer;
        z-index: 2;

        @include media-breakpoint-up(xl) {
            margin-right: 32px;
        }
    }

    .site-search {
        form {
            position: relative;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 20px;
        }

        .search-field::placeholder {
            font-size: 14px;
            font-weight: 400;
            font-family: "Open Sans", sans-serif;
            color: black !important;
        }

        .search-field {
            border-radius: 22px;
            height: 40px;
            background: $white;
            color: $black;
            text-indent: 10px;
            @include media-breakpoint-up(md) {
                text-indent: 15px;
            }
        }
    }

    .minicart {
        bottom: 0;
    }

    @include media-breakpoint-down(lg) {
        .main-menu {
            a {
                text-transform: uppercase;
            }
        }

        .menu-toggleable-left.navbar-toggleable-lg.in {
            right: 0;
            left: auto;
            margin: 0;
            transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
        }

        .menu-toggleable-left.navbar-toggleable-lg {
            right: -100%;
        }
    }
}

@include media-breakpoint-down(md) {
    .breadcrumb-container {
        position: relative;

        .breadcrumbs {
            margin: 0 10px;
            border-bottom: 1px solid #d7d3d1;
            overflow-x: scroll;
        }

        .breadcrumb-item {
            display: flex;
            align-items: center;
        }

        .breadcrumb {
            flex-wrap: nowrap;
            border-bottom: none;
            margin-left: -1rem;
            margin-bottom: 0;
        }

        .breadcrumb-item a {
            font-size: 14px;
            white-space: nowrap;
            font-weight: 400;
        }

        .breadcrumbs.active::after {
            content: '...';
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 20px;
            background: $white;
        }

        .breadcrumbs::after {
            content: none;
        }
    }
}

.modal-open {
    max-width: 100%;
}

.cls-logo-desktop {
    width: "287px";
    height: "46px";
}

.cls-logo-tablet {
    width: "222px";
    height: "37px";
}

.cls-logo-mobile {
    width: "24px";
    height: "24px";
}

.cls-search {
    width: "24px";
    height: "24px";
}

.cls-account {
    width: "24px";
    height: "24px";
}

.cls-minicart-icon {
    width: "86px";
    height: "26px";
}

.cls-minicart-total {
    width: "39px";
    height: "26px";
}

.cls-product {
    width: "266px";
}

.sticky-position {
    position: sticky;
}

.cls-minicart {
    img {
        width: "39px";
        height: "26px";
    }

    span {
        position: sticky;
    }
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
    background-color: #85704d !important;
    border: 1px solid #85704d !important;
    color: #fff !important;
}
