.container {
    .breadcrumb {
        border-radius: 0;
        border-bottom: none;
    }

    &.search-results,
    &.login-page,
    &.account-dashboard,
    &.account-payment,
    &.account-address {
        .breadcrumb {
            padding-left: 0;
            border-bottom: 0;
        }
    }
}
