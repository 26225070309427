.glide--swipeable {
    cursor: auto;
}

.primary-images {
    .product-carousel {
        visibility: hidden;

        &.glide--swipeable {
            visibility: visible;
        }

        .glide__slide {
            display: flex;
            align-items: center;

            .slide-link {
                max-width: 400px;
            }
        }
    }

    .bonusproduct-carousel {
        .glide__arrows {
            height: auto;
        }

        .cls-product {
            width: 100%;
        }
    }

    .glide__slides {
        .glide__slide {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .slide-link {
                display: flex;
                justify-content: center;

                @include media-breakpoint-up(sm) {
                    display: block;
                }
            }
        }
    }

    .glide__arrows {
        .glide__arrow--left {
            box-shadow: none;
            height: 30px;
            width: 30px;

            &::before {
                content: '';
                background: $svg-arrow-left no-repeat center;
                height: 30px;
                width: 30px;
                background-size: 30px;
                display: block;

                @include media-breakpoint-down(sm) {
                    height: 20px;
                    width: 20px;
                    background-size: 20px;
                }
            }
        }

        .glide__arrow--right {
            box-shadow: none;
            height: 30px;
            width: 30px;

            &::before {
                content: '';
                background: $svg-arrow-right no-repeat center;
                height: 30px;
                width: 30px;
                background-size: 30px;
                display: block;

                @include media-breakpoint-down(sm) {
                    height: 20px;
                    width: 20px;
                    background-size: 20px;
                }
            }

            &:active {
                background: none;
            }
        }
    }
}

.product-quickview {
    .primary-images {
        .glide__arrow--left,
        .glide__arrow--right {
            top: 25%;
        }
    }
}

.glide__arrow {
    border: none;
    padding: 0;
}

.product-tile-caro {
    position: relative;

    .glide__slide {
        @include media-breakpoint-up (md) {
            border-left: 1px solid $mercury;
        }

        width: auto;
        height: auto;
    }

    .glide__arrow--left {
        box-shadow: none;
        height: 48px;
        width: 48px;
        left: -35px;

        @include media-breakpoint-down(xs) {
            display: none;
        }

        &::before {
            content: '';
            background: $svg-arrow-left no-repeat center;
            height: 48px;
            width: 48px;
            background-size: 48px;
            display: block;
        }
    }

    .glide__arrow--right {
        box-shadow: none;
        height: 48px;
        width: 48px;
        right: -35px;

        @include media-breakpoint-down(xs) {
            display: none;
        }

        &::before {
            content: '';
            background: $svg-arrow-right no-repeat center;
            height: 48px;
            width: 48px;
            background-size: 48px;
            display: block;
        }
    }

    .product-tile {
        @include media-breakpoint-down(xs) {
            padding: 20px;
        }
    }
}

.video-carousel {
    position: relative;

    .glide__slide {
        display: flex;
    }

    .video {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    .glide__arrows {
        .glide__arrow {
            top: 50%;
            border-radius: 0;
            height: 50px;
            width: 50px;
            box-shadow: none;
        }

        .glide__arrow--left {
            left: 0;

            &::before {
                content: '';
                background: $svg-arrow-left no-repeat center;
                height: 48px;
                width: 48px;
                background-size: 48px;
                display: block;
            }
        }

        .glide__arrow--right {
            right: 0;

            &::before {
                content: '';
                background: $svg-arrow-right no-repeat center;
                height: 48px;
                width: 48px;
                background-size: 48px;
                display: block;
            }
        }
    }
}
