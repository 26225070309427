$breakpoint-name: 'lg';
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            z-index: 1;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            width: 90%;
            overflow-y: scroll;

            &.in {
                min-width: 50%;
                left: 0;

                .container {
                    max-width: 100% !important; // hack required to overwrite the container setting in the template, so that just the off-canvas nav receives full width treatment
                }
            }
        }
    }
}

.navbar.bg-inverse {
    background-color: $trans !important;
    padding: 0 1rem;

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;

            &:not(.link-thisisfederal) {
                padding-bottom: 9px;
            }
        }
    }
}

@include media-breakpoint-up($next-breakpoint) {
    .main-menu .nav-item > .nav-link {
        color: $white !important;
    }
}

.main-menu.menu-toggleable-left {

    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {
    .close-menu {
        flex: 0 0 auto; // IE11 bug - need to change flex-basis 100% to auto
        width: 100%; // IE11 bug - add width of 100%
        padding: 15px;
        max-height: 50px; // Need to set this max height because the login back button contains an empty element with layout
        background-color: $white;

        .back {
            padding-bottom: 0.8rem;
        }

        @include media-breakpoint-up(th) {
            display: none;
        }
    }

    .menu-group {
        flex: 0 0 100%;
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $gray-100;

            .user-message,
            .fa-sign-in,
            .dropdown-toggle::after {
                color: $link-color;
            }

            .user-message {
                font-family: $font-family-second;
                font-weight: $font-weight-bold;
            }
        }
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
            margin-right: 1.25em; /* 20/16 */
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item {
                + .nav-item {
                    border-top: $border;
                    margin-top: 5px;

                    &.utility {
                        padding-top: 0.7rem;

                        @include last {
                            margin-top: 0;
                            padding-top: 0;
                            border: none;
                        }

                        .nav-link {
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                        }
                    }
                }

                .nav-link {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }

            .dropdown {
                display: block;
                position: static;
            }

            .dropdown-caret {
                background: $svg-arrow-right no-repeat 98% 1.1em;
            }

            .show > .dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 94%;
        border: 0;
        border-radius: 0;

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
        }
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        padding-left: 1.5rem;
        background: $svg-arrow-left no-repeat left 7px;
    }

    .dropdown-item {
        padding: 0 0 0 1em;

        .dropdown-link {
            display: block;
            padding: 0.8em 1em 0.6em 0;
            color: $secondary !important;
            text-decoration: none;

            &:hover {
                color: $primary !important;
            }
        }

        + .dropdown-item {
            border-top: $border;

            @include last {
                border-bottom: $border;
                padding-bottom: 0.5em;

                @include media-breakpoint-up(th) {
                    border-bottom: none;
                }
            }
        }

        &.mobile-header {
            border: none;
        }

        &.top-category {
            font-weight: $font-weight-bold;
            padding-bottom: 0.7rem;
            padding-top: 0.5rem;
            border-bottom: $border;
            border-top: $border;

            .dropdown-caret {
                background: none;
            }

            .nav-link {
                font-size: $font-size-xl;
                text-transform: uppercase;
                padding: 0.6em 0 0.4em;
            }
        }
    }
}
