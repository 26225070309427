@import "~base/variables";
@import "../components/toastMessage";

$border-radius: 3px;

.compare-bar-wrapper {
    bottom: 0;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: rgba($gray-600, 0.65);

    .product-slots {
        height: 6.25em;
    }

    .slot {
        background-color: $white;
        height: 5em;
        margin: 0 auto;
        position: relative;
        width: 6.788em;
        border-radius: $border-radius;

        &:not([data-pid]) {
            border: 1px dashed $gray-500;
        }

        img {
            float: left;
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: $border-radius 0 0 $border-radius;
        }

        .close {
            width: 25%;
            border-radius: 0 $border-radius $border-radius 0;
            opacity: 1;
            position: absolute;
            top: 0;
            right: -1px;
            bottom: 0;
            left: 70%;

            button {
                &::before {
                    content: "";
                    display: block;
                    background: $gray-200 $svg-close no-repeat center center;
                    background-size: 10px 10px;
                    height: 100%;
                    width: 16px;
                    position: absolute;
                    right: -1px;
                    left: 70%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .action {
            text-align: center;
        }
    }

    .clear-all {
        color: $white;
    }
}

.min-products-msg {
    @include toast-message();
    @include toast-alert();
}

.product-comparison {
    .back-to-results {
        margin: map-get($spacers, 1) 0;
    }

    .product-header > .col {
        padding: 0;
    }
}

.table-striped-column td:nth-of-type(odd) {
    background-color: $grey2;
}

.compare-table {
    margin-bottom: map-get($spacers, 4);

    thead tr {
        &:hover {
            background: none;
        }
    }
}

.performance__table {
    td.label {
        &::before {
            content: "";
            display: inline-block;
            height: 15px;
            width: 15px;
            margin-right: map-get($spacers, 1);
            position: relative;
            top: 2px;
        }

        &.label--blue::before {
            background: map-get($chart-colors, 'blue');
        }

        &.label--orange::before {
            background: map-get($chart-colors, 'orange');
        }

        &.label--purple::before {
            background: map-get($chart-colors, 'purple');
        }

        &.label--light-green::before {
            background: map-get($chart-colors, 'light-green');
        }
    }
}
