
.badge {
    height: 26px;
    color: $white;
    padding: 0 18px;
    position: absolute;
    top: 0;
    left: -5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
}

.badge {
    &-new {
        background: $gray-500;
    }

    &-sale {
        background: $accent-2;
    }

    &-coming-soon {
        background: $gray-400;
    }

    &-discontinued {
        background: $gray-300;
        color: $body-color;
    }
}
