@charset "UTF-8";

@import "./utilities/nth_of_type";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-loader-path: "../lib/slick/" !default;
$slick-arrow-color: $black !default;
$slick-arrow-bg-color: $white !default;
$slick-arrow-bg-color-hover: $white !default;
$slick-arrow-thickness: 2px !default;
$slick-dot-color: $white !default;
$slick-dot-color-hover: $white !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-size: 25px !default;
$slick-opacity-default: 0.5 !default;
$slick-opacity-on-hover: 0.8 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {

    @if function-exists(image-url) {
        @return image-url($url);
    } @else {
        @return url($slick-loader-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: $white slick-image-url("../../images/ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 48px;
    width: 48px;
    min-height: 0;
    font-weight: initial;
    line-height: 0;
    font-size: 0;
    border-radius: 0;
    cursor: pointer;
    background: $slick-arrow-bg-color;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    opacity: $slick-opacity-default;
    z-index: 1;
    transition: $slick-arrow-transition;

    &:hover,
    &:focus {
        outline: none;
        color: transparent;
        height: 50px;
        width: 50px;
    }

    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }

    &::before {
        content: "";
        display: inline-block;
        width: 48px;
        height: 48px;
    }
}

.slick-prev {
    left: 1px;

    [dir="rtl"] & {
        left: auto;
        right: 1px;
    }

    &::before {
        background: $svg-arrow-left no-repeat;
        background-size: 48px 48px;
        transition: $slick-arrow-transition;

        [dir="rtl"] & {
            transform: rotate(45deg);
        }
    }

    &:hover {
        &::before {
            background-size: 50px 50px;
        }
    }
}

.slick-next {
    right: 1px;

    [dir="rtl"] & {
        left: 1px;
        right: auto;
    }

    &::before {
        background: $svg-arrow-right no-repeat;
        background-size: 48px 48px;
        transition: $slick-arrow-transition;

        [dir="rtl"] & {
            transform: rotate(-135deg);
        }
    }

    &:hover {
        &::before {
            background-size: 50px 50px;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 22px;
        width: 22px;
        margin: 0 2px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 10px;
            width: 10px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;
                background-color: transparent;

                &::before {
                    opacity: $slick-opacity-on-hover;
                    background-color: $slick-dot-color-hover;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: 5px;
                border-radius: 50%;
                border: $border4;
                width: 10px;
                height: 10px;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                background-color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button::before {
            background-color: $slick-dot-color-active;
            border: $border5;
            opacity: $slick-opacity-default;
        }
    }
}

.slick-dark-arrows {
    .slick-prev,
    .slick-next {
        height: 64px;
        width: 24px;
        background-color: $black;

        &:hover,
        &:focus {
            background: $black;
        }

        &::before {
            border-color: $white;
        }
    }
}

.product-tile-caro {
    .slick-prev {
        left: -35px;

        @include media-breakpoint-up(lg) {
            left: -48px;
        }
    }

    .slick-next {
        right: -35px;

        @include media-breakpoint-up(lg) {
            right: -48px;
        }
    }
}

.video-carousel {
    .slick-prev {
        left: 0;

        @include media-breakpoint-up(lg) {
            left: -48px;
        }
    }

    .slick-next {
        right: 0;

        @include media-breakpoint-up(lg) {
            right: -48px;
        }
    }
}

.product-tile-caro {
    .slick-prev,
    .slick-next {
        top: 35%;
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: auto;
    }

    @include media-breakpoint-up(sm) {
        .slick-active + .slick-active {
            border-left: $border;
        }
    }

    .product-tile {
        padding: 15px;
        height: 100%;

        &:focus {
            outline: 0;
        }

        .tile-image {
            margin: 0 auto;
        }

        .image-container {
            width: 100%;
        }
    }
}

// keeps pdp cleaner while slick loads
.product-carousel {
    visibility: hidden;

    &.slick-initialized {
        visibility: visible;
    }
}
