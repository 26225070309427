@import "~base/components/collapsibleItem";
@import "../utilities/nth_of_type";

footer {
    background-color: $black;
    padding-top: 20px;

    ul {
        list-style: none;
        padding-left: 0;
    }

    .social {
        text-align: center;
        margin-top: 30px;

        li {
            display: inline;
            padding-right: 25px;

            @include last() {
                padding-right: 0;
            }
        }

        .icon {
            height: 24px;
            width: 24px;
            pointer-events: none;
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
            margin-top: 0;
        }
    }

    .footer-container {
        padding: 0 10px;

        @include media-breakpoint-up(lg) {
            display: flex;
            border-left: $border7;
        }
    }

    .copyright-notice {
        padding: 0;
        text-align: center;

        > div {
            @include media-breakpoint-up(lg) {
                max-width: map-get($container-max-widths, lg);
                margin: 0 auto;
            }

            @include media-breakpoint-up(lg) {
                max-width: map-get($container-max-widths, xl);
            }
        }

        @include media-breakpoint-up(lg) {
            background: $gray-500;
            overflow: hidden;
            margin-top: 18px;
        }
    }

    .postscript {
        background: $gray-500;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        padding: 11px 0;
        overflow-y: scroll;

        a {
            color: $white;
            text-decoration: underline;

            @include media-breakpoint-up(lg) {
                //center vertically
                line-height: 21px;
            }
        }

        li {
            display: inline-block;
            width: 33%;
            padding: 0 12px;
            margin-top: 0;
            text-align: center;

            @include even() {
                border-right: $border7;
                border-left: $border7;
            }

            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }

        @include media-breakpoint-up(lg) {
            float: right;
        }
    }

    .copyright {
        color: $white;
        margin-bottom: 11px;

        @include media-breakpoint-up(lg) {
            float: left;
            margin-bottom: 0;
            line-height: 46px;
        }
    }

    .back-to-top {
        position: fixed;
        right: 1.2vw;
        bottom: 60px;
        background-color: $trans;
        background-image: $svg-arrow-up-gold;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 32px 32px;
        border-radius: 0;
        float: left;
        height: 40px;
        margin: 0.313em;
        margin-top: 0.1875em;
        border-color: $trans;
        cursor: pointer;
        padding: 0.125em 0.375em 0.1875em;
        width: 40px;
        z-index: 100;

        &:focus {
            outline: 0;
        }
    }
}

.footer-item {
    border-bottom: $border6;
    padding: 21px 0 22px;

    @include first() {
        border-top: $border6;
    }

    h1, h2 {
        cursor: pointer;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 0;
        position: relative;
        font-size: 0.875rem;

        &::after {
            content: $svg-plus-white;
            position: absolute;
            right: 15px;
        }

        @include media-breakpoint-up(lg) {
            &::after {
                content: '';
            }
        }
    }

    a {
        color: $white;
    }

    .footer-links {
        margin-bottom: 0;
        display: none;
        padding-top: 17px;

        li {
            margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &.active {
        h1::after, h2::after {
            content: $svg-minus-white;
            position: absolute;
            right: 15px;
        }

        .footer-links {
            display: block;
        }

        @include media-breakpoint-up(lg) {
            h1::after, h2::after {
                content: '';
            }
        }
    }

    @include media-breakpoint-up(lg) {
        border: none;
        flex: 1;
        padding-left: 20px;

        @include first() {
            border-top: none;
        }
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.email-signup {
    padding-bottom: 26px;

    .email-description,
    h4 {
        color: $white;
    }

    .email-description {
        margin-bottom: 19px;
    }

    button {
        margin: 0 auto;
        display: block;
        width: calc(100% - 12px);

        @include media-breakpoint-up(lg) {
            margin: 0;
            display: inline;
            width: 260px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 20px;
        padding-top: 30px;
    }
}

.optInCheck {
    margin-top: 20px;
}
