.form-control {
    font-size: $font-size-body;

    &:focus {
        border-color: $gray-400;
        box-shadow: none;
    }

    &::placeholder {
        @include type-placeholder-text();

        color: $gray-400;
    }

    &.is-invalid {
        border: $border-danger;
        background: none;
        box-shadow: none;

        &:focus {
            box-shadow: none;
        }
    }

    &:valid {
        border: $border;
    }
}

.custom-select {
    -webkit-appearance: none;
    line-height: 18px;
    background-position: right 10px center;

    &.is-invalid {
        background: $custom-select-background;
    }
}

.form-control,
.custom-select {
    height: 40px;
}

input,
textarea,
select {
    color: $input-color;
}

// Removes iOS shadow in these types of input fields
input[type=email],
input[type=password],
input[type=text],
input[type=tel] {
    -webkit-appearance: none;
}
