@import '../utilities/tooltipArrowShadowUtils';

.info-icon {
    position: relative;
    cursor: pointer;
    display: inline-block;
    border: none;
    padding: 0;
    background: inherit;

    .icon::before {
        content: $svg-tooltip;
    }

    &:hover .tooltip {
        display: block;
    }
}

.tooltip {
    .tooltip-inner {
        box-shadow: $shadow;
        text-align: left;
    }

    .arrow::before {
        z-index: 2;
    }
}

.bs-tooltip-top {
    .arrow {
        @include tooltipArrowShadow('top');
    }
}

.bs-tooltip-right {
    .arrow {
        @include tooltipArrowShadow('right');
    }
}

.bs-tooltip-bottom {
    .arrow {
        @include tooltipArrowShadow('bottom');
    }
}

.bs-tooltip-left {
    .arrow {
        @include tooltipArrowShadow('left');
    }
}
