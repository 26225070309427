.form-nav .nav-tabs .nav-link {
    color: $gray-100;
}

.form-nav .nav-tabs .nav-link.active,
.form-nav .nav-tabs .nav-link.active:focus,
.form-nav .nav-tabs .nav-link.active:hover {
    background-color: $white;
    color: $gray-100;
}

.nav-tabs .nav-link.active {
    background-color: transparent;
}

@include media-breakpoint-up(lg) {
    .form-nav .nav-item > .nav-link {
        color: $gray-100;
    }
}

.nav-links,
.nav-tabs,
.nav-pills {
    // remove styles if there is only one tab
    .nav-item:only-child .nav-link {
        border: none;
        background: transparent;
    }
}

.nav-tabs {
    border-bottom: none;
    .nav-link {
        border-radius: $tab-border-radius-top-left $tab-border-radius-top-right $tab-border-radius-bottom-right $tab-border-radius-bottom-left;
        border-top: $tab-border-top;
        border-right: $tab-border-right;
        border-bottom: $tab-border-bottom;
        border-left: $tab-border-left;
        font: $tab-font;
        text-transform: $tab-text-transform;
        font-weight: $tab-font-weight;
        text-decoration: $tab-text-decoration;
        letter-spacing: $tab-letter-spacing;
        padding: $tab-padding-top $tab-padding-right $tab-padding-bottom $tab-padding-left;
        background-color: $tab-background;
        color: $tab-color;
        &:hover {
            border-top: $tab-border-top-hover;
            border-right: $tab-border-right-hover;
            border-bottom: $tab-border-bottom-hover;
            border-left: $tab-border-left-hover;
            text-decoration: $tab-text-decoration-hover;
            background-color: $tab-background-hover;
            color: $tab-color-hover;
        }
        &.active {
            border-top: $tab-border-top-active;
            border-right: $tab-border-right-active;
            border-bottom: $tab-border-bottom-active;
            border-left: $tab-border-left-active;
            text-decoration: $tab-text-decoration-active;
            background-color: $tab-background-active;
            color: $tab-color-active;
        }
    }
}

.nav-links {
    .nav-link {
        font: $link-tab-font;
        text-transform: $link-tab-text-transform;
        font-weight: $link-tab-font-weight;
        text-decoration: $link-tab-text-decoration;
        letter-spacing: $link-tab-letter-spacing;
        color: $link-tab-color;
        padding: $link-tab-padding-top $link-tab-padding-right $link-tab-padding-bottom $link-tab-padding-left;
        &:hover {
            text-decoration: $link-tab-text-decoration-hover;
            color: $link-tab-color-hover;
            font-weight: $link-tab-font-weight-hover;
            text-transform: $link-tab-text-transform-hover;
        }
        &.active {
            text-decoration: $link-tab-text-decoration-active;
            color: $link-tab-color-active;
            font-weight: $link-tab-font-weight-active;
            text-transform: $link-tab-text-transform-active;
        }
    }
}

.nav-pills {
    .nav-link {
        font: $pills-tab-font;
        text-transform: $pills-tab-text-transform;
        font-weight: $pills-tab-font-weight;
        text-decoration: $pills-tab-text-decoration;
        letter-spacing: $pills-tab-letter-spacing;
        background-color: $pills-tab-background;
        color: $pills-tab-color;
        padding: $pills-tab-padding-top $pills-tab-padding-right $pills-tab-padding-bottom $pills-tab-padding-left;
        border-radius: $pills-tab-border-radius;
        &:hover {
            text-decoration: $pills-tab-text-decoration-hover;
            background-color: $pills-tab-background-hover;
            color: $pills-tab-color-hover;
        }
        &.active {
            text-decoration: $pills-tab-text-decoration-active;
            background-color: $pills-tab-background-active;
            color: $pills-tab-color-active;
        }
    }
}

