.quantity-form,
.quantity-form-SFL {
    display: flex;
    max-width: 132px;

    input[type="text"] {
        width: 40%;
        text-align: center;
        padding: 0;
    }

    .plus,
    .minus {
        position: relative;
        height: 40px;
        width: 30%;

        .icon {
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 16px;
            width: 16px;
        }

        input[type="button"] {
            height: 100%;
            width: 100%;
            background: none;
            border: $border;
            border-radius: 0;
        }
    }

    .plus {
        input[type="button"] {
            border-left: none;
        }
    }

    .minus {
        input[type="button"] {
            border-right: none;
        }
    }

    label {
        margin-bottom: 0;
    }
}
