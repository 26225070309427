@import '~org/variables';
@import 'rfs/scss';

// Typography definitions

// Displays

@mixin display-1 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $line: 1,
        $m-line: 1,
        $letter: 1px
    );

    @include responsive-font-size($display1-size-mobile);

    @include media-breakpoint-up(md) {
        @include responsive-font-size($display1-size);
    }
}

@mixin display-2 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $display2-size,
        $line: 71px,
        $m-size: $display2-size-mobile,
        $m-line: 51px,
        $letter: 1px
    );
}

@mixin display-3 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $display3-size,
        $line: 26px,
        $m-size: $display3-size-mobile,
        $m-line: 21px,
        $letter: 1px
    );
}

//Headings

@mixin type-h1 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $h1-font-size,
        $line: 71px,
        $m-size: $h1-font-size-mobile,
        $m-line: 51px,
        $letter: 1px
    );
}

@mixin type-h2 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $h2-font-size,
        $line: 40px,
        $m-size: $h2-font-size-mobile,
        $m-line: 40px,
        $letter: 1px
    );
}

@mixin type-h3 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $h3-font-size,
        $line: 41px,
        $m-size: $h3-font-size-mobile,
        $m-line: 30px,
        $letter: 1px
    );
}

@mixin type-h4 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $h4-font-size,
        $line: 32px,
        $m-size: $h4-font-size-mobile,
        $m-line: 26px,
        $letter: 1px
    );
}

@mixin type-h5 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $h5-font-size,
        $line: 24px,
        $letter: 1px
    );
}

@mixin type-h6 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $h6-font-size,
        $line: 21px,
        $letter: 0.5px
    );
}

// Labels

@mixin type-label-1 {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-light,
        $size: $label-size,
        $line: 21px,
        $m-size: $label-size-mobile,
        $m-line: 17px,
        $letter: 1px
    );
}

@mixin type-label-2 {
    @include typography(
        $weight: $font-weight-semi-bold,
        $size: $label-size,
        $line: 19px
    );
}

@mixin type-label-3 {
    @include typography(
        $weight: $font-weight-light,
        $size: $label-size,
        $line: 19px
    );
}

// Body text

@mixin type-body() {
    @include typography(
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 22px
    );
}

// Links 

@mixin main-nav() {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $font-size-body,
        $line: 21px,
        $letter: 1px
    );
}

@mixin link-primary() {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $font-size-body,
        $line: 21px,
        $letter: 1px
    );
}

@mixin link-secondary {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 21px,
        $letter: 1px
    );
}

// Button Text

@mixin type-button-text {
    @include typography(
        $family: $font-family-second,
        $weight: $font-weight-bold,
        $size: $btn-font-size,
        $line: 17px,
        $letter: 1px
    );
}

// Forms 

@mixin type-form-label {
    @include type-h6();
}

@mixin type-custom-input-label {
    @include typography(
        $weight: $font-weight-regular,
        $size: $font-size-body
    );
}

@mixin type-placeholder-text {
    @include typography(
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 20px,
        $letter: 0px
    );
}

@mixin type-form-control-text {
    @include typography(
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 20px,
        $letter: 0px
    );
}

@mixin type-form-text {
    @include typography(
        $weight: $font-weight-bold,
        $size: $font-size-xs,
        $line: 16px,
        $letter: 0px
    );
}

@mixin type-form-error-text {
    @include type-form-text();
}

// Alert

@mixin type-alert-text {
    @include typography (
        $weight: $font-weight-semi-bold,
        $size: $font-size-base,
        $line: 24px,
        $letter: 0px
    );
}

// Misc.

@mixin type-prop-65-msg {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-bold,
        $size: $font-size-body,
        $letter: 1px
    );
}

@mixin type-breadcrumbs {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-light,
        $size: $font-size-xs,
        $letter: 1px
    );
}

@mixin type-parent-list {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 19px,
        $letter: 0.5px
    );
}

@mixin type-child-list {
    @include typography (
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 22px
    );
}

@mixin type-badge {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-bold,
        $size: $font-size-body,
        $line: 21px,
        $letter: 1px
    );
}

// Product

@mixin type-pid {
    @include typography (
        $weight: $font-weight-regular,
        $size: $font-size-xs,
        $line: 16px
    );
}

@mixin type-part-number {
    @include typography (
        $weight: $font-weight-regular,
        $size: $font-size-body,
    );
}

@mixin type-promotion {
    @include typography (
        $weight: $font-weight-light,
        $size: $font-size-body,
        $line: 19px
    );
}

// Prices

@mixin type-tile-price {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $font-size-base,
        $line: 24px,
        $letter: 1px
    );
}

@mixin type-tile-price-crossed-out {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-light,
        $size: $font-size-base,
        $line: 24px,
        $letter: 1px
    );
}

@mixin type-product-price {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $font-size-price,
        $line: 39px,
        $letter: 1px
    );
}

@mixin type-product-price-crossed-out {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-light,
        $size: $font-size-price,
        $line: 39px,
        $letter: 1px
    );
}

// Footer

@mixin type-footer-item-link {
    @include typography (
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 2
    );
}

@mixin footer-postscript-links {
    @include typography (
        $weight: $font-weight-regular,
        $size: $font-size-xs,
        $line: 1
    );
}

@mixin footer-copyright {
    @include typography (
        $weight: $font-weight-regular,
        $size: $font-size-xs,
        $line: 1.83
    );
}

// Header

@mixin type-minicart-qty {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-regular,
        $size: $font-size-xs,
        $letter: 1px
    );
}

@mixin type-search-placeholder {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: 12px,
        $m-size: 14px,
        $letter: 1.2px
    );
}

@mixin type-header-utility-link {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: 12px,
        $letter: 1.2px
    );
}

// Product Card

@mixin type-line-item-name {
    @include typography (
        $weight: $font-weight-semi-bold,
        $family: $font-family-second,
        $size: $font-size-base,
        $line: 21px
    );
}

// Search

@mixin type-search-result-count {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-semi-bold,
        $size: $font-size-body,
        $line: 19px,
        $letter: 0.5px
    );
}

@mixin type-sort-options {
    @include typography (
        $family: $font-family-second,
        $weight: $font-weight-regular,
        $size: $font-size-body,
        $line: 19px,
        $letter: 0.5px
    );
}
