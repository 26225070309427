/// tooltipArrowShadow
/// Adds another slightly larger triangle under the tooltip arrow triangle to give the appearence of a shadow
/// @author Matt Meagher
///	@param {string} $tooltipDirection - up, down, left right (which way is the arrow pointing?)
/// @example
///     ex: @include tooltipDirection('up');
///     outputs:
///         &::after {
///        content: '';
///        position: absolute;
///        border-color: transparent;
///        border-style: solid;
///        z-index: 1;
///        top: 0;
///        border-width: $tooltip-arrow-height (($tooltip-arrow-width / 2) + $shadowSpace) 0 ($tooltip-arrow-width / 2);
///        border-top-color: $shadow-shade;
///      }
///    }
$shadowSpace: 10;

@mixin tooltipArrowShadow ($tooltipDirection) {
    &::after {
        content: '';
        position: absolute;
        border-color: transparent;
        border-style: solid;
        z-index: 1;

        @if ($tooltipDirection == 'top') {
            top: 0;
            border-width: $tooltip-arrow-height (($tooltip-arrow-width / 2) + $shadowSpace) 0 ($tooltip-arrow-width / 2);
            border-top-color: $shadow-shade;
        }
        @else if ($tooltipDirection == 'bottom') {
            bottom: 0;
            border-width: 0 (($tooltip-arrow-width / 2) + $shadowSpace) $tooltip-arrow-height ($tooltip-arrow-width / 2);
            border-bottom-color: $shadow-shade;
        }
        @else if ($tooltipDirection == 'left') {
            left: 0;
            border-width: ($tooltip-arrow-width / 2) 0 (($tooltip-arrow-width / 2) + $shadowSpace) $tooltip-arrow-height;
            border-left-color: $shadow-shade;
        }
        @else if ($tooltipDirection == 'right') {
            right: 0;
            border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height (($tooltip-arrow-width / 2 ) + $shadowSpace) 0;
            border-right-color: $shadow-shade;
        }
    }
}
