.blog-nav {
    .nav {
        background: $blog-nav-background-color;
        border-bottom: 1px solid $blog-nav-border-color;
    }

    .nav-item {
        margin: 0;

        .nav-link {
            text-decoration: $blog-nav-item-text-decoration;
            padding: 0.5rem 1rem;
        }

        &.active .nav-link {
            font-weight: $blog-nav-item-active-font-weight;
        }
    }
}

.blog-results {
    padding: $spacer;

    @include media-breakpoint-up(md) {
        max-width: 800px;
        .blog-results-row {
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: 1180px;
    }

    .blog-category-section {
        padding: 20px 0;
    }

    .blog-category-header-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0;

        .blog-category-title {
            margin: 0;
        }

        .view-all-link {
            justify-content: end;
            align-content: center;
        }
    }

    .blog-search-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto;

        .blog-category-dropdown {
            height: 44px;
        }
        @include media-breakpoint-down(sm) {
            .filter-button-column {
                padding: 0;
                .filter-button {
                    height: 40px;
                }
                -webkit-box-flex: 0;
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
            }
            .blog-search-column {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 75%;
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
        .blog-search-column {
            @include media-breakpoint-up(lg) {
                flex: 0 0 40% !important;
                max-width: 40% !important;
            }
            @include media-breakpoint-up(xl) {
                flex: 0 0 42% !important;
                max-width: 42% !important;
            }
            .search-container {
                position: relative;
                padding: 0 0 0 10px;
                .blog-suggestions-wrapper {
                    position: relative;
                }
                .search-icon {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 16px;
                    background-image: url('../images/svg-icons/icon-search-default.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    opacity: 1;
                    transition: opacity 0.3s;
                }
                .search-field:focus + .search-icon {
                    opacity: 0;
                }
                .search-field:blur:placeholder-shown + .search-icon {
                    opacity: 1;
                }
                .blog-search-field {
                    padding-right: 0;
                }
            }
        }
    }

    .blog-refine-bar {
        -webkit-box-flex: 0;
        @include media-breakpoint-down(sm) {
            background-color: $white;
            box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
            display: none;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1;
            height: auto !important;
        }
        @include media-breakpoint-down(xs) {
            position: fixed;
            height: 100%;
        }
        @include media-breakpoint-up(md) {
            margin-top: 55px;
            display: block !important;
        }
    }

    .blog-column-left {
        @include media-breakpoint-up(md) {
            max-width: 22%;
            flex: 0 0 22%;
        }
        @include media-breakpoint-up(lg) {
            max-width: 20%;
            flex: 0 0 20%;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 16%;
            max-width: 16%;
        }
    }

    .blog-column-right {
        @include media-breakpoint-up(md) {
            -webkit-box-flex: 0;
            flex: 0 0 77%;
            max-width: 77%;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 80%;
            max-width: 80%;
        }
        @include media-breakpoint-up(xl) {
            flex: 0 0 84%;
            max-width: 84%;
        }
    }

    .blog-refine-bar {
        .refine-bar {
            @include media-breakpoint-down(sm) {
                margin-left: 15px;
            }
            #accordion {
                .card {
                    .card-body {
                        padding-bottom: 0px !important;
                        .card-header .content-item {
                            padding-left: 15px;
                        }
                    }
                    .card-header {
                        .btn.blog-category-collapsible {
                            width: auto;
                            border: none !important;
                            border-top: none !important;
                        }
                        .content-item {
                            border-top: 1px solid #d7d3d1 !important;
                            .category-link {
                                padding: 15px 0;
                                width: -webkit-fill-available;
                            }
                            .category-link.selected {
                                font-weight: 800;
                            }
                        }
                        .refine-bar-button {
                            height: 30px;
                            text-align: center;
                            border: 2px solid #337321 !important;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
    .result-count {
        padding: $spacer 0;
    }

    .content-row {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }
    .content-tile-container {
        padding: 0 0 10px 10px;

        @include media-breakpoint-down(sm) {
            padding: 0 0 10px 0;
        }

        .content-tile {
            display: flex;
            flex-direction: column;
            height: 100%;
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
            border-top: 1px solid lightgrey;
            overflow: hidden;
            margin-bottom: 0px;

            @include media-breakpoint-down(sm) {
                width: 95%;
                z-index: 1;
                margin: auto;
                margin-bottom: 20px;
            }

            .content-tile-link {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            .content-tile-image-wrapper {
                margin: 6px;
            }

            .content-tile-body {
                padding: 5px 6px 0px 6px;
                flex-grow: 1;

                .content-tile-name {
                    font-weight: bold;
                }

                .content-tile-description {
                    font-size: 0.9em;
                    line-height: 1.5em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 9;
                    -webkit-box-orient: vertical;
                    margin-bottom: 0;
                }
            }
            .blog-icon-wrapper {
                display: flex;
                justify-content: end;
                padding: 0px 6px 2px 0px;
                .blog-icon {
                    width: 10%;
                    padding: 0px 0px 0px 2px;
                    margin-bottom: 5px;

                    @include media-breakpoint-down(sm) {
                        width: 6%;
                    }
                }
            }
        }
    }

}

.blog-detail-header {
    display: block;

    .page-header-subtitle {
        margin-left: 12px;
    }

    &.text-center {
        .page-header-subtitle {
            justify-content: center;
        }
    }

    .page-header-title {
        margin-top: $blog-detail-title-margin-top;
    }

    .blog-header-title {
        display: flex;
    }
    .blog-category {
        color: $blog-detail-category-color;

        ~ .blog-creation-date {
            &::before {
                content: $blog-detail-category-date-separator;
                color: $blog-detail-category-date-separator-color;
                padding-left: $blog-detail-category-date-separator-spacer-left;
                padding-right: $blog-detail-category-date-separator-spacer-right;
            }
        }
    }

    .blog-creation-date {
        color: $blog-detail-date-color;
    }

    .page-header-author {
        margin-top: $blog-detail-author-margin-top;
        color: $blog-detail-author-color;
    }
}

.blog,
.storepage {
    .social-container {
        .social-icons {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            li:not(:last-child) {
                margin-right: $blog-detail-social-icon-spacing;
            }

            .share-icons {
                font-size: $blog-detail-social-icon-size;
                color: $blog-detail-social-icon-color;

                &:hover {
                    color: $blog-detail-social-icon-color-hover;
                }
            }
        }
    }
}

.blog {
    .blog-footer {
        text-align: $blog-detail-footer-text-align;
    }

    .social-container {
        border-top: $blog-detail-social-icons-border-top;
        margin-top: $blog-detail-social-icons-margin-top;
        padding-top: $blog-detail-social-icons-padding-top;
    }

    .search-words-container {
        display: inline-flex;
        justify-content: center;
        margin-top: $blog-detail-search-words-margin-top;
        padding-top: $blog-detail-search-words-padding-top;
        border-top: $blog-detail-search-words-border-top;

        label {
            margin-right: $blog-detail-search-words-spacer-right;
            margin-bottom: 0;
        }

        .search-words {

            @include remove-list-styling;

            display: flex;
            flex-wrap: wrap;

            li:not(:last-child) {
                a::after {
                    content: $blog-detail-search-words-separator;
                    margin-left: $blog-detail-search-words-spacer-left;
                    margin-right: $blog-detail-search-words-spacer-right;
                }
            }
        }
    }
}
