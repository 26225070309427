.alert {
    border-radius: 4px;
    padding: 14px 15px;
    min-height: 55px;

    &::before {
        content: ' ';
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        display: block;
        margin-right: 10px;
        float: left;
    }

    span {
        display: block;
        overflow: hidden;
    }
}

.alert-success::before {
    content: "";
    background-image: $svg-alert-success;
}

.alert-info::before {
    content: "";
    background-image: $svg-alert-info;
}

.alert-warning {
    color: $body-color;

    &::before {
        content: "";
        background-image: $svg-alert-warning;
    }
}

.alert-danger::before {
    content: "";
    background-image: $svg-alert-danger;
}

.qty-alert {
    z-index: $zindex-alert;
}
