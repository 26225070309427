// Line Height Sizer
// ex:  @include line-height(14px);  //sets a line height size in rems with a fallback in pxs

@mixin line-height($size) {
    @if unit($size) == "px" {
        line-height: if($enable-font-px-fallback, $size, null);
        line-height: rem($size);
    } @else {
        line-height: $size;
    }
}

/// Font Sizer
/// Set a font-size rule in rem units, with a fallback to px, and if provided set a relative unitless line-height rule.
/// If a breakpoint is specified, set the font-size to scaling vw units.
/// @author Anthony Smith
///	@param {number} $size - font-size
///	@param {number} $lineheight - line-height
///	@param {number} $breakpoint - a responsive breakpoint at which the font should scale to exactly the defined $size
/// @example
///     ex: @include font-size(14px);
///     outputs:
///         font-size: 14px;
///         font-size: 0.875rem;
///
///     ex2: @include font-size(14px, 20px);
///     outputs:
///         font-size: 14px;
///         font-size: 0.875rem;
///         line-height: 0.7;
///
///     ex3: @include font-size(14px, $breakpoint: $medium-breakpoint);
///     outputs:
///         font-size: 14px;
///         font-size: 0.875rem;
///

@mixin font-sizer($size: null, $lineheight: null, $breakpoint: null) {
    @if $size != "" and $size != null {
        @if type-of($size) == string or $size == 0 or unit($size) != "px" {
            font-size: $size;
        } @else if $breakpoint == null {
            font-size: if($enable-font-px-fallback, $size, null);
            font-size: rem($size);
        } @else {
            // Get a font size in VWs that will match the given pixel font-size in the given viewport.
            font-size: if($enable-font-px-fallback, $size, null);
            font-size: vw($size, $breakpoint);
        }

        @if $lineheight != "" and $lineheight != null and (unit($size) == unit($lineheight) or unitless($lineheight)) {
            $calculated-line-height: $lineheight;
            @if unitless($lineheight) == false {
                $calculated-line-height: (strip-units($lineheight) / strip-units($size));
            }
            @include line-height($calculated-line-height);
        }
    }
}

/// Typography
/// Assign all typography rules needed in one comprehensive mixin.
/// @author Anthony Smith
/// @param {string} $family - font family
/// @param {string} $weight - font-weight
/// @param {number} $size - font-size (desktop)
/// @param {number} $line - line-height (desktop)
/// @param {number} $m-weight - font-weight in the mobile viewport only
/// @param {number} $m-size - font-size in the mobile viewport only
/// @param {number} $m-line - line-height in the mobile viewport only
/// @param {number} $letter - letter-spacing
/// @param {string} $case - text-transform
/// @param {string} $decor - text-decoration
/// @param {string} $style - font-style (ex: italic)
/// @param {string} $breakpoint - the breakpoint between mobile and desktop styles. It defaults to md.
/// @content Additional SCSS to be applied to this typography style
/// @output all typography rules needed for one typography definition, including responsive treatment
/// @requires font-sizer
/// @requires media-breakpoint-up
/// @example
///     @include typography(
///         $family:    $font-family-base,
///         $weight:    $font-weight-bold,
///         $size:      rem(16px),
///         $line:      rem(18px),
///         $letter:    0.1
///         $m-size:    rem(14px),
///         $m-line:    rem(15px)
///     );

@mixin typography($family: null, $weight: null, $size: null, $line: null, $m-family: null, $m-weight: null, $m-size: null, $m-line: null, $letter: null, $case : null, $decor : null, $style: null, $breakpoint : md) {
    // Define font-family & weight
    @if $family != null {
        font-family: $family;
    } @else {
        font-family: $font-family-base;
    }

    @if $m-weight == null {
        // No mobile font-weight style provided, use $weight for both viewports
        font-weight: $weight;
    } @else if $m-weight != null {
        // Mobile weight style was provided, so include the media query
        font-weight: $m-weight;
        @if $weight != null {
            @include media-breakpoint-up($breakpoint) {
                font-weight: $weight;
            }
        }
    }
    @if $m-size == null and $m-line == null {
        // No mobile styles provided, use $size amd $line for both viewports
        @include font-sizer($size, $line);
    } @else {
        // Mobile styles were provided, so include the media query
        $m-size: if($m-size, $m-size, $size);
        $m-line: if($m-line, $m-line, $line);
        @include font-sizer($m-size, $m-line);
        @if $size != null or $line != null {
            @include media-breakpoint-up($breakpoint) {
                @include font-sizer($size, $line);
            }
        }
    }

    letter-spacing: $letter;
    text-transform: $case;
    text-decoration: $decor;
    font-style: $style;
    // Extra styles, if any
    @content;
}
