@charset "UTF-8";
/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #056808;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #222;
  --gray-dark: #222;
  --shadow: #85704d;
  --aubergine: #231f20;
  --barley-corn: #b39659;
  --single-fawn: #6e5a3b;
  --mariner: #355ba8;
  --fire-engine-red: #d2232a;
  --pumpkin: #f47521;
  --pewter: #9e9f9e;
  --nero: #222;
  --night-rider: #353535;
  --hurricane: #888380;
  --mercury: #d7d3d1;
  --white-smoke: #f6f6f6;
  --snow: #fafafa;
  --trans: transparent;
  --black: #000;
  --astral: #31708f;
  --saffron: #fbc02d;
  --falu-red: #92110f;
  --charcoal: #4a4a4a;
  --wild-sand: #e6e2db;
  --hawkes-blue: #d2d7e1;
  --tapa: #76716e;
  --primary: #85704d;
  --secondary: #231f20;
  --success: #056808;
  --info: #31708f;
  --warning: #fbc02d;
  --danger: #92110f;
  --light: #b39659;
  --dark: #6e5a3b;
  --accent-1: #355ba8;
  --accent-2: #d2232a;
  --accent-3: #f47521;
  --accent-4: #9e9f9e;
  --accent-5: #76716e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-th: 1025px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #85704d;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #85704d;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #888380;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 3rem;
}
@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

h2, .h2 {
  font-size: 2.25rem;
}
@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.35rem + 1.2vw);
  }
}

h3, .h3 {
  font-size: 1.75rem;
}
@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4, .h4 {
  font-size: 1.375rem;
}
@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.2625rem + 0.15vw);
  }
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.625rem + 4.5vw);
  }
}

.display-2 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

.display-3 {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #d7d3d1;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #222;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #d7d3d1;
  border-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #222;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #222;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #222;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
}
@media (min-width: 1025px) {
  .container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-th,
.col-th-auto, .col-th-12, .col-th-11, .col-th-10, .col-th-9, .col-th-8, .col-th-7, .col-th-6, .col-th-5, .col-th-4, .col-th-3, .col-th-2, .col-th-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1025px) {
  .col-th {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-th-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-th-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-th-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-th-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-th-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-th-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-th-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-th-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-th-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-th-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-th-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-th-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-th-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-th-first {
    order: -1;
  }
  .order-th-last {
    order: 13;
  }
  .order-th-0 {
    order: 0;
  }
  .order-th-1 {
    order: 1;
  }
  .order-th-2 {
    order: 2;
  }
  .order-th-3 {
    order: 3;
  }
  .order-th-4 {
    order: 4;
  }
  .order-th-5 {
    order: 5;
  }
  .order-th-6 {
    order: 6;
  }
  .order-th-7 {
    order: 7;
  }
  .order-th-8 {
    order: 8;
  }
  .order-th-9 {
    order: 9;
  }
  .order-th-10 {
    order: 10;
  }
  .order-th-11 {
    order: 11;
  }
  .order-th-12 {
    order: 12;
  }
  .offset-th-0 {
    margin-left: 0;
  }
  .offset-th-1 {
    margin-left: 8.3333333333%;
  }
  .offset-th-2 {
    margin-left: 16.6666666667%;
  }
  .offset-th-3 {
    margin-left: 25%;
  }
  .offset-th-4 {
    margin-left: 33.3333333333%;
  }
  .offset-th-5 {
    margin-left: 41.6666666667%;
  }
  .offset-th-6 {
    margin-left: 50%;
  }
  .offset-th-7 {
    margin-left: 58.3333333333%;
  }
  .offset-th-8 {
    margin-left: 66.6666666667%;
  }
  .offset-th-9 {
    margin-left: 75%;
  }
  .offset-th-10 {
    margin-left: 83.3333333333%;
  }
  .offset-th-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #222;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #d7d3d1;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #d7d3d1;
}
.table tbody + tbody {
  border-top: 2px solid #d7d3d1;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #d7d3d1;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #d7d3d1;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #222;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ddd7cd;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c0b5a2;
}

.table-hover .table-primary:hover {
  background-color: #d3cbbe;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d3cbbe;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c1c0c1;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #8d8b8b;
}

.table-hover .table-secondary:hover {
  background-color: #b4b3b4;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b4b3b4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b9d5ba;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7db07f;
}

.table-hover .table-success:hover {
  background-color: #a9cbaa;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a9cbaa;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c5d7e0;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #94b5c5;
}

.table-hover .table-info:hover {
  background-color: #b4ccd7;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b4ccd7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #feedc4;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdde92;
}

.table-hover .table-warning:hover {
  background-color: #fee5ab;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fee5ab;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e0bcbc;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #c68382;
}

.table-hover .table-danger:hover {
  background-color: #d8abab;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #d8abab;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #eae2d1;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #d7c8a9;
}

.table-hover .table-light:hover {
  background-color: #e2d7bf;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e2d7bf;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d6d1c8;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #b4a999;
}

.table-hover .table-dark:hover {
  background-color: #cbc5b9;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #cbc5b9;
}

.table-accent-1,
.table-accent-1 > th,
.table-accent-1 > td {
  background-color: #c6d1e7;
}
.table-accent-1 th,
.table-accent-1 td,
.table-accent-1 thead th,
.table-accent-1 tbody + tbody {
  border-color: #96aad2;
}

.table-hover .table-accent-1:hover {
  background-color: #b4c3df;
}
.table-hover .table-accent-1:hover > td,
.table-hover .table-accent-1:hover > th {
  background-color: #b4c3df;
}

.table-accent-2,
.table-accent-2 > th,
.table-accent-2 > td {
  background-color: #f2c1c3;
}
.table-accent-2 th,
.table-accent-2 td,
.table-accent-2 thead th,
.table-accent-2 tbody + tbody {
  border-color: #e88d90;
}

.table-hover .table-accent-2:hover {
  background-color: #eeacaf;
}
.table-hover .table-accent-2:hover > td,
.table-hover .table-accent-2:hover > th {
  background-color: #eeacaf;
}

.table-accent-3,
.table-accent-3 > th,
.table-accent-3 > td {
  background-color: #fcd8c1;
}
.table-accent-3 th,
.table-accent-3 td,
.table-accent-3 thead th,
.table-accent-3 tbody + tbody {
  border-color: #f9b78c;
}

.table-hover .table-accent-3:hover {
  background-color: #fbc9a9;
}
.table-hover .table-accent-3:hover > td,
.table-hover .table-accent-3:hover > th {
  background-color: #fbc9a9;
}

.table-accent-4,
.table-accent-4 > th,
.table-accent-4 > td {
  background-color: #e4e4e4;
}
.table-accent-4 th,
.table-accent-4 td,
.table-accent-4 thead th,
.table-accent-4 tbody + tbody {
  border-color: #cdcdcd;
}

.table-hover .table-accent-4:hover {
  background-color: #d7d7d7;
}
.table-hover .table-accent-4:hover > td,
.table-hover .table-accent-4:hover > th {
  background-color: #d7d7d7;
}

.table-accent-5,
.table-accent-5 > th,
.table-accent-5 > td {
  background-color: #d9d7d6;
}
.table-accent-5 th,
.table-accent-5 td,
.table-accent-5 thead th,
.table-accent-5 tbody + tbody {
  border-color: #b8b5b4;
}

.table-hover .table-accent-5:hover {
  background-color: #cdcac9;
}
.table-hover .table-accent-5:hover > td,
.table-hover .table-accent-5:hover > th {
  background-color: #cdcac9;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #222;
  border-color: #353535;
}
.table .thead-light th {
  color: #222;
  background-color: #f6f6f6;
  border-color: #d7d3d1;
}

.table-dark {
  color: #fff;
  background-color: #222;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #353535;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1024.98px) {
  .table-responsive-th {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-th > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.8125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d7d3d1;
  border-radius: 0;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #4a4a4a;
  background-color: #fff;
  border-color: #c0af92;
  outline: 0;
  box-shadow: none, 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}
.form-control::placeholder {
  color: #888380;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f6f6f6;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #4a4a4a;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #222;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #888380;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #056808;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 20px 22px;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #056808;
  border-radius: 0;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #056808;
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23056808' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.3125rem);
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #056808;
  box-shadow: 0 0 0 0.2rem rgba(5, 104, 8, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #056808;
  padding-right: calc((1em + 1.25rem) * 3 / 4 + 1.8125rem);
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIwIDE4Ij4gICAgPHBhdGggZmlsbD0iIzRBNEE0QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42MDUgNkw0IDYuNTUgMTAgMTJsNi01LjQ1LS42MDUtLjU1LTUuMzk4IDQuOTA1eiIvPjwvc3ZnPg==) no-repeat right 0.8125rem center/22px 22px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23056808' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.8125rem/calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #056808;
  box-shadow: 0 0 0 0.2rem rgba(5, 104, 8, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #056808;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #056808;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #056808;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #07990c;
  background-color: #07990c;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(5, 104, 8, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #056808;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #056808;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #056808;
  box-shadow: 0 0 0 0.2rem rgba(5, 104, 8, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #92110f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 20px 22px;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #92110f;
  border-radius: 0;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #92110f;
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2392110f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%2392110f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.3125rem);
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #92110f;
  box-shadow: 0 0 0 0.2rem rgba(146, 17, 15, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #92110f;
  padding-right: calc((1em + 1.25rem) * 3 / 4 + 1.8125rem);
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIwIDE4Ij4gICAgPHBhdGggZmlsbD0iIzRBNEE0QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42MDUgNkw0IDYuNTUgMTAgMTJsNi01LjQ1LS42MDUtLjU1LTUuMzk4IDQuOTA1eiIvPjwvc3ZnPg==) no-repeat right 0.8125rem center/22px 22px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2392110f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%2392110f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.8125rem/calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #92110f;
  box-shadow: 0 0 0 0.2rem rgba(146, 17, 15, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #92110f;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #92110f;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #92110f;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #c01614;
  background-color: #c01614;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(146, 17, 15, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #92110f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #92110f;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #92110f;
  box-shadow: 0 0 0 0.2rem rgba(146, 17, 15, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #222;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0;
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #222;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 1;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: 0 0 0 transparent;
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 112, 77, 0.25), 0 0 0 transparent;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #85704d;
  border-color: #85704d;
  box-shadow: 0 0 0 transparent;
}
.btn-primary:hover {
  color: #fff;
  background-color: #6d5c3f;
  border-color: #65553a;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(151, 133, 104, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #85704d;
  border-color: #85704d;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #65553a;
  border-color: #5d4e36;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(151, 133, 104, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
  box-shadow: 0 0 0 transparent;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #0f0d0d;
  border-color: #080707;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(68, 65, 65, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #080707;
  border-color: #010101;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(68, 65, 65, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #056808;
  border-color: #056808;
  box-shadow: 0 0 0 transparent;
}
.btn-success:hover {
  color: #fff;
  background-color: #034405;
  border-color: #033704;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(43, 127, 45, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #056808;
  border-color: #056808;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #033704;
  border-color: #022b03;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(43, 127, 45, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
  box-shadow: 0 0 0 transparent;
}
.btn-info:hover {
  color: #fff;
  background-color: #275a73;
  border-color: #245269;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(80, 133, 160, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #245269;
  border-color: #214b60;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(80, 133, 160, 0.5);
}

.btn-warning {
  color: #222;
  background-color: #fbc02d;
  border-color: #fbc02d;
  box-shadow: 0 0 0 transparent;
}
.btn-warning:hover {
  color: #222;
  background-color: #fab507;
  border-color: #f0ad05;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(218, 168, 43, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #222;
  background-color: #fbc02d;
  border-color: #fbc02d;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #222;
  background-color: #f0ad05;
  border-color: #e4a404;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(218, 168, 43, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #92110f;
  border-color: #92110f;
  box-shadow: 0 0 0 transparent;
}
.btn-danger:hover {
  color: #fff;
  background-color: #6f0d0b;
  border-color: #640c0a;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(162, 53, 51, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #92110f;
  border-color: #92110f;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #640c0a;
  border-color: #580a09;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(162, 53, 51, 0.5);
}

.btn-light {
  color: #222;
  background-color: #b39659;
  border-color: #b39659;
  box-shadow: 0 0 0 transparent;
}
.btn-light:hover {
  color: #fff;
  background-color: #9e8248;
  border-color: #957b44;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(157, 133, 81, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #222;
  background-color: #b39659;
  border-color: #b39659;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #957b44;
  border-color: #8c7440;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(157, 133, 81, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #6e5a3b;
  border-color: #6e5a3b;
  box-shadow: 0 0 0 transparent;
}
.btn-dark:hover {
  color: #fff;
  background-color: #55462e;
  border-color: #4d3f29;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(132, 115, 88, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #6e5a3b;
  border-color: #6e5a3b;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #4d3f29;
  border-color: #453825;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(132, 115, 88, 0.5);
}

.btn-accent-1 {
  color: #fff;
  background-color: #355ba8;
  border-color: #355ba8;
  box-shadow: 0 0 0 transparent;
}
.btn-accent-1:hover {
  color: #fff;
  background-color: #2c4b8b;
  border-color: #294681;
}
.btn-accent-1:focus, .btn-accent-1.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(83, 116, 181, 0.5);
}
.btn-accent-1.disabled, .btn-accent-1:disabled {
  color: #fff;
  background-color: #355ba8;
  border-color: #355ba8;
}
.btn-accent-1:not(:disabled):not(.disabled):active, .btn-accent-1:not(:disabled):not(.disabled).active, .show > .btn-accent-1.dropdown-toggle {
  color: #fff;
  background-color: #294681;
  border-color: #264178;
}
.btn-accent-1:not(:disabled):not(.disabled):active:focus, .btn-accent-1:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(83, 116, 181, 0.5);
}

.btn-accent-2 {
  color: #fff;
  background-color: #d2232a;
  border-color: #d2232a;
  box-shadow: 0 0 0 transparent;
}
.btn-accent-2:hover {
  color: #fff;
  background-color: #b11e23;
  border-color: #a61c21;
}
.btn-accent-2:focus, .btn-accent-2.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(217, 68, 74, 0.5);
}
.btn-accent-2.disabled, .btn-accent-2:disabled {
  color: #fff;
  background-color: #d2232a;
  border-color: #d2232a;
}
.btn-accent-2:not(:disabled):not(.disabled):active, .btn-accent-2:not(:disabled):not(.disabled).active, .show > .btn-accent-2.dropdown-toggle {
  color: #fff;
  background-color: #a61c21;
  border-color: #9b1a1f;
}
.btn-accent-2:not(:disabled):not(.disabled):active:focus, .btn-accent-2:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(217, 68, 74, 0.5);
}

.btn-accent-3 {
  color: #fff;
  background-color: #f47521;
  border-color: #f47521;
  box-shadow: 0 0 0 transparent;
}
.btn-accent-3:hover {
  color: #fff;
  background-color: #e3610b;
  border-color: #d75c0b;
}
.btn-accent-3:focus, .btn-accent-3.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(246, 138, 66, 0.5);
}
.btn-accent-3.disabled, .btn-accent-3:disabled {
  color: #fff;
  background-color: #f47521;
  border-color: #f47521;
}
.btn-accent-3:not(:disabled):not(.disabled):active, .btn-accent-3:not(:disabled):not(.disabled).active, .show > .btn-accent-3.dropdown-toggle {
  color: #fff;
  background-color: #d75c0b;
  border-color: #cb570a;
}
.btn-accent-3:not(:disabled):not(.disabled):active:focus, .btn-accent-3:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(246, 138, 66, 0.5);
}

.btn-accent-4 {
  color: #222;
  background-color: #9e9f9e;
  border-color: #9e9f9e;
  box-shadow: 0 0 0 transparent;
}
.btn-accent-4:hover {
  color: #fff;
  background-color: #8b8c8b;
  border-color: #848684;
}
.btn-accent-4:focus, .btn-accent-4.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(139, 140, 139, 0.5);
}
.btn-accent-4.disabled, .btn-accent-4:disabled {
  color: #222;
  background-color: #9e9f9e;
  border-color: #9e9f9e;
}
.btn-accent-4:not(:disabled):not(.disabled):active, .btn-accent-4:not(:disabled):not(.disabled).active, .show > .btn-accent-4.dropdown-toggle {
  color: #fff;
  background-color: #848684;
  border-color: #7e7f7e;
}
.btn-accent-4:not(:disabled):not(.disabled):active:focus, .btn-accent-4:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-4.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(139, 140, 139, 0.5);
}

.btn-accent-5 {
  color: #fff;
  background-color: #76716e;
  border-color: #76716e;
  box-shadow: 0 0 0 transparent;
}
.btn-accent-5:hover {
  color: #fff;
  background-color: #625e5c;
  border-color: #5c5855;
}
.btn-accent-5:focus, .btn-accent-5.focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(139, 134, 132, 0.5);
}
.btn-accent-5.disabled, .btn-accent-5:disabled {
  color: #fff;
  background-color: #76716e;
  border-color: #76716e;
}
.btn-accent-5:not(:disabled):not(.disabled):active, .btn-accent-5:not(:disabled):not(.disabled).active, .show > .btn-accent-5.dropdown-toggle {
  color: #fff;
  background-color: #5c5855;
  border-color: #55514f;
}
.btn-accent-5:not(:disabled):not(.disabled):active:focus, .btn-accent-5:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-5.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(139, 134, 132, 0.5);
}

.btn-outline-primary {
  color: #85704d;
  border-color: #85704d;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #85704d;
  border-color: #85704d;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 112, 77, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #85704d;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #85704d;
  border-color: #85704d;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(133, 112, 77, 0.5);
}

.btn-outline-secondary {
  color: #231f20;
  border-color: #231f20;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 31, 32, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #231f20;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(35, 31, 32, 0.5);
}

.btn-outline-success {
  color: #056808;
  border-color: #056808;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #056808;
  border-color: #056808;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 104, 8, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #056808;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #056808;
  border-color: #056808;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(5, 104, 8, 0.5);
}

.btn-outline-info {
  color: #31708f;
  border-color: #31708f;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 112, 143, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #31708f;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(49, 112, 143, 0.5);
}

.btn-outline-warning {
  color: #fbc02d;
  border-color: #fbc02d;
}
.btn-outline-warning:hover {
  color: #222;
  background-color: #fbc02d;
  border-color: #fbc02d;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 192, 45, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fbc02d;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #222;
  background-color: #fbc02d;
  border-color: #fbc02d;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(251, 192, 45, 0.5);
}

.btn-outline-danger {
  color: #92110f;
  border-color: #92110f;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #92110f;
  border-color: #92110f;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 17, 15, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #92110f;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #92110f;
  border-color: #92110f;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(146, 17, 15, 0.5);
}

.btn-outline-light {
  color: #b39659;
  border-color: #b39659;
}
.btn-outline-light:hover {
  color: #222;
  background-color: #b39659;
  border-color: #b39659;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 150, 89, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #b39659;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #222;
  background-color: #b39659;
  border-color: #b39659;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(179, 150, 89, 0.5);
}

.btn-outline-dark {
  color: #6e5a3b;
  border-color: #6e5a3b;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #6e5a3b;
  border-color: #6e5a3b;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 90, 59, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #6e5a3b;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #6e5a3b;
  border-color: #6e5a3b;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(110, 90, 59, 0.5);
}

.btn-outline-accent-1 {
  color: #355ba8;
  border-color: #355ba8;
}
.btn-outline-accent-1:hover {
  color: #fff;
  background-color: #355ba8;
  border-color: #355ba8;
}
.btn-outline-accent-1:focus, .btn-outline-accent-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(53, 91, 168, 0.5);
}
.btn-outline-accent-1.disabled, .btn-outline-accent-1:disabled {
  color: #355ba8;
  background-color: transparent;
}
.btn-outline-accent-1:not(:disabled):not(.disabled):active, .btn-outline-accent-1:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-1.dropdown-toggle {
  color: #fff;
  background-color: #355ba8;
  border-color: #355ba8;
}
.btn-outline-accent-1:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-1:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(53, 91, 168, 0.5);
}

.btn-outline-accent-2 {
  color: #d2232a;
  border-color: #d2232a;
}
.btn-outline-accent-2:hover {
  color: #fff;
  background-color: #d2232a;
  border-color: #d2232a;
}
.btn-outline-accent-2:focus, .btn-outline-accent-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 42, 0.5);
}
.btn-outline-accent-2.disabled, .btn-outline-accent-2:disabled {
  color: #d2232a;
  background-color: transparent;
}
.btn-outline-accent-2:not(:disabled):not(.disabled):active, .btn-outline-accent-2:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-2.dropdown-toggle {
  color: #fff;
  background-color: #d2232a;
  border-color: #d2232a;
}
.btn-outline-accent-2:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-2:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(210, 35, 42, 0.5);
}

.btn-outline-accent-3 {
  color: #f47521;
  border-color: #f47521;
}
.btn-outline-accent-3:hover {
  color: #fff;
  background-color: #f47521;
  border-color: #f47521;
}
.btn-outline-accent-3:focus, .btn-outline-accent-3.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 117, 33, 0.5);
}
.btn-outline-accent-3.disabled, .btn-outline-accent-3:disabled {
  color: #f47521;
  background-color: transparent;
}
.btn-outline-accent-3:not(:disabled):not(.disabled):active, .btn-outline-accent-3:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-3.dropdown-toggle {
  color: #fff;
  background-color: #f47521;
  border-color: #f47521;
}
.btn-outline-accent-3:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-3:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(244, 117, 33, 0.5);
}

.btn-outline-accent-4 {
  color: #9e9f9e;
  border-color: #9e9f9e;
}
.btn-outline-accent-4:hover {
  color: #222;
  background-color: #9e9f9e;
  border-color: #9e9f9e;
}
.btn-outline-accent-4:focus, .btn-outline-accent-4.focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 159, 158, 0.5);
}
.btn-outline-accent-4.disabled, .btn-outline-accent-4:disabled {
  color: #9e9f9e;
  background-color: transparent;
}
.btn-outline-accent-4:not(:disabled):not(.disabled):active, .btn-outline-accent-4:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-4.dropdown-toggle {
  color: #222;
  background-color: #9e9f9e;
  border-color: #9e9f9e;
}
.btn-outline-accent-4:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-4:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-4.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(158, 159, 158, 0.5);
}

.btn-outline-accent-5 {
  color: #76716e;
  border-color: #76716e;
}
.btn-outline-accent-5:hover {
  color: #fff;
  background-color: #76716e;
  border-color: #76716e;
}
.btn-outline-accent-5:focus, .btn-outline-accent-5.focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 113, 110, 0.5);
}
.btn-outline-accent-5.disabled, .btn-outline-accent-5:disabled {
  color: #76716e;
  background-color: transparent;
}
.btn-outline-accent-5:not(:disabled):not(.disabled):active, .btn-outline-accent-5:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-5.dropdown-toggle {
  color: #fff;
  background-color: #76716e;
  border-color: #76716e;
}
.btn-outline-accent-5:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-5:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-5.dropdown-toggle:focus {
  box-shadow: 0 0 0 transparent, 0 0 0 0.2rem rgba(118, 113, 110, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #85704d;
  text-decoration: none;
}
.btn-link:hover {
  color: #85704d;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #222;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #222;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1025px) {
  .dropdown-menu-th-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-th-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f6f6f6;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #222;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #f6f6f6;
  text-decoration: none;
  background-color: #f6f6f6;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #85704d;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #222;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #222;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #222;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 0 transparent;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 0.8125rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  text-align: center;
  white-space: nowrap;
  background-color: #f6f6f6;
  border: 1px solid #d7d3d1;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.8125rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #85704d;
  background-color: #85704d;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none, 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #c0af92;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #d2c6b2;
  border-color: #d2c6b2;
  box-shadow: none;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #222;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f6f6f6;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #353535 solid 1px;
  box-shadow: none;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #85704d;
  background-color: #85704d;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(133, 112, 77, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(133, 112, 77, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(133, 112, 77, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #353535;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(133, 112, 77, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.8125rem 0.625rem 0.8125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  vertical-align: middle;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIwIDE4Ij4gICAgPHBhdGggZmlsbD0iIzRBNEE0QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42MDUgNkw0IDYuNTUgMTAgMTJsNi01LjQ1LS42MDUtLjU1LTUuMzk4IDQuOTA1eiIvPjwvc3ZnPg==) no-repeat right 0.8125rem center/22px 22px;
  background-color: #fff;
  border: 1px solid #d7d3d1;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}
.custom-select:focus {
  border-color: #c0af92;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), none;
}
.custom-select:focus::-ms-value {
  color: #4a4a4a;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.8125rem;
  background-image: none;
}
.custom-select:disabled {
  color: #222;
  background-color: #f6f6f6;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #c0af92;
  box-shadow: 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f6f6f6;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  background-color: #fff;
  border: 1px solid #d7d3d1;
  border-radius: 0;
  box-shadow: none;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.25rem);
  padding: 0.625rem 0.8125rem;
  line-height: 1.5;
  color: #4a4a4a;
  content: "Browse";
  background-color: #f6f6f6;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #85704d;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #d2c6b2;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d7d3d1;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #85704d;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #d2c6b2;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d7d3d1;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #85704d;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #d2c6b2;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #d7d3d1;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d7d3d1;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #353535;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #353535;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #353535;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #888380;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #85704d;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #fff;
}
.nav-tabs .nav-link.disabled {
  color: #888380;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #222;
  background-color: #fff;
  border-color: #d7d3d1 #d7d3d1 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #85704d;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1024.98px) {
  .navbar-expand-th > .container,
  .navbar-expand-th > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1025px) {
  .navbar-expand-th {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-th .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-th .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-th .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-th > .container,
  .navbar-expand-th > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-th .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-th .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0 - 1px) calc(0 - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0 - 1px) calc(0 - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 11px;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 11px;
  color: #222;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #85704d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #85704d;
  background-color: #fff;
  border: 1px solid #d7d3d1;
}
.page-link:hover {
  z-index: 2;
  color: #85704d;
  text-decoration: none;
  background-color: #f6f6f6;
  border-color: #d7d3d1;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(133, 112, 77, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #85704d;
  border-color: #85704d;
}
.page-item.disabled .page-link {
  color: #222;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #d7d3d1;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #85704d;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #65553a;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(133, 112, 77, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #231f20;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #080707;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 31, 32, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #056808;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #033704;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(5, 104, 8, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #31708f;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #245269;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 112, 143, 0.5);
}

.badge-warning {
  color: #222;
  background-color: #fbc02d;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #222;
  background-color: #f0ad05;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 192, 45, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #92110f;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #640c0a;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(146, 17, 15, 0.5);
}

.badge-light {
  color: #222;
  background-color: #b39659;
}
a.badge-light:hover, a.badge-light:focus {
  color: #222;
  background-color: #957b44;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(179, 150, 89, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #6e5a3b;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #4d3f29;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(110, 90, 59, 0.5);
}

.badge-accent-1 {
  color: #fff;
  background-color: #355ba8;
}
a.badge-accent-1:hover, a.badge-accent-1:focus {
  color: #fff;
  background-color: #294681;
}
a.badge-accent-1:focus, a.badge-accent-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(53, 91, 168, 0.5);
}

.badge-accent-2 {
  color: #fff;
  background-color: #d2232a;
}
a.badge-accent-2:hover, a.badge-accent-2:focus {
  color: #fff;
  background-color: #a61c21;
}
a.badge-accent-2:focus, a.badge-accent-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(210, 35, 42, 0.5);
}

.badge-accent-3 {
  color: #fff;
  background-color: #f47521;
}
a.badge-accent-3:hover, a.badge-accent-3:focus {
  color: #fff;
  background-color: #d75c0b;
}
a.badge-accent-3:focus, a.badge-accent-3.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 117, 33, 0.5);
}

.badge-accent-4 {
  color: #222;
  background-color: #9e9f9e;
}
a.badge-accent-4:hover, a.badge-accent-4:focus {
  color: #222;
  background-color: #848684;
}
a.badge-accent-4:focus, a.badge-accent-4.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(158, 159, 158, 0.5);
}

.badge-accent-5 {
  color: #fff;
  background-color: #76716e;
}
a.badge-accent-5:hover, a.badge-accent-5:focus {
  color: #fff;
  background-color: #5c5855;
}
a.badge-accent-5:focus, a.badge-accent-5.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(118, 113, 110, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f6f6f6;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #5a4c34;
  background-color: #e7e2db;
  border-color: #ddd7cd;
}
.alert-primary hr {
  border-top-color: #d3cbbe;
}
.alert-primary .alert-link {
  color: #3a3121;
}

.alert-secondary {
  color: #181516;
  background-color: #d3d2d2;
  border-color: #c1c0c1;
}
.alert-secondary hr {
  border-top-color: #b4b3b4;
}
.alert-secondary .alert-link {
  color: black;
}

.alert-success {
  color: #034705;
  background-color: #cde1ce;
  border-color: #b9d5ba;
}
.alert-success hr {
  border-top-color: #a9cbaa;
}
.alert-success .alert-link {
  color: #011602;
}

.alert-info {
  color: #214c61;
  background-color: #d6e2e9;
  border-color: #c5d7e0;
}
.alert-info hr {
  border-top-color: #b4ccd7;
}
.alert-info .alert-link {
  color: #142e3b;
}

.alert-warning {
  color: #ab831f;
  background-color: #fef2d5;
  border-color: #feedc4;
}
.alert-warning hr {
  border-top-color: #fee5ab;
}
.alert-warning .alert-link {
  color: #806217;
}

.alert-danger {
  color: #630c0a;
  background-color: #e9cfcf;
  border-color: #e0bcbc;
}
.alert-danger hr {
  border-top-color: #d8abab;
}
.alert-danger .alert-link {
  color: #350605;
}

.alert-light {
  color: #7a663d;
  background-color: #f0eade;
  border-color: #eae2d1;
}
.alert-light hr {
  border-top-color: #e2d7bf;
}
.alert-light .alert-link {
  color: #584a2c;
}

.alert-dark {
  color: #4b3d28;
  background-color: #e2ded8;
  border-color: #d6d1c8;
}
.alert-dark hr {
  border-top-color: #cbc5b9;
}
.alert-dark .alert-link {
  color: #2a2216;
}

.alert-accent-1 {
  color: #243e72;
  background-color: #d7deee;
  border-color: #c6d1e7;
}
.alert-accent-1 hr {
  border-top-color: #b4c3df;
}
.alert-accent-1 .alert-link {
  color: #18294b;
}

.alert-accent-2 {
  color: #8f181d;
  background-color: #f6d3d4;
  border-color: #f2c1c3;
}
.alert-accent-2 hr {
  border-top-color: #eeacaf;
}
.alert-accent-2 .alert-link {
  color: #631114;
}

.alert-accent-3 {
  color: #a65016;
  background-color: #fde3d3;
  border-color: #fcd8c1;
}
.alert-accent-3 hr {
  border-top-color: #fbc9a9;
}
.alert-accent-3 .alert-link {
  color: #793a10;
}

.alert-accent-4 {
  color: #6b6c6b;
  background-color: #ececec;
  border-color: #e4e4e4;
}
.alert-accent-4 hr {
  border-top-color: #d7d7d7;
}
.alert-accent-4 .alert-link {
  color: #525252;
}

.alert-accent-5 {
  color: #504d4b;
  background-color: #e4e3e2;
  border-color: #d9d7d6;
}
.alert-accent-5 hr {
  border-top-color: #cdcac9;
}
.alert-accent-5 .alert-link {
  color: #363432;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f6f6f6;
  border-radius: 0;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #85704d;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #222;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #222;
  text-decoration: none;
  background-color: #fafafa;
}
.list-group-item-action:active {
  color: #222;
  background-color: #f6f6f6;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #222;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #85704d;
  border-color: #85704d;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1025px) {
  .list-group-horizontal-th {
    flex-direction: row;
  }
  .list-group-horizontal-th .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-th .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-th .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #453a28;
  background-color: #ddd7cd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #453a28;
  background-color: #d3cbbe;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #453a28;
  border-color: #453a28;
}

.list-group-item-secondary {
  color: #121011;
  background-color: #c1c0c1;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #121011;
  background-color: #b4b3b4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #121011;
  border-color: #121011;
}

.list-group-item-success {
  color: #033604;
  background-color: #b9d5ba;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #033604;
  background-color: #a9cbaa;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #033604;
  border-color: #033604;
}

.list-group-item-info {
  color: #193a4a;
  background-color: #c5d7e0;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #193a4a;
  background-color: #b4ccd7;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #193a4a;
  border-color: #193a4a;
}

.list-group-item-warning {
  color: #836417;
  background-color: #feedc4;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #836417;
  background-color: #fee5ab;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #836417;
  border-color: #836417;
}

.list-group-item-danger {
  color: #4c0908;
  background-color: #e0bcbc;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #4c0908;
  background-color: #d8abab;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #4c0908;
  border-color: #4c0908;
}

.list-group-item-light {
  color: #5d4e2e;
  background-color: #eae2d1;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #5d4e2e;
  background-color: #e2d7bf;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #5d4e2e;
  border-color: #5d4e2e;
}

.list-group-item-dark {
  color: #392f1f;
  background-color: #d6d1c8;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #392f1f;
  background-color: #cbc5b9;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #392f1f;
  border-color: #392f1f;
}

.list-group-item-accent-1 {
  color: #1c2f57;
  background-color: #c6d1e7;
}
.list-group-item-accent-1.list-group-item-action:hover, .list-group-item-accent-1.list-group-item-action:focus {
  color: #1c2f57;
  background-color: #b4c3df;
}
.list-group-item-accent-1.list-group-item-action.active {
  color: #fff;
  background-color: #1c2f57;
  border-color: #1c2f57;
}

.list-group-item-accent-2 {
  color: #6d1216;
  background-color: #f2c1c3;
}
.list-group-item-accent-2.list-group-item-action:hover, .list-group-item-accent-2.list-group-item-action:focus {
  color: #6d1216;
  background-color: #eeacaf;
}
.list-group-item-accent-2.list-group-item-action.active {
  color: #fff;
  background-color: #6d1216;
  border-color: #6d1216;
}

.list-group-item-accent-3 {
  color: #7f3d11;
  background-color: #fcd8c1;
}
.list-group-item-accent-3.list-group-item-action:hover, .list-group-item-accent-3.list-group-item-action:focus {
  color: #7f3d11;
  background-color: #fbc9a9;
}
.list-group-item-accent-3.list-group-item-action.active {
  color: #fff;
  background-color: #7f3d11;
  border-color: #7f3d11;
}

.list-group-item-accent-4 {
  color: #525352;
  background-color: #e4e4e4;
}
.list-group-item-accent-4.list-group-item-action:hover, .list-group-item-accent-4.list-group-item-action:focus {
  color: #525352;
  background-color: #d7d7d7;
}
.list-group-item-accent-4.list-group-item-action.active {
  color: #fff;
  background-color: #525352;
  border-color: #525352;
}

.list-group-item-accent-5 {
  color: #3d3b39;
  background-color: #d9d7d6;
}
.list-group-item-accent-5.list-group-item-action:hover, .list-group-item-accent-5.list-group-item-action:focus {
  color: #3d3b39;
  background-color: #cdcac9;
}
.list-group-item-accent-5.list-group-item-action.active {
  color: #fff;
  background-color: #3d3b39;
  border-color: #3d3b39;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #222;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 20px 9px;
  border-bottom: 0 solid transparent;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 10px 20px 9px;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 0 solid transparent;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 35px;
  height: 19px;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 19px 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 19px 17.5px 0;
  border-top-color: #fff;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 19px;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 19px;
  height: 35px;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 17.5px 19px 17.5px 0;
  border-right-color: #fff;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 19px 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 17.5px 19px;
  border-bottom-color: #fff;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 19px;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 19px;
  height: 35px;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 17.5px 0 17.5px 19px;
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: 300px;
  padding: 20px 22px;
  color: #222;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #222;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #85704d !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #65553a !important;
}

.bg-secondary {
  background-color: #231f20 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #080707 !important;
}

.bg-success {
  background-color: #056808 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #033704 !important;
}

.bg-info {
  background-color: #31708f !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #245269 !important;
}

.bg-warning {
  background-color: #fbc02d !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f0ad05 !important;
}

.bg-danger {
  background-color: #92110f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #640c0a !important;
}

.bg-light {
  background-color: #b39659 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #957b44 !important;
}

.bg-dark {
  background-color: #6e5a3b !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #4d3f29 !important;
}

.bg-accent-1 {
  background-color: #355ba8 !important;
}

a.bg-accent-1:hover, a.bg-accent-1:focus,
button.bg-accent-1:hover,
button.bg-accent-1:focus {
  background-color: #294681 !important;
}

.bg-accent-2 {
  background-color: #d2232a !important;
}

a.bg-accent-2:hover, a.bg-accent-2:focus,
button.bg-accent-2:hover,
button.bg-accent-2:focus {
  background-color: #a61c21 !important;
}

.bg-accent-3 {
  background-color: #f47521 !important;
}

a.bg-accent-3:hover, a.bg-accent-3:focus,
button.bg-accent-3:hover,
button.bg-accent-3:focus {
  background-color: #d75c0b !important;
}

.bg-accent-4 {
  background-color: #9e9f9e !important;
}

a.bg-accent-4:hover, a.bg-accent-4:focus,
button.bg-accent-4:hover,
button.bg-accent-4:focus {
  background-color: #848684 !important;
}

.bg-accent-5 {
  background-color: #76716e !important;
}

a.bg-accent-5:hover, a.bg-accent-5:focus,
button.bg-accent-5:hover,
button.bg-accent-5:focus {
  background-color: #5c5855 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #d7d3d1 !important;
}

.border-top {
  border-top: 1px solid #d7d3d1 !important;
}

.border-right {
  border-right: 1px solid #d7d3d1 !important;
}

.border-bottom {
  border-bottom: 1px solid #d7d3d1 !important;
}

.border-left {
  border-left: 1px solid #d7d3d1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #85704d !important;
}

.border-secondary {
  border-color: #231f20 !important;
}

.border-success {
  border-color: #056808 !important;
}

.border-info {
  border-color: #31708f !important;
}

.border-warning {
  border-color: #fbc02d !important;
}

.border-danger {
  border-color: #92110f !important;
}

.border-light {
  border-color: #b39659 !important;
}

.border-dark {
  border-color: #6e5a3b !important;
}

.border-accent-1 {
  border-color: #355ba8 !important;
}

.border-accent-2 {
  border-color: #d2232a !important;
}

.border-accent-3 {
  border-color: #f47521 !important;
}

.border-accent-4 {
  border-color: #9e9f9e !important;
}

.border-accent-5 {
  border-color: #76716e !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1025px) {
  .d-th-none {
    display: none !important;
  }
  .d-th-inline {
    display: inline !important;
  }
  .d-th-inline-block {
    display: inline-block !important;
  }
  .d-th-block {
    display: block !important;
  }
  .d-th-table {
    display: table !important;
  }
  .d-th-table-row {
    display: table-row !important;
  }
  .d-th-table-cell {
    display: table-cell !important;
  }
  .d-th-flex {
    display: flex !important;
  }
  .d-th-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1025px) {
  .flex-th-row {
    flex-direction: row !important;
  }
  .flex-th-column {
    flex-direction: column !important;
  }
  .flex-th-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-th-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-th-wrap {
    flex-wrap: wrap !important;
  }
  .flex-th-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-th-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-th-fill {
    flex: 1 1 auto !important;
  }
  .flex-th-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-th-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-th-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-th-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-th-start {
    justify-content: flex-start !important;
  }
  .justify-content-th-end {
    justify-content: flex-end !important;
  }
  .justify-content-th-center {
    justify-content: center !important;
  }
  .justify-content-th-between {
    justify-content: space-between !important;
  }
  .justify-content-th-around {
    justify-content: space-around !important;
  }
  .align-items-th-start {
    align-items: flex-start !important;
  }
  .align-items-th-end {
    align-items: flex-end !important;
  }
  .align-items-th-center {
    align-items: center !important;
  }
  .align-items-th-baseline {
    align-items: baseline !important;
  }
  .align-items-th-stretch {
    align-items: stretch !important;
  }
  .align-content-th-start {
    align-content: flex-start !important;
  }
  .align-content-th-end {
    align-content: flex-end !important;
  }
  .align-content-th-center {
    align-content: center !important;
  }
  .align-content-th-between {
    align-content: space-between !important;
  }
  .align-content-th-around {
    align-content: space-around !important;
  }
  .align-content-th-stretch {
    align-content: stretch !important;
  }
  .align-self-th-auto {
    align-self: auto !important;
  }
  .align-self-th-start {
    align-self: flex-start !important;
  }
  .align-self-th-end {
    align-self: flex-end !important;
  }
  .align-self-th-center {
    align-self: center !important;
  }
  .align-self-th-baseline {
    align-self: baseline !important;
  }
  .align-self-th-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1025px) {
  .float-th-left {
    float: left !important;
  }
  .float-th-right {
    float: right !important;
  }
  .float-th-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-33 {
  width: 33.3333% !important;
}

.w-66 {
  width: 66.6666% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-33 {
  height: 33.3333% !important;
}

.h-66 {
  height: 66.6666% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.75rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.75rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.75rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.75rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.75rem !important;
}

.m-2 {
  margin: 1.25rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1.25rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1.25rem !important;
}

.m-3 {
  margin: 1.875rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.875rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.875rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.875rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.875rem !important;
}

.m-4 {
  margin: 2.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.5rem !important;
}

.m-5 {
  margin: 3.75rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3.75rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3.75rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3.75rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3.75rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.75rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.75rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.75rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.75rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.75rem !important;
}

.p-2 {
  padding: 1.25rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1.25rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1.25rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1.25rem !important;
}

.p-3 {
  padding: 1.875rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.875rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.875rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.875rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.875rem !important;
}

.p-4 {
  padding: 2.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.5rem !important;
}

.p-5 {
  padding: 3.75rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3.75rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3.75rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3.75rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3.75rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

.m-n1 {
  margin: -0.75rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.75rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.75rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.75rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.75rem !important;
}

.m-n2 {
  margin: -1.25rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1.25rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1.25rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1.25rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1.25rem !important;
}

.m-n3 {
  margin: -1.875rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.875rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.875rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.875rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.875rem !important;
}

.m-n4 {
  margin: -2.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.5rem !important;
}

.m-n5 {
  margin: -3.75rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3.75rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3.75rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3.75rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3.75rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.75rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.75rem !important;
  }
  .m-sm-2 {
    margin: 1.25rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.25rem !important;
  }
  .m-sm-3 {
    margin: 1.875rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.875rem !important;
  }
  .m-sm-4 {
    margin: 2.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.5rem !important;
  }
  .m-sm-5 {
    margin: 3.75rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.75rem !important;
  }
  .m-sm-6 {
    margin: 5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.75rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.75rem !important;
  }
  .p-sm-2 {
    padding: 1.25rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.25rem !important;
  }
  .p-sm-3 {
    padding: 1.875rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.875rem !important;
  }
  .p-sm-4 {
    padding: 2.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.5rem !important;
  }
  .p-sm-5 {
    padding: 3.75rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.75rem !important;
  }
  .p-sm-6 {
    padding: 5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }
  .m-sm-n1 {
    margin: -0.75rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n2 {
    margin: -1.25rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n3 {
    margin: -1.875rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.875rem !important;
  }
  .m-sm-n4 {
    margin: -2.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n5 {
    margin: -3.75rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3.75rem !important;
  }
  .m-sm-n6 {
    margin: -5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.75rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.75rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.75rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.75rem !important;
  }
  .m-md-2 {
    margin: 1.25rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.25rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.25rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.25rem !important;
  }
  .m-md-3 {
    margin: 1.875rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.875rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.875rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.875rem !important;
  }
  .m-md-4 {
    margin: 2.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.5rem !important;
  }
  .m-md-5 {
    margin: 3.75rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.75rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.75rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.75rem !important;
  }
  .m-md-6 {
    margin: 5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.75rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.75rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.75rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.75rem !important;
  }
  .p-md-2 {
    padding: 1.25rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.25rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.25rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.25rem !important;
  }
  .p-md-3 {
    padding: 1.875rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.875rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.875rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.875rem !important;
  }
  .p-md-4 {
    padding: 2.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.5rem !important;
  }
  .p-md-5 {
    padding: 3.75rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.75rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.75rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.75rem !important;
  }
  .p-md-6 {
    padding: 5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }
  .m-md-n1 {
    margin: -0.75rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.75rem !important;
  }
  .m-md-n2 {
    margin: -1.25rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1.25rem !important;
  }
  .m-md-n3 {
    margin: -1.875rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.875rem !important;
  }
  .m-md-n4 {
    margin: -2.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.5rem !important;
  }
  .m-md-n5 {
    margin: -3.75rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3.75rem !important;
  }
  .m-md-n6 {
    margin: -5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.75rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.75rem !important;
  }
  .m-lg-2 {
    margin: 1.25rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.25rem !important;
  }
  .m-lg-3 {
    margin: 1.875rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.875rem !important;
  }
  .m-lg-4 {
    margin: 2.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.5rem !important;
  }
  .m-lg-5 {
    margin: 3.75rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.75rem !important;
  }
  .m-lg-6 {
    margin: 5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.75rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.75rem !important;
  }
  .p-lg-2 {
    padding: 1.25rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.25rem !important;
  }
  .p-lg-3 {
    padding: 1.875rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.875rem !important;
  }
  .p-lg-4 {
    padding: 2.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.5rem !important;
  }
  .p-lg-5 {
    padding: 3.75rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.75rem !important;
  }
  .p-lg-6 {
    padding: 5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }
  .m-lg-n1 {
    margin: -0.75rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n2 {
    margin: -1.25rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n3 {
    margin: -1.875rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.875rem !important;
  }
  .m-lg-n4 {
    margin: -2.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n5 {
    margin: -3.75rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3.75rem !important;
  }
  .m-lg-n6 {
    margin: -5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1025px) {
  .m-th-0 {
    margin: 0 !important;
  }
  .mt-th-0,
  .my-th-0 {
    margin-top: 0 !important;
  }
  .mr-th-0,
  .mx-th-0 {
    margin-right: 0 !important;
  }
  .mb-th-0,
  .my-th-0 {
    margin-bottom: 0 !important;
  }
  .ml-th-0,
  .mx-th-0 {
    margin-left: 0 !important;
  }
  .m-th-1 {
    margin: 0.75rem !important;
  }
  .mt-th-1,
  .my-th-1 {
    margin-top: 0.75rem !important;
  }
  .mr-th-1,
  .mx-th-1 {
    margin-right: 0.75rem !important;
  }
  .mb-th-1,
  .my-th-1 {
    margin-bottom: 0.75rem !important;
  }
  .ml-th-1,
  .mx-th-1 {
    margin-left: 0.75rem !important;
  }
  .m-th-2 {
    margin: 1.25rem !important;
  }
  .mt-th-2,
  .my-th-2 {
    margin-top: 1.25rem !important;
  }
  .mr-th-2,
  .mx-th-2 {
    margin-right: 1.25rem !important;
  }
  .mb-th-2,
  .my-th-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-th-2,
  .mx-th-2 {
    margin-left: 1.25rem !important;
  }
  .m-th-3 {
    margin: 1.875rem !important;
  }
  .mt-th-3,
  .my-th-3 {
    margin-top: 1.875rem !important;
  }
  .mr-th-3,
  .mx-th-3 {
    margin-right: 1.875rem !important;
  }
  .mb-th-3,
  .my-th-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-th-3,
  .mx-th-3 {
    margin-left: 1.875rem !important;
  }
  .m-th-4 {
    margin: 2.5rem !important;
  }
  .mt-th-4,
  .my-th-4 {
    margin-top: 2.5rem !important;
  }
  .mr-th-4,
  .mx-th-4 {
    margin-right: 2.5rem !important;
  }
  .mb-th-4,
  .my-th-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-th-4,
  .mx-th-4 {
    margin-left: 2.5rem !important;
  }
  .m-th-5 {
    margin: 3.75rem !important;
  }
  .mt-th-5,
  .my-th-5 {
    margin-top: 3.75rem !important;
  }
  .mr-th-5,
  .mx-th-5 {
    margin-right: 3.75rem !important;
  }
  .mb-th-5,
  .my-th-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-th-5,
  .mx-th-5 {
    margin-left: 3.75rem !important;
  }
  .m-th-6 {
    margin: 5rem !important;
  }
  .mt-th-6,
  .my-th-6 {
    margin-top: 5rem !important;
  }
  .mr-th-6,
  .mx-th-6 {
    margin-right: 5rem !important;
  }
  .mb-th-6,
  .my-th-6 {
    margin-bottom: 5rem !important;
  }
  .ml-th-6,
  .mx-th-6 {
    margin-left: 5rem !important;
  }
  .p-th-0 {
    padding: 0 !important;
  }
  .pt-th-0,
  .py-th-0 {
    padding-top: 0 !important;
  }
  .pr-th-0,
  .px-th-0 {
    padding-right: 0 !important;
  }
  .pb-th-0,
  .py-th-0 {
    padding-bottom: 0 !important;
  }
  .pl-th-0,
  .px-th-0 {
    padding-left: 0 !important;
  }
  .p-th-1 {
    padding: 0.75rem !important;
  }
  .pt-th-1,
  .py-th-1 {
    padding-top: 0.75rem !important;
  }
  .pr-th-1,
  .px-th-1 {
    padding-right: 0.75rem !important;
  }
  .pb-th-1,
  .py-th-1 {
    padding-bottom: 0.75rem !important;
  }
  .pl-th-1,
  .px-th-1 {
    padding-left: 0.75rem !important;
  }
  .p-th-2 {
    padding: 1.25rem !important;
  }
  .pt-th-2,
  .py-th-2 {
    padding-top: 1.25rem !important;
  }
  .pr-th-2,
  .px-th-2 {
    padding-right: 1.25rem !important;
  }
  .pb-th-2,
  .py-th-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-th-2,
  .px-th-2 {
    padding-left: 1.25rem !important;
  }
  .p-th-3 {
    padding: 1.875rem !important;
  }
  .pt-th-3,
  .py-th-3 {
    padding-top: 1.875rem !important;
  }
  .pr-th-3,
  .px-th-3 {
    padding-right: 1.875rem !important;
  }
  .pb-th-3,
  .py-th-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-th-3,
  .px-th-3 {
    padding-left: 1.875rem !important;
  }
  .p-th-4 {
    padding: 2.5rem !important;
  }
  .pt-th-4,
  .py-th-4 {
    padding-top: 2.5rem !important;
  }
  .pr-th-4,
  .px-th-4 {
    padding-right: 2.5rem !important;
  }
  .pb-th-4,
  .py-th-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-th-4,
  .px-th-4 {
    padding-left: 2.5rem !important;
  }
  .p-th-5 {
    padding: 3.75rem !important;
  }
  .pt-th-5,
  .py-th-5 {
    padding-top: 3.75rem !important;
  }
  .pr-th-5,
  .px-th-5 {
    padding-right: 3.75rem !important;
  }
  .pb-th-5,
  .py-th-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-th-5,
  .px-th-5 {
    padding-left: 3.75rem !important;
  }
  .p-th-6 {
    padding: 5rem !important;
  }
  .pt-th-6,
  .py-th-6 {
    padding-top: 5rem !important;
  }
  .pr-th-6,
  .px-th-6 {
    padding-right: 5rem !important;
  }
  .pb-th-6,
  .py-th-6 {
    padding-bottom: 5rem !important;
  }
  .pl-th-6,
  .px-th-6 {
    padding-left: 5rem !important;
  }
  .m-th-n1 {
    margin: -0.75rem !important;
  }
  .mt-th-n1,
  .my-th-n1 {
    margin-top: -0.75rem !important;
  }
  .mr-th-n1,
  .mx-th-n1 {
    margin-right: -0.75rem !important;
  }
  .mb-th-n1,
  .my-th-n1 {
    margin-bottom: -0.75rem !important;
  }
  .ml-th-n1,
  .mx-th-n1 {
    margin-left: -0.75rem !important;
  }
  .m-th-n2 {
    margin: -1.25rem !important;
  }
  .mt-th-n2,
  .my-th-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-th-n2,
  .mx-th-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-th-n2,
  .my-th-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-th-n2,
  .mx-th-n2 {
    margin-left: -1.25rem !important;
  }
  .m-th-n3 {
    margin: -1.875rem !important;
  }
  .mt-th-n3,
  .my-th-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-th-n3,
  .mx-th-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-th-n3,
  .my-th-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-th-n3,
  .mx-th-n3 {
    margin-left: -1.875rem !important;
  }
  .m-th-n4 {
    margin: -2.5rem !important;
  }
  .mt-th-n4,
  .my-th-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-th-n4,
  .mx-th-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-th-n4,
  .my-th-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-th-n4,
  .mx-th-n4 {
    margin-left: -2.5rem !important;
  }
  .m-th-n5 {
    margin: -3.75rem !important;
  }
  .mt-th-n5,
  .my-th-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-th-n5,
  .mx-th-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-th-n5,
  .my-th-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-th-n5,
  .mx-th-n5 {
    margin-left: -3.75rem !important;
  }
  .m-th-n6 {
    margin: -5rem !important;
  }
  .mt-th-n6,
  .my-th-n6 {
    margin-top: -5rem !important;
  }
  .mr-th-n6,
  .mx-th-n6 {
    margin-right: -5rem !important;
  }
  .mb-th-n6,
  .my-th-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-th-n6,
  .mx-th-n6 {
    margin-left: -5rem !important;
  }
  .m-th-auto {
    margin: auto !important;
  }
  .mt-th-auto,
  .my-th-auto {
    margin-top: auto !important;
  }
  .mr-th-auto,
  .mx-th-auto {
    margin-right: auto !important;
  }
  .mb-th-auto,
  .my-th-auto {
    margin-bottom: auto !important;
  }
  .ml-th-auto,
  .mx-th-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.75rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.75rem !important;
  }
  .m-xl-2 {
    margin: 1.25rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.25rem !important;
  }
  .m-xl-3 {
    margin: 1.875rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.875rem !important;
  }
  .m-xl-4 {
    margin: 2.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.5rem !important;
  }
  .m-xl-5 {
    margin: 3.75rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.75rem !important;
  }
  .m-xl-6 {
    margin: 5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.75rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.75rem !important;
  }
  .p-xl-2 {
    padding: 1.25rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.25rem !important;
  }
  .p-xl-3 {
    padding: 1.875rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.875rem !important;
  }
  .p-xl-4 {
    padding: 2.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.5rem !important;
  }
  .p-xl-5 {
    padding: 3.75rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.75rem !important;
  }
  .p-xl-6 {
    padding: 5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }
  .m-xl-n1 {
    margin: -0.75rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n2 {
    margin: -1.25rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n3 {
    margin: -1.875rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.875rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.875rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.875rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.875rem !important;
  }
  .m-xl-n4 {
    margin: -2.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n5 {
    margin: -3.75rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3.75rem !important;
  }
  .m-xl-n6 {
    margin: -5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1025px) {
  .text-th-left {
    text-align: left !important;
  }
  .text-th-right {
    text-align: right !important;
  }
  .text-th-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #85704d !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #554731 !important;
}

.text-secondary {
  color: #231f20 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important;
}

.text-success {
  color: #056808 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #011f02 !important;
}

.text-info {
  color: #31708f !important;
}

a.text-info:hover, a.text-info:focus {
  color: #1d4356 !important;
}

.text-warning {
  color: #fbc02d !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d79b04 !important;
}

.text-danger {
  color: #92110f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #4d0908 !important;
}

.text-light {
  color: #b39659 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #836c3c !important;
}

.text-dark {
  color: #6e5a3b !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #3c3120 !important;
}

.text-accent-1 {
  color: #355ba8 !important;
}

a.text-accent-1:hover, a.text-accent-1:focus {
  color: #233c6e !important;
}

.text-accent-2 {
  color: #d2232a !important;
}

a.text-accent-2:hover, a.text-accent-2:focus {
  color: #90181d !important;
}

.text-accent-3 {
  color: #f47521 !important;
}

a.text-accent-3:hover, a.text-accent-3:focus {
  color: #bf5209 !important;
}

.text-accent-4 {
  color: #9e9f9e !important;
}

a.text-accent-4:hover, a.text-accent-4:focus {
  color: #787978 !important;
}

.text-accent-5 {
  color: #76716e !important;
}

a.text-accent-5:hover, a.text-accent-5:focus {
  color: #4e4b49 !important;
}

.text-body {
  color: #222 !important;
}

.text-muted {
  color: #888380 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #353535;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #d7d3d1 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #d7d3d1;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #d7d3d1;
  }
}
/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.breadcrumb {
  background-color: #fff;
}

.breadcrumb-item {
  margin: 0;
}
.breadcrumb-item a {
  color: #222;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "|";
  color: #222;
  font-weight: 300;
}
.breadcrumb-item.active {
  color: #85704d;
  font-weight: 600;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  background-color: #85704d;
  color: #fff;
}

button,
.btn {
  border-radius: 0;
  text-transform: uppercase;
  border: none;
  padding-top: 11px;
  padding-bottom: 11px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 1px;
}
button.disabled, button.disabled:hover, button:disabled:hover, button:disabled,
.btn.disabled,
.btn.disabled:hover,
.btn:disabled:hover,
.btn:disabled {
  background: #d7d3d1;
  color: #4a4a4a;
  border: none;
}

.btn-primary {
  border-color: #85704d;
  transition: background 0.2s ease;
}
.btn-primary:active, .btn-primary:hover, .btn-primary:focus {
  background: #6e5a3b;
}

.btn-outline-primary {
  border: 2px solid #85704d;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #fff;
  transition: all 0.2s ease;
}
.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus {
  background-color: #e6e2db;
  color: #85704d;
}

.btn-secondary {
  border: 2px solid #888380;
  color: #85704d;
  padding-top: 9px;
  padding-bottom: 9px;
  background: #fff;
  transition: all 0.2s ease;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  background: #f6f6f6;
  border: 2px solid #888380;
  color: #85704d;
}

.btn-text {
  color: #85704d;
  transition: all 0.2s ease;
}
.btn-text:hover {
  color: #85704d;
  text-decoration: underline;
}

.product-grid .grid-footer .pagination-links .pagination-button {
  margin: 0 3px;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: #888380;
  text-transform: lowercase;
  padding: 5px 16px;
}
@media (min-width: 576px) {
  .product-grid .grid-footer .pagination-links .pagination-button {
    margin: 0 8px;
    padding: 5px 12px;
  }
}
.product-grid .grid-footer .pagination-links .pagination-button.active {
  color: #85704d;
  font-weight: 900;
}

.card {
  margin-bottom: 1em;
}
.card .list-group {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.card .card-title {
  margin-bottom: 15px;
}
.card .card-text {
  margin-bottom: 20px;
}
.card .card-body {
  padding: 20px 20px 15px;
}
.card .card-body .btn {
  width: 100%;
}
.card .card-body ul {
  margin-bottom: -15px;
}
.card .card-body ul li {
  margin: 0;
  border: none;
  border-top: 1px solid #d7d3d1;
}
.card .card-body ul.payment-options {
  margin-bottom: 0;
}
.card .card-body .card-link {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 1px;
}
#accordion .card {
  border: none;
  border-bottom: 1px solid #d7d3d1;
  border-radius: none;
  margin-bottom: 0;
}
#accordion .card:first-of-type {
  border-top: 1px solid #d7d3d1;
}
#accordion .card .card-header {
  padding: 17px 0;
  border: none;
  background-color: #fff;
}
#accordion .card .card-header .btn {
  width: 100%;
  text-align: left;
  padding: 0;
}
#accordion .card .card-header .btn.btn-link {
  color: #222;
  text-decoration: none;
}
#accordion .card .card-header .btn.btn-link:hover {
  text-decoration: none;
}
#accordion .card .card-header .btn.btn-link::after {
  content: "";
  float: right;
  width: 14px;
  height: 14px;
  margin: 0 13px 0 0;
  background: url("../images/svg-icons/icon-minus.svg") no-repeat 0 0;
}
#accordion .card .card-header .btn.btn-link.collapsed::after {
  content: "";
  background: url("../images/svg-icons/icon-plus.svg") no-repeat 0 0;
}
#accordion .card .card-body {
  padding: 0 0 21px;
  margin-left: 0;
  margin-top: -5px;
  width: 90%;
}
.account-global .card, .login-page .card, .checkout-global .card {
  border: none;
  min-width: 0;
}
.account-global .card .card-header, .login-page .card .card-header, .checkout-global .card .card-header {
  border: none;
}
.account-global .card .card-header h2, .login-page .card .card-header h2, .checkout-global .card .card-header h2 {
  color: #6e5a3b;
}
.account-global .card .card-body ul li, .login-page .card .card-body ul li, .checkout-global .card .card-body ul li {
  border-top: none;
  padding: 0;
}
.account-global .card .card-body ul li .nav-link, .login-page .card .card-body ul li .nav-link, .checkout-global .card .card-body ul li .nav-link {
  display: block;
  color: #85704d !important;
}
.account-global .card .card-body ul li .nav-link:hover, .account-global .card .card-body ul li .nav-link.active, .login-page .card .card-body ul li .nav-link:hover, .login-page .card .card-body ul li .nav-link.active, .checkout-global .card .card-body ul li .nav-link:hover, .checkout-global .card .card-body ul li .nav-link.active {
  background-color: transparent;
  color: #6e5a3b !important;
}

.card-link:hover {
  text-decoration: underline;
}

.form-control {
  font-size: 0.875rem;
}
.form-control:focus {
  border-color: #888380;
  box-shadow: none;
}
.form-control::placeholder {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0px;
  color: #888380;
}
.form-control.is-invalid {
  border: 1px solid #92110f;
  background: none;
  box-shadow: none;
}
.form-control.is-invalid:focus {
  box-shadow: none;
}
.form-control:valid {
  border: 1px solid #d7d3d1;
}

.custom-select {
  -webkit-appearance: none;
  line-height: 18px;
  background-position: right 10px center;
}
.custom-select.is-invalid {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIwIDE4Ij4gICAgPHBhdGggZmlsbD0iIzRBNEE0QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42MDUgNkw0IDYuNTUgMTAgMTJsNi01LjQ1LS42MDUtLjU1LTUuMzk4IDQuOTA1eiIvPjwvc3ZnPg==) no-repeat right 0.8125rem center/22px 22px;
}

.form-control,
.custom-select {
  height: 40px;
}

input,
textarea,
select {
  color: #4a4a4a;
}

input[type=email],
input[type=password],
input[type=text],
input[type=tel] {
  -webkit-appearance: none;
}

.modal-dialog:not(.modal-lg, .modal-sm) {
  width: 300px;
  margin: 1.75rem auto;
}
@media (min-width: 768px) {
  .modal-dialog:not(.modal-lg, .modal-sm) {
    width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-dialog:not(.modal-lg, .modal-sm) {
    width: 500px;
  }
}

.modal-content {
  background-clip: padding-box;
  border-radius: 0;
}

.modal-header {
  border-bottom: 0 solid transparent;
  border-top: none;
  padding: 34px 30px 12px;
  position: relative;
}
.modal-header h6 {
  margin-bottom: 0;
}
.modal-header h4 {
  font-size: 1.375rem;
}
.modal-header .close::before {
  content: "";
  display: block;
  background: url("../images/svg-icons/icon-close.svg");
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 21px;
  top: 21px;
}
@media (min-width: 576px) {
  .modal-header .close {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .modal-header {
    padding: 44px 40px 12px;
  }
}

.modal-footer {
  background: #fafafa;
  padding: 20px 15px;
  justify-content: flex-start;
}
.modal-footer button {
  max-width: 130px;
  font-size: 0.8rem;
}
@media (min-width: 768px) {
  .modal-footer {
    padding: 20px 40px;
  }
}

.modal-body {
  padding: 0 30px 30px;
}
.modal-body p {
  margin: 0;
}
@media (min-width: 768px) {
  .modal-body {
    padding: 0 40px 26px;
  }
}
@media (min-width: 992px) {
  .modal-body {
    padding: 0 40px 36px;
  }
}
@media (min-width: 768px) {
  .modal-body:last-child {
    padding-bottom: 36px;
  }
}
.modal-body h3 {
  font-size: 0.9rem;
  letter-spacing: 1px;
}

#requestPasswordResetModal .modal-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
#requestPasswordResetModal .request-password-body > p {
  margin-bottom: 1.25rem;
}

#bonus-item-warning-modal .modal-footer button {
  max-width: 100%;
}
#bonus-item-warning-modal .modal-header {
  padding-bottom: 5px;
}

.nav-link {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #231f20;
  padding: 0.8em 0 0.6em;
  display: inline-block;
  position: relative;
  width: 100%;
}
@media (min-width: 1025px) {
  .nav-link {
    margin-right: 1rem;
    padding: 0;
    color: #fff;
    text-transform: uppercase;
    width: auto;
  }
  .nav-link::after {
    content: "";
    width: 0;
    height: 3px;
    background: #fff;
    position: absolute;
    right: 0;
    bottom: -5px;
    transition: width 0.3s ease;
  }
  .nav-link:hover {
    color: #fff;
  }
  .nav-link:hover::after {
    content: "";
    width: 100%;
    left: 0;
  }
}

.nav-tabs {
  justify-content: space-between;
  flex-direction: column;
}
.nav-tabs .nav-link {
  width: 100%;
  color: #222;
  white-space: nowrap;
  padding: 0.5rem 0;
  text-transform: none;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #222;
  border: none;
  border-bottom: 2px solid #85704d;
}
.nav-tabs .nav-item {
  margin: 0;
}
.nav-tabs .nav-item > .nav-link-override {
  color: #222 !important;
}
.nav-tabs .nav-item > .pdp-chart-header {
  color: #222 !important;
}
.nav-tabs .nav-item > .nav-link {
  color: #222;
}
@media (min-width: 1025px) {
  .nav-tabs {
    border: none;
    flex-direction: row;
  }
}

.experience-component .nav-tabs {
  justify-content: normal;
}

html {
  font-size: 1rem;
}

body {
  font-size: 0.875rem;
}

form {
  font-size: 0.875rem;
}

label {
  font-weight: 700;
}

strong {
  font-weight: 700;
}

ul ul > li {
  list-style-type: disc;
}

ol ol > li {
  list-style-type: upper-alpha;
}

ul > li + li,
ol > li + li {
  margin-top: 10px;
}
ul > li + ul,
ul > li + ol,
ol > li + ul,
ol > li + ol {
  margin-top: 9px;
}
ul ol > li + li,
ul ul > li + li,
ol ol > li + li,
ol ul > li + li {
  margin-top: 5px;
}
ul ol + li,
ul ul + li,
ol ol + li,
ol ul + li {
  margin-top: 18px;
}

hr {
  width: 100%;
}

.height-0 {
  height: 0px;
}

.width-0 {
  width: 0px;
}

.padding-0 {
  padding: 0px;
}

.padding-y-0 {
  padding: 0px 0;
}

.padding-x-0 {
  padding: 0 0px;
}

.padding-t-0 {
  padding-top: 0px;
}

.padding-r-0 {
  padding-right: 0px;
}

.padding-b-0 {
  padding-bottom: 0px;
}

.padding-l-0 {
  padding-left: 0px;
}

.margin-0 {
  margin: 0px;
}

.margin-y-0 {
  margin: 0px 0;
}

.margin-x-0 {
  margin: 0 0px;
}

.margin-t-0 {
  margin-top: 0px;
}

.margin-r-0 {
  margin-right: 0px;
}

.margin-b-0 {
  margin-bottom: 0px;
}

.margin-l-0 {
  margin-left: 0px;
}

.height-2 {
  height: 2px;
}

.width-2 {
  width: 2px;
}

.padding-2 {
  padding: 2px;
}

.padding-y-2 {
  padding: 2px 0;
}

.padding-x-2 {
  padding: 0 2px;
}

.padding-t-2 {
  padding-top: 2px;
}

.padding-r-2 {
  padding-right: 2px;
}

.padding-b-2 {
  padding-bottom: 2px;
}

.padding-l-2 {
  padding-left: 2px;
}

.margin-2 {
  margin: 2px;
}

.margin-y-2 {
  margin: 2px 0;
}

.margin-x-2 {
  margin: 0 2px;
}

.margin-t-2 {
  margin-top: 2px;
}

.margin-r-2 {
  margin-right: 2px;
}

.margin-b-2 {
  margin-bottom: 2px;
}

.margin-l-2 {
  margin-left: 2px;
}

.height-4 {
  height: 4px;
}

.width-4 {
  width: 4px;
}

.padding-4 {
  padding: 4px;
}

.padding-y-4 {
  padding: 4px 0;
}

.padding-x-4 {
  padding: 0 4px;
}

.padding-t-4 {
  padding-top: 4px;
}

.padding-r-4 {
  padding-right: 4px;
}

.padding-b-4 {
  padding-bottom: 4px;
}

.padding-l-4 {
  padding-left: 4px;
}

.margin-4 {
  margin: 4px;
}

.margin-y-4 {
  margin: 4px 0;
}

.margin-x-4 {
  margin: 0 4px;
}

.margin-t-4 {
  margin-top: 4px;
}

.margin-r-4 {
  margin-right: 4px;
}

.margin-b-4 {
  margin-bottom: 4px;
}

.margin-l-4 {
  margin-left: 4px;
}

.height-5 {
  height: 5px;
}

.width-5 {
  width: 5px;
}

.padding-5 {
  padding: 5px;
}

.padding-y-5 {
  padding: 5px 0;
}

.padding-x-5 {
  padding: 0 5px;
}

.padding-t-5 {
  padding-top: 5px;
}

.padding-r-5 {
  padding-right: 5px;
}

.padding-b-5 {
  padding-bottom: 5px;
}

.padding-l-5 {
  padding-left: 5px;
}

.margin-5 {
  margin: 5px;
}

.margin-y-5 {
  margin: 5px 0;
}

.margin-x-5 {
  margin: 0 5px;
}

.margin-t-5 {
  margin-top: 5px;
}

.margin-r-5 {
  margin-right: 5px;
}

.margin-b-5 {
  margin-bottom: 5px;
}

.margin-l-5 {
  margin-left: 5px;
}

.height-8 {
  height: 8px;
}

.width-8 {
  width: 8px;
}

.padding-8 {
  padding: 8px;
}

.padding-y-8 {
  padding: 8px 0;
}

.padding-x-8 {
  padding: 0 8px;
}

.padding-t-8 {
  padding-top: 8px;
}

.padding-r-8 {
  padding-right: 8px;
}

.padding-b-8 {
  padding-bottom: 8px;
}

.padding-l-8 {
  padding-left: 8px;
}

.margin-8 {
  margin: 8px;
}

.margin-y-8 {
  margin: 8px 0;
}

.margin-x-8 {
  margin: 0 8px;
}

.margin-t-8 {
  margin-top: 8px;
}

.margin-r-8 {
  margin-right: 8px;
}

.margin-b-8 {
  margin-bottom: 8px;
}

.margin-l-8 {
  margin-left: 8px;
}

.height-10 {
  height: 10px;
}

.width-10 {
  width: 10px;
}

.padding-10 {
  padding: 10px;
}

.padding-y-10 {
  padding: 10px 0;
}

.padding-x-10 {
  padding: 0 10px;
}

.padding-t-10 {
  padding-top: 10px;
}

.padding-r-10 {
  padding-right: 10px;
}

.padding-b-10 {
  padding-bottom: 10px;
}

.padding-l-10 {
  padding-left: 10px;
}

.margin-10 {
  margin: 10px;
}

.margin-y-10 {
  margin: 10px 0;
}

.margin-x-10 {
  margin: 0 10px;
}

.margin-t-10 {
  margin-top: 10px;
}

.margin-r-10 {
  margin-right: 10px;
}

.margin-b-10 {
  margin-bottom: 10px;
}

.margin-l-10 {
  margin-left: 10px;
}

.height-12 {
  height: 12px;
}

.width-12 {
  width: 12px;
}

.padding-12 {
  padding: 12px;
}

.padding-y-12 {
  padding: 12px 0;
}

.padding-x-12 {
  padding: 0 12px;
}

.padding-t-12 {
  padding-top: 12px;
}

.padding-r-12 {
  padding-right: 12px;
}

.padding-b-12 {
  padding-bottom: 12px;
}

.padding-l-12 {
  padding-left: 12px;
}

.margin-12 {
  margin: 12px;
}

.margin-y-12 {
  margin: 12px 0;
}

.margin-x-12 {
  margin: 0 12px;
}

.margin-t-12 {
  margin-top: 12px;
}

.margin-r-12 {
  margin-right: 12px;
}

.margin-b-12 {
  margin-bottom: 12px;
}

.margin-l-12 {
  margin-left: 12px;
}

.height-14 {
  height: 14px;
}

.width-14 {
  width: 14px;
}

.padding-14 {
  padding: 14px;
}

.padding-y-14 {
  padding: 14px 0;
}

.padding-x-14 {
  padding: 0 14px;
}

.padding-t-14 {
  padding-top: 14px;
}

.padding-r-14 {
  padding-right: 14px;
}

.padding-b-14 {
  padding-bottom: 14px;
}

.padding-l-14 {
  padding-left: 14px;
}

.margin-14 {
  margin: 14px;
}

.margin-y-14 {
  margin: 14px 0;
}

.margin-x-14 {
  margin: 0 14px;
}

.margin-t-14 {
  margin-top: 14px;
}

.margin-r-14 {
  margin-right: 14px;
}

.margin-b-14 {
  margin-bottom: 14px;
}

.margin-l-14 {
  margin-left: 14px;
}

.height-16 {
  height: 16px;
}

.width-16 {
  width: 16px;
}

.padding-16 {
  padding: 16px;
}

.padding-y-16 {
  padding: 16px 0;
}

.padding-x-16 {
  padding: 0 16px;
}

.padding-t-16 {
  padding-top: 16px;
}

.padding-r-16 {
  padding-right: 16px;
}

.padding-b-16 {
  padding-bottom: 16px;
}

.padding-l-16 {
  padding-left: 16px;
}

.margin-16 {
  margin: 16px;
}

.margin-y-16 {
  margin: 16px 0;
}

.margin-x-16 {
  margin: 0 16px;
}

.margin-t-16 {
  margin-top: 16px;
}

.margin-r-16 {
  margin-right: 16px;
}

.margin-b-16 {
  margin-bottom: 16px;
}

.margin-l-16 {
  margin-left: 16px;
}

.height-18 {
  height: 18px;
}

.width-18 {
  width: 18px;
}

.padding-18 {
  padding: 18px;
}

.padding-y-18 {
  padding: 18px 0;
}

.padding-x-18 {
  padding: 0 18px;
}

.padding-t-18 {
  padding-top: 18px;
}

.padding-r-18 {
  padding-right: 18px;
}

.padding-b-18 {
  padding-bottom: 18px;
}

.padding-l-18 {
  padding-left: 18px;
}

.margin-18 {
  margin: 18px;
}

.margin-y-18 {
  margin: 18px 0;
}

.margin-x-18 {
  margin: 0 18px;
}

.margin-t-18 {
  margin-top: 18px;
}

.margin-r-18 {
  margin-right: 18px;
}

.margin-b-18 {
  margin-bottom: 18px;
}

.margin-l-18 {
  margin-left: 18px;
}

.height-19 {
  height: 19px;
}

.width-19 {
  width: 19px;
}

.padding-19 {
  padding: 19px;
}

.padding-y-19 {
  padding: 19px 0;
}

.padding-x-19 {
  padding: 0 19px;
}

.padding-t-19 {
  padding-top: 19px;
}

.padding-r-19 {
  padding-right: 19px;
}

.padding-b-19 {
  padding-bottom: 19px;
}

.padding-l-19 {
  padding-left: 19px;
}

.margin-19 {
  margin: 19px;
}

.margin-y-19 {
  margin: 19px 0;
}

.margin-x-19 {
  margin: 0 19px;
}

.margin-t-19 {
  margin-top: 19px;
}

.margin-r-19 {
  margin-right: 19px;
}

.margin-b-19 {
  margin-bottom: 19px;
}

.margin-l-19 {
  margin-left: 19px;
}

.height-20 {
  height: 20px;
}

.width-20 {
  width: 20px;
}

.padding-20 {
  padding: 20px;
}

.padding-y-20 {
  padding: 20px 0;
}

.padding-x-20 {
  padding: 0 20px;
}

.padding-t-20 {
  padding-top: 20px;
}

.padding-r-20 {
  padding-right: 20px;
}

.padding-b-20 {
  padding-bottom: 20px;
}

.padding-l-20 {
  padding-left: 20px;
}

.margin-20 {
  margin: 20px;
}

.margin-y-20 {
  margin: 20px 0;
}

.margin-x-20 {
  margin: 0 20px;
}

.margin-t-20 {
  margin-top: 20px;
}

.margin-r-20 {
  margin-right: 20px;
}

.margin-b-20 {
  margin-bottom: 20px;
}

.margin-l-20 {
  margin-left: 20px;
}

.height-24 {
  height: 24px;
}

.width-24 {
  width: 24px;
}

.padding-24 {
  padding: 24px;
}

.padding-y-24 {
  padding: 24px 0;
}

.padding-x-24 {
  padding: 0 24px;
}

.padding-t-24 {
  padding-top: 24px;
}

.padding-r-24 {
  padding-right: 24px;
}

.padding-b-24 {
  padding-bottom: 24px;
}

.padding-l-24 {
  padding-left: 24px;
}

.margin-24 {
  margin: 24px;
}

.margin-y-24 {
  margin: 24px 0;
}

.margin-x-24 {
  margin: 0 24px;
}

.margin-t-24 {
  margin-top: 24px;
}

.margin-r-24 {
  margin-right: 24px;
}

.margin-b-24 {
  margin-bottom: 24px;
}

.margin-l-24 {
  margin-left: 24px;
}

.height-26 {
  height: 26px;
}

.width-26 {
  width: 26px;
}

.padding-26 {
  padding: 26px;
}

.padding-y-26 {
  padding: 26px 0;
}

.padding-x-26 {
  padding: 0 26px;
}

.padding-t-26 {
  padding-top: 26px;
}

.padding-r-26 {
  padding-right: 26px;
}

.padding-b-26 {
  padding-bottom: 26px;
}

.padding-l-26 {
  padding-left: 26px;
}

.margin-26 {
  margin: 26px;
}

.margin-y-26 {
  margin: 26px 0;
}

.margin-x-26 {
  margin: 0 26px;
}

.margin-t-26 {
  margin-top: 26px;
}

.margin-r-26 {
  margin-right: 26px;
}

.margin-b-26 {
  margin-bottom: 26px;
}

.margin-l-26 {
  margin-left: 26px;
}

.height-28 {
  height: 28px;
}

.width-28 {
  width: 28px;
}

.padding-28 {
  padding: 28px;
}

.padding-y-28 {
  padding: 28px 0;
}

.padding-x-28 {
  padding: 0 28px;
}

.padding-t-28 {
  padding-top: 28px;
}

.padding-r-28 {
  padding-right: 28px;
}

.padding-b-28 {
  padding-bottom: 28px;
}

.padding-l-28 {
  padding-left: 28px;
}

.margin-28 {
  margin: 28px;
}

.margin-y-28 {
  margin: 28px 0;
}

.margin-x-28 {
  margin: 0 28px;
}

.margin-t-28 {
  margin-top: 28px;
}

.margin-r-28 {
  margin-right: 28px;
}

.margin-b-28 {
  margin-bottom: 28px;
}

.margin-l-28 {
  margin-left: 28px;
}

.height-30 {
  height: 30px;
}

.width-30 {
  width: 30px;
}

.padding-30 {
  padding: 30px;
}

.padding-y-30 {
  padding: 30px 0;
}

.padding-x-30 {
  padding: 0 30px;
}

.padding-t-30 {
  padding-top: 30px;
}

.padding-r-30 {
  padding-right: 30px;
}

.padding-b-30 {
  padding-bottom: 30px;
}

.padding-l-30 {
  padding-left: 30px;
}

.margin-30 {
  margin: 30px;
}

.margin-y-30 {
  margin: 30px 0;
}

.margin-x-30 {
  margin: 0 30px;
}

.margin-t-30 {
  margin-top: 30px;
}

.margin-r-30 {
  margin-right: 30px;
}

.margin-b-30 {
  margin-bottom: 30px;
}

.margin-l-30 {
  margin-left: 30px;
}

.height-32 {
  height: 32px;
}

.width-32 {
  width: 32px;
}

.padding-32 {
  padding: 32px;
}

.padding-y-32 {
  padding: 32px 0;
}

.padding-x-32 {
  padding: 0 32px;
}

.padding-t-32 {
  padding-top: 32px;
}

.padding-r-32 {
  padding-right: 32px;
}

.padding-b-32 {
  padding-bottom: 32px;
}

.padding-l-32 {
  padding-left: 32px;
}

.margin-32 {
  margin: 32px;
}

.margin-y-32 {
  margin: 32px 0;
}

.margin-x-32 {
  margin: 0 32px;
}

.margin-t-32 {
  margin-top: 32px;
}

.margin-r-32 {
  margin-right: 32px;
}

.margin-b-32 {
  margin-bottom: 32px;
}

.margin-l-32 {
  margin-left: 32px;
}

.height-36 {
  height: 36px;
}

.width-36 {
  width: 36px;
}

.padding-36 {
  padding: 36px;
}

.padding-y-36 {
  padding: 36px 0;
}

.padding-x-36 {
  padding: 0 36px;
}

.padding-t-36 {
  padding-top: 36px;
}

.padding-r-36 {
  padding-right: 36px;
}

.padding-b-36 {
  padding-bottom: 36px;
}

.padding-l-36 {
  padding-left: 36px;
}

.margin-36 {
  margin: 36px;
}

.margin-y-36 {
  margin: 36px 0;
}

.margin-x-36 {
  margin: 0 36px;
}

.margin-t-36 {
  margin-top: 36px;
}

.margin-r-36 {
  margin-right: 36px;
}

.margin-b-36 {
  margin-bottom: 36px;
}

.margin-l-36 {
  margin-left: 36px;
}

.height-40 {
  height: 40px;
}

.width-40 {
  width: 40px;
}

.padding-40 {
  padding: 40px;
}

.padding-y-40 {
  padding: 40px 0;
}

.padding-x-40 {
  padding: 0 40px;
}

.padding-t-40 {
  padding-top: 40px;
}

.padding-r-40 {
  padding-right: 40px;
}

.padding-b-40 {
  padding-bottom: 40px;
}

.padding-l-40 {
  padding-left: 40px;
}

.margin-40 {
  margin: 40px;
}

.margin-y-40 {
  margin: 40px 0;
}

.margin-x-40 {
  margin: 0 40px;
}

.margin-t-40 {
  margin-top: 40px;
}

.margin-r-40 {
  margin-right: 40px;
}

.margin-b-40 {
  margin-bottom: 40px;
}

.margin-l-40 {
  margin-left: 40px;
}

.height-43 {
  height: 43px;
}

.width-43 {
  width: 43px;
}

.padding-43 {
  padding: 43px;
}

.padding-y-43 {
  padding: 43px 0;
}

.padding-x-43 {
  padding: 0 43px;
}

.padding-t-43 {
  padding-top: 43px;
}

.padding-r-43 {
  padding-right: 43px;
}

.padding-b-43 {
  padding-bottom: 43px;
}

.padding-l-43 {
  padding-left: 43px;
}

.margin-43 {
  margin: 43px;
}

.margin-y-43 {
  margin: 43px 0;
}

.margin-x-43 {
  margin: 0 43px;
}

.margin-t-43 {
  margin-top: 43px;
}

.margin-r-43 {
  margin-right: 43px;
}

.margin-b-43 {
  margin-bottom: 43px;
}

.margin-l-43 {
  margin-left: 43px;
}

.height-48 {
  height: 48px;
}

.width-48 {
  width: 48px;
}

.padding-48 {
  padding: 48px;
}

.padding-y-48 {
  padding: 48px 0;
}

.padding-x-48 {
  padding: 0 48px;
}

.padding-t-48 {
  padding-top: 48px;
}

.padding-r-48 {
  padding-right: 48px;
}

.padding-b-48 {
  padding-bottom: 48px;
}

.padding-l-48 {
  padding-left: 48px;
}

.margin-48 {
  margin: 48px;
}

.margin-y-48 {
  margin: 48px 0;
}

.margin-x-48 {
  margin: 0 48px;
}

.margin-t-48 {
  margin-top: 48px;
}

.margin-r-48 {
  margin-right: 48px;
}

.margin-b-48 {
  margin-bottom: 48px;
}

.margin-l-48 {
  margin-left: 48px;
}

.height-55 {
  height: 55px;
}

.width-55 {
  width: 55px;
}

.padding-55 {
  padding: 55px;
}

.padding-y-55 {
  padding: 55px 0;
}

.padding-x-55 {
  padding: 0 55px;
}

.padding-t-55 {
  padding-top: 55px;
}

.padding-r-55 {
  padding-right: 55px;
}

.padding-b-55 {
  padding-bottom: 55px;
}

.padding-l-55 {
  padding-left: 55px;
}

.margin-55 {
  margin: 55px;
}

.margin-y-55 {
  margin: 55px 0;
}

.margin-x-55 {
  margin: 0 55px;
}

.margin-t-55 {
  margin-top: 55px;
}

.margin-r-55 {
  margin-right: 55px;
}

.margin-b-55 {
  margin-bottom: 55px;
}

.margin-l-55 {
  margin-left: 55px;
}

.height-58 {
  height: 58px;
}

.width-58 {
  width: 58px;
}

.padding-58 {
  padding: 58px;
}

.padding-y-58 {
  padding: 58px 0;
}

.padding-x-58 {
  padding: 0 58px;
}

.padding-t-58 {
  padding-top: 58px;
}

.padding-r-58 {
  padding-right: 58px;
}

.padding-b-58 {
  padding-bottom: 58px;
}

.padding-l-58 {
  padding-left: 58px;
}

.margin-58 {
  margin: 58px;
}

.margin-y-58 {
  margin: 58px 0;
}

.margin-x-58 {
  margin: 0 58px;
}

.margin-t-58 {
  margin-top: 58px;
}

.margin-r-58 {
  margin-right: 58px;
}

.margin-b-58 {
  margin-bottom: 58px;
}

.margin-l-58 {
  margin-left: 58px;
}

.height-60 {
  height: 60px;
}

.width-60 {
  width: 60px;
}

.padding-60 {
  padding: 60px;
}

.padding-y-60 {
  padding: 60px 0;
}

.padding-x-60 {
  padding: 0 60px;
}

.padding-t-60 {
  padding-top: 60px;
}

.padding-r-60 {
  padding-right: 60px;
}

.padding-b-60 {
  padding-bottom: 60px;
}

.padding-l-60 {
  padding-left: 60px;
}

.margin-60 {
  margin: 60px;
}

.margin-y-60 {
  margin: 60px 0;
}

.margin-x-60 {
  margin: 0 60px;
}

.margin-t-60 {
  margin-top: 60px;
}

.margin-r-60 {
  margin-right: 60px;
}

.margin-b-60 {
  margin-bottom: 60px;
}

.margin-l-60 {
  margin-left: 60px;
}

.height-68 {
  height: 68px;
}

.width-68 {
  width: 68px;
}

.padding-68 {
  padding: 68px;
}

.padding-y-68 {
  padding: 68px 0;
}

.padding-x-68 {
  padding: 0 68px;
}

.padding-t-68 {
  padding-top: 68px;
}

.padding-r-68 {
  padding-right: 68px;
}

.padding-b-68 {
  padding-bottom: 68px;
}

.padding-l-68 {
  padding-left: 68px;
}

.margin-68 {
  margin: 68px;
}

.margin-y-68 {
  margin: 68px 0;
}

.margin-x-68 {
  margin: 0 68px;
}

.margin-t-68 {
  margin-top: 68px;
}

.margin-r-68 {
  margin-right: 68px;
}

.margin-b-68 {
  margin-bottom: 68px;
}

.margin-l-68 {
  margin-left: 68px;
}

.height-70 {
  height: 70px;
}

.width-70 {
  width: 70px;
}

.padding-70 {
  padding: 70px;
}

.padding-y-70 {
  padding: 70px 0;
}

.padding-x-70 {
  padding: 0 70px;
}

.padding-t-70 {
  padding-top: 70px;
}

.padding-r-70 {
  padding-right: 70px;
}

.padding-b-70 {
  padding-bottom: 70px;
}

.padding-l-70 {
  padding-left: 70px;
}

.margin-70 {
  margin: 70px;
}

.margin-y-70 {
  margin: 70px 0;
}

.margin-x-70 {
  margin: 0 70px;
}

.margin-t-70 {
  margin-top: 70px;
}

.margin-r-70 {
  margin-right: 70px;
}

.margin-b-70 {
  margin-bottom: 70px;
}

.margin-l-70 {
  margin-left: 70px;
}

.height-72 {
  height: 72px;
}

.width-72 {
  width: 72px;
}

.padding-72 {
  padding: 72px;
}

.padding-y-72 {
  padding: 72px 0;
}

.padding-x-72 {
  padding: 0 72px;
}

.padding-t-72 {
  padding-top: 72px;
}

.padding-r-72 {
  padding-right: 72px;
}

.padding-b-72 {
  padding-bottom: 72px;
}

.padding-l-72 {
  padding-left: 72px;
}

.margin-72 {
  margin: 72px;
}

.margin-y-72 {
  margin: 72px 0;
}

.margin-x-72 {
  margin: 0 72px;
}

.margin-t-72 {
  margin-top: 72px;
}

.margin-r-72 {
  margin-right: 72px;
}

.margin-b-72 {
  margin-bottom: 72px;
}

.margin-l-72 {
  margin-left: 72px;
}

.height-80 {
  height: 80px;
}

.width-80 {
  width: 80px;
}

.padding-80 {
  padding: 80px;
}

.padding-y-80 {
  padding: 80px 0;
}

.padding-x-80 {
  padding: 0 80px;
}

.padding-t-80 {
  padding-top: 80px;
}

.padding-r-80 {
  padding-right: 80px;
}

.padding-b-80 {
  padding-bottom: 80px;
}

.padding-l-80 {
  padding-left: 80px;
}

.margin-80 {
  margin: 80px;
}

.margin-y-80 {
  margin: 80px 0;
}

.margin-x-80 {
  margin: 0 80px;
}

.margin-t-80 {
  margin-top: 80px;
}

.margin-r-80 {
  margin-right: 80px;
}

.margin-b-80 {
  margin-bottom: 80px;
}

.margin-l-80 {
  margin-left: 80px;
}

.height-86 {
  height: 86px;
}

.width-86 {
  width: 86px;
}

.padding-86 {
  padding: 86px;
}

.padding-y-86 {
  padding: 86px 0;
}

.padding-x-86 {
  padding: 0 86px;
}

.padding-t-86 {
  padding-top: 86px;
}

.padding-r-86 {
  padding-right: 86px;
}

.padding-b-86 {
  padding-bottom: 86px;
}

.padding-l-86 {
  padding-left: 86px;
}

.margin-86 {
  margin: 86px;
}

.margin-y-86 {
  margin: 86px 0;
}

.margin-x-86 {
  margin: 0 86px;
}

.margin-t-86 {
  margin-top: 86px;
}

.margin-r-86 {
  margin-right: 86px;
}

.margin-b-86 {
  margin-bottom: 86px;
}

.margin-l-86 {
  margin-left: 86px;
}

.height-100 {
  height: 100px;
}

.width-100 {
  width: 100px;
}

.padding-100 {
  padding: 100px;
}

.padding-y-100 {
  padding: 100px 0;
}

.padding-x-100 {
  padding: 0 100px;
}

.padding-t-100 {
  padding-top: 100px;
}

.padding-r-100 {
  padding-right: 100px;
}

.padding-b-100 {
  padding-bottom: 100px;
}

.padding-l-100 {
  padding-left: 100px;
}

.margin-100 {
  margin: 100px;
}

.margin-y-100 {
  margin: 100px 0;
}

.margin-x-100 {
  margin: 0 100px;
}

.margin-t-100 {
  margin-top: 100px;
}

.margin-r-100 {
  margin-right: 100px;
}

.margin-b-100 {
  margin-bottom: 100px;
}

.margin-l-100 {
  margin-left: 100px;
}

.height-110 {
  height: 110px;
}

.width-110 {
  width: 110px;
}

.padding-110 {
  padding: 110px;
}

.padding-y-110 {
  padding: 110px 0;
}

.padding-x-110 {
  padding: 0 110px;
}

.padding-t-110 {
  padding-top: 110px;
}

.padding-r-110 {
  padding-right: 110px;
}

.padding-b-110 {
  padding-bottom: 110px;
}

.padding-l-110 {
  padding-left: 110px;
}

.margin-110 {
  margin: 110px;
}

.margin-y-110 {
  margin: 110px 0;
}

.margin-x-110 {
  margin: 0 110px;
}

.margin-t-110 {
  margin-top: 110px;
}

.margin-r-110 {
  margin-right: 110px;
}

.margin-b-110 {
  margin-bottom: 110px;
}

.margin-l-110 {
  margin-left: 110px;
}

.height-120 {
  height: 120px;
}

.width-120 {
  width: 120px;
}

.padding-120 {
  padding: 120px;
}

.padding-y-120 {
  padding: 120px 0;
}

.padding-x-120 {
  padding: 0 120px;
}

.padding-t-120 {
  padding-top: 120px;
}

.padding-r-120 {
  padding-right: 120px;
}

.padding-b-120 {
  padding-bottom: 120px;
}

.padding-l-120 {
  padding-left: 120px;
}

.margin-120 {
  margin: 120px;
}

.margin-y-120 {
  margin: 120px 0;
}

.margin-x-120 {
  margin: 0 120px;
}

.margin-t-120 {
  margin-top: 120px;
}

.margin-r-120 {
  margin-right: 120px;
}

.margin-b-120 {
  margin-bottom: 120px;
}

.margin-l-120 {
  margin-left: 120px;
}

.height-140 {
  height: 140px;
}

.width-140 {
  width: 140px;
}

.padding-140 {
  padding: 140px;
}

.padding-y-140 {
  padding: 140px 0;
}

.padding-x-140 {
  padding: 0 140px;
}

.padding-t-140 {
  padding-top: 140px;
}

.padding-r-140 {
  padding-right: 140px;
}

.padding-b-140 {
  padding-bottom: 140px;
}

.padding-l-140 {
  padding-left: 140px;
}

.margin-140 {
  margin: 140px;
}

.margin-y-140 {
  margin: 140px 0;
}

.margin-x-140 {
  margin: 0 140px;
}

.margin-t-140 {
  margin-top: 140px;
}

.margin-r-140 {
  margin-right: 140px;
}

.margin-b-140 {
  margin-bottom: 140px;
}

.margin-l-140 {
  margin-left: 140px;
}

.height-150 {
  height: 150px;
}

.width-150 {
  width: 150px;
}

.padding-150 {
  padding: 150px;
}

.padding-y-150 {
  padding: 150px 0;
}

.padding-x-150 {
  padding: 0 150px;
}

.padding-t-150 {
  padding-top: 150px;
}

.padding-r-150 {
  padding-right: 150px;
}

.padding-b-150 {
  padding-bottom: 150px;
}

.padding-l-150 {
  padding-left: 150px;
}

.margin-150 {
  margin: 150px;
}

.margin-y-150 {
  margin: 150px 0;
}

.margin-x-150 {
  margin: 0 150px;
}

.margin-t-150 {
  margin-top: 150px;
}

.margin-r-150 {
  margin-right: 150px;
}

.margin-b-150 {
  margin-bottom: 150px;
}

.margin-l-150 {
  margin-left: 150px;
}

.height-200 {
  height: 200px;
}

.width-200 {
  width: 200px;
}

.padding-200 {
  padding: 200px;
}

.padding-y-200 {
  padding: 200px 0;
}

.padding-x-200 {
  padding: 0 200px;
}

.padding-t-200 {
  padding-top: 200px;
}

.padding-r-200 {
  padding-right: 200px;
}

.padding-b-200 {
  padding-bottom: 200px;
}

.padding-l-200 {
  padding-left: 200px;
}

.margin-200 {
  margin: 200px;
}

.margin-y-200 {
  margin: 200px 0;
}

.margin-x-200 {
  margin: 0 200px;
}

.margin-t-200 {
  margin-top: 200px;
}

.margin-r-200 {
  margin-right: 200px;
}

.margin-b-200 {
  margin-bottom: 200px;
}

.margin-l-200 {
  margin-left: 200px;
}

.height-250 {
  height: 250px;
}

.width-250 {
  width: 250px;
}

.padding-250 {
  padding: 250px;
}

.padding-y-250 {
  padding: 250px 0;
}

.padding-x-250 {
  padding: 0 250px;
}

.padding-t-250 {
  padding-top: 250px;
}

.padding-r-250 {
  padding-right: 250px;
}

.padding-b-250 {
  padding-bottom: 250px;
}

.padding-l-250 {
  padding-left: 250px;
}

.margin-250 {
  margin: 250px;
}

.margin-y-250 {
  margin: 250px 0;
}

.margin-x-250 {
  margin: 0 250px;
}

.margin-t-250 {
  margin-top: 250px;
}

.margin-r-250 {
  margin-right: 250px;
}

.margin-b-250 {
  margin-bottom: 250px;
}

.margin-l-250 {
  margin-left: 250px;
}

.height-265 {
  height: 265px;
}

.width-265 {
  width: 265px;
}

.padding-265 {
  padding: 265px;
}

.padding-y-265 {
  padding: 265px 0;
}

.padding-x-265 {
  padding: 0 265px;
}

.padding-t-265 {
  padding-top: 265px;
}

.padding-r-265 {
  padding-right: 265px;
}

.padding-b-265 {
  padding-bottom: 265px;
}

.padding-l-265 {
  padding-left: 265px;
}

.margin-265 {
  margin: 265px;
}

.margin-y-265 {
  margin: 265px 0;
}

.margin-x-265 {
  margin: 0 265px;
}

.margin-t-265 {
  margin-top: 265px;
}

.margin-r-265 {
  margin-right: 265px;
}

.margin-b-265 {
  margin-bottom: 265px;
}

.margin-l-265 {
  margin-left: 265px;
}

@media (max-width: 767.98px) {
  .height-m-0 {
    height: 0px;
  }
  .width-m-0 {
    width: 0px;
  }
  .padding-m-0 {
    padding: 0px;
  }
  .padding-m-y-0 {
    padding: 0px 0;
  }
  .padding-m-x-0 {
    padding: 0 0px;
  }
  .padding-m-t-0 {
    padding-top: 0px;
  }
  .padding-m-r-0 {
    padding-right: 0px;
  }
  .padding-m-b-0 {
    padding-bottom: 0px;
  }
  .padding-m-l-0 {
    padding-left: 0px;
  }
  .margin-m-0 {
    margin: 0px;
  }
  .margin-m-y-0 {
    margin: 0px 0;
  }
  .margin-m-x-0 {
    margin: 0 0px;
  }
  .margin-m-t-0 {
    margin-top: 0px;
  }
  .margin-m-r-0 {
    margin-right: 0px;
  }
  .margin-m-b-0 {
    margin-bottom: 0px;
  }
  .margin-m-l-0 {
    margin-left: 0px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-0 {
    padding-top: 0px;
  }
  .padding-lg-r-0 {
    padding-right: 0px;
  }
  .padding-lg-b-0 {
    padding-bottom: 0px;
  }
  .padding-lg-l-0 {
    padding-left: 0px;
  }
  .margin-lg-t-0 {
    margin-top: 0px;
  }
  .margin-lg-r-0 {
    margin-right: 0px;
  }
  .margin-lg-b-0 {
    margin-bottom: 0px;
  }
  .margin-lg-l-0 {
    margin-left: 0px;
  }
}
@media (max-width: 767.98px) {
  .height-m-2 {
    height: 2px;
  }
  .width-m-2 {
    width: 2px;
  }
  .padding-m-2 {
    padding: 2px;
  }
  .padding-m-y-2 {
    padding: 2px 0;
  }
  .padding-m-x-2 {
    padding: 0 2px;
  }
  .padding-m-t-2 {
    padding-top: 2px;
  }
  .padding-m-r-2 {
    padding-right: 2px;
  }
  .padding-m-b-2 {
    padding-bottom: 2px;
  }
  .padding-m-l-2 {
    padding-left: 2px;
  }
  .margin-m-2 {
    margin: 2px;
  }
  .margin-m-y-2 {
    margin: 2px 0;
  }
  .margin-m-x-2 {
    margin: 0 2px;
  }
  .margin-m-t-2 {
    margin-top: 2px;
  }
  .margin-m-r-2 {
    margin-right: 2px;
  }
  .margin-m-b-2 {
    margin-bottom: 2px;
  }
  .margin-m-l-2 {
    margin-left: 2px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-2 {
    padding-top: 2px;
  }
  .padding-lg-r-2 {
    padding-right: 2px;
  }
  .padding-lg-b-2 {
    padding-bottom: 2px;
  }
  .padding-lg-l-2 {
    padding-left: 2px;
  }
  .margin-lg-t-2 {
    margin-top: 2px;
  }
  .margin-lg-r-2 {
    margin-right: 2px;
  }
  .margin-lg-b-2 {
    margin-bottom: 2px;
  }
  .margin-lg-l-2 {
    margin-left: 2px;
  }
}
@media (max-width: 767.98px) {
  .height-m-4 {
    height: 4px;
  }
  .width-m-4 {
    width: 4px;
  }
  .padding-m-4 {
    padding: 4px;
  }
  .padding-m-y-4 {
    padding: 4px 0;
  }
  .padding-m-x-4 {
    padding: 0 4px;
  }
  .padding-m-t-4 {
    padding-top: 4px;
  }
  .padding-m-r-4 {
    padding-right: 4px;
  }
  .padding-m-b-4 {
    padding-bottom: 4px;
  }
  .padding-m-l-4 {
    padding-left: 4px;
  }
  .margin-m-4 {
    margin: 4px;
  }
  .margin-m-y-4 {
    margin: 4px 0;
  }
  .margin-m-x-4 {
    margin: 0 4px;
  }
  .margin-m-t-4 {
    margin-top: 4px;
  }
  .margin-m-r-4 {
    margin-right: 4px;
  }
  .margin-m-b-4 {
    margin-bottom: 4px;
  }
  .margin-m-l-4 {
    margin-left: 4px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-4 {
    padding-top: 4px;
  }
  .padding-lg-r-4 {
    padding-right: 4px;
  }
  .padding-lg-b-4 {
    padding-bottom: 4px;
  }
  .padding-lg-l-4 {
    padding-left: 4px;
  }
  .margin-lg-t-4 {
    margin-top: 4px;
  }
  .margin-lg-r-4 {
    margin-right: 4px;
  }
  .margin-lg-b-4 {
    margin-bottom: 4px;
  }
  .margin-lg-l-4 {
    margin-left: 4px;
  }
}
@media (max-width: 767.98px) {
  .height-m-5 {
    height: 5px;
  }
  .width-m-5 {
    width: 5px;
  }
  .padding-m-5 {
    padding: 5px;
  }
  .padding-m-y-5 {
    padding: 5px 0;
  }
  .padding-m-x-5 {
    padding: 0 5px;
  }
  .padding-m-t-5 {
    padding-top: 5px;
  }
  .padding-m-r-5 {
    padding-right: 5px;
  }
  .padding-m-b-5 {
    padding-bottom: 5px;
  }
  .padding-m-l-5 {
    padding-left: 5px;
  }
  .margin-m-5 {
    margin: 5px;
  }
  .margin-m-y-5 {
    margin: 5px 0;
  }
  .margin-m-x-5 {
    margin: 0 5px;
  }
  .margin-m-t-5 {
    margin-top: 5px;
  }
  .margin-m-r-5 {
    margin-right: 5px;
  }
  .margin-m-b-5 {
    margin-bottom: 5px;
  }
  .margin-m-l-5 {
    margin-left: 5px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-5 {
    padding-top: 5px;
  }
  .padding-lg-r-5 {
    padding-right: 5px;
  }
  .padding-lg-b-5 {
    padding-bottom: 5px;
  }
  .padding-lg-l-5 {
    padding-left: 5px;
  }
  .margin-lg-t-5 {
    margin-top: 5px;
  }
  .margin-lg-r-5 {
    margin-right: 5px;
  }
  .margin-lg-b-5 {
    margin-bottom: 5px;
  }
  .margin-lg-l-5 {
    margin-left: 5px;
  }
}
@media (max-width: 767.98px) {
  .height-m-8 {
    height: 8px;
  }
  .width-m-8 {
    width: 8px;
  }
  .padding-m-8 {
    padding: 8px;
  }
  .padding-m-y-8 {
    padding: 8px 0;
  }
  .padding-m-x-8 {
    padding: 0 8px;
  }
  .padding-m-t-8 {
    padding-top: 8px;
  }
  .padding-m-r-8 {
    padding-right: 8px;
  }
  .padding-m-b-8 {
    padding-bottom: 8px;
  }
  .padding-m-l-8 {
    padding-left: 8px;
  }
  .margin-m-8 {
    margin: 8px;
  }
  .margin-m-y-8 {
    margin: 8px 0;
  }
  .margin-m-x-8 {
    margin: 0 8px;
  }
  .margin-m-t-8 {
    margin-top: 8px;
  }
  .margin-m-r-8 {
    margin-right: 8px;
  }
  .margin-m-b-8 {
    margin-bottom: 8px;
  }
  .margin-m-l-8 {
    margin-left: 8px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-8 {
    padding-top: 8px;
  }
  .padding-lg-r-8 {
    padding-right: 8px;
  }
  .padding-lg-b-8 {
    padding-bottom: 8px;
  }
  .padding-lg-l-8 {
    padding-left: 8px;
  }
  .margin-lg-t-8 {
    margin-top: 8px;
  }
  .margin-lg-r-8 {
    margin-right: 8px;
  }
  .margin-lg-b-8 {
    margin-bottom: 8px;
  }
  .margin-lg-l-8 {
    margin-left: 8px;
  }
}
@media (max-width: 767.98px) {
  .height-m-10 {
    height: 10px;
  }
  .width-m-10 {
    width: 10px;
  }
  .padding-m-10 {
    padding: 10px;
  }
  .padding-m-y-10 {
    padding: 10px 0;
  }
  .padding-m-x-10 {
    padding: 0 10px;
  }
  .padding-m-t-10 {
    padding-top: 10px;
  }
  .padding-m-r-10 {
    padding-right: 10px;
  }
  .padding-m-b-10 {
    padding-bottom: 10px;
  }
  .padding-m-l-10 {
    padding-left: 10px;
  }
  .margin-m-10 {
    margin: 10px;
  }
  .margin-m-y-10 {
    margin: 10px 0;
  }
  .margin-m-x-10 {
    margin: 0 10px;
  }
  .margin-m-t-10 {
    margin-top: 10px;
  }
  .margin-m-r-10 {
    margin-right: 10px;
  }
  .margin-m-b-10 {
    margin-bottom: 10px;
  }
  .margin-m-l-10 {
    margin-left: 10px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-10 {
    padding-top: 10px;
  }
  .padding-lg-r-10 {
    padding-right: 10px;
  }
  .padding-lg-b-10 {
    padding-bottom: 10px;
  }
  .padding-lg-l-10 {
    padding-left: 10px;
  }
  .margin-lg-t-10 {
    margin-top: 10px;
  }
  .margin-lg-r-10 {
    margin-right: 10px;
  }
  .margin-lg-b-10 {
    margin-bottom: 10px;
  }
  .margin-lg-l-10 {
    margin-left: 10px;
  }
}
@media (max-width: 767.98px) {
  .height-m-12 {
    height: 12px;
  }
  .width-m-12 {
    width: 12px;
  }
  .padding-m-12 {
    padding: 12px;
  }
  .padding-m-y-12 {
    padding: 12px 0;
  }
  .padding-m-x-12 {
    padding: 0 12px;
  }
  .padding-m-t-12 {
    padding-top: 12px;
  }
  .padding-m-r-12 {
    padding-right: 12px;
  }
  .padding-m-b-12 {
    padding-bottom: 12px;
  }
  .padding-m-l-12 {
    padding-left: 12px;
  }
  .margin-m-12 {
    margin: 12px;
  }
  .margin-m-y-12 {
    margin: 12px 0;
  }
  .margin-m-x-12 {
    margin: 0 12px;
  }
  .margin-m-t-12 {
    margin-top: 12px;
  }
  .margin-m-r-12 {
    margin-right: 12px;
  }
  .margin-m-b-12 {
    margin-bottom: 12px;
  }
  .margin-m-l-12 {
    margin-left: 12px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-12 {
    padding-top: 12px;
  }
  .padding-lg-r-12 {
    padding-right: 12px;
  }
  .padding-lg-b-12 {
    padding-bottom: 12px;
  }
  .padding-lg-l-12 {
    padding-left: 12px;
  }
  .margin-lg-t-12 {
    margin-top: 12px;
  }
  .margin-lg-r-12 {
    margin-right: 12px;
  }
  .margin-lg-b-12 {
    margin-bottom: 12px;
  }
  .margin-lg-l-12 {
    margin-left: 12px;
  }
}
@media (max-width: 767.98px) {
  .height-m-14 {
    height: 14px;
  }
  .width-m-14 {
    width: 14px;
  }
  .padding-m-14 {
    padding: 14px;
  }
  .padding-m-y-14 {
    padding: 14px 0;
  }
  .padding-m-x-14 {
    padding: 0 14px;
  }
  .padding-m-t-14 {
    padding-top: 14px;
  }
  .padding-m-r-14 {
    padding-right: 14px;
  }
  .padding-m-b-14 {
    padding-bottom: 14px;
  }
  .padding-m-l-14 {
    padding-left: 14px;
  }
  .margin-m-14 {
    margin: 14px;
  }
  .margin-m-y-14 {
    margin: 14px 0;
  }
  .margin-m-x-14 {
    margin: 0 14px;
  }
  .margin-m-t-14 {
    margin-top: 14px;
  }
  .margin-m-r-14 {
    margin-right: 14px;
  }
  .margin-m-b-14 {
    margin-bottom: 14px;
  }
  .margin-m-l-14 {
    margin-left: 14px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-14 {
    padding-top: 14px;
  }
  .padding-lg-r-14 {
    padding-right: 14px;
  }
  .padding-lg-b-14 {
    padding-bottom: 14px;
  }
  .padding-lg-l-14 {
    padding-left: 14px;
  }
  .margin-lg-t-14 {
    margin-top: 14px;
  }
  .margin-lg-r-14 {
    margin-right: 14px;
  }
  .margin-lg-b-14 {
    margin-bottom: 14px;
  }
  .margin-lg-l-14 {
    margin-left: 14px;
  }
}
@media (max-width: 767.98px) {
  .height-m-16 {
    height: 16px;
  }
  .width-m-16 {
    width: 16px;
  }
  .padding-m-16 {
    padding: 16px;
  }
  .padding-m-y-16 {
    padding: 16px 0;
  }
  .padding-m-x-16 {
    padding: 0 16px;
  }
  .padding-m-t-16 {
    padding-top: 16px;
  }
  .padding-m-r-16 {
    padding-right: 16px;
  }
  .padding-m-b-16 {
    padding-bottom: 16px;
  }
  .padding-m-l-16 {
    padding-left: 16px;
  }
  .margin-m-16 {
    margin: 16px;
  }
  .margin-m-y-16 {
    margin: 16px 0;
  }
  .margin-m-x-16 {
    margin: 0 16px;
  }
  .margin-m-t-16 {
    margin-top: 16px;
  }
  .margin-m-r-16 {
    margin-right: 16px;
  }
  .margin-m-b-16 {
    margin-bottom: 16px;
  }
  .margin-m-l-16 {
    margin-left: 16px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-16 {
    padding-top: 16px;
  }
  .padding-lg-r-16 {
    padding-right: 16px;
  }
  .padding-lg-b-16 {
    padding-bottom: 16px;
  }
  .padding-lg-l-16 {
    padding-left: 16px;
  }
  .margin-lg-t-16 {
    margin-top: 16px;
  }
  .margin-lg-r-16 {
    margin-right: 16px;
  }
  .margin-lg-b-16 {
    margin-bottom: 16px;
  }
  .margin-lg-l-16 {
    margin-left: 16px;
  }
}
@media (max-width: 767.98px) {
  .height-m-18 {
    height: 18px;
  }
  .width-m-18 {
    width: 18px;
  }
  .padding-m-18 {
    padding: 18px;
  }
  .padding-m-y-18 {
    padding: 18px 0;
  }
  .padding-m-x-18 {
    padding: 0 18px;
  }
  .padding-m-t-18 {
    padding-top: 18px;
  }
  .padding-m-r-18 {
    padding-right: 18px;
  }
  .padding-m-b-18 {
    padding-bottom: 18px;
  }
  .padding-m-l-18 {
    padding-left: 18px;
  }
  .margin-m-18 {
    margin: 18px;
  }
  .margin-m-y-18 {
    margin: 18px 0;
  }
  .margin-m-x-18 {
    margin: 0 18px;
  }
  .margin-m-t-18 {
    margin-top: 18px;
  }
  .margin-m-r-18 {
    margin-right: 18px;
  }
  .margin-m-b-18 {
    margin-bottom: 18px;
  }
  .margin-m-l-18 {
    margin-left: 18px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-18 {
    padding-top: 18px;
  }
  .padding-lg-r-18 {
    padding-right: 18px;
  }
  .padding-lg-b-18 {
    padding-bottom: 18px;
  }
  .padding-lg-l-18 {
    padding-left: 18px;
  }
  .margin-lg-t-18 {
    margin-top: 18px;
  }
  .margin-lg-r-18 {
    margin-right: 18px;
  }
  .margin-lg-b-18 {
    margin-bottom: 18px;
  }
  .margin-lg-l-18 {
    margin-left: 18px;
  }
}
@media (max-width: 767.98px) {
  .height-m-19 {
    height: 19px;
  }
  .width-m-19 {
    width: 19px;
  }
  .padding-m-19 {
    padding: 19px;
  }
  .padding-m-y-19 {
    padding: 19px 0;
  }
  .padding-m-x-19 {
    padding: 0 19px;
  }
  .padding-m-t-19 {
    padding-top: 19px;
  }
  .padding-m-r-19 {
    padding-right: 19px;
  }
  .padding-m-b-19 {
    padding-bottom: 19px;
  }
  .padding-m-l-19 {
    padding-left: 19px;
  }
  .margin-m-19 {
    margin: 19px;
  }
  .margin-m-y-19 {
    margin: 19px 0;
  }
  .margin-m-x-19 {
    margin: 0 19px;
  }
  .margin-m-t-19 {
    margin-top: 19px;
  }
  .margin-m-r-19 {
    margin-right: 19px;
  }
  .margin-m-b-19 {
    margin-bottom: 19px;
  }
  .margin-m-l-19 {
    margin-left: 19px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-19 {
    padding-top: 19px;
  }
  .padding-lg-r-19 {
    padding-right: 19px;
  }
  .padding-lg-b-19 {
    padding-bottom: 19px;
  }
  .padding-lg-l-19 {
    padding-left: 19px;
  }
  .margin-lg-t-19 {
    margin-top: 19px;
  }
  .margin-lg-r-19 {
    margin-right: 19px;
  }
  .margin-lg-b-19 {
    margin-bottom: 19px;
  }
  .margin-lg-l-19 {
    margin-left: 19px;
  }
}
@media (max-width: 767.98px) {
  .height-m-20 {
    height: 20px;
  }
  .width-m-20 {
    width: 20px;
  }
  .padding-m-20 {
    padding: 20px;
  }
  .padding-m-y-20 {
    padding: 20px 0;
  }
  .padding-m-x-20 {
    padding: 0 20px;
  }
  .padding-m-t-20 {
    padding-top: 20px;
  }
  .padding-m-r-20 {
    padding-right: 20px;
  }
  .padding-m-b-20 {
    padding-bottom: 20px;
  }
  .padding-m-l-20 {
    padding-left: 20px;
  }
  .margin-m-20 {
    margin: 20px;
  }
  .margin-m-y-20 {
    margin: 20px 0;
  }
  .margin-m-x-20 {
    margin: 0 20px;
  }
  .margin-m-t-20 {
    margin-top: 20px;
  }
  .margin-m-r-20 {
    margin-right: 20px;
  }
  .margin-m-b-20 {
    margin-bottom: 20px;
  }
  .margin-m-l-20 {
    margin-left: 20px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-20 {
    padding-top: 20px;
  }
  .padding-lg-r-20 {
    padding-right: 20px;
  }
  .padding-lg-b-20 {
    padding-bottom: 20px;
  }
  .padding-lg-l-20 {
    padding-left: 20px;
  }
  .margin-lg-t-20 {
    margin-top: 20px;
  }
  .margin-lg-r-20 {
    margin-right: 20px;
  }
  .margin-lg-b-20 {
    margin-bottom: 20px;
  }
  .margin-lg-l-20 {
    margin-left: 20px;
  }
}
@media (max-width: 767.98px) {
  .height-m-24 {
    height: 24px;
  }
  .width-m-24 {
    width: 24px;
  }
  .padding-m-24 {
    padding: 24px;
  }
  .padding-m-y-24 {
    padding: 24px 0;
  }
  .padding-m-x-24 {
    padding: 0 24px;
  }
  .padding-m-t-24 {
    padding-top: 24px;
  }
  .padding-m-r-24 {
    padding-right: 24px;
  }
  .padding-m-b-24 {
    padding-bottom: 24px;
  }
  .padding-m-l-24 {
    padding-left: 24px;
  }
  .margin-m-24 {
    margin: 24px;
  }
  .margin-m-y-24 {
    margin: 24px 0;
  }
  .margin-m-x-24 {
    margin: 0 24px;
  }
  .margin-m-t-24 {
    margin-top: 24px;
  }
  .margin-m-r-24 {
    margin-right: 24px;
  }
  .margin-m-b-24 {
    margin-bottom: 24px;
  }
  .margin-m-l-24 {
    margin-left: 24px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-24 {
    padding-top: 24px;
  }
  .padding-lg-r-24 {
    padding-right: 24px;
  }
  .padding-lg-b-24 {
    padding-bottom: 24px;
  }
  .padding-lg-l-24 {
    padding-left: 24px;
  }
  .margin-lg-t-24 {
    margin-top: 24px;
  }
  .margin-lg-r-24 {
    margin-right: 24px;
  }
  .margin-lg-b-24 {
    margin-bottom: 24px;
  }
  .margin-lg-l-24 {
    margin-left: 24px;
  }
}
@media (max-width: 767.98px) {
  .height-m-26 {
    height: 26px;
  }
  .width-m-26 {
    width: 26px;
  }
  .padding-m-26 {
    padding: 26px;
  }
  .padding-m-y-26 {
    padding: 26px 0;
  }
  .padding-m-x-26 {
    padding: 0 26px;
  }
  .padding-m-t-26 {
    padding-top: 26px;
  }
  .padding-m-r-26 {
    padding-right: 26px;
  }
  .padding-m-b-26 {
    padding-bottom: 26px;
  }
  .padding-m-l-26 {
    padding-left: 26px;
  }
  .margin-m-26 {
    margin: 26px;
  }
  .margin-m-y-26 {
    margin: 26px 0;
  }
  .margin-m-x-26 {
    margin: 0 26px;
  }
  .margin-m-t-26 {
    margin-top: 26px;
  }
  .margin-m-r-26 {
    margin-right: 26px;
  }
  .margin-m-b-26 {
    margin-bottom: 26px;
  }
  .margin-m-l-26 {
    margin-left: 26px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-26 {
    padding-top: 26px;
  }
  .padding-lg-r-26 {
    padding-right: 26px;
  }
  .padding-lg-b-26 {
    padding-bottom: 26px;
  }
  .padding-lg-l-26 {
    padding-left: 26px;
  }
  .margin-lg-t-26 {
    margin-top: 26px;
  }
  .margin-lg-r-26 {
    margin-right: 26px;
  }
  .margin-lg-b-26 {
    margin-bottom: 26px;
  }
  .margin-lg-l-26 {
    margin-left: 26px;
  }
}
@media (max-width: 767.98px) {
  .height-m-28 {
    height: 28px;
  }
  .width-m-28 {
    width: 28px;
  }
  .padding-m-28 {
    padding: 28px;
  }
  .padding-m-y-28 {
    padding: 28px 0;
  }
  .padding-m-x-28 {
    padding: 0 28px;
  }
  .padding-m-t-28 {
    padding-top: 28px;
  }
  .padding-m-r-28 {
    padding-right: 28px;
  }
  .padding-m-b-28 {
    padding-bottom: 28px;
  }
  .padding-m-l-28 {
    padding-left: 28px;
  }
  .margin-m-28 {
    margin: 28px;
  }
  .margin-m-y-28 {
    margin: 28px 0;
  }
  .margin-m-x-28 {
    margin: 0 28px;
  }
  .margin-m-t-28 {
    margin-top: 28px;
  }
  .margin-m-r-28 {
    margin-right: 28px;
  }
  .margin-m-b-28 {
    margin-bottom: 28px;
  }
  .margin-m-l-28 {
    margin-left: 28px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-28 {
    padding-top: 28px;
  }
  .padding-lg-r-28 {
    padding-right: 28px;
  }
  .padding-lg-b-28 {
    padding-bottom: 28px;
  }
  .padding-lg-l-28 {
    padding-left: 28px;
  }
  .margin-lg-t-28 {
    margin-top: 28px;
  }
  .margin-lg-r-28 {
    margin-right: 28px;
  }
  .margin-lg-b-28 {
    margin-bottom: 28px;
  }
  .margin-lg-l-28 {
    margin-left: 28px;
  }
}
@media (max-width: 767.98px) {
  .height-m-30 {
    height: 30px;
  }
  .width-m-30 {
    width: 30px;
  }
  .padding-m-30 {
    padding: 30px;
  }
  .padding-m-y-30 {
    padding: 30px 0;
  }
  .padding-m-x-30 {
    padding: 0 30px;
  }
  .padding-m-t-30 {
    padding-top: 30px;
  }
  .padding-m-r-30 {
    padding-right: 30px;
  }
  .padding-m-b-30 {
    padding-bottom: 30px;
  }
  .padding-m-l-30 {
    padding-left: 30px;
  }
  .margin-m-30 {
    margin: 30px;
  }
  .margin-m-y-30 {
    margin: 30px 0;
  }
  .margin-m-x-30 {
    margin: 0 30px;
  }
  .margin-m-t-30 {
    margin-top: 30px;
  }
  .margin-m-r-30 {
    margin-right: 30px;
  }
  .margin-m-b-30 {
    margin-bottom: 30px;
  }
  .margin-m-l-30 {
    margin-left: 30px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-30 {
    padding-top: 30px;
  }
  .padding-lg-r-30 {
    padding-right: 30px;
  }
  .padding-lg-b-30 {
    padding-bottom: 30px;
  }
  .padding-lg-l-30 {
    padding-left: 30px;
  }
  .margin-lg-t-30 {
    margin-top: 30px;
  }
  .margin-lg-r-30 {
    margin-right: 30px;
  }
  .margin-lg-b-30 {
    margin-bottom: 30px;
  }
  .margin-lg-l-30 {
    margin-left: 30px;
  }
}
@media (max-width: 767.98px) {
  .height-m-32 {
    height: 32px;
  }
  .width-m-32 {
    width: 32px;
  }
  .padding-m-32 {
    padding: 32px;
  }
  .padding-m-y-32 {
    padding: 32px 0;
  }
  .padding-m-x-32 {
    padding: 0 32px;
  }
  .padding-m-t-32 {
    padding-top: 32px;
  }
  .padding-m-r-32 {
    padding-right: 32px;
  }
  .padding-m-b-32 {
    padding-bottom: 32px;
  }
  .padding-m-l-32 {
    padding-left: 32px;
  }
  .margin-m-32 {
    margin: 32px;
  }
  .margin-m-y-32 {
    margin: 32px 0;
  }
  .margin-m-x-32 {
    margin: 0 32px;
  }
  .margin-m-t-32 {
    margin-top: 32px;
  }
  .margin-m-r-32 {
    margin-right: 32px;
  }
  .margin-m-b-32 {
    margin-bottom: 32px;
  }
  .margin-m-l-32 {
    margin-left: 32px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-32 {
    padding-top: 32px;
  }
  .padding-lg-r-32 {
    padding-right: 32px;
  }
  .padding-lg-b-32 {
    padding-bottom: 32px;
  }
  .padding-lg-l-32 {
    padding-left: 32px;
  }
  .margin-lg-t-32 {
    margin-top: 32px;
  }
  .margin-lg-r-32 {
    margin-right: 32px;
  }
  .margin-lg-b-32 {
    margin-bottom: 32px;
  }
  .margin-lg-l-32 {
    margin-left: 32px;
  }
}
@media (max-width: 767.98px) {
  .height-m-36 {
    height: 36px;
  }
  .width-m-36 {
    width: 36px;
  }
  .padding-m-36 {
    padding: 36px;
  }
  .padding-m-y-36 {
    padding: 36px 0;
  }
  .padding-m-x-36 {
    padding: 0 36px;
  }
  .padding-m-t-36 {
    padding-top: 36px;
  }
  .padding-m-r-36 {
    padding-right: 36px;
  }
  .padding-m-b-36 {
    padding-bottom: 36px;
  }
  .padding-m-l-36 {
    padding-left: 36px;
  }
  .margin-m-36 {
    margin: 36px;
  }
  .margin-m-y-36 {
    margin: 36px 0;
  }
  .margin-m-x-36 {
    margin: 0 36px;
  }
  .margin-m-t-36 {
    margin-top: 36px;
  }
  .margin-m-r-36 {
    margin-right: 36px;
  }
  .margin-m-b-36 {
    margin-bottom: 36px;
  }
  .margin-m-l-36 {
    margin-left: 36px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-36 {
    padding-top: 36px;
  }
  .padding-lg-r-36 {
    padding-right: 36px;
  }
  .padding-lg-b-36 {
    padding-bottom: 36px;
  }
  .padding-lg-l-36 {
    padding-left: 36px;
  }
  .margin-lg-t-36 {
    margin-top: 36px;
  }
  .margin-lg-r-36 {
    margin-right: 36px;
  }
  .margin-lg-b-36 {
    margin-bottom: 36px;
  }
  .margin-lg-l-36 {
    margin-left: 36px;
  }
}
@media (max-width: 767.98px) {
  .height-m-40 {
    height: 40px;
  }
  .width-m-40 {
    width: 40px;
  }
  .padding-m-40 {
    padding: 40px;
  }
  .padding-m-y-40 {
    padding: 40px 0;
  }
  .padding-m-x-40 {
    padding: 0 40px;
  }
  .padding-m-t-40 {
    padding-top: 40px;
  }
  .padding-m-r-40 {
    padding-right: 40px;
  }
  .padding-m-b-40 {
    padding-bottom: 40px;
  }
  .padding-m-l-40 {
    padding-left: 40px;
  }
  .margin-m-40 {
    margin: 40px;
  }
  .margin-m-y-40 {
    margin: 40px 0;
  }
  .margin-m-x-40 {
    margin: 0 40px;
  }
  .margin-m-t-40 {
    margin-top: 40px;
  }
  .margin-m-r-40 {
    margin-right: 40px;
  }
  .margin-m-b-40 {
    margin-bottom: 40px;
  }
  .margin-m-l-40 {
    margin-left: 40px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-40 {
    padding-top: 40px;
  }
  .padding-lg-r-40 {
    padding-right: 40px;
  }
  .padding-lg-b-40 {
    padding-bottom: 40px;
  }
  .padding-lg-l-40 {
    padding-left: 40px;
  }
  .margin-lg-t-40 {
    margin-top: 40px;
  }
  .margin-lg-r-40 {
    margin-right: 40px;
  }
  .margin-lg-b-40 {
    margin-bottom: 40px;
  }
  .margin-lg-l-40 {
    margin-left: 40px;
  }
}
@media (max-width: 767.98px) {
  .height-m-43 {
    height: 43px;
  }
  .width-m-43 {
    width: 43px;
  }
  .padding-m-43 {
    padding: 43px;
  }
  .padding-m-y-43 {
    padding: 43px 0;
  }
  .padding-m-x-43 {
    padding: 0 43px;
  }
  .padding-m-t-43 {
    padding-top: 43px;
  }
  .padding-m-r-43 {
    padding-right: 43px;
  }
  .padding-m-b-43 {
    padding-bottom: 43px;
  }
  .padding-m-l-43 {
    padding-left: 43px;
  }
  .margin-m-43 {
    margin: 43px;
  }
  .margin-m-y-43 {
    margin: 43px 0;
  }
  .margin-m-x-43 {
    margin: 0 43px;
  }
  .margin-m-t-43 {
    margin-top: 43px;
  }
  .margin-m-r-43 {
    margin-right: 43px;
  }
  .margin-m-b-43 {
    margin-bottom: 43px;
  }
  .margin-m-l-43 {
    margin-left: 43px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-43 {
    padding-top: 43px;
  }
  .padding-lg-r-43 {
    padding-right: 43px;
  }
  .padding-lg-b-43 {
    padding-bottom: 43px;
  }
  .padding-lg-l-43 {
    padding-left: 43px;
  }
  .margin-lg-t-43 {
    margin-top: 43px;
  }
  .margin-lg-r-43 {
    margin-right: 43px;
  }
  .margin-lg-b-43 {
    margin-bottom: 43px;
  }
  .margin-lg-l-43 {
    margin-left: 43px;
  }
}
@media (max-width: 767.98px) {
  .height-m-48 {
    height: 48px;
  }
  .width-m-48 {
    width: 48px;
  }
  .padding-m-48 {
    padding: 48px;
  }
  .padding-m-y-48 {
    padding: 48px 0;
  }
  .padding-m-x-48 {
    padding: 0 48px;
  }
  .padding-m-t-48 {
    padding-top: 48px;
  }
  .padding-m-r-48 {
    padding-right: 48px;
  }
  .padding-m-b-48 {
    padding-bottom: 48px;
  }
  .padding-m-l-48 {
    padding-left: 48px;
  }
  .margin-m-48 {
    margin: 48px;
  }
  .margin-m-y-48 {
    margin: 48px 0;
  }
  .margin-m-x-48 {
    margin: 0 48px;
  }
  .margin-m-t-48 {
    margin-top: 48px;
  }
  .margin-m-r-48 {
    margin-right: 48px;
  }
  .margin-m-b-48 {
    margin-bottom: 48px;
  }
  .margin-m-l-48 {
    margin-left: 48px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-48 {
    padding-top: 48px;
  }
  .padding-lg-r-48 {
    padding-right: 48px;
  }
  .padding-lg-b-48 {
    padding-bottom: 48px;
  }
  .padding-lg-l-48 {
    padding-left: 48px;
  }
  .margin-lg-t-48 {
    margin-top: 48px;
  }
  .margin-lg-r-48 {
    margin-right: 48px;
  }
  .margin-lg-b-48 {
    margin-bottom: 48px;
  }
  .margin-lg-l-48 {
    margin-left: 48px;
  }
}
@media (max-width: 767.98px) {
  .height-m-55 {
    height: 55px;
  }
  .width-m-55 {
    width: 55px;
  }
  .padding-m-55 {
    padding: 55px;
  }
  .padding-m-y-55 {
    padding: 55px 0;
  }
  .padding-m-x-55 {
    padding: 0 55px;
  }
  .padding-m-t-55 {
    padding-top: 55px;
  }
  .padding-m-r-55 {
    padding-right: 55px;
  }
  .padding-m-b-55 {
    padding-bottom: 55px;
  }
  .padding-m-l-55 {
    padding-left: 55px;
  }
  .margin-m-55 {
    margin: 55px;
  }
  .margin-m-y-55 {
    margin: 55px 0;
  }
  .margin-m-x-55 {
    margin: 0 55px;
  }
  .margin-m-t-55 {
    margin-top: 55px;
  }
  .margin-m-r-55 {
    margin-right: 55px;
  }
  .margin-m-b-55 {
    margin-bottom: 55px;
  }
  .margin-m-l-55 {
    margin-left: 55px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-55 {
    padding-top: 55px;
  }
  .padding-lg-r-55 {
    padding-right: 55px;
  }
  .padding-lg-b-55 {
    padding-bottom: 55px;
  }
  .padding-lg-l-55 {
    padding-left: 55px;
  }
  .margin-lg-t-55 {
    margin-top: 55px;
  }
  .margin-lg-r-55 {
    margin-right: 55px;
  }
  .margin-lg-b-55 {
    margin-bottom: 55px;
  }
  .margin-lg-l-55 {
    margin-left: 55px;
  }
}
@media (max-width: 767.98px) {
  .height-m-58 {
    height: 58px;
  }
  .width-m-58 {
    width: 58px;
  }
  .padding-m-58 {
    padding: 58px;
  }
  .padding-m-y-58 {
    padding: 58px 0;
  }
  .padding-m-x-58 {
    padding: 0 58px;
  }
  .padding-m-t-58 {
    padding-top: 58px;
  }
  .padding-m-r-58 {
    padding-right: 58px;
  }
  .padding-m-b-58 {
    padding-bottom: 58px;
  }
  .padding-m-l-58 {
    padding-left: 58px;
  }
  .margin-m-58 {
    margin: 58px;
  }
  .margin-m-y-58 {
    margin: 58px 0;
  }
  .margin-m-x-58 {
    margin: 0 58px;
  }
  .margin-m-t-58 {
    margin-top: 58px;
  }
  .margin-m-r-58 {
    margin-right: 58px;
  }
  .margin-m-b-58 {
    margin-bottom: 58px;
  }
  .margin-m-l-58 {
    margin-left: 58px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-58 {
    padding-top: 58px;
  }
  .padding-lg-r-58 {
    padding-right: 58px;
  }
  .padding-lg-b-58 {
    padding-bottom: 58px;
  }
  .padding-lg-l-58 {
    padding-left: 58px;
  }
  .margin-lg-t-58 {
    margin-top: 58px;
  }
  .margin-lg-r-58 {
    margin-right: 58px;
  }
  .margin-lg-b-58 {
    margin-bottom: 58px;
  }
  .margin-lg-l-58 {
    margin-left: 58px;
  }
}
@media (max-width: 767.98px) {
  .height-m-60 {
    height: 60px;
  }
  .width-m-60 {
    width: 60px;
  }
  .padding-m-60 {
    padding: 60px;
  }
  .padding-m-y-60 {
    padding: 60px 0;
  }
  .padding-m-x-60 {
    padding: 0 60px;
  }
  .padding-m-t-60 {
    padding-top: 60px;
  }
  .padding-m-r-60 {
    padding-right: 60px;
  }
  .padding-m-b-60 {
    padding-bottom: 60px;
  }
  .padding-m-l-60 {
    padding-left: 60px;
  }
  .margin-m-60 {
    margin: 60px;
  }
  .margin-m-y-60 {
    margin: 60px 0;
  }
  .margin-m-x-60 {
    margin: 0 60px;
  }
  .margin-m-t-60 {
    margin-top: 60px;
  }
  .margin-m-r-60 {
    margin-right: 60px;
  }
  .margin-m-b-60 {
    margin-bottom: 60px;
  }
  .margin-m-l-60 {
    margin-left: 60px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-60 {
    padding-top: 60px;
  }
  .padding-lg-r-60 {
    padding-right: 60px;
  }
  .padding-lg-b-60 {
    padding-bottom: 60px;
  }
  .padding-lg-l-60 {
    padding-left: 60px;
  }
  .margin-lg-t-60 {
    margin-top: 60px;
  }
  .margin-lg-r-60 {
    margin-right: 60px;
  }
  .margin-lg-b-60 {
    margin-bottom: 60px;
  }
  .margin-lg-l-60 {
    margin-left: 60px;
  }
}
@media (max-width: 767.98px) {
  .height-m-68 {
    height: 68px;
  }
  .width-m-68 {
    width: 68px;
  }
  .padding-m-68 {
    padding: 68px;
  }
  .padding-m-y-68 {
    padding: 68px 0;
  }
  .padding-m-x-68 {
    padding: 0 68px;
  }
  .padding-m-t-68 {
    padding-top: 68px;
  }
  .padding-m-r-68 {
    padding-right: 68px;
  }
  .padding-m-b-68 {
    padding-bottom: 68px;
  }
  .padding-m-l-68 {
    padding-left: 68px;
  }
  .margin-m-68 {
    margin: 68px;
  }
  .margin-m-y-68 {
    margin: 68px 0;
  }
  .margin-m-x-68 {
    margin: 0 68px;
  }
  .margin-m-t-68 {
    margin-top: 68px;
  }
  .margin-m-r-68 {
    margin-right: 68px;
  }
  .margin-m-b-68 {
    margin-bottom: 68px;
  }
  .margin-m-l-68 {
    margin-left: 68px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-68 {
    padding-top: 68px;
  }
  .padding-lg-r-68 {
    padding-right: 68px;
  }
  .padding-lg-b-68 {
    padding-bottom: 68px;
  }
  .padding-lg-l-68 {
    padding-left: 68px;
  }
  .margin-lg-t-68 {
    margin-top: 68px;
  }
  .margin-lg-r-68 {
    margin-right: 68px;
  }
  .margin-lg-b-68 {
    margin-bottom: 68px;
  }
  .margin-lg-l-68 {
    margin-left: 68px;
  }
}
@media (max-width: 767.98px) {
  .height-m-70 {
    height: 70px;
  }
  .width-m-70 {
    width: 70px;
  }
  .padding-m-70 {
    padding: 70px;
  }
  .padding-m-y-70 {
    padding: 70px 0;
  }
  .padding-m-x-70 {
    padding: 0 70px;
  }
  .padding-m-t-70 {
    padding-top: 70px;
  }
  .padding-m-r-70 {
    padding-right: 70px;
  }
  .padding-m-b-70 {
    padding-bottom: 70px;
  }
  .padding-m-l-70 {
    padding-left: 70px;
  }
  .margin-m-70 {
    margin: 70px;
  }
  .margin-m-y-70 {
    margin: 70px 0;
  }
  .margin-m-x-70 {
    margin: 0 70px;
  }
  .margin-m-t-70 {
    margin-top: 70px;
  }
  .margin-m-r-70 {
    margin-right: 70px;
  }
  .margin-m-b-70 {
    margin-bottom: 70px;
  }
  .margin-m-l-70 {
    margin-left: 70px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-70 {
    padding-top: 70px;
  }
  .padding-lg-r-70 {
    padding-right: 70px;
  }
  .padding-lg-b-70 {
    padding-bottom: 70px;
  }
  .padding-lg-l-70 {
    padding-left: 70px;
  }
  .margin-lg-t-70 {
    margin-top: 70px;
  }
  .margin-lg-r-70 {
    margin-right: 70px;
  }
  .margin-lg-b-70 {
    margin-bottom: 70px;
  }
  .margin-lg-l-70 {
    margin-left: 70px;
  }
}
@media (max-width: 767.98px) {
  .height-m-72 {
    height: 72px;
  }
  .width-m-72 {
    width: 72px;
  }
  .padding-m-72 {
    padding: 72px;
  }
  .padding-m-y-72 {
    padding: 72px 0;
  }
  .padding-m-x-72 {
    padding: 0 72px;
  }
  .padding-m-t-72 {
    padding-top: 72px;
  }
  .padding-m-r-72 {
    padding-right: 72px;
  }
  .padding-m-b-72 {
    padding-bottom: 72px;
  }
  .padding-m-l-72 {
    padding-left: 72px;
  }
  .margin-m-72 {
    margin: 72px;
  }
  .margin-m-y-72 {
    margin: 72px 0;
  }
  .margin-m-x-72 {
    margin: 0 72px;
  }
  .margin-m-t-72 {
    margin-top: 72px;
  }
  .margin-m-r-72 {
    margin-right: 72px;
  }
  .margin-m-b-72 {
    margin-bottom: 72px;
  }
  .margin-m-l-72 {
    margin-left: 72px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-72 {
    padding-top: 72px;
  }
  .padding-lg-r-72 {
    padding-right: 72px;
  }
  .padding-lg-b-72 {
    padding-bottom: 72px;
  }
  .padding-lg-l-72 {
    padding-left: 72px;
  }
  .margin-lg-t-72 {
    margin-top: 72px;
  }
  .margin-lg-r-72 {
    margin-right: 72px;
  }
  .margin-lg-b-72 {
    margin-bottom: 72px;
  }
  .margin-lg-l-72 {
    margin-left: 72px;
  }
}
@media (max-width: 767.98px) {
  .height-m-80 {
    height: 80px;
  }
  .width-m-80 {
    width: 80px;
  }
  .padding-m-80 {
    padding: 80px;
  }
  .padding-m-y-80 {
    padding: 80px 0;
  }
  .padding-m-x-80 {
    padding: 0 80px;
  }
  .padding-m-t-80 {
    padding-top: 80px;
  }
  .padding-m-r-80 {
    padding-right: 80px;
  }
  .padding-m-b-80 {
    padding-bottom: 80px;
  }
  .padding-m-l-80 {
    padding-left: 80px;
  }
  .margin-m-80 {
    margin: 80px;
  }
  .margin-m-y-80 {
    margin: 80px 0;
  }
  .margin-m-x-80 {
    margin: 0 80px;
  }
  .margin-m-t-80 {
    margin-top: 80px;
  }
  .margin-m-r-80 {
    margin-right: 80px;
  }
  .margin-m-b-80 {
    margin-bottom: 80px;
  }
  .margin-m-l-80 {
    margin-left: 80px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-80 {
    padding-top: 80px;
  }
  .padding-lg-r-80 {
    padding-right: 80px;
  }
  .padding-lg-b-80 {
    padding-bottom: 80px;
  }
  .padding-lg-l-80 {
    padding-left: 80px;
  }
  .margin-lg-t-80 {
    margin-top: 80px;
  }
  .margin-lg-r-80 {
    margin-right: 80px;
  }
  .margin-lg-b-80 {
    margin-bottom: 80px;
  }
  .margin-lg-l-80 {
    margin-left: 80px;
  }
}
@media (max-width: 767.98px) {
  .height-m-86 {
    height: 86px;
  }
  .width-m-86 {
    width: 86px;
  }
  .padding-m-86 {
    padding: 86px;
  }
  .padding-m-y-86 {
    padding: 86px 0;
  }
  .padding-m-x-86 {
    padding: 0 86px;
  }
  .padding-m-t-86 {
    padding-top: 86px;
  }
  .padding-m-r-86 {
    padding-right: 86px;
  }
  .padding-m-b-86 {
    padding-bottom: 86px;
  }
  .padding-m-l-86 {
    padding-left: 86px;
  }
  .margin-m-86 {
    margin: 86px;
  }
  .margin-m-y-86 {
    margin: 86px 0;
  }
  .margin-m-x-86 {
    margin: 0 86px;
  }
  .margin-m-t-86 {
    margin-top: 86px;
  }
  .margin-m-r-86 {
    margin-right: 86px;
  }
  .margin-m-b-86 {
    margin-bottom: 86px;
  }
  .margin-m-l-86 {
    margin-left: 86px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-86 {
    padding-top: 86px;
  }
  .padding-lg-r-86 {
    padding-right: 86px;
  }
  .padding-lg-b-86 {
    padding-bottom: 86px;
  }
  .padding-lg-l-86 {
    padding-left: 86px;
  }
  .margin-lg-t-86 {
    margin-top: 86px;
  }
  .margin-lg-r-86 {
    margin-right: 86px;
  }
  .margin-lg-b-86 {
    margin-bottom: 86px;
  }
  .margin-lg-l-86 {
    margin-left: 86px;
  }
}
@media (max-width: 767.98px) {
  .height-m-100 {
    height: 100px;
  }
  .width-m-100 {
    width: 100px;
  }
  .padding-m-100 {
    padding: 100px;
  }
  .padding-m-y-100 {
    padding: 100px 0;
  }
  .padding-m-x-100 {
    padding: 0 100px;
  }
  .padding-m-t-100 {
    padding-top: 100px;
  }
  .padding-m-r-100 {
    padding-right: 100px;
  }
  .padding-m-b-100 {
    padding-bottom: 100px;
  }
  .padding-m-l-100 {
    padding-left: 100px;
  }
  .margin-m-100 {
    margin: 100px;
  }
  .margin-m-y-100 {
    margin: 100px 0;
  }
  .margin-m-x-100 {
    margin: 0 100px;
  }
  .margin-m-t-100 {
    margin-top: 100px;
  }
  .margin-m-r-100 {
    margin-right: 100px;
  }
  .margin-m-b-100 {
    margin-bottom: 100px;
  }
  .margin-m-l-100 {
    margin-left: 100px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-100 {
    padding-top: 100px;
  }
  .padding-lg-r-100 {
    padding-right: 100px;
  }
  .padding-lg-b-100 {
    padding-bottom: 100px;
  }
  .padding-lg-l-100 {
    padding-left: 100px;
  }
  .margin-lg-t-100 {
    margin-top: 100px;
  }
  .margin-lg-r-100 {
    margin-right: 100px;
  }
  .margin-lg-b-100 {
    margin-bottom: 100px;
  }
  .margin-lg-l-100 {
    margin-left: 100px;
  }
}
@media (max-width: 767.98px) {
  .height-m-110 {
    height: 110px;
  }
  .width-m-110 {
    width: 110px;
  }
  .padding-m-110 {
    padding: 110px;
  }
  .padding-m-y-110 {
    padding: 110px 0;
  }
  .padding-m-x-110 {
    padding: 0 110px;
  }
  .padding-m-t-110 {
    padding-top: 110px;
  }
  .padding-m-r-110 {
    padding-right: 110px;
  }
  .padding-m-b-110 {
    padding-bottom: 110px;
  }
  .padding-m-l-110 {
    padding-left: 110px;
  }
  .margin-m-110 {
    margin: 110px;
  }
  .margin-m-y-110 {
    margin: 110px 0;
  }
  .margin-m-x-110 {
    margin: 0 110px;
  }
  .margin-m-t-110 {
    margin-top: 110px;
  }
  .margin-m-r-110 {
    margin-right: 110px;
  }
  .margin-m-b-110 {
    margin-bottom: 110px;
  }
  .margin-m-l-110 {
    margin-left: 110px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-110 {
    padding-top: 110px;
  }
  .padding-lg-r-110 {
    padding-right: 110px;
  }
  .padding-lg-b-110 {
    padding-bottom: 110px;
  }
  .padding-lg-l-110 {
    padding-left: 110px;
  }
  .margin-lg-t-110 {
    margin-top: 110px;
  }
  .margin-lg-r-110 {
    margin-right: 110px;
  }
  .margin-lg-b-110 {
    margin-bottom: 110px;
  }
  .margin-lg-l-110 {
    margin-left: 110px;
  }
}
@media (max-width: 767.98px) {
  .height-m-120 {
    height: 120px;
  }
  .width-m-120 {
    width: 120px;
  }
  .padding-m-120 {
    padding: 120px;
  }
  .padding-m-y-120 {
    padding: 120px 0;
  }
  .padding-m-x-120 {
    padding: 0 120px;
  }
  .padding-m-t-120 {
    padding-top: 120px;
  }
  .padding-m-r-120 {
    padding-right: 120px;
  }
  .padding-m-b-120 {
    padding-bottom: 120px;
  }
  .padding-m-l-120 {
    padding-left: 120px;
  }
  .margin-m-120 {
    margin: 120px;
  }
  .margin-m-y-120 {
    margin: 120px 0;
  }
  .margin-m-x-120 {
    margin: 0 120px;
  }
  .margin-m-t-120 {
    margin-top: 120px;
  }
  .margin-m-r-120 {
    margin-right: 120px;
  }
  .margin-m-b-120 {
    margin-bottom: 120px;
  }
  .margin-m-l-120 {
    margin-left: 120px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-120 {
    padding-top: 120px;
  }
  .padding-lg-r-120 {
    padding-right: 120px;
  }
  .padding-lg-b-120 {
    padding-bottom: 120px;
  }
  .padding-lg-l-120 {
    padding-left: 120px;
  }
  .margin-lg-t-120 {
    margin-top: 120px;
  }
  .margin-lg-r-120 {
    margin-right: 120px;
  }
  .margin-lg-b-120 {
    margin-bottom: 120px;
  }
  .margin-lg-l-120 {
    margin-left: 120px;
  }
}
@media (max-width: 767.98px) {
  .height-m-140 {
    height: 140px;
  }
  .width-m-140 {
    width: 140px;
  }
  .padding-m-140 {
    padding: 140px;
  }
  .padding-m-y-140 {
    padding: 140px 0;
  }
  .padding-m-x-140 {
    padding: 0 140px;
  }
  .padding-m-t-140 {
    padding-top: 140px;
  }
  .padding-m-r-140 {
    padding-right: 140px;
  }
  .padding-m-b-140 {
    padding-bottom: 140px;
  }
  .padding-m-l-140 {
    padding-left: 140px;
  }
  .margin-m-140 {
    margin: 140px;
  }
  .margin-m-y-140 {
    margin: 140px 0;
  }
  .margin-m-x-140 {
    margin: 0 140px;
  }
  .margin-m-t-140 {
    margin-top: 140px;
  }
  .margin-m-r-140 {
    margin-right: 140px;
  }
  .margin-m-b-140 {
    margin-bottom: 140px;
  }
  .margin-m-l-140 {
    margin-left: 140px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-140 {
    padding-top: 140px;
  }
  .padding-lg-r-140 {
    padding-right: 140px;
  }
  .padding-lg-b-140 {
    padding-bottom: 140px;
  }
  .padding-lg-l-140 {
    padding-left: 140px;
  }
  .margin-lg-t-140 {
    margin-top: 140px;
  }
  .margin-lg-r-140 {
    margin-right: 140px;
  }
  .margin-lg-b-140 {
    margin-bottom: 140px;
  }
  .margin-lg-l-140 {
    margin-left: 140px;
  }
}
@media (max-width: 767.98px) {
  .height-m-150 {
    height: 150px;
  }
  .width-m-150 {
    width: 150px;
  }
  .padding-m-150 {
    padding: 150px;
  }
  .padding-m-y-150 {
    padding: 150px 0;
  }
  .padding-m-x-150 {
    padding: 0 150px;
  }
  .padding-m-t-150 {
    padding-top: 150px;
  }
  .padding-m-r-150 {
    padding-right: 150px;
  }
  .padding-m-b-150 {
    padding-bottom: 150px;
  }
  .padding-m-l-150 {
    padding-left: 150px;
  }
  .margin-m-150 {
    margin: 150px;
  }
  .margin-m-y-150 {
    margin: 150px 0;
  }
  .margin-m-x-150 {
    margin: 0 150px;
  }
  .margin-m-t-150 {
    margin-top: 150px;
  }
  .margin-m-r-150 {
    margin-right: 150px;
  }
  .margin-m-b-150 {
    margin-bottom: 150px;
  }
  .margin-m-l-150 {
    margin-left: 150px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-150 {
    padding-top: 150px;
  }
  .padding-lg-r-150 {
    padding-right: 150px;
  }
  .padding-lg-b-150 {
    padding-bottom: 150px;
  }
  .padding-lg-l-150 {
    padding-left: 150px;
  }
  .margin-lg-t-150 {
    margin-top: 150px;
  }
  .margin-lg-r-150 {
    margin-right: 150px;
  }
  .margin-lg-b-150 {
    margin-bottom: 150px;
  }
  .margin-lg-l-150 {
    margin-left: 150px;
  }
}
@media (max-width: 767.98px) {
  .height-m-200 {
    height: 200px;
  }
  .width-m-200 {
    width: 200px;
  }
  .padding-m-200 {
    padding: 200px;
  }
  .padding-m-y-200 {
    padding: 200px 0;
  }
  .padding-m-x-200 {
    padding: 0 200px;
  }
  .padding-m-t-200 {
    padding-top: 200px;
  }
  .padding-m-r-200 {
    padding-right: 200px;
  }
  .padding-m-b-200 {
    padding-bottom: 200px;
  }
  .padding-m-l-200 {
    padding-left: 200px;
  }
  .margin-m-200 {
    margin: 200px;
  }
  .margin-m-y-200 {
    margin: 200px 0;
  }
  .margin-m-x-200 {
    margin: 0 200px;
  }
  .margin-m-t-200 {
    margin-top: 200px;
  }
  .margin-m-r-200 {
    margin-right: 200px;
  }
  .margin-m-b-200 {
    margin-bottom: 200px;
  }
  .margin-m-l-200 {
    margin-left: 200px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-200 {
    padding-top: 200px;
  }
  .padding-lg-r-200 {
    padding-right: 200px;
  }
  .padding-lg-b-200 {
    padding-bottom: 200px;
  }
  .padding-lg-l-200 {
    padding-left: 200px;
  }
  .margin-lg-t-200 {
    margin-top: 200px;
  }
  .margin-lg-r-200 {
    margin-right: 200px;
  }
  .margin-lg-b-200 {
    margin-bottom: 200px;
  }
  .margin-lg-l-200 {
    margin-left: 200px;
  }
}
@media (max-width: 767.98px) {
  .height-m-250 {
    height: 250px;
  }
  .width-m-250 {
    width: 250px;
  }
  .padding-m-250 {
    padding: 250px;
  }
  .padding-m-y-250 {
    padding: 250px 0;
  }
  .padding-m-x-250 {
    padding: 0 250px;
  }
  .padding-m-t-250 {
    padding-top: 250px;
  }
  .padding-m-r-250 {
    padding-right: 250px;
  }
  .padding-m-b-250 {
    padding-bottom: 250px;
  }
  .padding-m-l-250 {
    padding-left: 250px;
  }
  .margin-m-250 {
    margin: 250px;
  }
  .margin-m-y-250 {
    margin: 250px 0;
  }
  .margin-m-x-250 {
    margin: 0 250px;
  }
  .margin-m-t-250 {
    margin-top: 250px;
  }
  .margin-m-r-250 {
    margin-right: 250px;
  }
  .margin-m-b-250 {
    margin-bottom: 250px;
  }
  .margin-m-l-250 {
    margin-left: 250px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-250 {
    padding-top: 250px;
  }
  .padding-lg-r-250 {
    padding-right: 250px;
  }
  .padding-lg-b-250 {
    padding-bottom: 250px;
  }
  .padding-lg-l-250 {
    padding-left: 250px;
  }
  .margin-lg-t-250 {
    margin-top: 250px;
  }
  .margin-lg-r-250 {
    margin-right: 250px;
  }
  .margin-lg-b-250 {
    margin-bottom: 250px;
  }
  .margin-lg-l-250 {
    margin-left: 250px;
  }
}
@media (max-width: 767.98px) {
  .height-m-265 {
    height: 265px;
  }
  .width-m-265 {
    width: 265px;
  }
  .padding-m-265 {
    padding: 265px;
  }
  .padding-m-y-265 {
    padding: 265px 0;
  }
  .padding-m-x-265 {
    padding: 0 265px;
  }
  .padding-m-t-265 {
    padding-top: 265px;
  }
  .padding-m-r-265 {
    padding-right: 265px;
  }
  .padding-m-b-265 {
    padding-bottom: 265px;
  }
  .padding-m-l-265 {
    padding-left: 265px;
  }
  .margin-m-265 {
    margin: 265px;
  }
  .margin-m-y-265 {
    margin: 265px 0;
  }
  .margin-m-x-265 {
    margin: 0 265px;
  }
  .margin-m-t-265 {
    margin-top: 265px;
  }
  .margin-m-r-265 {
    margin-right: 265px;
  }
  .margin-m-b-265 {
    margin-bottom: 265px;
  }
  .margin-m-l-265 {
    margin-left: 265px;
  }
}
@media (max-width: 991.98px) {
  .padding-lg-t-265 {
    padding-top: 265px;
  }
  .padding-lg-r-265 {
    padding-right: 265px;
  }
  .padding-lg-b-265 {
    padding-bottom: 265px;
  }
  .padding-lg-l-265 {
    padding-left: 265px;
  }
  .margin-lg-t-265 {
    margin-top: 265px;
  }
  .margin-lg-r-265 {
    margin-right: 265px;
  }
  .margin-lg-b-265 {
    margin-bottom: 265px;
  }
  .margin-lg-l-265 {
    margin-left: 265px;
  }
}
.table thead th {
  border-top: none;
  border-bottom: 2px solid #85704d;
  padding: 9px 12px 9px 0;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
}
.table tbody td {
  padding-left: 0;
}
.table tr {
  transition: background-color 0.1s ease;
}
.table tr:hover {
  background-color: #f6f6f6;
}

h1,
.h1 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 2.125rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 3rem;
  }
}

h2,
.h2 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1.625rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  h2,
  .h2 {
    font-size: 2.25rem;
  }
}

h3,
.h3 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 1.375rem;
  }
}

h5,
.h5 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
}

h6,
.h6 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
}

.display-1 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 3.4375rem;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.46875rem + 2.625vw);
  }
}
@media (min-width: 768px) {
  .display-1 {
    font-size: 5rem;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.625rem + 4.5vw);
  }
}

.display-2 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 2.125rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .display-2 {
    font-size: 3rem;
  }
}

.display-3 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .display-3 {
    font-size: 1.125rem;
  }
}

.custom-control-label {
  font-weight: 400;
}
.custom-control-label::before {
  top: 0;
}

.custom-checkbox .custom-control-input:checked + .custom-control-label,
.custom-radio .custom-control-input:checked + .custom-control-label {
  font-weight: 600;
}

.custom-radio .custom-control-input + .custom-control-label::before {
  content: url("../images/svg-icons/icon-radio-unselected.svg");
  border: none;
}
.custom-radio .custom-control-input:checked + .custom-control-label::before {
  content: url("../images/svg-icons/icon-radio-selected.svg");
  border: none;
  background: none;
}
.custom-radio .custom-control-input:checked + .custom-control-label::after {
  content: "";
  background: none;
}

.custom-checkbox .custom-control-input + .custom-control-label::before {
  content: url("../images/svg-icons/icon-checkbox-unselected.svg");
  border: none;
}
.custom-checkbox .custom-control-input:checked + .custom-control-label::before {
  content: url("../images/svg-icons/icon-checkbox-selected.svg");
  border: none;
  background: none;
}
.custom-checkbox .custom-control-input:checked + .custom-control-label::after {
  content: "";
  background: none;
}
.custom-checkbox.required label::after {
  content: "";
  display: none;
}

.removedItem {
  display: none;
}

option[disabled=disabled] {
  display: none;
}

.alert {
  border-radius: 4px;
  padding: 14px 15px;
  min-height: 55px;
}
.alert::before {
  content: " ";
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  display: block;
  margin-right: 10px;
  float: left;
}
.alert span {
  display: block;
  overflow: hidden;
}

.alert-success::before {
  content: "";
  background-image: url("../images/svg-icons/icon-alert-success.svg");
}

.alert-info::before {
  content: "";
  background-image: url("../images/svg-icons/icon-alert-info.svg");
}

.alert-warning {
  color: #222;
}
.alert-warning::before {
  content: "";
  background-image: url("../images/svg-icons/icon-alert-warning.svg");
}

.alert-danger::before {
  content: "";
  background-image: url("../images/svg-icons/icon-alert-danger.svg");
}

.qty-alert {
  z-index: 1080;
}

.shadow {
  box-shadow: 2px 2px 4px 2px rgba(210, 215, 225, 0.5) !important;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

ul.slick-slider {
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
  -khtml-padding-start: 0;
  -o-padding-start: 0;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track::before, .slick-track::after {
  content: "";
  display: table;
}
.slick-track::after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
  flex-shrink: 0;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  max-width: 100%;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../lib/slick/../../images/ajax-loader.gif") center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 48px;
  width: 48px;
  min-height: 0;
  font-weight: initial;
  line-height: 0;
  font-size: 0;
  border-radius: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  opacity: 1;
  z-index: 1;
  transition: all 0.1s ease;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  color: transparent;
  height: 50px;
  width: 50px;
}
.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  opacity: 1;
}
.slick-prev::before,
.slick-next::before {
  content: "";
  display: inline-block;
  width: 48px;
  height: 48px;
}

.slick-prev {
  left: 1px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: 1px;
}
.slick-prev::before {
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat;
  background-size: 48px 48px;
  transition: all 0.1s ease;
}
[dir=rtl] .slick-prev::before {
  transform: rotate(45deg);
}
.slick-prev:hover::before {
  background-size: 50px 50px;
}

.slick-next {
  right: 1px;
}
[dir=rtl] .slick-next {
  left: 1px;
  right: auto;
}
.slick-next::before {
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat;
  background-size: 48px 48px;
  transition: all 0.1s ease;
}
[dir=rtl] .slick-next::before {
  transform: rotate(-135deg);
}
.slick-next:hover::before {
  background-size: 50px 50px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 22px;
  margin: 0 2px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 10px;
  width: 10px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
  background-color: transparent;
}
.slick-dots li button:hover::before, .slick-dots li button:focus::before {
  opacity: 0.8;
  background-color: #fff;
}
.slick-dots li button::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  border: 1px solid #353535;
  width: 10px;
  height: 10px;
  font-size: 25px;
  line-height: 20px;
  text-align: center;
  background-color: #f6f6f6;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button::before {
  background-color: #353535;
  border: 1px solid #f6f6f6;
  opacity: 1;
}

.slick-dark-arrows .slick-prev,
.slick-dark-arrows .slick-next {
  height: 64px;
  width: 24px;
  background-color: #000;
}
.slick-dark-arrows .slick-prev:hover, .slick-dark-arrows .slick-prev:focus,
.slick-dark-arrows .slick-next:hover,
.slick-dark-arrows .slick-next:focus {
  background: #000;
}
.slick-dark-arrows .slick-prev::before,
.slick-dark-arrows .slick-next::before {
  border-color: #fff;
}

.product-tile-caro .slick-prev {
  left: -35px;
}
@media (min-width: 992px) {
  .product-tile-caro .slick-prev {
    left: -48px;
  }
}
.product-tile-caro .slick-next {
  right: -35px;
}
@media (min-width: 992px) {
  .product-tile-caro .slick-next {
    right: -48px;
  }
}

.video-carousel .slick-prev {
  left: 0;
}
@media (min-width: 992px) {
  .video-carousel .slick-prev {
    left: -48px;
  }
}
.video-carousel .slick-next {
  right: 0;
}
@media (min-width: 992px) {
  .video-carousel .slick-next {
    right: -48px;
  }
}

.product-tile-caro .slick-prev,
.product-tile-caro .slick-next {
  top: 35%;
}
.product-tile-caro .slick-track {
  display: flex;
}
.product-tile-caro .slick-slide {
  height: auto;
}
@media (min-width: 576px) {
  .product-tile-caro .slick-active + .slick-active {
    border-left: 1px solid #d7d3d1;
  }
}
.product-tile-caro .product-tile {
  padding: 15px;
  height: 100%;
}
.product-tile-caro .product-tile:focus {
  outline: 0;
}
.product-tile-caro .product-tile .tile-image {
  margin: 0 auto;
}
.product-tile-caro .product-tile .image-container {
  width: 100%;
}

.product-carousel {
  visibility: hidden;
}
.product-carousel.slick-initialized {
  visibility: visible;
}

.pull-left-xs {
  float: left;
}

.pull-right-xs {
  float: right;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .pull-left-sm {
    float: left;
  }
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left;
  }
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 992px) and (max-width: 1024.98px) {
  .pull-left-lg {
    float: left;
  }
  .pull-right-lg {
    float: right;
  }
}
@media (min-width: 1025px) and (max-width: 1199.98px) {
  .pull-left-th {
    float: left;
  }
  .pull-right-th {
    float: right;
  }
}
@media (min-width: 1200px) {
  .pull-left-xl {
    float: left;
  }
  .pull-right-xl {
    float: right;
  }
}
@media (max-width: 575.98px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (max-width: 1024.98px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .hidden-th-down {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.row.equal-height > [class^=col] {
  display: flex;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
@font-face {
  font-family: "Blender Pro Bold";
  src: url("../fonts/Blender Pro Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Blender Pro Book";
  src: url("../fonts/Blender Pro Book.ttf") format("truetype");
}
@font-face {
  font-family: "Blender Pro Heavy";
  src: url("../fonts/Blender Pro Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "Blender Pro Medium";
  src: url("../fonts/Blender Pro Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Blender Pro Thin";
  src: url("../fonts/Blender Pro Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Tungsten Compressed Semi";
  src: url("../fonts/TungstenCompressed-SemiBold.eot");
  src: url("../fonts/TungstenCompressed-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-SemiBold.woff2") format("woff2"), url("../fonts/TungstenCompressed-SemiBold.woff") format("woff"), url("../fonts/TungstenCompressed-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Compressed";
  src: url("../fonts/TungstenCompressed-Light.eot");
  src: url("../fonts/TungstenCompressed-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-Light.woff2") format("woff2"), url("../fonts/TungstenCompressed-Light.woff") format("woff"), url("../fonts/TungstenCompressed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Compressed";
  src: url("../fonts/TungstenCompressed-Thin.eot");
  src: url("../fonts/TungstenCompressed-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-Thin.woff2") format("woff2"), url("../fonts/TungstenCompressed-Thin.woff") format("woff"), url("../fonts/TungstenCompressed-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow";
  src: url("../fonts/TungstenNarrow-SemiBold.eot");
  src: url("../fonts/TungstenNarrow-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-SemiBold.woff2") format("woff2"), url("../fonts/TungstenNarrow-SemiBold.woff") format("woff"), url("../fonts/TungstenNarrow-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow";
  src: url("../fonts/TungstenNarrow-Light.eot");
  src: url("../fonts/TungstenNarrow-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-Light.woff2") format("woff2"), url("../fonts/TungstenNarrow-Light.woff") format("woff"), url("../fonts/TungstenNarrow-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Compressed XLight";
  src: url("../fonts/TungstenCompressed-ExtraLight.eot");
  src: url("../fonts/TungstenCompressed-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-ExtraLight.woff2") format("woff2"), url("../fonts/TungstenCompressed-ExtraLight.woff") format("woff"), url("../fonts/TungstenCompressed-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Compressed Book";
  src: url("../fonts/TungstenCompressed-Book.eot");
  src: url("../fonts/TungstenCompressed-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-Book.woff2") format("woff2"), url("../fonts/TungstenCompressed-Book.woff") format("woff"), url("../fonts/TungstenCompressed-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed Book";
  src: url("../fonts/TungstenCondensed-Book.eot");
  src: url("../fonts/TungstenCondensed-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-Book.woff2") format("woff2"), url("../fonts/TungstenCondensed-Book.woff") format("woff"), url("../fonts/TungstenCondensed-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow Book";
  src: url("../fonts/TungstenNarrow-Book.eot");
  src: url("../fonts/TungstenNarrow-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-Book.woff2") format("woff2"), url("../fonts/TungstenNarrow-Book.woff") format("woff"), url("../fonts/TungstenNarrow-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-Thin.eot");
  src: url("../fonts/Tungsten-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-Thin.woff2") format("woff2"), url("../fonts/Tungsten-Thin.woff") format("woff"), url("../fonts/Tungsten-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow";
  src: url("../fonts/TungstenNarrow-Thin.eot");
  src: url("../fonts/TungstenNarrow-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-Thin.woff2") format("woff2"), url("../fonts/TungstenNarrow-Thin.woff") format("woff"), url("../fonts/TungstenNarrow-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten XLight";
  src: url("../fonts/Tungsten-ExtraLight.eot");
  src: url("../fonts/Tungsten-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-ExtraLight.woff2") format("woff2"), url("../fonts/Tungsten-ExtraLight.woff") format("woff"), url("../fonts/Tungsten-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed";
  src: url("../fonts/TungstenCondensed-Bold.eot");
  src: url("../fonts/TungstenCondensed-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-Bold.woff2") format("woff2"), url("../fonts/TungstenCondensed-Bold.woff") format("woff"), url("../fonts/TungstenCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed";
  src: url("../fonts/TungstenCondensed-Black.eot");
  src: url("../fonts/TungstenCondensed-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-Black.woff2") format("woff2"), url("../fonts/TungstenCondensed-Black.woff") format("woff"), url("../fonts/TungstenCondensed-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Compressed";
  src: url("../fonts/TungstenCompressed-Medium.eot");
  src: url("../fonts/TungstenCompressed-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-Medium.woff2") format("woff2"), url("../fonts/TungstenCompressed-Medium.woff") format("woff"), url("../fonts/TungstenCompressed-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow";
  src: url("../fonts/TungstenNarrow-Medium.eot");
  src: url("../fonts/TungstenNarrow-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-Medium.woff2") format("woff2"), url("../fonts/TungstenNarrow-Medium.woff") format("woff"), url("../fonts/TungstenNarrow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed XLight";
  src: url("../fonts/TungstenCondensed-ExtraLight.eot");
  src: url("../fonts/TungstenCondensed-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-ExtraLight.woff2") format("woff2"), url("../fonts/TungstenCondensed-ExtraLight.woff") format("woff"), url("../fonts/TungstenCondensed-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed";
  src: url("../fonts/TungstenCondensed-Light.eot");
  src: url("../fonts/TungstenCondensed-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-Light.woff2") format("woff2"), url("../fonts/TungstenCondensed-Light.woff") format("woff"), url("../fonts/TungstenCondensed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-Medium.eot");
  src: url("../fonts/Tungsten-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-Medium.woff2") format("woff2"), url("../fonts/Tungsten-Medium.woff") format("woff"), url("../fonts/Tungsten-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Book";
  src: url("../fonts/Tungsten-Book.eot");
  src: url("../fonts/Tungsten-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-Book.woff2") format("woff2"), url("../fonts/Tungsten-Book.woff") format("woff"), url("../fonts/Tungsten-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed";
  src: url("../fonts/TungstenCondensed-Medium.eot");
  src: url("../fonts/TungstenCondensed-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-Medium.woff2") format("woff2"), url("../fonts/TungstenCondensed-Medium.woff") format("woff"), url("../fonts/TungstenCondensed-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-Light.eot");
  src: url("../fonts/Tungsten-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-Light.woff2") format("woff2"), url("../fonts/Tungsten-Light.woff") format("woff"), url("../fonts/Tungsten-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow";
  src: url("../fonts/TungstenNarrow-Bold.eot");
  src: url("../fonts/TungstenNarrow-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-Bold.woff2") format("woff2"), url("../fonts/TungstenNarrow-Bold.woff") format("woff"), url("../fonts/TungstenNarrow-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Compressed";
  src: url("../fonts/TungstenCompressed-Black.eot");
  src: url("../fonts/TungstenCompressed-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-Black.woff2") format("woff2"), url("../fonts/TungstenCompressed-Black.woff") format("woff"), url("../fonts/TungstenCompressed-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed";
  src: url("../fonts/TungstenCondensed-SemiBold.eot");
  src: url("../fonts/TungstenCondensed-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-SemiBold.woff2") format("woff2"), url("../fonts/TungstenCondensed-SemiBold.woff") format("woff"), url("../fonts/TungstenCondensed-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Compressed";
  src: url("../fonts/TungstenCompressed-Bold.eot");
  src: url("../fonts/TungstenCompressed-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCompressed-Bold.woff2") format("woff2"), url("../fonts/TungstenCompressed-Bold.woff") format("woff"), url("../fonts/TungstenCompressed-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow";
  src: url("../fonts/TungstenNarrow-Black.eot");
  src: url("../fonts/TungstenNarrow-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-Black.woff2") format("woff2"), url("../fonts/TungstenNarrow-Black.woff") format("woff"), url("../fonts/TungstenNarrow-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-Black.eot");
  src: url("../fonts/Tungsten-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-Black.woff2") format("woff2"), url("../fonts/Tungsten-Black.woff") format("woff"), url("../fonts/Tungsten-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-Bold.eot");
  src: url("../fonts/Tungsten-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-Bold.woff2") format("woff2"), url("../fonts/Tungsten-Bold.woff") format("woff"), url("../fonts/Tungsten-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten";
  src: url("../fonts/Tungsten-SemiBold.eot");
  src: url("../fonts/Tungsten-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Tungsten-SemiBold.woff2") format("woff2"), url("../fonts/Tungsten-SemiBold.woff") format("woff"), url("../fonts/Tungsten-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Condensed";
  src: url("../fonts/TungstenCondensed-Thin.eot");
  src: url("../fonts/TungstenCondensed-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenCondensed-Thin.woff2") format("woff2"), url("../fonts/TungstenCondensed-Thin.woff") format("woff"), url("../fonts/TungstenCondensed-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Tungsten Narrow XLight";
  src: url("../fonts/TungstenNarrow-ExtraLight.eot");
  src: url("../fonts/TungstenNarrow-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/TungstenNarrow-ExtraLight.woff2") format("woff2"), url("../fonts/TungstenNarrow-ExtraLight.woff") format("woff"), url("../fonts/TungstenNarrow-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
a,
.link-primary,
.link-primary span {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #85704d;
  font-size: 0.875rem;
  line-height: 21px;
  text-decoration: none;
}
a:hover,
.link-primary:hover,
.link-primary span:hover {
  text-decoration: underline;
  color: #85704d;
}

p a,
label a,
.link-secondary,
.link-secondary span {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: #85704d;
  font-size: 0.875rem;
  line-height: 21px;
  text-decoration: underline;
}
p a:hover,
label a:hover,
.link-secondary:hover,
.link-secondary span:hover {
  color: #6e5a3b;
}

.label-1 {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .label-1 {
    font-size: 0.875rem;
  }
}

.label-2 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
}

.label-3 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
}

p,
span,
.body-txt {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.non-input-label,
label {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
}

.custom-control-label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.form-control::placeholder {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0px;
}

input,
textarea,
select {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0px;
}

.form-control ~ .form-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 0px;
}
.form-control ~ .invalid-feedback {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 0px;
}

.alert,
.alert span {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0px;
}

.prop-65-msg {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 1px;
}

.breadcrumb-item,
.breadcrumb-item a {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 1px;
}

ol > li,
ul > li {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
}
ol ul > li,
ol ol > li,
ul ul > li,
ul ol > li {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.pdp-link,
.pop-link a {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
}

.tile-body .pid {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
}

.tile-body .price,
.tile-body .price .starting,
.tile-body .price .range .dash,
.tile-body .price .list,
.tile-body .price .sales,
.tile-body .price .value,
.tile-body .price .list .value,
.tile-body .price .sales .value {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
}

.tile-body .price .strike-through,
.tile-body .price .strike-through .value {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
}

.badge {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 1px;
}

.prices-add-to-cart-actions .price,
.prices-add-to-cart-actions .price .starting,
.prices-add-to-cart-actions .price .list,
.prices-add-to-cart-actions .price .range .dash,
.prices-add-to-cart-actions .price .sales,
.prices-add-to-cart-actions .price .value {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1.625rem;
  letter-spacing: 1px;
}

.prices-add-to-cart-actions .price td,
.prices-add-to-cart-actions .price td.value,
.prices-add-to-cart-actions .price td .sales,
.prices-add-to-cart-actions .price td .value {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.price .strike-through,
.price .strike-through .value {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 1.625rem;
  letter-spacing: 1px;
}

.tile-body .price,
.tile-body .price .list,
.tile-body .price .sales,
.minicart .price .sales,
.minicart .price .sales span,
.minicart .price .pricing,
.minicart .sub-total {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
}

.tile-body .price .strike-through,
.minicart .price .strike-through {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
}

.pc-promotion-message {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
}

footer .footer-item a {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
}

footer .postscript a {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1;
}

footer .copyright {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.83;
}

.minicart-quantity {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 1px;
}

.site-search .search-field::placeholder {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  letter-spacing: 1.2px;
}
@media (min-width: 768px) {
  .site-search .search-field::placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

.header-utility-links a,
.header-utility-links a span {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 1.2px;
}

.search-result-count,
.result-count span {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
}

.sort-options select {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
}

.part-id {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.blog-nav .nav {
  background: #6e5a3b;
  border-bottom: 1px solid #d7d3d1;
}
.blog-nav .nav-item {
  margin: 0;
}
.blog-nav .nav-item .nav-link {
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.blog-nav .nav-item.active .nav-link {
  font-weight: bold;
}

.blog-results {
  padding: 1rem;
}
@media (min-width: 768px) {
  .blog-results {
    max-width: 800px;
  }
  .blog-results .blog-results-row {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .blog-results {
    max-width: 1180px;
  }
}
.blog-results .blog-category-section {
  padding: 20px 0;
}
.blog-results .blog-category-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
}
.blog-results .blog-category-header-row .blog-category-title {
  margin: 0;
}
.blog-results .blog-category-header-row .view-all-link {
  justify-content: end;
  align-content: center;
}
.blog-results .blog-search-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}
.blog-results .blog-search-row .blog-category-dropdown {
  height: 44px;
}
@media (max-width: 767.98px) {
  .blog-results .blog-search-row .filter-button-column {
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .blog-results .blog-search-row .filter-button-column .filter-button {
    height: 40px;
  }
  .blog-results .blog-search-row .blog-search-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 992px) {
  .blog-results .blog-search-row .blog-search-column {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
}
@media (min-width: 1200px) {
  .blog-results .blog-search-row .blog-search-column {
    flex: 0 0 42% !important;
    max-width: 42% !important;
  }
}
.blog-results .blog-search-row .blog-search-column .search-container {
  position: relative;
  padding: 0 0 0 10px;
}
.blog-results .blog-search-row .blog-search-column .search-container .blog-suggestions-wrapper {
  position: relative;
}
.blog-results .blog-search-row .blog-search-column .search-container .search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url("../images/svg-icons/icon-search-default.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s;
}
.blog-results .blog-search-row .blog-search-column .search-container .search-field:focus + .search-icon {
  opacity: 0;
}
.blog-results .blog-search-row .blog-search-column .search-container .search-field:blur:placeholder-shown + .search-icon {
  opacity: 1;
}
.blog-results .blog-search-row .blog-search-column .search-container .blog-search-field {
  padding-right: 0;
}
.blog-results .blog-refine-bar {
  -webkit-box-flex: 0;
}
@media (max-width: 767.98px) {
  .blog-results .blog-refine-bar {
    background-color: #fff;
    box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
    display: none;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    height: auto !important;
  }
}
@media (max-width: 575.98px) {
  .blog-results .blog-refine-bar {
    position: fixed;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .blog-results .blog-refine-bar {
    margin-top: 55px;
    display: block !important;
  }
}
@media (min-width: 768px) {
  .blog-results .blog-column-left {
    max-width: 22%;
    flex: 0 0 22%;
  }
}
@media (min-width: 992px) {
  .blog-results .blog-column-left {
    max-width: 20%;
    flex: 0 0 20%;
  }
}
@media (min-width: 1200px) {
  .blog-results .blog-column-left {
    flex: 0 0 16%;
    max-width: 16%;
  }
}
@media (min-width: 768px) {
  .blog-results .blog-column-right {
    -webkit-box-flex: 0;
    flex: 0 0 77%;
    max-width: 77%;
  }
}
@media (min-width: 992px) {
  .blog-results .blog-column-right {
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@media (min-width: 1200px) {
  .blog-results .blog-column-right {
    flex: 0 0 84%;
    max-width: 84%;
  }
}
@media (max-width: 767.98px) {
  .blog-results .blog-refine-bar .refine-bar {
    margin-left: 15px;
  }
}
.blog-results .blog-refine-bar .refine-bar #accordion .card .card-body {
  padding-bottom: 0px !important;
}
.blog-results .blog-refine-bar .refine-bar #accordion .card .card-body .card-header .content-item {
  padding-left: 15px;
}
.blog-results .blog-refine-bar .refine-bar #accordion .card .card-header .btn.blog-category-collapsible {
  width: auto;
  border: none !important;
  border-top: none !important;
}
.blog-results .blog-refine-bar .refine-bar #accordion .card .card-header .content-item {
  border-top: 1px solid #d7d3d1 !important;
}
.blog-results .blog-refine-bar .refine-bar #accordion .card .card-header .content-item .category-link {
  padding: 15px 0;
  width: -webkit-fill-available;
}
.blog-results .blog-refine-bar .refine-bar #accordion .card .card-header .content-item .category-link.selected {
  font-weight: 800;
}
.blog-results .blog-refine-bar .refine-bar #accordion .card .card-header .refine-bar-button {
  height: 30px;
  text-align: center;
  border: 2px solid #337321 !important;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.blog-results .result-count {
  padding: 1rem 0;
}
.blog-results .content-row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.blog-results .content-tile-container {
  padding: 0 0 10px 10px;
}
@media (max-width: 767.98px) {
  .blog-results .content-tile-container {
    padding: 0 0 10px 0;
  }
}
.blog-results .content-tile-container .content-tile {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
  border-top: 1px solid lightgrey;
  overflow: hidden;
  margin-bottom: 0px;
}
@media (max-width: 767.98px) {
  .blog-results .content-tile-container .content-tile {
    width: 95%;
    z-index: 1;
    margin: auto;
    margin-bottom: 20px;
  }
}
.blog-results .content-tile-container .content-tile .content-tile-link {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.blog-results .content-tile-container .content-tile .content-tile-image-wrapper {
  margin: 6px;
}
.blog-results .content-tile-container .content-tile .content-tile-body {
  padding: 5px 6px 0px 6px;
  flex-grow: 1;
}
.blog-results .content-tile-container .content-tile .content-tile-body .content-tile-name {
  font-weight: bold;
}
.blog-results .content-tile-container .content-tile .content-tile-body .content-tile-description {
  font-size: 0.9em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
.blog-results .content-tile-container .content-tile .blog-icon-wrapper {
  display: flex;
  justify-content: end;
  padding: 0px 6px 2px 0px;
}
.blog-results .content-tile-container .content-tile .blog-icon-wrapper .blog-icon {
  width: 10%;
  padding: 0px 0px 0px 2px;
  margin-bottom: 5px;
}
@media (max-width: 767.98px) {
  .blog-results .content-tile-container .content-tile .blog-icon-wrapper .blog-icon {
    width: 6%;
  }
}

.blog-detail-header {
  display: block;
}
.blog-detail-header .page-header-subtitle {
  margin-left: 12px;
}
.blog-detail-header.text-center .page-header-subtitle {
  justify-content: center;
}
.blog-detail-header .page-header-title {
  margin-top: 10px;
}
.blog-detail-header .blog-header-title {
  display: flex;
}
.blog-detail-header .blog-category {
  color: #888380;
}
.blog-detail-header .blog-category ~ .blog-creation-date::before {
  content: "|";
  color: #888380;
  padding-left: 10px;
  padding-right: 10px;
}
.blog-detail-header .blog-creation-date {
  color: #888380;
}
.blog-detail-header .page-header-author {
  margin-top: 10px;
  color: #888380;
}

.blog .social-container .social-icons,
.storepage .social-container .social-icons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.blog .social-container .social-icons li:not(:last-child),
.storepage .social-container .social-icons li:not(:last-child) {
  margin-right: 30px;
}
.blog .social-container .social-icons .share-icons,
.storepage .social-container .social-icons .share-icons {
  font-size: 30px;
  color: #85704d;
}
.blog .social-container .social-icons .share-icons:hover,
.storepage .social-container .social-icons .share-icons:hover {
  color: #85704d;
}

.blog .blog-footer {
  text-align: center;
}
.blog .social-container {
  border-top: none;
  margin-top: 80px;
  padding-top: 0;
}
.blog .search-words-container {
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #d7d3d1;
}
.blog .search-words-container label {
  margin-right: 7px;
  margin-bottom: 0;
}
.blog .search-words-container .search-words {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.blog .search-words-container .search-words li {
  padding: 0;
}
.blog .search-words-container .search-words li:not(:last-child) a::after {
  content: ",";
  margin-left: 0;
  margin-right: 7px;
}

.category-tile {
  position: relative;
}
.category-tile h1,
.category-tile h2,
.category-tile h3 {
  font-size: 1.75rem;
  position: absolute;
  bottom: 1.875rem;
  left: 1.875rem;
  color: #fff;
}
.category-tile::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.category-tile {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
}
.category-tile:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  .category-tile.zoom .image-wrapper {
    will-change: transform;
    transition: transform 200ms ease-out;
  }
  .category-tile.zoom:hover .image-wrapper {
    transform: scale(1.2);
  }
}
.category-tile .image-cropper {
  border-radius: 0;
  overflow: hidden;
  margin: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.category-tile .image-wrapper {
  width: 100%;
  position: relative;
}
.category-tile .image-wrapper .background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.category-tile .image-wrapper-custom .background-image-tablet,
.category-tile .image-wrapper-custom .background-image-desktop {
  display: none;
}
@media (min-width: 768px) {
  .category-tile .image-wrapper-custom .background-image-mobile,
  .category-tile .image-wrapper-custom .background-image-desktop {
    display: none;
  }
  .category-tile .image-wrapper-custom .background-image-tablet {
    display: block;
  }
}
@media (min-width: 992px) {
  .category-tile .image-wrapper-custom .background-image-mobile,
  .category-tile .image-wrapper-custom .background-image-tablet {
    display: none;
  }
  .category-tile .image-wrapper-custom .background-image-desktop {
    display: block;
  }
}
.category-tile .image-wrapper .video-container .video-aspect-ratio {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding-top: 56.25%;
  padding-left: 177.7777777778vh;
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio .video-player {
  position: static;
  pointer-events: none;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
  position: absolute;
}
.category-tile .image-text-block-text .primary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-size: 16px;
}
.category-tile .image-text-block-text .secondary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  color: orange;
  font-size: 13px;
}
.category-tile.text-placement-below .category-tile-text {
  margin-top: 1rem;
}
.category-tile.text-placement-inside::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
}
.category-tile.text-placement-inside .category-tile-text {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: #fff;
  z-index: 1;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}
.category-tile.aspect-ratio-square .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-square .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.category-tile.aspect-ratio-landscape .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-landscape .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 66.6666666667%;
}
.category-tile.aspect-ratio-portrait .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-portrait .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 150%;
}

.modal-background {
  background-color: #000;
  display: none;
  height: 100%;
  position: fixed;
  opacity: 0.5;
  width: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 991.98px) {
  .modal-open {
    position: fixed;
  }
}

input[placeholder] {
  text-overflow: ellipsis;
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
  color: #007bff;
}

.hide-order-discount {
  display: none;
}

.hide-shipping-discount {
  display: none;
}

.hidden {
  display: none;
}

.order-discount {
  color: #056808;
}

.shipping-discount {
  color: #056808;
}

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.error-hero {
  margin-bottom: 0.625em;
}

.error-message {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

.error.continue-shopping {
  margin: 0 auto;
}

.error-page h4 a {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .error-page h4 a {
    font-size: 1.375rem;
  }
}
.error-page h2 a {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1.625rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .error-page h2 a {
    font-size: 2.25rem;
  }
}

.error-unassigned-category {
  color: #dc3545;
}

.skip {
  position: absolute;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #fff;
  transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: 10000000;
  background: #fff;
  transition: all 0.2s ease-in-out;
}

.card-header-custom {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.svg {
  position: relative;
  display: block;
}
.svg::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.content-tile {
  display: block;
  text-decoration: none;
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .content-tile .content-tile-image {
    will-change: transform;
    transition: transform 200ms ease-out;
  }
  .content-tile:hover .content-tile-image {
    transform: scale(1.2);
  }
}
.content-tile .content-tile-image-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}
.content-tile .content-tile-image {
  display: block;
}
.content-tile .content-tile-image::after {
  display: block;
  content: "";
  clear: both;
}
.content-tile .content-tile-image::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.content-tile .content-tile-body {
  display: block;
}
.content-tile .content-tile-category {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  color: #888380;
  margin-bottom: 7px;
}
.content-tile .content-tile-name {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  color: #000;
  margin-bottom: 10px;
}
.content-tile .content-tile-date {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  color: #000;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.content-tile .content-tile-description {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  color: #000;
}
.content-tile:hover {
  text-decoration: none;
}
.content-tile:hover .content-tile-image-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  box-shadow: inset 0px -2px 0px #000;
}
.content-tile:hover .content-tile-name {
  text-decoration: none;
  color: #000;
}
.content-tile:hover .content-tile-description {
  text-decoration: none;
  color: #000;
}

@media (max-width: 575.98px) {
  .menu-toggleable-left.navbar-toggleable-xs {
    position: fixed;
    z-index: 1;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    width: 90%;
    overflow-y: scroll;
  }
  .menu-toggleable-left.navbar-toggleable-xs.in {
    min-width: 50%;
    left: 0;
  }
  .menu-toggleable-left.navbar-toggleable-xs.in .container {
    max-width: 100% !important;
  }
}
@media (max-width: 767.98px) {
  .menu-toggleable-left.navbar-toggleable-sm {
    position: fixed;
    z-index: 1;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    width: 90%;
    overflow-y: scroll;
  }
  .menu-toggleable-left.navbar-toggleable-sm.in {
    min-width: 50%;
    left: 0;
  }
  .menu-toggleable-left.navbar-toggleable-sm.in .container {
    max-width: 100% !important;
  }
}
@media (max-width: 991.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    position: fixed;
    z-index: 1;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    width: 90%;
    overflow-y: scroll;
  }
  .menu-toggleable-left.navbar-toggleable-md.in {
    min-width: 50%;
    left: 0;
  }
  .menu-toggleable-left.navbar-toggleable-md.in .container {
    max-width: 100% !important;
  }
}
@media (max-width: 1024.98px) {
  .menu-toggleable-left.navbar-toggleable-lg {
    position: fixed;
    z-index: 1;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    width: 90%;
    overflow-y: scroll;
  }
  .menu-toggleable-left.navbar-toggleable-lg.in {
    min-width: 50%;
    left: 0;
  }
  .menu-toggleable-left.navbar-toggleable-lg.in .container {
    max-width: 100% !important;
  }
}
@media (max-width: 1199.98px) {
  .menu-toggleable-left.navbar-toggleable-th {
    position: fixed;
    z-index: 1;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    width: 90%;
    overflow-y: scroll;
  }
  .menu-toggleable-left.navbar-toggleable-th.in {
    min-width: 50%;
    left: 0;
  }
  .menu-toggleable-left.navbar-toggleable-th.in .container {
    max-width: 100% !important;
  }
}
.menu-toggleable-left.navbar-toggleable-xl {
  position: fixed;
  z-index: 1;
  left: -100%;
  top: 0;
  bottom: 0;
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  display: block;
  width: 90%;
  overflow-y: scroll;
}
.menu-toggleable-left.navbar-toggleable-xl.in {
  min-width: 50%;
  left: 0;
}
.menu-toggleable-left.navbar-toggleable-xl.in .container {
  max-width: 100% !important;
}

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0 1rem;
}
@media (min-width: 1025px) {
  .navbar.bg-inverse .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .navbar.bg-inverse .navbar-nav .nav-item + .nav-item:not(.link-thisisfederal) {
    padding-bottom: 9px;
  }
}

@media (min-width: 1025px) {
  .main-menu .nav-item > .nav-link {
    color: #fff !important;
  }
}
@media (max-width: 1024.98px) {
  .main-menu.menu-toggleable-left {
    background-color: #fff;
    z-index: 4;
  }
}

.menu-toggleable-left .close-menu {
  flex: 0 0 auto;
  width: 100%;
  padding: 15px;
  max-height: 50px;
  background-color: #fff;
}
.menu-toggleable-left .close-menu .back {
  padding-bottom: 0.8rem;
}
@media (min-width: 1025px) {
  .menu-toggleable-left .close-menu {
    display: none;
  }
}
.menu-toggleable-left .menu-group {
  flex: 0 0 100%;
}
.menu-toggleable-left li > .close-menu {
  margin-right: 0;
  margin-top: -0.6rem;
  margin-left: 0;
}
@media (max-width: 1024.98px) {
  .menu-toggleable-left .bg-inverse {
    background-color: white !important;
    color: #fafafa;
  }
  .menu-toggleable-left .bg-inverse .user-message,
  .menu-toggleable-left .bg-inverse .fa-sign-in,
  .menu-toggleable-left .bg-inverse .dropdown-toggle::after {
    color: #85704d;
  }
  .menu-toggleable-left .bg-inverse .user-message {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
  }
}
@media (max-width: 991.98px) {
  .menu-toggleable-left.in {
    right: 0;
    margin-right: 1.25em; /* 20/16 */
  }
}
@media (max-width: 1024.98px) {
  .menu-toggleable-left.in .nav-item + .nav-item {
    border-top: 1px solid #d7d3d1;
    margin-top: 5px;
  }
  .menu-toggleable-left.in .nav-item + .nav-item.utility {
    padding-top: 0.7rem;
  }
  .menu-toggleable-left.in .nav-item + .nav-item.utility:last-of-type {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
  .menu-toggleable-left.in .nav-item + .nav-item.utility .nav-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .menu-toggleable-left.in .nav-item .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .menu-toggleable-left.in .dropdown {
    display: block;
    position: static;
  }
  .menu-toggleable-left.in .dropdown-caret {
    background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat 98% 1.1em;
  }
  .menu-toggleable-left.in .show > .dropdown-menu {
    left: 0;
  }
  .menu-toggleable-left.in .dropdown-menu {
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
  }
}

.multilevel-dropdown .dropdown-menu {
  top: 94%;
  border: 0;
  border-radius: 0;
}
@media (min-width: 1025px) {
  .multilevel-dropdown .dropdown-menu {
    box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
  }
}
@media (min-width: 1025px) {
  .multilevel-dropdown .dropdown-menu > .dropdown > .dropdown-menu {
    top: -0.65em;
    left: 99%;
  }
}
.multilevel-dropdown .navbar > .close-menu > .back {
  display: none;
}
.multilevel-dropdown .close-menu .back {
  padding-left: 1.5rem;
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat left 7px;
}
.multilevel-dropdown .dropdown-item {
  padding: 0 0 0 1em;
}
.multilevel-dropdown .dropdown-item .dropdown-link {
  display: block;
  padding: 0.8em 1em 0.6em 0;
  color: #231f20 !important;
  text-decoration: none;
}
.multilevel-dropdown .dropdown-item .dropdown-link:hover {
  color: #85704d !important;
}
.multilevel-dropdown .dropdown-item + .dropdown-item {
  border-top: 1px solid #d7d3d1;
}
.multilevel-dropdown .dropdown-item + .dropdown-item:last-of-type {
  border-bottom: 1px solid #d7d3d1;
  padding-bottom: 0.5em;
}
@media (min-width: 1025px) {
  .multilevel-dropdown .dropdown-item + .dropdown-item:last-of-type {
    border-bottom: none;
  }
}
.multilevel-dropdown .dropdown-item.mobile-header {
  border: none;
}
.multilevel-dropdown .dropdown-item.top-category {
  font-weight: 700;
  padding-bottom: 0.7rem;
  padding-top: 0.5rem;
  border-bottom: 1px solid #d7d3d1;
  border-top: 1px solid #d7d3d1;
}
.multilevel-dropdown .dropdown-item.top-category .dropdown-caret {
  background: none;
}
.multilevel-dropdown .dropdown-item.top-category .nav-link {
  font-size: 1.125rem;
  text-transform: uppercase;
  padding: 0.6em 0 0.4em;
}

@media (max-width: 575.98px) {
  .position-xs-static {
    position: static;
  }
}
@media (max-width: 767.98px) {
  .position-sm-static {
    position: static;
  }
}
@media (max-width: 991.98px) {
  .position-md-static {
    position: static;
  }
}
@media (max-width: 1024.98px) {
  .position-lg-static {
    position: static;
  }
}
@media (max-width: 1199.98px) {
  .position-th-static {
    position: static;
  }
}
.position-xl-static {
  position: static;
}

.main-menu {
  position: relative;
}

.navbar {
  position: static;
}

.navbar.bg-inverse {
  padding: 0;
}
@media (min-width: 1025px) {
  .navbar.bg-inverse .navbar-nav {
    display: inline-block;
    width: 100%;
  }
  .navbar.bg-inverse .navbar-nav .nav-item,
  .navbar.bg-inverse .navbar-nav .dropdown {
    display: inline-block;
  }
  .navbar.bg-inverse .navbar-nav .nav-item.link-thisisfederal {
    float: right;
    background: #76716e;
    margin-top: 0;
  }
  .navbar.bg-inverse .navbar-nav .nav-item.link-thisisfederal a {
    display: block;
    padding: 10px 1rem 9px;
    margin-right: 0;
  }
}

.menu-toggleable-left.in .has-sub-cat::after {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  right: 0.3em;
  margin-top: 0.55em;
  margin-right: 0.255em;
}
@media (max-width: 1024.98px) {
  .menu-toggleable-left.in .menu-column {
    display: block;
    position: static;
  }
  .menu-toggleable-left.in .show > .menu-list {
    left: 0 !important;
  }
  .menu-toggleable-left.in .menu-column > .menu-list > .dropdown-item > .menu-list {
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #222;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    z-index: 1;
  }
  .menu-toggleable-left.in .dropdown-menu,
  .menu-toggleable-left.in .menu-list {
    box-shadow: none;
  }
}

.multilevel-dropdown .dropdown-item:hover, .multilevel-dropdown .dropdown-item:focus {
  background-color: transparent;
}
.multilevel-dropdown .dropdown-item.top-category .nav-link {
  padding-left: 0;
}
.multilevel-dropdown .dropdown-item.top-category .caret-icon {
  display: none;
}
@media (min-width: 1025px) {
  .multilevel-dropdown .dropdown-item {
    padding: 0.425em 0;
  }
  .multilevel-dropdown .dropdown-item + .dropdown-item {
    border: none;
  }
  .multilevel-dropdown .dropdown-item .dropdown-link {
    padding: 0;
    line-height: normal;
  }
}
.multilevel-dropdown .nav-item img,
.multilevel-dropdown .dropdown-item img {
  max-width: 100%;
}
@media (max-width: 767.98px) {
  .multilevel-dropdown .nav-item,
  .multilevel-dropdown .dropdown-item {
    border-top: none;
  }
}
@media (min-width: 1025px) {
  .multilevel-dropdown .dropdown-menu,
  .multilevel-dropdown .menu-list {
    width: auto;
  }
}
@media (min-width: 1025px) {
  .multilevel-dropdown .dropdown-toggle::after {
    display: none;
  }
}

@media (max-width: 1024.98px) {
  .menu-row {
    padding: 0;
  }
}
@media (min-width: 1025px) {
  .menu-row {
    display: flex;
  }
  .menu-row .dropdown-item.active, .menu-row .dropdown-item:active, .menu-row .dropdown-item:hover {
    color: inherit;
    background-color: inherit;
  }
}

@media (min-width: 1025px) {
  .menu-column {
    padding: 0.25rem 12px;
    margin-bottom: 10px;
  }
}

.menu-list {
  padding-left: 0;
}
.menu-list .caret-icon.caret-right {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  right: 0.3em;
  margin-top: 0.55em;
  margin-right: 0.255em;
}
@media (min-width: 1025px) {
  .menu-list .has-sub-cat {
    font-weight: bold;
  }
}

.navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: #fff !important;
}

.header-wrapper {
  background: #222;
}

.header {
  position: relative;
}
@media (min-width: 1025px) {
  .header {
    padding: 27px 12px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.navbar-header {
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
  .navbar-header .user,
  .navbar-header .country-selector,
  .navbar-header .minicart {
    display: inline-block;
  }
}
.navbar-header .company-user-name-header {
  color: #000 !important;
}
.navbar-header .user,
.navbar-header .country-selector,
.navbar-header .minicart,
.navbar-header .navbar-toggler {
  line-height: 2.25em; /* 36/16 */
  height: auto;
}
.navbar-header .navbar-toggler {
  height: 24px;
  width: auto;
  background: url("../images/svg-icons/icon-menu.svg");
}
.navbar-header .header-utility-links a {
  color: #fff;
  margin-right: 40px;
}
.navbar-header .header-utility-links a span {
  color: #fff;
}
.navbar-header .header-utility-links a:nth-of-type(2) {
  margin-right: 0;
}
.navbar-header .user {
  position: relative;
}
.navbar-header .user .account-dropdown-spacing {
  margin-top: 10px;
}
.navbar-header .user .popover {
  position: absolute;
  display: none;
  padding: 1em;
  top: 85%;
  left: 0;
  background: #222;
}
.navbar-header .user .popover a {
  white-space: nowrap;
  margin-bottom: 0.5em;
}
.navbar-header .user .popover ul {
  margin-bottom: 0;
  padding-left: 0;
}
.navbar-header .user .popover.show {
  display: block;
}
.navbar-header .search {
  display: inline-block;
  width: 260px;
}
@media (min-width: 1025px) {
  .navbar-header {
    height: 100%;
    display: block;
  }
}

.brand img {
  width: 100%;
}
@media (min-width: 1025px) {
  .brand {
    width: 288px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.no-menu-header .brand {
  justify-content: center;
}
@media (min-width: 1025px) {
  .no-menu-header .brand {
    display: flex;
    flex-direction: column;
  }
}

.main-menu {
  background-color: #353535;
}
.main-menu .navbar .close-menu button,
.main-menu .navbar .close-button button {
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.main-menu.no-menu {
  height: 40px;
}
.main-menu.no-menu .continue-shopping-wrapper {
  text-align: center;
  padding-top: 9px;
}
.main-menu.no-menu .continue-shopping {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 1px;
  margin-right: 1rem;
  padding: 0;
  color: #fff;
  text-transform: uppercase;
  width: auto;
}
.main-menu.no-menu .continue-shopping::after {
  content: "";
  width: 0;
  height: 3px;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: -5px;
  transition: width 0.3s ease;
}
.main-menu.no-menu .continue-shopping:hover {
  color: #fff;
}
.main-menu.no-menu .continue-shopping:hover::after {
  content: "";
  width: 100%;
  left: 0;
}
@media (min-width: 1025px) {
  .main-menu {
    height: 40px;
  }
}

.header-banner {
  background-color: #85704d;
  text-align: center;
  color: #fff;
}
.header-banner a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}
.header-banner .content {
  width: 100%;
  padding-top: 0.3125em;
  padding-bottom: 0.3125em;
}

.minicart a.minicart-link:hover {
  text-decoration: none;
}
.minicart .minicart-quantity {
  display: inline-block;
  text-align: center;
  position: relative;
  top: 1px;
  left: 5px;
  color: #fff;
}
.minicart .icon {
  height: 16px;
  width: 20px;
  position: relative;
  top: 4px;
  pointer-events: none;
}
@media (min-width: 1025px) {
  .minicart {
    position: relative;
    bottom: 4px;
  }
}

a.normal {
  color: #85704d;
  text-decoration: underline;
}

.slide-up {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden;
}
.slide-up.hide {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.dropdown-country-selector {
  margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none;
}

.valid-cookie-warning {
  background-color: #007bff;
  color: #fff;
  white-space: nowrap;
}
.valid-cookie-warning p {
  margin-top: 0;
  margin-bottom: 0.2em;
  padding-right: 2em;
}

nav.menu-container .navbar-header {
  display: flex;
  justify-content: space-between;
}
nav.menu-container .minicart-total.hide-no-link {
  margin-right: 25px;
}
nav.menu-container .search-mobile {
  display: none;
}
nav.menu-container .brand {
  position: relative;
  margin: 0;
  bottom: 0;
}
nav.menu-container .searchmenu.active .search-content {
  right: 0;
}
nav.menu-container .utility,
nav.menu-container .minicart {
  flex-shrink: 0;
}
nav.menu-container .search-icon.active {
  z-index: 1;
}
nav.menu-container #myaccount {
  text-decoration: none;
}
nav.menu-container #myaccount .user-message {
  position: relative;
  top: 2px;
  margin-left: 6px;
}
nav.menu-container .suggestions {
  width: 100%;
}
nav.menu-container .searchmenu {
  position: relative;
  overflow: hidden;
  height: 45px;
  width: 650px;
  left: 25px;
  z-index: 2;
}
nav.menu-container .searchmenu .search-content {
  position: absolute;
  width: 100%;
  right: -101%;
  top: 4px;
  transition: all 0.5s ease;
}
nav.menu-container .site-search {
  margin-right: 0;
}
nav.menu-container .site-search .search {
  background: url("../images/svg-icons/icon-close.svg");
  width: 24px;
  height: 24px;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  right: 15px;
  background-repeat: no-repeat;
}
nav.menu-container .search-icon {
  position: relative;
  top: 2px;
}
nav.menu-container .search-icon,
nav.menu-container .header-utility-links a {
  margin-right: 25px;
  cursor: pointer;
  z-index: 2;
}
@media (min-width: 1200px) {
  nav.menu-container .search-icon,
  nav.menu-container .header-utility-links a {
    margin-right: 32px;
  }
}
nav.menu-container .site-search form {
  position: relative;
}
@media (max-width: 991.98px) {
  nav.menu-container .site-search {
    padding-bottom: 20px;
  }
}
nav.menu-container .site-search .search-field::placeholder {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: black !important;
}
nav.menu-container .site-search .search-field {
  border-radius: 22px;
  height: 40px;
  background: #fff;
  color: #000;
  text-indent: 10px;
}
@media (min-width: 768px) {
  nav.menu-container .site-search .search-field {
    text-indent: 15px;
  }
}
nav.menu-container .minicart {
  bottom: 0;
}
@media (max-width: 1024.98px) {
  nav.menu-container .main-menu a {
    text-transform: uppercase;
  }
  nav.menu-container .menu-toggleable-left.navbar-toggleable-lg.in {
    right: 0;
    left: auto;
    margin: 0;
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
  }
  nav.menu-container .menu-toggleable-left.navbar-toggleable-lg {
    right: -100%;
  }
}

@media (max-width: 991.98px) {
  .breadcrumb-container {
    position: relative;
  }
  .breadcrumb-container .breadcrumbs {
    margin: 0 10px;
    border-bottom: 1px solid #d7d3d1;
    overflow-x: scroll;
  }
  .breadcrumb-container .breadcrumb-item {
    display: flex;
    align-items: center;
  }
  .breadcrumb-container .breadcrumb {
    flex-wrap: nowrap;
    border-bottom: none;
    margin-left: -1rem;
    margin-bottom: 0;
  }
  .breadcrumb-container .breadcrumb-item a {
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;
  }
  .breadcrumb-container .breadcrumbs.active::after {
    content: "...";
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    background: #fff;
  }
  .breadcrumb-container .breadcrumbs::after {
    content: none;
  }
}
.modal-open {
  max-width: 100%;
}

.cls-logo-desktop {
  width: "287px";
  height: "46px";
}

.cls-logo-tablet {
  width: "222px";
  height: "37px";
}

.cls-logo-mobile {
  width: "24px";
  height: "24px";
}

.cls-search {
  width: "24px";
  height: "24px";
}

.cls-account {
  width: "24px";
  height: "24px";
}

.cls-minicart-icon {
  width: "86px";
  height: "26px";
}

.cls-minicart-total {
  width: "39px";
  height: "26px";
}

.cls-product {
  width: "266px";
}

.sticky-position {
  position: sticky;
}

.cls-minicart img {
  width: "39px";
  height: "26px";
}
.cls-minicart span {
  position: sticky;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  background-color: #85704d !important;
  border: 1px solid #85704d !important;
  color: #fff !important;
}

@media (max-width: 575.98px) {
  .collapsible-xs .title {
    line-height: 2.5rem; /* 40/16 */
  }
  .collapsible-xs .title::after {
    display: block;
    content: "";
    clear: both;
  }
  .collapsible-xs .title::after {
    float: right;
    content: "\f078";
    font-family: "FontAwesome";
  }
  .collapsible-xs .content,
  .collapsible-xs .card-body {
    display: none;
  }
  .collapsible-xs.active .title::after {
    content: "\f077";
    margin-top: -0.125em; /* 2/16 */
  }
  .collapsible-xs.active .content,
  .collapsible-xs.active .card-body {
    display: block;
  }
}
.container div.collapsible-xs button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.container div.collapsible-xs button.title:hover {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .collapsible-sm .title {
    line-height: 2.5rem; /* 40/16 */
  }
  .collapsible-sm .title::after {
    display: block;
    content: "";
    clear: both;
  }
  .collapsible-sm .title::after {
    float: right;
    content: "\f078";
    font-family: "FontAwesome";
  }
  .collapsible-sm .content,
  .collapsible-sm .card-body {
    display: none;
  }
  .collapsible-sm.active .title::after {
    content: "\f077";
    margin-top: -0.125em; /* 2/16 */
  }
  .collapsible-sm.active .content,
  .collapsible-sm.active .card-body {
    display: block;
  }
}
.container div.collapsible-sm button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.container div.collapsible-sm button.title:hover {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .collapsible-md .title {
    line-height: 2.5rem; /* 40/16 */
  }
  .collapsible-md .title::after {
    display: block;
    content: "";
    clear: both;
  }
  .collapsible-md .title::after {
    float: right;
    content: "\f078";
    font-family: "FontAwesome";
  }
  .collapsible-md .content,
  .collapsible-md .card-body {
    display: none;
  }
  .collapsible-md.active .title::after {
    content: "\f077";
    margin-top: -0.125em; /* 2/16 */
  }
  .collapsible-md.active .content,
  .collapsible-md.active .card-body {
    display: block;
  }
}
.container div.collapsible-md button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.container div.collapsible-md button.title:hover {
  text-decoration: none;
}

@media (max-width: 1024.98px) {
  .collapsible-lg .title {
    line-height: 2.5rem; /* 40/16 */
  }
  .collapsible-lg .title::after {
    display: block;
    content: "";
    clear: both;
  }
  .collapsible-lg .title::after {
    float: right;
    content: "\f078";
    font-family: "FontAwesome";
  }
  .collapsible-lg .content,
  .collapsible-lg .card-body {
    display: none;
  }
  .collapsible-lg.active .title::after {
    content: "\f077";
    margin-top: -0.125em; /* 2/16 */
  }
  .collapsible-lg.active .content,
  .collapsible-lg.active .card-body {
    display: block;
  }
}
.container div.collapsible-lg button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.container div.collapsible-lg button.title:hover {
  text-decoration: none;
}

@media (max-width: 1199.98px) {
  .collapsible-th .title {
    line-height: 2.5rem; /* 40/16 */
  }
  .collapsible-th .title::after {
    display: block;
    content: "";
    clear: both;
  }
  .collapsible-th .title::after {
    float: right;
    content: "\f078";
    font-family: "FontAwesome";
  }
  .collapsible-th .content,
  .collapsible-th .card-body {
    display: none;
  }
  .collapsible-th.active .title::after {
    content: "\f077";
    margin-top: -0.125em; /* 2/16 */
  }
  .collapsible-th.active .content,
  .collapsible-th.active .card-body {
    display: block;
  }
}
.container div.collapsible-th button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.container div.collapsible-th button.title:hover {
  text-decoration: none;
}

.collapsible-xl .title {
  line-height: 2.5rem; /* 40/16 */
}
.collapsible-xl .title::after {
  display: block;
  content: "";
  clear: both;
}
.collapsible-xl .title::after {
  float: right;
  content: "\f078";
  font-family: "FontAwesome";
}
.collapsible-xl .content,
.collapsible-xl .card-body {
  display: none;
}
.collapsible-xl.active .title::after {
  content: "\f077";
  margin-top: -0.125em; /* 2/16 */
}
.collapsible-xl.active .content,
.collapsible-xl.active .card-body {
  display: block;
}

.container div.collapsible-xl button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.container div.collapsible-xl button.title:hover {
  text-decoration: none;
}

footer {
  background-color: #000;
  padding-top: 20px;
}
footer ul {
  list-style: none;
  padding-left: 0;
}
footer .social {
  text-align: center;
  margin-top: 30px;
}
footer .social li {
  display: inline;
  padding-right: 25px;
}
footer .social li:last-of-type {
  padding-right: 0;
}
footer .social .icon {
  height: 24px;
  width: 24px;
  pointer-events: none;
}
@media (min-width: 992px) {
  footer .social {
    text-align: left;
    margin-top: 0;
  }
}
footer .footer-container {
  padding: 0 10px;
}
@media (min-width: 992px) {
  footer .footer-container {
    display: flex;
    border-left: 1px solid #fff;
  }
}
footer .copyright-notice {
  padding: 0;
  text-align: center;
}
@media (min-width: 992px) {
  footer .copyright-notice > div {
    max-width: 940px;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  footer .copyright-notice > div {
    max-width: 1200px;
  }
}
@media (min-width: 992px) {
  footer .copyright-notice {
    background: #353535;
    overflow: hidden;
    margin-top: 18px;
  }
}
footer .postscript {
  background: #353535;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding: 11px 0;
  overflow-y: scroll;
}
footer .postscript a {
  color: #fff;
  text-decoration: underline;
}
@media (min-width: 992px) {
  footer .postscript a {
    line-height: 21px;
  }
}
footer .postscript li {
  display: inline-block;
  width: 33%;
  padding: 0 12px;
  margin-top: 0;
  text-align: center;
}
footer .postscript li:nth-of-type(even) {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
@media (min-width: 992px) {
  footer .postscript li {
    width: auto;
  }
}
@media (min-width: 992px) {
  footer .postscript {
    float: right;
  }
}
footer .copyright {
  color: #fff;
  margin-bottom: 11px;
}
@media (min-width: 992px) {
  footer .copyright {
    float: left;
    margin-bottom: 0;
    line-height: 46px;
  }
}
footer .back-to-top {
  position: fixed;
  right: 1.2vw;
  bottom: 60px;
  background-color: transparent;
  background-image: url("../images/svg-icons/icon-arrow-up-gold.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 32px 32px;
  border-radius: 0;
  float: left;
  height: 40px;
  margin: 0.313em;
  margin-top: 0.1875em;
  border-color: transparent;
  cursor: pointer;
  padding: 0.125em 0.375em 0.1875em;
  width: 40px;
  z-index: 100;
}
footer .back-to-top:focus {
  outline: 0;
}

.footer-item {
  border-bottom: 1px solid #fff;
  padding: 21px 0 22px;
}
.footer-item:first-of-type {
  border-top: 1px solid #fff;
}
.footer-item h1, .footer-item h2 {
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
  font-size: 0.875rem;
}
.footer-item h1::after, .footer-item h2::after {
  content: url("../images/svg-icons/icon-plus-white.svg");
  position: absolute;
  right: 15px;
}
@media (min-width: 992px) {
  .footer-item h1::after, .footer-item h2::after {
    content: "";
  }
}
.footer-item a {
  color: #fff;
}
.footer-item .footer-links {
  margin-bottom: 0;
  display: none;
  padding-top: 17px;
}
.footer-item .footer-links li {
  margin-top: 0;
}
@media (min-width: 992px) {
  .footer-item .footer-links {
    display: block;
  }
}
.footer-item.active h1::after, .footer-item.active h2::after {
  content: url("../images/svg-icons/icon-minus-white.svg");
  position: absolute;
  right: 15px;
}
.footer-item.active .footer-links {
  display: block;
}
@media (min-width: 992px) {
  .footer-item.active h1::after, .footer-item.active h2::after {
    content: "";
  }
}
@media (min-width: 992px) {
  .footer-item {
    border: none;
    flex: 1;
    padding-left: 20px;
  }
  .footer-item:first-of-type {
    border-top: none;
  }
}

#consent-tracking .button-wrapper button {
  margin: 0.5em;
}

.email-signup {
  padding-bottom: 26px;
}
.email-signup .email-description,
.email-signup h4 {
  color: #fff;
}
.email-signup .email-description {
  margin-bottom: 19px;
}
.email-signup button {
  margin: 0 auto;
  display: block;
  width: calc(100% - 12px);
}
@media (min-width: 992px) {
  .email-signup button {
    margin: 0;
    display: inline;
    width: 260px;
  }
}
@media (min-width: 992px) {
  .email-signup {
    padding-bottom: 20px;
    padding-top: 30px;
  }
}

.optInCheck {
  margin-top: 20px;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.hero {
  height: 25vw;
  background-size: cover;
  background-position: 50%;
  position: relative;
}
.hero h1.page-title {
  top: 50%;
  margin: -1em 0 0 0;
}
.page[data-action=Search-Show] .hero h1.page-title {
  color: #fff;
}

@media (min-width: 576px) {
  .slant-down::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-right-width: 0;
    border-left-width: 90vw;
    border-bottom: 4vw solid #fff;
  }
}

@media (min-width: 576px) {
  .slant-up::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-left-width: 0;
    border-right-width: 90vw;
    border-top: 4vw solid #fff;
  }
}

h1.page-title {
  position: relative;
  color: #231f20;
  padding: 0.3125em 0.625em 0.3125em 12px;
  display: inline-block;
  margin: 0.9375em 0;
  font-size: 1.5rem;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  h1.page-title {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  h1.page-title {
    font-size: 3rem;
  }
}
h1.page-title::before {
  content: "";
  height: 100%;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 1200px) {
  h1.page-title::before {
    width: calc((100vw - 1200px) / 2);
    left: calc((100vw - 1200px) / 2 * -1);
  }
}
@media (min-width: 992px) and (max-width: 1024.98px) {
  h1.page-title::before {
    width: calc((100vw - 940px) / 2);
    left: calc((100vw - 940px) / 2 * -1);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  h1.page-title::before {
    width: calc((100vw - 720px) / 2);
    left: calc((100vw - 720px) / 2 * -1);
  }
}
@media (min-width: 1200px) {
  h1.page-title {
    left: calc((100% - 1200px) / 2);
  }
}
@media (min-width: 992px) and (max-width: 1024.98px) {
  h1.page-title {
    left: calc((100% - 940px) / 2);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  h1.page-title {
    left: calc((100% - 720px) / 2);
  }
}
@media (max-width: 767.98px) {
  h1.page-title {
    left: 0;
  }
}

.hero {
  position: relative;
  overflow: hidden;
  display: flex;
}
.hero .hero-container {
  display: flex;
  padding: 1rem;
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .hero .hero-container {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .hero .hero-container {
    padding: 50px;
  }
}
@media (min-width: 1200px) {
  .full-bleed .hero .hero-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
.hero .hero-container .hero-background-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero .hero-container .hero-background-link .video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero .hero-container .hero-background-link .video-container .video-player {
  display: none;
}
.hero .embedded-component {
  width: 100%;
}
.hero .embedded-component:empty {
  display: none;
}
.hero .embedded-component .experience-region:empty {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hero {
    height: 0;
  }
  .hero.no-image {
    height: auto;
  }
}
.hero.short {
  min-height: 150px;
}
@media (min-width: 768px) {
  .hero.short {
    min-height: 80px;
  }
}
@media (min-width: 992px) {
  .hero.short {
    min-height: 80px;
  }
}
.hero.medium {
  min-height: 250px;
}
@media (min-width: 768px) {
  .hero.medium {
    min-height: 300px;
  }
}
@media (min-width: 992px) {
  .hero.medium {
    min-height: 350px;
  }
}
.hero.tall {
  min-height: 600px;
}
@media (min-width: 768px) {
  .hero.tall {
    min-height: 650px;
  }
}
@media (min-width: 992px) {
  .hero.tall {
    min-height: 700px;
  }
}
.hero.screen-height {
  min-height: calc(100vh - 50px - 1px - 20px - 10px - 10px);
}
@media (min-width: 768px) {
  .hero.screen-height {
    min-height: calc(100vh - 70px - 1px - 20px - 10px - 10px);
  }
}
@media (min-width: 992px) {
  .hero.screen-height {
    min-height: calc(100vh - 64px - 1px - 20px - 10px - 10px);
  }
}
.transparent-header ~ div .hero.screen-height {
  min-height: calc(100vh - 20px - 10px - 10px);
}
@media (min-width: 768px) {
  .transparent-header ~ div .hero.screen-height {
    min-height: calc(100vh - 20px - 10px - 10px);
  }
}
@media (min-width: 992px) {
  .transparent-header ~ div .hero.screen-height {
    min-height: calc(100vh - 20px - 10px - 10px);
  }
}

.hero-media {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.full-bleed .hero-media {
  width: calc(100vw + 2px);
}
.hero-media.hero-media-tablet, .hero-media.hero-media-desktop {
  display: none;
}
@media (min-width: 768px) {
  .hero-media.hero-media-mobile, .hero-media.hero-media-desktop {
    display: none;
  }
  .hero-media.hero-media-tablet {
    display: block;
  }
}
@media (min-width: 992px) {
  .hero-media.hero-media-mobile, .hero-media.hero-media-tablet {
    display: none;
  }
  .hero-media.hero-media-desktop {
    display: block;
  }
}
.hero-media.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  right: auto;
  transform: none;
  transition: opacity 200ms ease-out;
}
.hero-media.video-container .video-aspect-ratio {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
}
.hero-media.video-container .video-aspect-ratio.aspect-ratio-16-9 {
  padding-top: 56.25%;
  padding-left: 177.7777777778vh;
}
.hero-media.video-container .video-aspect-ratio.aspect-ratio-9-16 {
  padding-top: 177.7777777778%;
  padding-left: 56.25vh;
}
.hero-media.video-container .video-aspect-ratio.aspect-ratio-4-3 {
  padding-top: 75%;
  padding-left: 133.3333333333vh;
}
.hero-media.video-container .video-aspect-ratio.aspect-ratio-3-4 {
  padding-top: 133.3333333333%;
  padding-left: 75vh;
}
.hero-media.video-container .video-aspect-ratio.aspect-ratio-1-1 {
  padding-top: 100%;
  padding-left: 100vh;
}
.hero-media.video-container .video-aspect-ratio .video-player {
  position: static;
  pointer-events: none;
}
.hero-media.video-container .video-aspect-ratio iframe,
.hero-media.video-container .video-aspect-ratio video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.hero-media.video-container .video-aspect-ratio iframe.video-player,
.hero-media.video-container .video-aspect-ratio video.video-player {
  position: absolute;
}
.hero-media.video-container ~ .replay-video {
  z-index: 1;
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-out;
}
.align-items-start .hero-media.video-container ~ .replay-video, .align-items-center .hero-media.video-container ~ .replay-video {
  bottom: 1rem;
}
.align-items-end .hero-media.video-container ~ .replay-video {
  top: 1rem;
}
.transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
  top: 66px;
}
@media (min-width: 768px) {
  .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
    top: 86px;
  }
}
@media (min-width: 992px) {
  .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
    top: 80px;
  }
}
.justify-content-md-start .hero-media.video-container ~ .replay-video {
  right: 1rem;
}
.justify-content-md-end .hero-media.video-container ~ .replay-video, .justify-content-md-center .hero-media.video-container ~ .replay-video {
  left: 1rem;
}
.hero-media.video-container.video-stopped {
  opacity: 0;
}
.hero-media.video-container.video-stopped ~ .replay-video {
  transition: opacity 200ms ease-out 600ms;
  opacity: 1;
}

.hero-text {
  overflow: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.text-in-card .hero-text {
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
}
@media (min-width: 768px) {
  .text-in-card .hero-text {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .text-in-card .hero-text {
    padding: 1rem;
  }
}
.text-in-card .hero-text:not([class*=border--]) {
  border-color: transparent;
}
.text-in-card .hero-text.semitransparent:not([class*=background--]).dark-theme {
  background: rgba(0, 0, 0, 0.7);
}
.text-in-card .hero-text.semitransparent:not([class*=background--]).light-theme {
  background: rgba(255, 255, 255, 0.7);
}
.text-in-card .hero-text.opaque:not([class*=background--]).dark-theme {
  background: #000;
}
.text-in-card .hero-text.opaque:not([class*=background--]).light-theme {
  background: #fff;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hero-text.text-center {
    align-items: center;
  }
  .hero-text.text-right {
    align-items: flex-end;
  }
}
@media (min-width: 768px) {
  .hero-text.width-1-4 {
    max-width: 25%;
  }
  .hero-text.width-1-3 {
    max-width: 33.3333%;
  }
  .hero-text.width-1-2 {
    max-width: 50%;
  }
  .hero-text.width-2-3 {
    max-width: 66.6666%;
  }
  .hero-text.width-3-4 {
    max-width: 75%;
  }
  .hero-text.width-1 {
    max-width: 100%;
  }
}
.hero-text .hero-subtitle > *,
.hero-text .hero-title > *,
.hero-text .hero-body > * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin-bottom: 0;
}
.hero-text .hero-subtitle:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-transform: none;
}
.hero-text .hero-title:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal;
}
@media (min-width: 768px) {
  .hero-text .hero-title:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    font-size: 3rem;
    line-height: 1.14;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
  }
}
@media (min-width: 992px) {
  .hero-text .hero-title:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    font-size: 54px;
    line-height: 1.11;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
  }
}
.hero-text .hero-body ul {
  list-style: disc;
  list-style-position: outside;
  margin-left: 1rem;
}
@media (min-width: 576px) {
  .hero-text .hero-body ul {
    columns: 2;
  }
}
.hero-text .hero-subtitle + .hero-title,
.hero-text .hero-title + .hero-body,
.hero-text .hero-embedded-component + .hero-body,
.hero-text .hero-body + .hero-buttons .hero-button,
.hero-text .hero-embedded-component .experience-region > * {
  margin-top: 1rem;
}
.hero-text .hero-buttons {
  font-size: 0;
}
.hero-text .hero-button {
  margin-top: 1rem;
  display: inline-block;
  position: relative;
}
.hero-text.text-center .hero-button:not(:only-child) {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.hero-text.text-left .hero-button:not(:only-child) {
  margin-right: 1rem;
}
.hero-text.text-right .hero-button:not(:only-child) {
  margin-left: 1rem;
}

.hero-slider-container .hero-slot.container {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 767.98px) {
  .hero.half-and-half {
    height: auto;
  }
  .hero.half-and-half .hero-container {
    flex-flow: column !important;
  }
  .hero.half-and-half .hero-media {
    transform: translateX(50%);
    top: 0;
    position: relative;
    width: calc(100% + 2rem);
    margin: -1rem 0 1rem -2rem;
  }
  .hero.half-and-half.short {
    min-height: 0;
  }
  .hero.half-and-half.short .hero-media {
    height: 150px;
  }
  .hero.half-and-half.medium {
    min-height: 0;
  }
  .hero.half-and-half.medium .hero-media {
    height: 250px;
  }
  .hero.half-and-half.tall, .hero.half-and-half.screen-height {
    min-height: 0;
  }
  .hero.half-and-half.tall .hero-media, .hero.half-and-half.screen-height .hero-media {
    height: 600px;
  }
}
@media (min-width: 768px) {
  .hero.half-and-half .hero-media {
    transform: translateY(-50%);
  }
  .hero.half-and-half .hero-media.width-1-4 {
    max-width: 75%;
  }
  .hero.half-and-half .hero-media.width-1-3 {
    max-width: 66.66666%;
  }
  .hero.half-and-half .hero-media.width-1-2 {
    max-width: 50%;
  }
  .hero.half-and-half .hero-media.width-2-3 {
    max-width: 33.3333%;
  }
  .hero.half-and-half .hero-media.width-3-4 {
    max-width: 25%;
  }
  .hero.half-and-half .hero-media.width-1 {
    max-width: 50%;
  }
  .hero.half-and-half .hero-text.width-1 {
    max-width: 50%;
  }
  .hero.half-and-half .justify-content-md-start .hero-media {
    right: 0;
    left: auto;
  }
  .hero.half-and-half .justify-content-md-start .hero-text {
    padding-right: 1rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .hero.half-and-half .justify-content-md-start .hero-text {
    padding-right: 1rem;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .hero.half-and-half .justify-content-md-start .hero-text {
    padding-right: 50px;
  }
}
@media (min-width: 768px) {
  .hero.half-and-half .justify-content-md-end .hero-media {
    left: 0;
    right: auto;
  }
  .hero.half-and-half .justify-content-md-end .hero-text {
    padding-left: 1rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .hero.half-and-half .justify-content-md-end .hero-text {
    padding-left: 1rem;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .hero.half-and-half .justify-content-md-end .hero-text {
    padding-left: 50px;
  }
}

@media (max-width: 767.98px) {
  .hero .flex-column .hero-media {
    transform: translateX(50%);
    top: 0;
    position: relative;
    width: calc(100% + 2rem);
    margin: -1rem 0 1rem -2rem;
  }
  .hero.short .flex-column {
    min-height: 0;
  }
  .hero.short .flex-column .hero-media {
    height: 150px;
  }
  .hero.medium .flex-column {
    min-height: 0;
  }
  .hero.medium .flex-column .hero-media {
    height: 250px;
  }
  .hero.tall .flex-column, .hero.screen-height .flex-column {
    min-height: 0;
  }
  .hero.tall .flex-column .hero-media, .hero.screen-height .flex-column .hero-media {
    height: 600px;
  }
  .hero.half-and-half .flex-column[class*=justify-content-md] .hero-media {
    left: auto;
    right: 50%;
    max-width: calc(100% + 2rem);
  }
  .hero.half-and-half .flex-column[class*=justify-content-md] .hero-text {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hero .flex-md-column .hero-media {
    transform: translateX(50%);
    top: 0;
    position: relative;
    width: calc(100% + 2rem);
    margin: -1rem 0 1rem -2rem;
  }
  .hero.short .flex-md-column {
    min-height: 0;
  }
  .hero.short .flex-md-column .hero-media {
    height: 150px;
  }
  .hero.medium .flex-md-column {
    min-height: 0;
  }
  .hero.medium .flex-md-column .hero-media {
    height: 250px;
  }
  .hero.tall .flex-md-column, .hero.screen-height .flex-md-column {
    min-height: 0;
  }
  .hero.tall .flex-md-column .hero-media, .hero.screen-height .flex-md-column .hero-media {
    height: 600px;
  }
  .hero.half-and-half .flex-md-column[class*=justify-content-md] .hero-media {
    left: auto;
    right: 50%;
    max-width: calc(100% + 2rem);
  }
  .hero.half-and-half .flex-md-column[class*=justify-content-md] .hero-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.experience-component .hero-button .video-container::before,
.experience-component .hero-button .video-container .video-player,
.experience-component .button .video-container::before,
.experience-component .button .video-container .video-player,
.experience-component .image-text-block-button .video-container::before,
.experience-component .image-text-block-button .video-container .video-player {
  display: none;
}

.image-text-block {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
}
.image-text-block:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  .image-text-block.zoom .image-wrapper {
    will-change: transform;
    transition: transform 200ms ease-out;
  }
  .image-text-block.zoom:hover .image-wrapper {
    transform: scale(1.2);
  }
}
.image-text-block .image-cropper {
  border-radius: 0;
  overflow: hidden;
  margin: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.image-text-block .image-wrapper {
  width: 100%;
  position: relative;
}
.image-text-block .image-wrapper .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
}
.image-text-block .image-wrapper .background-image-tablet,
.image-text-block .image-wrapper .background-image-desktop {
  display: none;
}
@media (min-width: 768px) {
  .image-text-block .image-wrapper .background-image-mobile,
  .image-text-block .image-wrapper .background-image-desktop {
    display: none;
  }
  .image-text-block .image-wrapper .background-image-tablet {
    display: block;
  }
}
@media (min-width: 992px) {
  .image-text-block .image-wrapper .background-image-mobile,
  .image-text-block .image-wrapper .background-image-tablet {
    display: none;
  }
  .image-text-block .image-wrapper .background-image-desktop {
    display: block;
  }
}
.image-text-block .image-wrapper .video-container .video-aspect-ratio {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding-top: 56.25%;
  padding-left: 177.7777777778vh;
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
}
.image-text-block .image-wrapper .video-container .video-aspect-ratio .video-player {
  position: static;
  pointer-events: none;
}
.image-text-block .image-wrapper .video-container .video-aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.image-text-block .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
  position: absolute;
}
.image-text-block .image-text-block-text .primary-text > *,
.image-text-block .image-text-block-text .secondary-text > *,
.image-text-block .image-text-block-text .image-text-block-subtitle > * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin-bottom: 0;
}
.image-text-block .image-text-block-text .image-text-block-subtitle:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-size: 13px;
}
.image-text-block .image-text-block-text .primary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-size: 16px;
}
.image-text-block .image-text-block-text .secondary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-size: 13px;
}
.image-text-block .image-text-block-text .image-text-block-subtitle + .primary-text {
  margin-top: 6px;
}
.image-text-block .image-text-block-text .secondary-text + .image-text-block-button {
  margin-top: 1rem;
}
.image-text-block .image-text-block-text .image-text-block-button {
  text-shadow: none;
}
.image-text-block.text-placement-below .image-text-block-text {
  margin-top: 1rem;
}
.image-text-block.text-placement-inside::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
}
.image-text-block.text-placement-inside .image-text-block-text {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: #fff;
  z-index: 1;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  width: calc(100% - 1rem);
}
@media (min-width: 992px) {
  .image-text-block.text-placement-inside .image-text-block-text {
    width: calc(100% - 2rem);
  }
}
.image-text-block.aspect-ratio-square .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.image-text-block.aspect-ratio-square .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.image-text-block.aspect-ratio-landscape .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.image-text-block.aspect-ratio-landscape .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 66.6666666667%;
}
.image-text-block.aspect-ratio-portrait .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.image-text-block.aspect-ratio-portrait .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 150%;
}
.image-text-block.aspect-ratio-twelve-five .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.image-text-block.aspect-ratio-twelve-five .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 41.6666666667%;
}

.notify {
  position: fixed;
  top: 0;
}

.page-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .page-header {
    padding-top: 44px;
    padding-bottom: 44px;
  }
}
.page-header.blog-detail-header .page-header-title {
  font-family: "Roboto", sans-serif;
  font-size: 2.25rem;
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal;
}
@media (min-width: 768px) {
  .page-header.blog-detail-header .page-header-title {
    font-size: 2.25rem;
    line-height: 1.17;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
  }
}
@media (min-width: 992px) {
  .page-header.blog-detail-header .page-header-title {
    font-size: 40px;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
  }
}
.page-header.search-results-header .page-header-title {
  font-family: "Roboto", sans-serif;
  font-size: 2.25rem;
  line-height: 1.14;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: none;
  font-style: normal;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .page-header.search-results-header .page-header-title {
    font-size: 2.25rem;
    line-height: 1.17;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
  }
}
@media (min-width: 992px) {
  .page-header.search-results-header .page-header-title {
    font-size: 40px;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    font-style: normal;
  }
}

.photo-hotspots {
  position: relative;
}
.photo-hotspots .hotspot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  margin-top: -10px;
  margin-left: -10px;
  box-shadow: none;
  transition: all 200ms ease-out;
  text-decoration: none;
}
.photo-hotspots .hotspot::before {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 10px;
}
.photo-hotspots .hotspot .hotspot-border-inner,
.photo-hotspots .hotspot .hotspot-border-outer {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  border-radius: 50%;
  transition: all 200ms ease-out;
}
.photo-hotspots .hotspot .hotspot-border-inner {
  font-size: 10px;
  line-height: 20px;
  width: 26px;
  height: 26px;
  border: 3px solid transparent;
  z-index: 1;
}
.photo-hotspots .hotspot .hotspot-border-outer {
  content: "";
  width: 32px;
  height: 32px;
  border: 3px solid #fff;
}
.photo-hotspots .hotspot:hover {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}
.photo-hotspots .hotspot:hover .hotspot-border-inner {
  line-height: 40px;
  width: 46px;
  height: 46px;
  border: 3px solid transparent;
}
.photo-hotspots .hotspot:hover .hotspot-border-outer {
  width: 52px;
  height: 52px;
  border: 3px solid #fff;
}
.photo-hotspots .hotspot.dark {
  background: rgba(0, 0, 0, 0.3);
}
.photo-hotspots .hotspot.dark::before {
  color: #000;
}
.photo-hotspots .hotspot.dark .hotspot-border-inner {
  border-color: transparent;
}
.photo-hotspots .hotspot.dark .hotspot-border-outer {
  border-color: #000;
}
.photo-hotspots .hotspot.dark:hover .hotspot-border-inner {
  border-color: transparent;
}
.photo-hotspots .hotspot.dark:hover .hotspot-border-outer {
  border-color: #000;
}

.photo-hotspots-tooltip.tooltip {
  z-index: 999;
}
.photo-hotspots-tooltip.tooltip .tooltip-inner {
  padding: 0;
  max-width: 300px;
}

.photo-hotspots-tooltip-text {
  text-align: left;
  padding: 8px;
}
.photo-hotspots-tooltip-text > * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin-bottom: 0;
}
.photo-hotspots-tooltip-text + .secondary-text {
  padding-top: 0;
}
.photo-hotspots-tooltip-text + .photo-hotspots-tooltip-product {
  border-top: 1px solid #d7d3d1;
}

.photo-hotspots-tooltip-product {
  text-decoration: none;
}
.photo-hotspots-tooltip-product:hover {
  text-decoration: none;
}
.photo-hotspots-tooltip-product .product-image {
  width: 75px;
  height: 75px;
  background-size: cover;
  background-repeat: no-repeat;
}
.photo-hotspots-tooltip-product .product-attributes {
  max-width: calc(300px - 75px - 5px);
  padding: 8px;
  text-align: left;
}
.photo-hotspots-tooltip-product .product-name {
  font-size: 13px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.photo-hotspots-tooltip-product .product-price {
  font-size: 13px;
  color: #888380;
  margin-top: 4px;
}
.photo-hotspots-tooltip-product .product-price .strike-through {
  margin-right: 5px;
}
.photo-hotspots-tooltip-product .product-price .sales {
  color: #d2232a;
}
.photo-hotspots-tooltip-product .product-message {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  color: #888380;
  margin-top: 4px;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.price {
  color: #222;
}
.price .strike-through,
.price del {
  text-decoration: line-through;
}
.price .strike-through + .sales,
.price del + .sales {
  color: #d2232a;
}
.price .strike-through {
  margin-right: 10px;
}
.price .tiered {
  padding: 0 0.75rem;
  background: #f6f6f6;
  margin-bottom: 0.75rem;
}
.price .tiered table {
  margin: 0 auto;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-beige.disabled {
  opacity: 0.2;
}

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative;
}
.swatch-filter-beige.disabled {
  opacity: 0.2;
}

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-black.disabled {
  opacity: 0.2;
}

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative;
}
.swatch-filter-black.disabled {
  opacity: 0.2;
}

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #007bff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-blue.disabled {
  opacity: 0.2;
}

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #007bff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #007bff;
  display: block;
  position: relative;
}
.swatch-filter-blue.disabled {
  opacity: 0.2;
}

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-brown.disabled {
  opacity: 0.2;
}

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative;
}
.swatch-filter-brown.disabled {
  opacity: 0.2;
}

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #056808;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-green.disabled {
  opacity: 0.2;
}

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #056808;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #056808;
  display: block;
  position: relative;
}
.swatch-filter-green.disabled {
  opacity: 0.2;
}

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-grey.disabled {
  opacity: 0.2;
}

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative;
}
.swatch-filter-grey.disabled {
  opacity: 0.2;
}

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-navy.disabled {
  opacity: 0.2;
}

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative;
}
.swatch-filter-navy.disabled {
  opacity: 0.2;
}

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-orange.disabled {
  opacity: 0.2;
}

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative;
}
.swatch-filter-orange.disabled {
  opacity: 0.2;
}

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-pink.disabled {
  opacity: 0.2;
}

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative;
}
.swatch-filter-pink.disabled {
  opacity: 0.2;
}

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-purple.disabled {
  opacity: 0.2;
}

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative;
}
.swatch-filter-purple.disabled {
  opacity: 0.2;
}

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-red.disabled {
  opacity: 0.2;
}

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative;
}
.swatch-filter-red.disabled {
  opacity: 0.2;
}

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-white.disabled {
  opacity: 0.2;
}

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative;
}
.swatch-filter-white.disabled {
  opacity: 0.2;
}

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-yellow.disabled {
  opacity: 0.2;
}

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative;
}
.swatch-filter-yellow.disabled {
  opacity: 0.2;
}

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em;
}
.swatch-circle-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-circle-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.swatch-square-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  line-height: 1.38em;
  position: relative;
  width: 1.38em;
}
.swatch-square-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-square-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.color-attribute {
  border: none;
  padding: 0;
  background: none;
}
.color-attribute .unselectable {
  pointer-events: none;
}
.color-attribute[disabled] {
  background: none;
  position: relative;
}
.color-attribute[disabled]:hover {
  background: none;
}
.color-attribute[disabled] .swatch-circle {
  border: none;
  opacity: 0.7;
}
.color-attribute[disabled] .cross-out {
  height: 2px;
  width: 31px;
  background: #231f20;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(45deg);
  transform-origin: 0 0;
  box-sizing: content-box;
  border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff;
}

.badge {
  height: 26px;
  color: #fff;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.badge-new {
  background: #353535;
}
.badge-sale {
  background: #d2232a;
}
.badge-coming-soon {
  background: #888380;
}
.badge-discontinued {
  background: #d7d3d1;
  color: #222;
}

.attribute {
  margin-top: 0.938em;
}
.attribute label {
  display: block;
}

.swatch a {
  text-decoration: none;
}

.primary-images {
  height: 290px;
  padding-bottom: 10px;
}
.primary-images .slick-prev,
.primary-images .slick-next {
  height: 20px;
  width: 20px;
}
.primary-images .slick-prev::before,
.primary-images .slick-next::before {
  content: "";
  height: 20px;
  width: 20px;
}
@media (min-width: 768px) {
  .primary-images .slick-prev,
  .primary-images .slick-next {
    height: 30px;
    width: 30px;
  }
  .primary-images .slick-prev::before,
  .primary-images .slick-next::before {
    content: "";
    height: 30px;
    width: 30px;
  }
}
.primary-images .slick-prev {
  left: 25px;
}
.primary-images .slick-prev::before {
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-prev {
    left: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-prev {
    left: 10px;
  }
  .primary-images .slick-prev::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-next {
  right: 25px;
}
.primary-images .slick-next::before {
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-next {
    right: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-next {
    right: 10px;
  }
  .primary-images .slick-next::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-list {
  height: 290px;
}
@media (min-width: 768px) {
  .primary-images .slick-list {
    height: 600px;
  }
}
.primary-images .slick-initialized .slick-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.primary-images div:not(.badge) {
  height: 100%;
}
.primary-images .product-carousel img {
  max-height: 100%;
  height: auto;
  max-width: 290px;
}
@media (max-width: 991.98px) {
  .primary-images .product-carousel img {
    max-width: 211px;
    max-height: 90%;
  }
}
@media (min-width: 768px) {
  .primary-images .product-carousel img {
    max-width: 400px;
  }
}
@media (max-width: 575.98px) {
  .primary-images {
    margin: 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .primary-images {
    height: 600px;
  }
}

.prices-add-to-cart-actions {
  margin-top: 0.75rem;
}
.prices-add-to-cart-actions .prices {
  text-align: left;
  width: 100%;
}
.prices-add-to-cart-actions .price {
  position: relative;
  top: 2px;
}

.cart-and-ipay {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1.25rem;
}
.cart-and-ipay .btn {
  width: 100%;
  display: block;
}
.cart-and-ipay .hidden {
  display: none;
}
@media (min-width: 768px) {
  .cart-and-ipay {
    padding: 0 12px 1.25rem;
    margin-top: 1.25rem;
  }
}

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.add-to-wish-list {
  margin-top: 1em;
}

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.add-to-basket-alert.show {
  display: block;
}

.qty-alert {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.simple-quantity {
  margin-top: 1.25rem;
}

.main-attributes {
  margin-top: 1em;
}

.size-chart {
  margin-top: 1.071em;
}

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #d7d3d1;
}
.bundle-item:last-child {
  border-bottom: none;
}

.container.product-detail {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .container.product-detail {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
}

.product-option:not(:first-child) {
  margin-top: 0.75rem;
}

.slide-link {
  display: block;
}

.customContact {
  flex: 0 0 100%;
}
.customContact a {
  float: right;
  margin-right: 12px;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-beige.disabled {
  opacity: 0.2;
}

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative;
}
.swatch-filter-beige.disabled {
  opacity: 0.2;
}

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-black.disabled {
  opacity: 0.2;
}

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative;
}
.swatch-filter-black.disabled {
  opacity: 0.2;
}

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #007bff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-blue.disabled {
  opacity: 0.2;
}

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #007bff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #007bff;
  display: block;
  position: relative;
}
.swatch-filter-blue.disabled {
  opacity: 0.2;
}

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-brown.disabled {
  opacity: 0.2;
}

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative;
}
.swatch-filter-brown.disabled {
  opacity: 0.2;
}

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #056808;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-green.disabled {
  opacity: 0.2;
}

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #056808;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #056808;
  display: block;
  position: relative;
}
.swatch-filter-green.disabled {
  opacity: 0.2;
}

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-grey.disabled {
  opacity: 0.2;
}

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative;
}
.swatch-filter-grey.disabled {
  opacity: 0.2;
}

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-navy.disabled {
  opacity: 0.2;
}

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative;
}
.swatch-filter-navy.disabled {
  opacity: 0.2;
}

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-orange.disabled {
  opacity: 0.2;
}

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative;
}
.swatch-filter-orange.disabled {
  opacity: 0.2;
}

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-pink.disabled {
  opacity: 0.2;
}

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative;
}
.swatch-filter-pink.disabled {
  opacity: 0.2;
}

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-purple.disabled {
  opacity: 0.2;
}

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative;
}
.swatch-filter-purple.disabled {
  opacity: 0.2;
}

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-red.disabled {
  opacity: 0.2;
}

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative;
}
.swatch-filter-red.disabled {
  opacity: 0.2;
}

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-white.disabled {
  opacity: 0.2;
}

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative;
}
.swatch-filter-white.disabled {
  opacity: 0.2;
}

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-yellow.disabled {
  opacity: 0.2;
}

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative;
}
.swatch-filter-yellow.disabled {
  opacity: 0.2;
}

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em;
}
.swatch-circle-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-circle-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.swatch-square-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  line-height: 1.38em;
  position: relative;
  width: 1.38em;
}
.swatch-square-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-square-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.color-attribute {
  border: none;
  padding: 0;
  background: none;
}
.color-attribute .unselectable {
  pointer-events: none;
}
.color-attribute[disabled] {
  background: none;
  position: relative;
}
.color-attribute[disabled]:hover {
  background: none;
}
.color-attribute[disabled] .swatch-circle {
  border: none;
  opacity: 0.7;
}
.color-attribute[disabled] .cross-out {
  height: 2px;
  width: 31px;
  background: #231f20;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(45deg);
  transform-origin: 0 0;
  box-sizing: content-box;
  border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff;
}

.badge {
  height: 26px;
  color: #fff;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.badge-new {
  background: #353535;
}
.badge-sale {
  background: #d2232a;
}
.badge-coming-soon {
  background: #888380;
}
.badge-discontinued {
  background: #d7d3d1;
  color: #222;
}

.attribute {
  margin-top: 0.938em;
}
.attribute label {
  display: block;
}

.swatch a {
  text-decoration: none;
}

.primary-images {
  height: 290px;
  padding-bottom: 10px;
}
.primary-images .slick-prev,
.primary-images .slick-next {
  height: 20px;
  width: 20px;
}
.primary-images .slick-prev::before,
.primary-images .slick-next::before {
  content: "";
  height: 20px;
  width: 20px;
}
@media (min-width: 768px) {
  .primary-images .slick-prev,
  .primary-images .slick-next {
    height: 30px;
    width: 30px;
  }
  .primary-images .slick-prev::before,
  .primary-images .slick-next::before {
    content: "";
    height: 30px;
    width: 30px;
  }
}
.primary-images .slick-prev {
  left: 25px;
}
.primary-images .slick-prev::before {
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-prev {
    left: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-prev {
    left: 10px;
  }
  .primary-images .slick-prev::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-next {
  right: 25px;
}
.primary-images .slick-next::before {
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-next {
    right: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-next {
    right: 10px;
  }
  .primary-images .slick-next::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-list {
  height: 290px;
}
@media (min-width: 768px) {
  .primary-images .slick-list {
    height: 600px;
  }
}
.primary-images .slick-initialized .slick-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.primary-images div:not(.badge) {
  height: 100%;
}
.primary-images .product-carousel img {
  max-height: 100%;
  height: auto;
  max-width: 290px;
}
@media (max-width: 991.98px) {
  .primary-images .product-carousel img {
    max-width: 211px;
    max-height: 90%;
  }
}
@media (min-width: 768px) {
  .primary-images .product-carousel img {
    max-width: 400px;
  }
}
@media (max-width: 575.98px) {
  .primary-images {
    margin: 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .primary-images {
    height: 600px;
  }
}

.prices-add-to-cart-actions {
  margin-top: 0.75rem;
}
.prices-add-to-cart-actions .prices {
  text-align: left;
  width: 100%;
}
.prices-add-to-cart-actions .price {
  position: relative;
  top: 2px;
}

.cart-and-ipay {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1.25rem;
}
.cart-and-ipay .btn {
  width: 100%;
  display: block;
}
.cart-and-ipay .hidden {
  display: none;
}
@media (min-width: 768px) {
  .cart-and-ipay {
    padding: 0 12px 1.25rem;
    margin-top: 1.25rem;
  }
}

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.add-to-wish-list {
  margin-top: 1em;
}

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.add-to-basket-alert.show {
  display: block;
}

.qty-alert {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.simple-quantity {
  margin-top: 1.25rem;
}

.main-attributes {
  margin-top: 1em;
}

.size-chart {
  margin-top: 1.071em;
}

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #d7d3d1;
}
.bundle-item:last-child {
  border-bottom: none;
}

.container.product-detail {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .container.product-detail {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
}

.product-option:not(:first-child) {
  margin-top: 0.75rem;
}

.slide-link {
  display: block;
}

.customContact {
  flex: 0 0 100%;
}
.customContact a {
  float: right;
  margin-right: 12px;
}

.quantity-form,
.quantity-form-SFL {
  display: flex;
  max-width: 132px;
}
.quantity-form input[type=text],
.quantity-form-SFL input[type=text] {
  width: 40%;
  text-align: center;
  padding: 0;
}
.quantity-form .plus,
.quantity-form .minus,
.quantity-form-SFL .plus,
.quantity-form-SFL .minus {
  position: relative;
  height: 40px;
  width: 30%;
}
.quantity-form .plus .icon,
.quantity-form .minus .icon,
.quantity-form-SFL .plus .icon,
.quantity-form-SFL .minus .icon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
}
.quantity-form .plus input[type=button],
.quantity-form .minus input[type=button],
.quantity-form-SFL .plus input[type=button],
.quantity-form-SFL .minus input[type=button] {
  height: 100%;
  width: 100%;
  background: none;
  border: 1px solid #d7d3d1;
  border-radius: 0;
}
.quantity-form .plus input[type=button],
.quantity-form-SFL .plus input[type=button] {
  border-left: none;
}
.quantity-form .minus input[type=button],
.quantity-form-SFL .minus input[type=button] {
  border-right: none;
}
.quantity-form label,
.quantity-form-SFL label {
  margin-bottom: 0;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.price {
  color: #222;
}
.price .strike-through,
.price del {
  text-decoration: line-through;
}
.price .strike-through + .sales,
.price del + .sales {
  color: #d2232a;
}
.price .strike-through {
  margin-right: 10px;
}
.price .tiered {
  padding: 0 0.75rem;
  background: #f6f6f6;
  margin-bottom: 0.75rem;
}
.price .tiered table {
  margin: 0 auto;
}

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: 56.25em;
}
.quick-view-dialog .product-name,
.choose-bonus-product-dialog .product-name {
  padding-top: 0;
}
.quick-view-dialog .selectable-bonus-product-line-item,
.choose-bonus-product-dialog .selectable-bonus-product-line-item {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
.quick-view-dialog .beenSelected,
.choose-bonus-product-dialog .beenSelected {
  background-color: #888380;
}
.quick-view-dialog .primary-images,
.quick-view-dialog .slick-list,
.choose-bonus-product-dialog .primary-images,
.choose-bonus-product-dialog .slick-list {
  height: auto;
}
.quick-view-dialog .primary-images .slick-initialized .slick-slide,
.quick-view-dialog .slick-list .slick-initialized .slick-slide,
.choose-bonus-product-dialog .primary-images .slick-initialized .slick-slide,
.choose-bonus-product-dialog .slick-list .slick-initialized .slick-slide {
  justify-content: start;
}
.quick-view-dialog .primary-images .slick-next,
.quick-view-dialog .primary-images .slick-prev,
.quick-view-dialog .slick-list .slick-next,
.quick-view-dialog .slick-list .slick-prev,
.choose-bonus-product-dialog .primary-images .slick-next,
.choose-bonus-product-dialog .primary-images .slick-prev,
.choose-bonus-product-dialog .slick-list .slick-next,
.choose-bonus-product-dialog .slick-list .slick-prev {
  top: 25%;
}
@media (min-width: 768px) {
  .quick-view-dialog .primary-images .slick-initialized .slick-slide,
  .choose-bonus-product-dialog .primary-images .slick-initialized .slick-slide {
    display: block;
  }
  .quick-view-dialog .primary-images .product-carousel img,
  .choose-bonus-product-dialog .primary-images .product-carousel img {
    max-width: 100%;
  }
}
.quick-view-dialog .product-carousel:not(.slick-initialized) .slide,
.choose-bonus-product-dialog .product-carousel:not(.slick-initialized) .slide {
  display: none;
}
.quick-view-dialog .product-number,
.choose-bonus-product-dialog .product-number {
  width: 100%;
  border-top: 1px solid #d7d3d1;
}
.quick-view-dialog .product-number,
.quick-view-dialog .attributes ul,
.quick-view-dialog .attributes .last-swatch .attribute,
.choose-bonus-product-dialog .product-number,
.choose-bonus-product-dialog .attributes ul,
.choose-bonus-product-dialog .attributes .last-swatch .attribute {
  padding: 0.75rem 0;
  border-bottom: 1px solid #d7d3d1;
}
.quick-view-dialog .product-name,
.choose-bonus-product-dialog .product-name {
  font-size: 1.75rem;
}
.quick-view-dialog .attributes ul,
.choose-bonus-product-dialog .attributes ul {
  padding-left: 0;
  margin-bottom: 0;
}
.quick-view-dialog .attributes ul li,
.choose-bonus-product-dialog .attributes ul li {
  list-style-type: none;
}
.quick-view-dialog .attributes ul li .label-2,
.choose-bonus-product-dialog .attributes ul li .label-2 {
  white-space: nowrap;
}
.quick-view-dialog .swatch-circle,
.choose-bonus-product-dialog .swatch-circle {
  border: 1px solid #353535;
  border-color: #fff;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  border-radius: 0;
}
.quick-view-dialog .swatch-circle.color-value[data-selected=true]::after,
.choose-bonus-product-dialog .swatch-circle.color-value[data-selected=true]::after {
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  left: 0.295em;
  position: absolute;
}
.quick-view-dialog .swatch-circle.color-value.selected::after,
.choose-bonus-product-dialog .swatch-circle.color-value.selected::after {
  background: #fff;
  border-radius: 50%;
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  height: 0.75em;
  left: 0.31em;
  line-height: 0.8em;
  position: absolute;
  top: 0.35em;
  width: 0.8em;
}
.quick-view-dialog .swatch-circle:hover,
.choose-bonus-product-dialog .swatch-circle:hover {
  border: 1px solid #353535;
}
.quick-view-dialog a[disabled] .swatch-circle,
.choose-bonus-product-dialog a[disabled] .swatch-circle {
  cursor: not-allowed;
}
.quick-view-dialog a[disabled] .swatch-circle.color-value.selected::after,
.choose-bonus-product-dialog a[disabled] .swatch-circle.color-value.selected::after {
  background-color: #fafafa;
}
.quick-view-dialog .tiered th,
.choose-bonus-product-dialog .tiered th {
  font-size: 0.875rem;
  white-space: nowrap;
}
.quick-view-dialog .modal-header,
.choose-bonus-product-dialog .modal-header {
  padding-top: 20px;
}
.quick-view-dialog .modal-body,
.choose-bonus-product-dialog .modal-body {
  max-height: 390px;
  overflow-y: auto;
  padding: 0 20px;
}
.quick-view-dialog .modal-footer,
.choose-bonus-product-dialog .modal-footer {
  background-color: #fff;
  border: none;
  flex-direction: column;
  align-items: normal;
  padding: 0 20px 20px;
}
@media (min-width: 768px) {
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    padding: 20px 15px;
  }
}
.quick-view-dialog .modal-footer .prices .price,
.quick-view-dialog .modal-footer .prices .price span,
.choose-bonus-product-dialog .modal-footer .prices .price,
.choose-bonus-product-dialog .modal-footer .prices .price span {
  font-size: 1.625rem;
}
.quick-view-dialog .modal-footer button,
.choose-bonus-product-dialog .modal-footer button {
  max-width: none;
  width: 100%;
}
.quick-view-dialog .modal-footer .row,
.choose-bonus-product-dialog .modal-footer .row {
  margin: 0;
  width: 100%;
}
.quick-view-dialog .modal-footer .availability,
.choose-bonus-product-dialog .modal-footer .availability {
  width: 100%;
}
.quick-view-dialog .modal-footer .availability ul,
.choose-bonus-product-dialog .modal-footer .availability ul {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .quick-view-dialog .modal-footer .availability ul .label-1,
  .choose-bonus-product-dialog .modal-footer .availability ul .label-1 {
    text-align: right;
  }
}
.quick-view-dialog .modal-footer .global-availability .prices,
.choose-bonus-product-dialog .modal-footer .global-availability .prices {
  padding: 0 12px;
}
.quick-view-dialog .quantity-form,
.quick-view-dialog .quantity-form-SFL,
.choose-bonus-product-dialog .quantity-form,
.choose-bonus-product-dialog .quantity-form-SFL {
  margin-top: 0;
}
@media (min-width: 768px) {
  .quick-view-dialog .quantity-form,
  .quick-view-dialog .quantity-form-SFL,
  .choose-bonus-product-dialog .quantity-form,
  .choose-bonus-product-dialog .quantity-form-SFL {
    margin-top: 1.25rem;
  }
}
.quick-view-dialog .cart-and-ipay,
.choose-bonus-product-dialog .cart-and-ipay {
  padding: 0;
}
.quick-view-dialog .promotions,
.choose-bonus-product-dialog .promotions {
  text-align: left;
  color: #d2232a;
  padding-top: 0.75rem;
}
@media (max-width: 767.98px) {
  .quick-view-dialog .bonus-summary,
  .choose-bonus-product-dialog .bonus-summary {
    font-size: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .quick-view-dialog .bonus-summary .bonus-product-name,
  .choose-bonus-product-dialog .bonus-summary .bonus-product-name {
    padding: 0;
  }
}
.quick-view-dialog .selected-pid .close::before,
.choose-bonus-product-dialog .selected-pid .close::before {
  content: "";
  display: block;
  background: url("../images/svg-icons/icon-close.svg");
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.quick-view-dialog .selected-pid .bonus-product-name + div,
.choose-bonus-product-dialog .selected-pid .bonus-product-name + div {
  padding: 0;
  position: relative;
}
.quick-view-dialog .pre-cart-products,
.choose-bonus-product-dialog .pre-cart-products {
  margin-right: 0.125em;
}
.quick-view-dialog .color-attribute,
.choose-bonus-product-dialog .color-attribute {
  border: none;
  padding: 0;
  background: none;
}
.quick-view-dialog .non-input-label,
.choose-bonus-product-dialog .non-input-label {
  display: block;
  margin-bottom: 0.5rem;
}
.quick-view-dialog .bundle-items-label,
.choose-bonus-product-dialog .bundle-items-label {
  display: none;
}
.quick-view-dialog .product-options .quantity,
.choose-bonus-product-dialog .product-options .quantity {
  position: static;
}
.quick-view-dialog .product-availability,
.choose-bonus-product-dialog .product-availability {
  text-align: left;
}
.quick-view-dialog .tiered table,
.choose-bonus-product-dialog .tiered table {
  background: #f6f6f6;
}
.quick-view-dialog .update-cart-product-global,
.choose-bonus-product-dialog .update-cart-product-global {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .quick-view-dialog .update-cart-product-global,
  .choose-bonus-product-dialog .update-cart-product-global {
    margin-top: 0;
  }
}

.choose-bonus-product-dialog .modal-body {
  max-height: 70vh;
  overflow-y: scroll;
}
.choose-bonus-product-dialog .choice-of-bonus-product:first-of-type .product-name {
  padding-top: 0;
}

.prices .sales .value {
  display: inline-block !important;
}

.badge {
  height: 26px;
  color: #fff;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.badge-new {
  background: #353535;
}
.badge-sale {
  background: #d2232a;
}
.badge-coming-soon {
  background: #888380;
}
.badge-discontinued {
  background: #d7d3d1;
  color: #222;
}

.Federal_review .yotpo .yotpo-bottomline .yotpo-icon-star,
.Federal_review .yotpo .yotpo-bottomline .yotpo-icon-half-star,
.Federal_review .yotpo .yotpo-bottomline .yotpo-icon-empty-star {
  color: #876841 !important;
}
.Federal_review .yotpo .text-m {
  padding-left: 10px !important;
  color: #020202 !important;
}
.Federal_review .yotpo .text-m:hover {
  color: #020202 !important;
}
.Federal_review .yotpo .text-m span {
  font-size: 13px;
  color: #020202;
  letter-spacing: 1px;
  font-weight: 600;
}

.product-carousel img {
  width: 100%;
  height: 100%;
}

.grid-tile {
  height: 100%;
}

.product-tile {
  padding: 0 7px;
  height: 100%;
  margin-bottom: 0;
}
.product-tile .tile-body {
  padding-top: 7px;
}
.product-tile .tile-body .color-swatches {
  margin-bottom: 11px;
}
.product-tile .tile-body .price {
  margin-bottom: 0;
  white-space: nowrap;
}
.product-tile .tile-body .price .tiered {
  font-size: 0.875em;
}
.product-tile .tile-body .price .tiered .value {
  font-weight: bold;
}
.product-tile .tile-body .coming-soon-tile {
  text-align: center;
}
.product-tile .tile-body .pc-promotion-message {
  color: #d2232a;
  margin-bottom: 4px;
}
.product-tile .tile-body .availability {
  margin-top: 0;
  text-align: right;
}
.product-tile .tile-body .pdp-link {
  margin-bottom: 5px;
}
.product-tile .tile-body .pdp-link a {
  color: #222;
}
.product-tile .tile-body .pid {
  color: #353535;
  margin-bottom: 17px;
}
.product-tile .tile-body .tile-info {
  margin-bottom: 12px;
}
.product-tile .tile-body .tile-attributes {
  padding-left: 0;
  margin: 0;
}
.product-tile .tile-body .tile-attributes li {
  list-style: none;
  margin-top: 0;
}
.product-tile .tile-body .tile-attributes li span:first-child {
  margin-right: 7px;
}
.product-tile .tile-body .compare label {
  margin-top: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}
.product-tile .tile-body .compare label::before {
  top: 1px;
}
.product-tile .image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 245px;
  padding: 0 10px;
}
.product-tile .image-container .quickview {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  max-width: 130px;
  display: none;
  text-transform: uppercase;
}
.product-tile .image-container:hover .quickview {
  display: block;
  text-decoration: none;
}
.product-tile .image-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
}
.product-tile .image-container a .tile-image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-tile .swatches a {
  text-decoration: none;
}
.product-tile span[data-attr-value=White] .swatch-circle,
.product-tile span[data-attr-value=white] .swatch-circle {
  border: 1px solid #d7d3d1;
}
.product-tile .swatch-circle {
  border: 1px solid #353535;
  border-color: #fff;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  border-radius: 0;
}
.product-tile .swatch-circle.color-value[data-selected=true]::after {
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  left: 0.295em;
  position: absolute;
}
.product-tile .swatch-circle.color-value.selected::after {
  background: #fff;
  border-radius: 50%;
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  height: 0.75em;
  left: 0.31em;
  line-height: 0.8em;
  position: absolute;
  top: 0.35em;
  width: 0.8em;
}
.product-tile .swatch-circle:hover {
  border: 1px solid #353535;
}

#chooseBonusProductModal .modal-footer .container {
  margin-left: 0;
  width: 100%;
  margin-right: 0;
}
#chooseBonusProductModal .select-cbp-container {
  margin-top: auto;
  margin-bottom: auto;
}
#chooseBonusProductModal .product-name-wrapper {
  width: 100%;
}
#chooseBonusProductModal .bonus-quantity,
#chooseBonusProductModal .bonus-option {
  margin-top: 0.938em;
}
#chooseBonusProductModal .select-bonus-product {
  margin-top: 1em;
}
#chooseBonusProductModal .selected-pid {
  border: 1px solid #d7d3d1;
}
#chooseBonusProductModal .selected-pid .bonus-product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#chooseBonusProductModal .bonus-product-price {
  text-align: center;
  margin-top: 1em;
}

.form-nav .nav-tabs .nav-link {
  color: #fafafa;
}

.form-nav .nav-tabs .nav-link.active,
.form-nav .nav-tabs .nav-link.active:focus,
.form-nav .nav-tabs .nav-link.active:hover {
  background-color: #fff;
  color: #fafafa;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
}

@media (min-width: 992px) {
  .form-nav .nav-item > .nav-link {
    color: #fafafa;
  }
}
.nav-links .nav-item:only-child .nav-link,
.nav-tabs .nav-item:only-child .nav-link,
.nav-pills .nav-item:only-child .nav-link {
  border: none;
  background: transparent;
}

.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  border-radius: 0 0 0 0;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #f6f6f6;
  border-left: none;
  font: 14px "Roboto", sans-serif;
  text-transform: none;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0.05em;
  padding: 15px 1rem 11px 1rem;
  background-color: transparent;
  color: #888380;
}
.nav-tabs .nav-link:hover {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #f6f6f6;
  border-left: none;
  text-decoration: none;
  background-color: transparent;
  color: #85704d;
}
.nav-tabs .nav-link.active {
  border-top: 1px solid #f6f6f6;
  border-right: 1px solid #f6f6f6;
  border-bottom: none;
  border-left: 1px solid #f6f6f6;
  text-decoration: none;
  background-color: transparent;
  color: #85704d;
}

.nav-links .nav-link {
  font: 14px "Roboto", sans-serif;
  text-transform: none;
  font-weight: normal;
  text-decoration: underline;
  letter-spacing: 0.05em;
  color: #85704d;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.nav-links .nav-link:hover {
  text-decoration: underline;
  color: #85704d;
  font-weight: normal;
  text-transform: none;
}
.nav-links .nav-link.active {
  text-decoration: none;
  color: #85704d;
  font-weight: bold;
  text-transform: none;
}

.nav-pills .nav-link {
  font: 14px "Roboto", sans-serif;
  text-transform: none;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0.05em;
  background-color: transparent;
  color: #888380;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 6px;
}
.nav-pills .nav-link:hover {
  text-decoration: none;
  background-color: transparent;
  color: #85704d;
}
.nav-pills .nav-link.active {
  text-decoration: none;
  background-color: #3091E7;
  color: #fff;
}

.container .breadcrumb {
  border-radius: 0;
  border-bottom: none;
}
.container.search-results .breadcrumb, .container.login-page .breadcrumb, .container.account-dashboard .breadcrumb, .container.account-payment .breadcrumb, .container.account-address .breadcrumb {
  padding-left: 0;
  border-bottom: 0;
}

/**
 * ==============================================
 * Chamber Spinner
 * ==============================================
 */
.veil {
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.veil .underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000;
}

.choose-bonus-product-dialog .veil .underlay {
  background-color: transparent;
}

.spinner {
  width: 200px;
  height: 200px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
}

/* Vendors */
/* Cylinder animation */
/* Bullets animation */
/* Animation */
@keyframes anim_cylinder {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  06% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  08% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  16% {
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  18% {
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  26% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  28% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  36% {
    -webkit-transform: rotate(240deg);
    -moz-transform: rotate(240deg);
    -o-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  38% {
    -webkit-transform: rotate(240deg);
    -moz-transform: rotate(240deg);
    -o-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  46% {
    -webkit-transform: rotate(300deg);
    -moz-transform: rotate(300deg);
    -o-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  48% {
    -webkit-transform: rotate(300deg);
    -moz-transform: rotate(300deg);
    -o-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  56% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  58% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  66% {
    -webkit-transform: rotate(-420deg);
    -moz-transform: rotate(-420deg);
    -o-transform: rotate(-420deg);
    transform: rotate(-420deg);
  }
  68% {
    -webkit-transform: rotate(-420deg);
    -moz-transform: rotate(-420deg);
    -o-transform: rotate(-420deg);
    transform: rotate(-420deg);
  }
  72% {
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  76% {
    -webkit-transform: rotate(-300deg);
    -moz-transform: rotate(-300deg);
    -o-transform: rotate(-300deg);
    transform: rotate(-300deg);
  }
  80% {
    -webkit-transform: rotate(-240deg);
    -moz-transform: rotate(-240deg);
    -o-transform: rotate(-240deg);
    transform: rotate(-240deg);
  }
  84% {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  88% {
    -webkit-transform: rotate(-120deg);
    -moz-transform: rotate(-120deg);
    -o-transform: rotate(-120deg);
    transform: rotate(-120deg);
  }
  92% {
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes anim_bullet1 {
  04.5% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  06% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  71% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes anim_bullet2 {
  14.5% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  16% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  74% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes anim_bullet3 {
  24.5% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  26% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  78% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  79% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes anim_bullet4 {
  34.5% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  36% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  82% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  83% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes anim_bullet5 {
  44.5% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  46% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  86% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  87% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes anim_bullet6 {
  54.5% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  56% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  91% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}
/* Rules */
/* General */
#cylinder {
  margin: 50px auto;
  background: #353535;
  position: relative;
  width: 100px;
  height: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: anim_cylinder 8000ms ease 800ms infinite;
  -moz-animation: anim_cylinder 8000ms ease 800ms infinite;
  -o-animation: anim_cylinder 8000ms ease 800ms infinite;
  animation: anim_cylinder 8000ms ease 800ms infinite;
}

.sculpt {
  display: none;
  position: absolute;
  background-color: #9e9f9e;
  width: 27px;
  height: 27px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.sculpt.one {
  top: -12px;
  left: 65px;
}

.sculpt.two {
  top: -12px;
  left: 9px;
}

.sculpt.three {
  top: 37px;
  left: -20px;
}

.sculpt.four {
  top: 86px;
  left: 9px;
}

.sculpt.five {
  top: 86px;
  left: 65px;
}

.sculpt.six {
  top: 37px;
  left: 94px;
}

.ring.center {
  background-color: #222;
  position: absolute;
  left: 38px;
  top: 38px;
  width: 23px;
  height: 23px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 0 2px #4a4a4a;
  box-shadow: 0 0 0 2px #4a4a4a;
}

.chamber {
  position: relative;
  overflow: hidden;
  background-color: #4a4a4a;
  width: 23px;
  height: 23px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.chamber.one {
  top: 7px;
  left: 38px;
}

.chamber.two {
  top: -1px;
  left: 11px;
}

.chamber.three {
  top: 7px;
  left: 11px;
}

.chamber.four {
  top: 1px;
  left: 38px;
}

.chamber.five {
  top: -71px;
  left: 66px;
}

.chamber.six {
  top: -62px;
  left: 66px;
}

.bullet {
  background: #76716e;
  opacity: 0;
  width: 23px;
  height: 23px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.one .bullet {
  -webkit-animation: anim_bullet1 8000ms ease 800ms infinite;
  -moz-animation: anim_bullet1 8000ms ease 800ms infinite;
  -o-animation: anim_bullet1 8000ms ease 800ms infinite;
  animation: anim_bullet1 8000ms ease 800ms infinite;
}

.two .bullet {
  -webkit-animation: anim_bullet2 8000ms ease 800ms infinite;
  -moz-animation: anim_bullet2 8000ms ease 800ms infinite;
  -o-animation: anim_bullet2 8000ms ease 800ms infinite;
  animation: anim_bullet2 8000ms ease 800ms infinite;
}

.three .bullet {
  -webkit-animation: anim_bullet3 8000ms ease 800ms infinite;
  -moz-animation: anim_bullet3 8000ms ease 800ms infinite;
  -o-animation: anim_bullet3 8000ms ease 800ms infinite;
  animation: anim_bullet3 8000ms ease 800ms infinite;
}

.four .bullet {
  -webkit-animation: anim_bullet4 8000ms ease 800ms infinite;
  -moz-animation: anim_bullet4 8000ms ease 800ms infinite;
  -o-animation: anim_bullet4 8000ms ease 800ms infinite;
  animation: anim_bullet4 8000ms ease 800ms infinite;
}

.five .bullet {
  -webkit-animation: anim_bullet6 8000ms ease 800ms infinite;
  -moz-animation: anim_bullet6 8000ms ease 800ms infinite;
  -o-animation: anim_bullet6 8000ms ease 800ms infinite;
  animation: anim_bullet6 8000ms ease 800ms infinite;
}

.six .bullet {
  -webkit-animation: anim_bullet5 8000ms ease 800ms infinite;
  -moz-animation: anim_bullet5 8000ms ease 800ms infinite;
  -o-animation: anim_bullet5 8000ms ease 800ms infinite;
  animation: anim_bullet5 8000ms ease 800ms infinite;
}

.bullet .ring {
  background-color: #b39659;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 13px;
  height: 13px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.item-attributes {
  vertical-align: top;
  padding-left: 0;
}

.line-item-attributes,
.line-item-option {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  margin: 0;
}

.line-item-attributes-value {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  white-space: pre-wrap;
}

.line-item-name,
.line-item-name span {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #353535;
  margin-bottom: 0.313em;
}

.line-item-price-info {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.line-item-price .price,
.line-item-price .sales {
  font-size: 1rem;
}

.line-item-pricing-info,
.line-item-pricing-info span {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}
.line-item-pricing-info + .price,
.line-item-pricing-info span + .price {
  font-size: 1rem;
}

.line-item-price .price span,
.line-item-price .price span .list,
.line-item-price .price span .sales,
.line-item-total-price .price span,
.line-item-total-price .price span .list,
.line-item-total-price .price span .sales,
.line-item-unit-price .price span,
.line-item-unit-price .price span .list,
.line-item-unit-price .price span .sales {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1.625rem;
  letter-spacing: 1px;
  font-size: 1rem;
  display: block;
}
.line-item-price .price span del,
.line-item-total-price .price span del,
.line-item-unit-price .price span del {
  margin: 0;
  font-size: 1rem;
}
.line-item-price .price span .strike-through span,
.line-item-price .price .strike-through,
.line-item-total-price .price span .strike-through span,
.line-item-total-price .price .strike-through,
.line-item-unit-price .price span .strike-through span,
.line-item-unit-price .price .strike-through {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 1.625rem;
  letter-spacing: 1px;
  font-size: 1rem;
}

.line-item-total-price-amount {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
}

.line-item-price-quantity-info {
  margin-top: 0.625em;
  border-top: 1px solid #d7d3d1;
}

.flex {
  display: flex;
}

.line-item-total-text {
  font-size: 1rem;
}

.product-image {
  height: auto;
  width: 5.625em;
  margin-right: 0.938em;
  flex-grow: 0;
  flex-shrink: 0;
}

.non-adjusted-price {
  display: none;
}

.line-item-promo {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  color: #d2232a;
}

.line-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.line-item-header .remove-line-item {
  position: absolute;
  top: 0;
  right: 0;
}

.bundled-line-item + .bundled-line-item {
  margin-top: 0.625em;
}

.bundle-includes {
  font-size: 1rem;
  margin-bottom: 0.625em;
}

.line-item-divider {
  margin: 0.625em -1.225em 0.625em -1.325em;
}

.line-item-availability {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 1px;
}
@media (min-width: 768px) {
  .line-item-availability {
    font-size: 0.875rem;
  }
}

.preorder-shipdate {
  margin-right: initial;
  margin-left: initial;
  width: 75%;
}

.preorder-content {
  margin-left: initial;
}

.minicart .product-line-item-details {
  overflow-y: hidden;
}

@media (max-width: 767.98px) {
  .cart-product-card-price {
    display: none;
  }
}
@media (min-width: 768px) {
  .cart-product-card-price {
    display: contents;
  }
}

.quantity-form,
.quantity-form-SFL {
  display: flex;
  max-width: 132px;
}
.quantity-form input[type=text],
.quantity-form-SFL input[type=text] {
  width: 40%;
  text-align: center;
  padding: 0;
}
.quantity-form .plus,
.quantity-form .minus,
.quantity-form-SFL .plus,
.quantity-form-SFL .minus {
  position: relative;
  height: 40px;
  width: 30%;
}
.quantity-form .plus .icon,
.quantity-form .minus .icon,
.quantity-form-SFL .plus .icon,
.quantity-form-SFL .minus .icon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
}
.quantity-form .plus input[type=button],
.quantity-form .minus input[type=button],
.quantity-form-SFL .plus input[type=button],
.quantity-form-SFL .minus input[type=button] {
  height: 100%;
  width: 100%;
  background: none;
  border: 1px solid #d7d3d1;
  border-radius: 0;
}
.quantity-form .plus input[type=button],
.quantity-form-SFL .plus input[type=button] {
  border-left: none;
}
.quantity-form .minus input[type=button],
.quantity-form-SFL .minus input[type=button] {
  border-right: none;
}
.quantity-form label,
.quantity-form-SFL label {
  margin-bottom: 0;
}

.minicart {
  position: relative;
}
.minicart h4 {
  white-space: nowrap;
}
.minicart .cart {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
  background-color: #fff;
}
.minicart .btn.remove-product {
  color: #d7d3d1;
  background-color: white;
  border: none;
  margin-top: -0.313em;
  padding: 0 5px;
  height: 20px;
}
.minicart .btn.remove-product .icon {
  height: 10px;
  width: 10px;
  top: 0;
}
.minicart .product-summary {
  margin-right: -0.938em;
  max-height: 21.875em;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.938em;
}
.minicart .card-body {
  padding: 0.625em;
}
.minicart .quantity-label {
  font-size: 0.813em;
}
.minicart .quantity {
  width: 100%;
}
.minicart .popover {
  top: 100%;
  left: auto;
  right: 0;
  min-width: 23.44rem;
  max-width: 23.44rem;
  min-height: 6.25rem;
  display: none;
}
.minicart .popover::before {
  left: auto;
  right: 15px;
}
.minicart .popover::after {
  left: auto;
  right: 16px;
}
.minicart .popover.show {
  display: block;
}
.minicart .minicart-footer {
  border-top: 1px solid #d7d3d1;
}
.minicart .estimated-total {
  margin-top: 0.625em;
}
.minicart .sub-total-label {
  font-size: 1em;
  font-weight: 600;
}
.minicart .sub-total {
  font-size: 1em;
  font-weight: 600;
}
.minicart .line-item-divider {
  margin: 0.625em -0.625em;
  width: calc(100% + 0.625em + 0.625em);
}
.minicart .line-item-name {
  width: 90%;
}
.minicart .line-item-unit-price .price .strike-through,
.minicart .line-item-unit-price .price del {
  margin-right: 0;
}
.minicart .checkout-btn {
  margin: 0 auto;
}
.minicart .product-summary-info {
  border-top: 1px solid #d7d3d1;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  margin-right: -10px;
  margin-left: -10px;
}
.minicart .minicart-header a {
  line-height: 34px;
}
.minicart .bundled-line-item {
  padding-left: 0.75rem;
}
.minicart .quantity-form input[type=text]:read-only {
  background: #fff;
}
.minicart .minicart-link {
  color: #fff;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hide-link-med {
    display: none;
  }
}

@media (min-width: 768px) {
  .hide-no-link {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .hide-no-link {
    display: none;
  }
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.page-designer .photoHotspot {
  position: relative;
}
.page-designer .photoHotspot h4 {
  white-space: nowrap;
}
.page-designer .photoHotspot .card-body {
  padding: 0.625em;
}
.page-designer .photoHotspot .quantity-label {
  font-size: 0.813em;
}
.page-designer .photoHotspot .quantity {
  width: 100%;
}
.page-designer .photoHotspot .primary-text {
  font-weight: 600;
  font-size: 1.5rem;
}
.page-designer .photoHotspot .secondary-text {
  font-weight: 600;
  font-size: 1rem;
  font-style: italic;
}
.page-designer .photoHotspot .product-quickview {
  border-bottom: 1px solid #d7d3d1;
}
.page-designer .photoHotspot .product-quickview .product-name {
  font-weight: 600;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.page-designer .photoHotspot .product-quickview .photo-hotspot-product-image {
  height: auto;
}
.page-designer .photoHotspot .product-quickview .photo-hotspot-product-image .product-carousel {
  visibility: visible;
}
.page-designer .photoHotspot .product-quickview .photo-hotspot-product-image .product-carousel .slide {
  margin-top: auto;
  margin-bottom: auto;
}
.page-designer .photoHotspot .product-quickview .photo-hotspot-product-image .product-carousel .slide .slide-img {
  max-height: 200px;
}
.page-designer .photoHotspot .popover {
  top: 100%;
  left: auto;
  right: 0;
  min-width: 23.44rem;
  max-width: 23.44rem;
  min-height: 6.25rem;
  display: none;
  padding: 0.5rem;
}
.page-designer .photoHotspot .popover .modal-footer {
  background-color: transparent;
  padding: 10px 40px;
}
.page-designer .photoHotspot .popover .modal-footer .prices-availability {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.page-designer .photoHotspot .popover .modal-footer .prices-availability .value {
  font-size: 1.25rem;
}
.page-designer .photoHotspot .popover .modal-footer .prices-availability .availability-label {
  font-size: 1.25rem;
}
.page-designer .photoHotspot .popover .modal-footer .cart-and-ipay {
  padding: 0;
  margin-top: 0.25rem;
}
.page-designer .photoHotspot .popover::before {
  left: auto;
  right: 15px;
}
.page-designer .photoHotspot .popover::after {
  left: auto;
  right: 16px;
}
.page-designer .photoHotspot .popover.show {
  display: block;
}
.page-designer .photoHotspot .line-item-divider {
  margin: 0.625em -0.625em;
  width: calc(100% + 0.625em + 0.625em);
}
.page-designer .photoHotspot .line-item-name {
  width: 90%;
}
.page-designer .photoHotspot .line-item-unit-price .price .strike-through,
.page-designer .photoHotspot .line-item-unit-price .price del {
  margin-right: 0;
}
.page-designer .photoHotspot .checkout-btn {
  margin: 0 auto;
}
.page-designer .photoHotspot .product-summary-info {
  border-top: 0.5px solid #fff;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  margin-right: -10px;
  margin-left: -10px;
}
.page-designer .d-hide-mobile p {
  display: none !important;
}
@media (min-width: 992px) {
  .page-designer .d-hide-mobile p {
    display: initial !important;
  }
}
.page-designer .video-modal .modal-dialog {
  width: 100%;
  max-width: 900px;
  height: 400px;
}
.page-designer .video-modal .modal-dialog .modal-header {
  padding: 0;
  height: 40px;
}
.page-designer .video-modal .modal-dialog .modal-header .close::before {
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  right: 1.33%;
  margin-top: 10px;
  margin-right: 10px;
  right: 0;
  top: 0;
}
.page-designer .video-modal .modal-dialog .modal-body {
  padding: 0;
}
.page-designer .video-modal .modal-dialog .modal-footer {
  padding: 0;
  height: 20px;
}
@media (min-width: 768px) {
  .page-designer .video-modal .modal-dialog {
    width: 90%;
  }
  .page-designer .video-modal .modal-dialog .modal-header {
    padding: 2% 4% 2%;
  }
  .page-designer .video-modal .modal-dialog .modal-header .close::before {
    background-size: 10px 10px;
    height: 10px;
    width: 10px;
    margin-top: 1.5%;
    margin-right: 1.5%;
    right: 0;
    top: 0;
  }
  .page-designer .video-modal .modal-dialog .modal-body {
    padding: 0 4% 0;
  }
  .page-designer .video-modal .modal-dialog .modal-footer {
    padding: 2% 4% 2%;
  }
}
@media (max-width: 767.98px) {
  .page-designer .carousel {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (max-width: 767.98px) {
  .page-designer .carousel-container {
    padding-left: 0;
    padding-right: 0;
  }
}
.page-designer .carousel-title {
  font-size: 5rem;
}
.page-designer .carousel-copy {
  font-size: 1.025rem;
  font-style: italic;
}
.page-designer .remove-padding {
  margin-top: 0px !important;
}
.page-designer .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2385704d' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.page-designer .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2385704d' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.page-designer .carousel-indicators {
  position: inherit;
}
.page-designer .carousel-indicators li {
  background-color: #85704d;
  width: 50px;
  height: 5px;
}
.page-designer .carousel-caption {
  position: initial;
  color: black;
}
.page-designer .carousel-control-prev, .page-designer .carousel-control-next {
  width: 5% !important;
}
@media (max-width: 767.98px) {
  .page-designer .carousel-image {
    margin-bottom: 2%;
  }
}

.info-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
  border: none;
  padding: 0;
  background: inherit;
}
.info-icon .icon::before {
  content: url("../images/svg-icons/icon-tooltip.svg");
}
.info-icon:hover .tooltip {
  display: block;
}

.tooltip .tooltip-inner {
  box-shadow: 2px 2px 4px 2px rgba(210, 215, 225, 0.5);
  text-align: left;
}
.tooltip .arrow::before {
  z-index: 2;
}

.bs-tooltip-top .arrow::after, .bs-tooltip-auto[x-placement^=top] .arrow::after {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
  z-index: 1;
  top: 0;
  border-width: 19px 27.5px 0 17.5px;
  border-top-color: rgba(210, 215, 225, 0.5);
}

.bs-tooltip-right .arrow::after, .bs-tooltip-auto[x-placement^=right] .arrow::after {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
  z-index: 1;
  right: 0;
  border-width: 17.5px 19px 27.5px 0;
  border-right-color: rgba(210, 215, 225, 0.5);
}

.bs-tooltip-bottom .arrow::after, .bs-tooltip-auto[x-placement^=bottom] .arrow::after {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
  z-index: 1;
  bottom: 0;
  border-width: 0 27.5px 19px 17.5px;
  border-bottom-color: rgba(210, 215, 225, 0.5);
}

.bs-tooltip-left .arrow::after, .bs-tooltip-auto[x-placement^=left] .arrow::after {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
  z-index: 1;
  left: 0;
  border-width: 17.5px 0 27.5px 19px;
  border-left-color: rgba(210, 215, 225, 0.5);
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.site-search {
  position: relative;
  height: auto; /* 40/16 */
  padding: 10px 0;
  border-bottom: 1px solid #d7d3d1;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}
@media (min-width: 1025px) {
  .site-search {
    padding: 10px;
    height: 32px;
  }
}
.site-search .search {
  position: absolute;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  padding: 0;
  background: url("../images/svg-icons/icon-search-default.svg");
  width: 20px;
  height: 20px;
}
@media (min-width: 1025px) {
  .site-search .search {
    background: url("../images/svg-icons/icon-search-white.svg");
    right: 0;
    bottom: 8px;
    top: 0;
    transform: translateY(0);
  }
}
.site-search input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.site-search input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.site-search input[type=search]::-webkit-search-decoration,
.site-search input[type=search]::-webkit-search-cancel-button,
.site-search input[type=search]::-webkit-search-results-button,
.site-search input[type=search]::-webkit-search-results-decoration {
  display: none;
}
.site-search .search-field {
  background: transparent;
}
@media (min-width: 1025px) {
  .site-search .search-field {
    border: none;
    border-bottom: 1px solid #fff;
    padding: 3px;
    font-family: "Oswald", sans-serif;
    height: 30px;
    color: #fff;
  }
}
.site-search .search-field:focus {
  outline: 6px auto -webkit-focus-ring-color;
}
.site-search .fa-search:hover {
  cursor: pointer;
}
.site-search input {
  padding-right: 2rem;
  height: 100%;
}
.site-search input::placeholder {
  color: #231f20;
}
.site-search input::-webkit-input-placeholder {
  color: #231f20;
}
.site-search input::-moz-placeholder {
  color: #231f20;
}
.site-search input:-ms-input-placeholder {
  color: #231f20;
}
@media (min-width: 1025px) {
  .site-search input {
    color: #fff;
  }
  .site-search input::placeholder {
    color: #fff;
  }
  .site-search input::-webkit-input-placeholder {
    color: #fff;
  }
  .site-search input::-moz-placeholder {
    color: #fff;
  }
  .site-search input:-ms-input-placeholder {
    color: #fff;
  }
}
@media (min-width: 1025px) {
  .site-search {
    border-bottom: none;
    padding: 0;
    margin-right: 40px;
  }
}

.suggestions-wrapper {
  position: relative;
}

.suggestions {
  display: flex;
  position: fixed;
  width: 100%;
  border: 1px solid #d7d3d1;
  background-color: #fff;
  top: 0;
  right: 0;
  z-index: 3;
  padding-bottom: 20px;
}
@media (min-width: 1025px) {
  .suggestions {
    display: block;
    position: absolute;
    width: 21.875rem;
    padding-bottom: 0;
  }
}
.suggestions .suggestion-img {
  height: 50px;
}
.suggestions .header {
  padding: 0.75rem 0 0;
  min-height: 38px;
}
.suggestions .header h4 {
  margin-bottom: 0;
}
.suggestions .header:not(:first-child) {
  border-top: 1px solid #d7d3d1;
}
.suggestions ul {
  height: 80%;
  overflow-y: scroll;
}
.suggestions ul li {
  margin: 0;
}
.suggestions .items {
  padding: 1.25rem 0;
}
.suggestions .item {
  padding-bottom: 0.75rem;
}
.suggestions .item .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.suggestions .category-parent {
  color: #353535;
  font-size: 0.75rem;
}
.suggestions .selected {
  background-color: #d7d3d1;
}
.suggestions .container {
  list-style-type: none;
}

.more-below {
  -moz-border-radius: 1.25em;
  background: #d7d3d1;
  border: 1px solid #d7d3d1;
  border-radius: 0;
  bottom: 1.875em;
  box-shadow: 2px 2px 4px 2px rgba(210, 215, 225, 0.5);
  display: none;
  height: 2.5em;
  position: fixed;
  right: 1.875em;
  width: 2.5em;
  padding-top: 7.5px;
}
.more-below .icon {
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 auto;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-beige.disabled {
  opacity: 0.2;
}

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative;
}
.swatch-filter-beige.disabled {
  opacity: 0.2;
}

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-black.disabled {
  opacity: 0.2;
}

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative;
}
.swatch-filter-black.disabled {
  opacity: 0.2;
}

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #007bff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-blue.disabled {
  opacity: 0.2;
}

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #007bff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #007bff;
  display: block;
  position: relative;
}
.swatch-filter-blue.disabled {
  opacity: 0.2;
}

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-brown.disabled {
  opacity: 0.2;
}

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative;
}
.swatch-filter-brown.disabled {
  opacity: 0.2;
}

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #056808;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-green.disabled {
  opacity: 0.2;
}

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #056808;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #056808;
  display: block;
  position: relative;
}
.swatch-filter-green.disabled {
  opacity: 0.2;
}

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-grey.disabled {
  opacity: 0.2;
}

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative;
}
.swatch-filter-grey.disabled {
  opacity: 0.2;
}

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-navy.disabled {
  opacity: 0.2;
}

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative;
}
.swatch-filter-navy.disabled {
  opacity: 0.2;
}

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-orange.disabled {
  opacity: 0.2;
}

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative;
}
.swatch-filter-orange.disabled {
  opacity: 0.2;
}

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-pink.disabled {
  opacity: 0.2;
}

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative;
}
.swatch-filter-pink.disabled {
  opacity: 0.2;
}

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-purple.disabled {
  opacity: 0.2;
}

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative;
}
.swatch-filter-purple.disabled {
  opacity: 0.2;
}

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-red.disabled {
  opacity: 0.2;
}

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative;
}
.swatch-filter-red.disabled {
  opacity: 0.2;
}

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-white.disabled {
  opacity: 0.2;
}

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative;
}
.swatch-filter-white.disabled {
  opacity: 0.2;
}

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-yellow.disabled {
  opacity: 0.2;
}

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative;
}
.swatch-filter-yellow.disabled {
  opacity: 0.2;
}

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em;
}
.swatch-circle-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-circle-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.swatch-square-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  line-height: 1.38em;
  position: relative;
  width: 1.38em;
}
.swatch-square-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-square-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.color-attribute {
  border: none;
  padding: 0;
  background: none;
}
.color-attribute .unselectable {
  pointer-events: none;
}
.color-attribute[disabled] {
  background: none;
  position: relative;
}
.color-attribute[disabled]:hover {
  background: none;
}
.color-attribute[disabled] .swatch-circle {
  border: none;
  opacity: 0.7;
}
.color-attribute[disabled] .cross-out {
  height: 2px;
  width: 31px;
  background: #231f20;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(45deg);
  transform-origin: 0 0;
  box-sizing: content-box;
  border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff;
}

.badge {
  height: 26px;
  color: #fff;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.badge-new {
  background: #353535;
}
.badge-sale {
  background: #d2232a;
}
.badge-coming-soon {
  background: #888380;
}
.badge-discontinued {
  background: #d7d3d1;
  color: #222;
}

.attribute {
  margin-top: 0.938em;
}
.attribute label {
  display: block;
}

.swatch a {
  text-decoration: none;
}

.primary-images {
  height: 290px;
  padding-bottom: 10px;
}
.primary-images .slick-prev,
.primary-images .slick-next {
  height: 20px;
  width: 20px;
}
.primary-images .slick-prev::before,
.primary-images .slick-next::before {
  content: "";
  height: 20px;
  width: 20px;
}
@media (min-width: 768px) {
  .primary-images .slick-prev,
  .primary-images .slick-next {
    height: 30px;
    width: 30px;
  }
  .primary-images .slick-prev::before,
  .primary-images .slick-next::before {
    content: "";
    height: 30px;
    width: 30px;
  }
}
.primary-images .slick-prev {
  left: 25px;
}
.primary-images .slick-prev::before {
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-prev {
    left: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-prev {
    left: 10px;
  }
  .primary-images .slick-prev::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-next {
  right: 25px;
}
.primary-images .slick-next::before {
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-next {
    right: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-next {
    right: 10px;
  }
  .primary-images .slick-next::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-list {
  height: 290px;
}
@media (min-width: 768px) {
  .primary-images .slick-list {
    height: 600px;
  }
}
.primary-images .slick-initialized .slick-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.primary-images div:not(.badge) {
  height: 100%;
}
.primary-images .product-carousel img {
  max-height: 100%;
  height: auto;
  max-width: 290px;
}
@media (max-width: 991.98px) {
  .primary-images .product-carousel img {
    max-width: 211px;
    max-height: 90%;
  }
}
@media (min-width: 768px) {
  .primary-images .product-carousel img {
    max-width: 400px;
  }
}
@media (max-width: 575.98px) {
  .primary-images {
    margin: 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .primary-images {
    height: 600px;
  }
}

.prices-add-to-cart-actions {
  margin-top: 0.75rem;
}
.prices-add-to-cart-actions .prices {
  text-align: left;
  width: 100%;
}
.prices-add-to-cart-actions .price {
  position: relative;
  top: 2px;
}

.cart-and-ipay {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1.25rem;
}
.cart-and-ipay .btn {
  width: 100%;
  display: block;
}
.cart-and-ipay .hidden {
  display: none;
}
@media (min-width: 768px) {
  .cart-and-ipay {
    padding: 0 12px 1.25rem;
    margin-top: 1.25rem;
  }
}

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.add-to-wish-list {
  margin-top: 1em;
}

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.add-to-basket-alert.show {
  display: block;
}

.qty-alert {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.simple-quantity {
  margin-top: 1.25rem;
}

.main-attributes {
  margin-top: 1em;
}

.size-chart {
  margin-top: 1.071em;
}

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #d7d3d1;
}
.bundle-item:last-child {
  border-bottom: none;
}

.container.product-detail {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .container.product-detail {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
}

.product-option:not(:first-child) {
  margin-top: 0.75rem;
}

.slide-link {
  display: block;
}

.customContact {
  flex: 0 0 100%;
}
.customContact a {
  float: right;
  margin-right: 12px;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-beige.disabled {
  opacity: 0.2;
}

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative;
}
.swatch-filter-beige.disabled {
  opacity: 0.2;
}

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-black.disabled {
  opacity: 0.2;
}

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative;
}
.swatch-filter-black.disabled {
  opacity: 0.2;
}

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #007bff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-blue.disabled {
  opacity: 0.2;
}

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #007bff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #007bff;
  display: block;
  position: relative;
}
.swatch-filter-blue.disabled {
  opacity: 0.2;
}

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-brown.disabled {
  opacity: 0.2;
}

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative;
}
.swatch-filter-brown.disabled {
  opacity: 0.2;
}

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #056808;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-green.disabled {
  opacity: 0.2;
}

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #056808;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #056808;
  display: block;
  position: relative;
}
.swatch-filter-green.disabled {
  opacity: 0.2;
}

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-grey.disabled {
  opacity: 0.2;
}

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative;
}
.swatch-filter-grey.disabled {
  opacity: 0.2;
}

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-navy.disabled {
  opacity: 0.2;
}

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative;
}
.swatch-filter-navy.disabled {
  opacity: 0.2;
}

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-orange.disabled {
  opacity: 0.2;
}

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative;
}
.swatch-filter-orange.disabled {
  opacity: 0.2;
}

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-pink.disabled {
  opacity: 0.2;
}

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative;
}
.swatch-filter-pink.disabled {
  opacity: 0.2;
}

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-purple.disabled {
  opacity: 0.2;
}

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative;
}
.swatch-filter-purple.disabled {
  opacity: 0.2;
}

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-red.disabled {
  opacity: 0.2;
}

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative;
}
.swatch-filter-red.disabled {
  opacity: 0.2;
}

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-white.disabled {
  opacity: 0.2;
}

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative;
}
.swatch-filter-white.disabled {
  opacity: 0.2;
}

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-yellow.disabled {
  opacity: 0.2;
}

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative;
}
.swatch-filter-yellow.disabled {
  opacity: 0.2;
}

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em;
}
.swatch-circle-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-circle-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.swatch-square-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  line-height: 1.38em;
  position: relative;
  width: 1.38em;
}
.swatch-square-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-square-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.color-attribute {
  border: none;
  padding: 0;
  background: none;
}
.color-attribute .unselectable {
  pointer-events: none;
}
.color-attribute[disabled] {
  background: none;
  position: relative;
}
.color-attribute[disabled]:hover {
  background: none;
}
.color-attribute[disabled] .swatch-circle {
  border: none;
  opacity: 0.7;
}
.color-attribute[disabled] .cross-out {
  height: 2px;
  width: 31px;
  background: #231f20;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(45deg);
  transform-origin: 0 0;
  box-sizing: content-box;
  border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff;
}

.badge {
  height: 26px;
  color: #fff;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.badge-new {
  background: #353535;
}
.badge-sale {
  background: #d2232a;
}
.badge-coming-soon {
  background: #888380;
}
.badge-discontinued {
  background: #d7d3d1;
  color: #222;
}

.attribute {
  margin-top: 0.938em;
}
.attribute label {
  display: block;
}

.swatch a {
  text-decoration: none;
}

.primary-images {
  height: 290px;
  padding-bottom: 10px;
}
.primary-images .slick-prev,
.primary-images .slick-next {
  height: 20px;
  width: 20px;
}
.primary-images .slick-prev::before,
.primary-images .slick-next::before {
  content: "";
  height: 20px;
  width: 20px;
}
@media (min-width: 768px) {
  .primary-images .slick-prev,
  .primary-images .slick-next {
    height: 30px;
    width: 30px;
  }
  .primary-images .slick-prev::before,
  .primary-images .slick-next::before {
    content: "";
    height: 30px;
    width: 30px;
  }
}
.primary-images .slick-prev {
  left: 25px;
}
.primary-images .slick-prev::before {
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-prev {
    left: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-prev {
    left: 10px;
  }
  .primary-images .slick-prev::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-next {
  right: 25px;
}
.primary-images .slick-next::before {
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat;
  background-size: 20px 20px;
}
@media (min-width: 576px) {
  .primary-images .slick-next {
    right: -10px;
  }
}
@media (min-width: 992px) {
  .primary-images .slick-next {
    right: 10px;
  }
  .primary-images .slick-next::before {
    background-size: 30px 30px;
  }
}
.primary-images .slick-list {
  height: 290px;
}
@media (min-width: 768px) {
  .primary-images .slick-list {
    height: 600px;
  }
}
.primary-images .slick-initialized .slick-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.primary-images div:not(.badge) {
  height: 100%;
}
.primary-images .product-carousel img {
  max-height: 100%;
  height: auto;
  max-width: 290px;
}
@media (max-width: 991.98px) {
  .primary-images .product-carousel img {
    max-width: 211px;
    max-height: 90%;
  }
}
@media (min-width: 768px) {
  .primary-images .product-carousel img {
    max-width: 400px;
  }
}
@media (max-width: 575.98px) {
  .primary-images {
    margin: 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .primary-images {
    height: 600px;
  }
}

.prices-add-to-cart-actions {
  margin-top: 0.75rem;
}
.prices-add-to-cart-actions .prices {
  text-align: left;
  width: 100%;
}
.prices-add-to-cart-actions .price {
  position: relative;
  top: 2px;
}

.cart-and-ipay {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1.25rem;
}
.cart-and-ipay .btn {
  width: 100%;
  display: block;
}
.cart-and-ipay .hidden {
  display: none;
}
@media (min-width: 768px) {
  .cart-and-ipay {
    padding: 0 12px 1.25rem;
    margin-top: 1.25rem;
  }
}

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.add-to-wish-list {
  margin-top: 1em;
}

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.add-to-basket-alert.show {
  display: block;
}

.qty-alert {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.simple-quantity {
  margin-top: 1.25rem;
}

.main-attributes {
  margin-top: 1em;
}

.size-chart {
  margin-top: 1.071em;
}

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #d7d3d1;
}
.bundle-item:last-child {
  border-bottom: none;
}

.container.product-detail {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .container.product-detail {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
}

.product-option:not(:first-child) {
  margin-top: 0.75rem;
}

.slide-link {
  display: block;
}

.customContact {
  flex: 0 0 100%;
}
.customContact a {
  float: right;
  margin-right: 12px;
}

.quantity-form,
.quantity-form-SFL {
  display: flex;
  max-width: 132px;
}
.quantity-form input[type=text],
.quantity-form-SFL input[type=text] {
  width: 40%;
  text-align: center;
  padding: 0;
}
.quantity-form .plus,
.quantity-form .minus,
.quantity-form-SFL .plus,
.quantity-form-SFL .minus {
  position: relative;
  height: 40px;
  width: 30%;
}
.quantity-form .plus .icon,
.quantity-form .minus .icon,
.quantity-form-SFL .plus .icon,
.quantity-form-SFL .minus .icon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
}
.quantity-form .plus input[type=button],
.quantity-form .minus input[type=button],
.quantity-form-SFL .plus input[type=button],
.quantity-form-SFL .minus input[type=button] {
  height: 100%;
  width: 100%;
  background: none;
  border: 1px solid #d7d3d1;
  border-radius: 0;
}
.quantity-form .plus input[type=button],
.quantity-form-SFL .plus input[type=button] {
  border-left: none;
}
.quantity-form .minus input[type=button],
.quantity-form-SFL .minus input[type=button] {
  border-right: none;
}
.quantity-form label,
.quantity-form-SFL label {
  margin-bottom: 0;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.price {
  color: #222;
}
.price .strike-through,
.price del {
  text-decoration: line-through;
}
.price .strike-through + .sales,
.price del + .sales {
  color: #d2232a;
}
.price .strike-through {
  margin-right: 10px;
}
.price .tiered {
  padding: 0 0.75rem;
  background: #f6f6f6;
  margin-bottom: 0.75rem;
}
.price .tiered table {
  margin: 0 auto;
}

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: 56.25em;
}
.quick-view-dialog .product-name,
.choose-bonus-product-dialog .product-name {
  padding-top: 0;
}
.quick-view-dialog .selectable-bonus-product-line-item,
.choose-bonus-product-dialog .selectable-bonus-product-line-item {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
.quick-view-dialog .beenSelected,
.choose-bonus-product-dialog .beenSelected {
  background-color: #888380;
}
.quick-view-dialog .primary-images,
.quick-view-dialog .slick-list,
.choose-bonus-product-dialog .primary-images,
.choose-bonus-product-dialog .slick-list {
  height: auto;
}
.quick-view-dialog .primary-images .slick-initialized .slick-slide,
.quick-view-dialog .slick-list .slick-initialized .slick-slide,
.choose-bonus-product-dialog .primary-images .slick-initialized .slick-slide,
.choose-bonus-product-dialog .slick-list .slick-initialized .slick-slide {
  justify-content: start;
}
.quick-view-dialog .primary-images .slick-next,
.quick-view-dialog .primary-images .slick-prev,
.quick-view-dialog .slick-list .slick-next,
.quick-view-dialog .slick-list .slick-prev,
.choose-bonus-product-dialog .primary-images .slick-next,
.choose-bonus-product-dialog .primary-images .slick-prev,
.choose-bonus-product-dialog .slick-list .slick-next,
.choose-bonus-product-dialog .slick-list .slick-prev {
  top: 25%;
}
@media (min-width: 768px) {
  .quick-view-dialog .primary-images .slick-initialized .slick-slide,
  .choose-bonus-product-dialog .primary-images .slick-initialized .slick-slide {
    display: block;
  }
  .quick-view-dialog .primary-images .product-carousel img,
  .choose-bonus-product-dialog .primary-images .product-carousel img {
    max-width: 100%;
  }
}
.quick-view-dialog .product-carousel:not(.slick-initialized) .slide,
.choose-bonus-product-dialog .product-carousel:not(.slick-initialized) .slide {
  display: none;
}
.quick-view-dialog .product-number,
.choose-bonus-product-dialog .product-number {
  width: 100%;
  border-top: 1px solid #d7d3d1;
}
.quick-view-dialog .product-number,
.quick-view-dialog .attributes ul,
.quick-view-dialog .attributes .last-swatch .attribute,
.choose-bonus-product-dialog .product-number,
.choose-bonus-product-dialog .attributes ul,
.choose-bonus-product-dialog .attributes .last-swatch .attribute {
  padding: 0.75rem 0;
  border-bottom: 1px solid #d7d3d1;
}
.quick-view-dialog .product-name,
.choose-bonus-product-dialog .product-name {
  font-size: 1.75rem;
}
.quick-view-dialog .attributes ul,
.choose-bonus-product-dialog .attributes ul {
  padding-left: 0;
  margin-bottom: 0;
}
.quick-view-dialog .attributes ul li,
.choose-bonus-product-dialog .attributes ul li {
  list-style-type: none;
}
.quick-view-dialog .attributes ul li .label-2,
.choose-bonus-product-dialog .attributes ul li .label-2 {
  white-space: nowrap;
}
.quick-view-dialog .swatch-circle,
.choose-bonus-product-dialog .swatch-circle {
  border: 1px solid #353535;
  border-color: #fff;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  border-radius: 0;
}
.quick-view-dialog .swatch-circle.color-value[data-selected=true]::after,
.choose-bonus-product-dialog .swatch-circle.color-value[data-selected=true]::after {
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  left: 0.295em;
  position: absolute;
}
.quick-view-dialog .swatch-circle.color-value.selected::after,
.choose-bonus-product-dialog .swatch-circle.color-value.selected::after {
  background: #fff;
  border-radius: 50%;
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  height: 0.75em;
  left: 0.31em;
  line-height: 0.8em;
  position: absolute;
  top: 0.35em;
  width: 0.8em;
}
.quick-view-dialog .swatch-circle:hover,
.choose-bonus-product-dialog .swatch-circle:hover {
  border: 1px solid #353535;
}
.quick-view-dialog a[disabled] .swatch-circle,
.choose-bonus-product-dialog a[disabled] .swatch-circle {
  cursor: not-allowed;
}
.quick-view-dialog a[disabled] .swatch-circle.color-value.selected::after,
.choose-bonus-product-dialog a[disabled] .swatch-circle.color-value.selected::after {
  background-color: #fafafa;
}
.quick-view-dialog .tiered th,
.choose-bonus-product-dialog .tiered th {
  font-size: 0.875rem;
  white-space: nowrap;
}
.quick-view-dialog .modal-header,
.choose-bonus-product-dialog .modal-header {
  padding-top: 20px;
}
.quick-view-dialog .modal-body,
.choose-bonus-product-dialog .modal-body {
  max-height: 390px;
  overflow-y: auto;
  padding: 0 20px;
}
.quick-view-dialog .modal-footer,
.choose-bonus-product-dialog .modal-footer {
  background-color: #fff;
  border: none;
  flex-direction: column;
  align-items: normal;
  padding: 0 20px 20px;
}
@media (min-width: 768px) {
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    padding: 20px 15px;
  }
}
.quick-view-dialog .modal-footer .prices .price,
.quick-view-dialog .modal-footer .prices .price span,
.choose-bonus-product-dialog .modal-footer .prices .price,
.choose-bonus-product-dialog .modal-footer .prices .price span {
  font-size: 1.625rem;
}
.quick-view-dialog .modal-footer button,
.choose-bonus-product-dialog .modal-footer button {
  max-width: none;
  width: 100%;
}
.quick-view-dialog .modal-footer .row,
.choose-bonus-product-dialog .modal-footer .row {
  margin: 0;
  width: 100%;
}
.quick-view-dialog .modal-footer .availability,
.choose-bonus-product-dialog .modal-footer .availability {
  width: 100%;
}
.quick-view-dialog .modal-footer .availability ul,
.choose-bonus-product-dialog .modal-footer .availability ul {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .quick-view-dialog .modal-footer .availability ul .label-1,
  .choose-bonus-product-dialog .modal-footer .availability ul .label-1 {
    text-align: right;
  }
}
.quick-view-dialog .modal-footer .global-availability .prices,
.choose-bonus-product-dialog .modal-footer .global-availability .prices {
  padding: 0 12px;
}
.quick-view-dialog .quantity-form,
.quick-view-dialog .quantity-form-SFL,
.choose-bonus-product-dialog .quantity-form,
.choose-bonus-product-dialog .quantity-form-SFL {
  margin-top: 0;
}
@media (min-width: 768px) {
  .quick-view-dialog .quantity-form,
  .quick-view-dialog .quantity-form-SFL,
  .choose-bonus-product-dialog .quantity-form,
  .choose-bonus-product-dialog .quantity-form-SFL {
    margin-top: 1.25rem;
  }
}
.quick-view-dialog .cart-and-ipay,
.choose-bonus-product-dialog .cart-and-ipay {
  padding: 0;
}
.quick-view-dialog .promotions,
.choose-bonus-product-dialog .promotions {
  text-align: left;
  color: #d2232a;
  padding-top: 0.75rem;
}
@media (max-width: 767.98px) {
  .quick-view-dialog .bonus-summary,
  .choose-bonus-product-dialog .bonus-summary {
    font-size: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .quick-view-dialog .bonus-summary .bonus-product-name,
  .choose-bonus-product-dialog .bonus-summary .bonus-product-name {
    padding: 0;
  }
}
.quick-view-dialog .selected-pid .close::before,
.choose-bonus-product-dialog .selected-pid .close::before {
  content: "";
  display: block;
  background: url("../images/svg-icons/icon-close.svg");
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.quick-view-dialog .selected-pid .bonus-product-name + div,
.choose-bonus-product-dialog .selected-pid .bonus-product-name + div {
  padding: 0;
  position: relative;
}
.quick-view-dialog .pre-cart-products,
.choose-bonus-product-dialog .pre-cart-products {
  margin-right: 0.125em;
}
.quick-view-dialog .color-attribute,
.choose-bonus-product-dialog .color-attribute {
  border: none;
  padding: 0;
  background: none;
}
.quick-view-dialog .non-input-label,
.choose-bonus-product-dialog .non-input-label {
  display: block;
  margin-bottom: 0.5rem;
}
.quick-view-dialog .bundle-items-label,
.choose-bonus-product-dialog .bundle-items-label {
  display: none;
}
.quick-view-dialog .product-options .quantity,
.choose-bonus-product-dialog .product-options .quantity {
  position: static;
}
.quick-view-dialog .product-availability,
.choose-bonus-product-dialog .product-availability {
  text-align: left;
}
.quick-view-dialog .tiered table,
.choose-bonus-product-dialog .tiered table {
  background: #f6f6f6;
}
.quick-view-dialog .update-cart-product-global,
.choose-bonus-product-dialog .update-cart-product-global {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .quick-view-dialog .update-cart-product-global,
  .choose-bonus-product-dialog .update-cart-product-global {
    margin-top: 0;
  }
}

.choose-bonus-product-dialog .modal-body {
  max-height: 70vh;
  overflow-y: scroll;
}
.choose-bonus-product-dialog .choice-of-bonus-product:first-of-type .product-name {
  padding-top: 0;
}

.prices .sales .value {
  display: inline-block !important;
}

.badge {
  height: 26px;
  color: #fff;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.badge-new {
  background: #353535;
}
.badge-sale {
  background: #d2232a;
}
.badge-coming-soon {
  background: #888380;
}
.badge-discontinued {
  background: #d7d3d1;
  color: #222;
}

.Federal_review .yotpo .yotpo-bottomline .yotpo-icon-star,
.Federal_review .yotpo .yotpo-bottomline .yotpo-icon-half-star,
.Federal_review .yotpo .yotpo-bottomline .yotpo-icon-empty-star {
  color: #876841 !important;
}
.Federal_review .yotpo .text-m {
  padding-left: 10px !important;
  color: #020202 !important;
}
.Federal_review .yotpo .text-m:hover {
  color: #020202 !important;
}
.Federal_review .yotpo .text-m span {
  font-size: 13px;
  color: #020202;
  letter-spacing: 1px;
  font-weight: 600;
}

.product-carousel img {
  width: 100%;
  height: 100%;
}

.grid-tile {
  height: 100%;
}

.product-tile {
  padding: 0 7px;
  height: 100%;
  margin-bottom: 0;
}
.product-tile .tile-body {
  padding-top: 7px;
}
.product-tile .tile-body .color-swatches {
  margin-bottom: 11px;
}
.product-tile .tile-body .price {
  margin-bottom: 0;
  white-space: nowrap;
}
.product-tile .tile-body .price .tiered {
  font-size: 0.875em;
}
.product-tile .tile-body .price .tiered .value {
  font-weight: bold;
}
.product-tile .tile-body .coming-soon-tile {
  text-align: center;
}
.product-tile .tile-body .pc-promotion-message {
  color: #d2232a;
  margin-bottom: 4px;
}
.product-tile .tile-body .availability {
  margin-top: 0;
  text-align: right;
}
.product-tile .tile-body .pdp-link {
  margin-bottom: 5px;
}
.product-tile .tile-body .pdp-link a {
  color: #222;
}
.product-tile .tile-body .pid {
  color: #353535;
  margin-bottom: 17px;
}
.product-tile .tile-body .tile-info {
  margin-bottom: 12px;
}
.product-tile .tile-body .tile-attributes {
  padding-left: 0;
  margin: 0;
}
.product-tile .tile-body .tile-attributes li {
  list-style: none;
  margin-top: 0;
}
.product-tile .tile-body .tile-attributes li span:first-child {
  margin-right: 7px;
}
.product-tile .tile-body .compare label {
  margin-top: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}
.product-tile .tile-body .compare label::before {
  top: 1px;
}
.product-tile .image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 245px;
  padding: 0 10px;
}
.product-tile .image-container .quickview {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  max-width: 130px;
  display: none;
  text-transform: uppercase;
}
.product-tile .image-container:hover .quickview {
  display: block;
  text-decoration: none;
}
.product-tile .image-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
}
.product-tile .image-container a .tile-image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product-tile .swatches a {
  text-decoration: none;
}
.product-tile span[data-attr-value=White] .swatch-circle,
.product-tile span[data-attr-value=white] .swatch-circle {
  border: 1px solid #d7d3d1;
}
.product-tile .swatch-circle {
  border: 1px solid #353535;
  border-color: #fff;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  border-radius: 0;
}
.product-tile .swatch-circle.color-value[data-selected=true]::after {
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  left: 0.295em;
  position: absolute;
}
.product-tile .swatch-circle.color-value.selected::after {
  background: #fff;
  border-radius: 50%;
  color: #000;
  content: "\f058";
  display: table-caption;
  font-family: "FontAwesome";
  font-size: 1.625em;
  height: 0.75em;
  left: 0.31em;
  line-height: 0.8em;
  position: absolute;
  top: 0.35em;
  width: 0.8em;
}
.product-tile .swatch-circle:hover {
  border: 1px solid #353535;
}

#chooseBonusProductModal .modal-footer .container {
  margin-left: 0;
  width: 100%;
  margin-right: 0;
}
#chooseBonusProductModal .select-cbp-container {
  margin-top: auto;
  margin-bottom: auto;
}
#chooseBonusProductModal .product-name-wrapper {
  width: 100%;
}
#chooseBonusProductModal .bonus-quantity,
#chooseBonusProductModal .bonus-option {
  margin-top: 0.938em;
}
#chooseBonusProductModal .select-bonus-product {
  margin-top: 1em;
}
#chooseBonusProductModal .selected-pid {
  border: 1px solid #d7d3d1;
}
#chooseBonusProductModal .selected-pid .bonus-product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#chooseBonusProductModal .bonus-product-price {
  text-align: center;
  margin-top: 1em;
}

.form-group {
  margin-bottom: 0;
}
.form-group .form-control-feedback {
  display: none;
}
.form-group.has-danger .form-control-feedback {
  display: block;
}
.form-group.required label::after {
  content: "*";
  color: #000;
}
.form-group.required label.is-invalid::after {
  content: "*";
  color: #92110f;
}
.form-group label {
  margin-bottom: 6px;
  line-height: 1;
}
.form-group label.is-invalid {
  color: #92110f;
}
.form-group .form-control ~ .form-text {
  color: #222;
}
.form-group + .form-group,
.form-group + form {
  margin-top: 18px;
}
.form-group > .form-control-feedback {
  display: none;
  font-size: 0.8125rem;
}
.form-group.has-danger > .form-control-feedback, .form-group.has-warning > .form-control-feedback {
  display: block;
}
.form-group ~ button[type=submit] {
  margin-top: 18px;
}

.add-to-wishlist-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
  z-index: 100;
}

.add-to-wishlist-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.add-to-wishlist-alert.show {
  display: block;
}

.wishlistTile i.fa-heart-o {
  color: rgba(0, 0, 0, 0.7);
}
.wishlistTile i.fa-circle {
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.wishlistTile span.fa-stack {
  position: absolute;
  top: 8px;
  right: 16px;
}

.wishlistTile .fa-heart {
  color: black;
}

.hero-content {
  position: relative;
}

.center-vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.top-vertical {
  position: absolute;
  top: 0;
}

.bottom-vertical {
  position: absolute;
  bottom: 0;
}

.full-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}

.play-button {
  position: relative;
}
.play-button::before {
  content: "";
  background: url("../../images/../images/svg-icons/icon-video.svg");
  background-size: 80px 80px;
  opacity: 0.6;
  display: block;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-button:hover::before {
  transition: all 0.2s ease;
  opacity: 0.8;
  cursor: pointer;
}

.video {
  position: relative;
  margin: 0 0.75rem;
}
.video::before {
  content: "";
  background: url("../../../images/../images/svg-icons/icon-video.svg");
  background-size: 80px 80px;
  opacity: 0.6;
  display: block;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video:hover::before {
  transition: all 0.2s ease;
  opacity: 0.8;
}

.video-container {
  position: relative;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
}
.video-container.aspect-ratio-16-9::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-16-9::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 56.25%;
}
.video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 56.25%;
}
.video-container.aspect-ratio-9-16::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-9-16::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 177.7777777778%;
}
.video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 177.7777777778%;
}
.video-container.aspect-ratio-4-3::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-4-3::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 75%;
}
.video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 75%;
}
.video-container.aspect-ratio-3-4::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-3-4::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 133.3333333333%;
}
.video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 133.3333333333%;
}
.video-container.aspect-ratio-1-1::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-1-1::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::after {
  display: block;
  content: "";
  clear: both;
}
.video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.video-container .video-player,
.video-container .video-player iframe,
.video-container video.vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container .video-thumbnail:not(.btn) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 1;
  transition: opacity 200ms;
  z-index: 1;
}
.video-container .video-thumbnail:not(.btn).faded {
  opacity: 0;
  transition: opacity 200ms;
}
.video-container .video-thumbnail:not(.btn).faded .video-thumbnail-image {
  opacity: 0;
  pointer-events: none;
}
.video-container .video-thumbnail:not(.btn).faded:hover {
  opacity: 1;
}
.video-container .video-thumbnail:not(.btn):focus .video-thumbnail-button:before, .video-container .video-thumbnail:not(.btn):hover:not(.faded) .video-thumbnail-button:before {
  color: #231f20;
  transform: scale(1.1);
}
.video-container.has-controls .video-thumbnail.faded {
  pointer-events: none;
}
.video-container.has-controls .video-thumbnail.faded .video-thumbnail-button {
  opacity: 0;
}
.video-container.has-controls .video-js .vjs-big-play-button {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  left: auto;
  border: none;
  background: transparent;
  width: 50px;
  height: 50px;
}
.video-container.has-controls .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  transition: all 200ms ease-in-out;
}
.video-container.has-controls .video-js:hover .vjs-icon-placeholder::before {
  color: #231f20;
  transform: scale(1.1);
}
.video-container.has-controls .video-js .vjs-poster {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.video-container .video-thumbnail-button {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.video-container .video-thumbnail-button:before {
  transition: all 200ms ease-in-out;
}
.video-container .video-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.video-container .video-thumbnail-image.background-image-tablet, .video-container .video-thumbnail-image.background-image-desktop {
  display: none;
}
@media (min-width: 768px) {
  .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-desktop {
    display: none;
  }
  .video-container .video-thumbnail-image.background-image-tablet {
    display: block;
  }
}
@media (min-width: 992px) {
  .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-tablet {
    display: none;
  }
  .video-container .video-thumbnail-image.background-image-desktop {
    display: block;
  }
}

.video-width-100 {
  max-width: 100%;
  min-width: 100%;
}

.video-width-90 {
  max-width: 90%;
  min-width: 90%;
}

.video-width-80 {
  max-width: 80%;
  min-width: 80%;
}

.video-width-70 {
  max-width: 70%;
  min-width: 70%;
}

.video-width-60 {
  max-width: 60%;
  min-width: 60%;
}

.video-width-50 {
  max-width: 50%;
  min-width: 50%;
}

.video-width-40 {
  max-width: 40%;
  min-width: 40%;
}

.video-width-30 {
  max-width: 30%;
  min-width: 30%;
}

.video-width-20 {
  max-width: 20%;
  min-width: 20%;
}

.video-width-10 {
  max-width: 10%;
  min-width: 10%;
}

.video-centered {
  margin-left: auto;
  margin-right: auto;
}

.video-modal .modal-dialog {
  max-width: none;
  margin: 8px;
}
@media (min-width: 992px) {
  .video-modal .modal-dialog {
    max-width: 900px;
    margin: 2rem auto;
  }
}
@media (min-width: 1200px) {
  .video-modal .modal-dialog {
    max-width: 1140px;
  }
}
.video-modal .modal-body {
  padding: 0;
}
.video-modal .video-player {
  width: 100%;
}

@media (min-width: 768px) {
  #quickViewModal .quick-view-dialog .primary-images,
  #quickViewModal .quick-view-dialog .slick-list {
    max-height: none;
  }
  #quickViewModal .quick-view-dialog .primary-images .slide,
  #quickViewModal .quick-view-dialog .slick-list .slide {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  #quickViewModal .quick-view-dialog .primary-images .slide a,
  #quickViewModal .quick-view-dialog .slick-list .slide a {
    display: table-cell;
    position: relative;
    vertical-align: top;
    text-align: center;
    width: 205px;
  }
  #quickViewModal .quick-view-dialog .primary-images .slide a img,
  #quickViewModal .quick-view-dialog .slick-list .slide a img {
    height: auto;
    width: auto;
    max-height: 344px;
    margin: 0 auto;
  }
}
.quick-view-dialog .modal-body {
  overflow-y: visible;
  max-height: 550px;
}
.quick-view-dialog .product-carousel:not(.slick-initialized) .slide.glide__slide {
  display: block;
}

.quick-view-dialog .yotpo .rating-star.fill-star {
  background-image: url("../../images/svg-icons/icon-star-filled.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}
.quick-view-dialog .yotpo .rating-star.empty-star {
  background-image: url("../../images/svg-icons/icon-star-empty.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}
.quick-view-dialog .yotpo .rating-star.half-star {
  background-image: url("../../images/svg-icons/icon-half-star.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}
.quick-view-dialog .yotpo .fill-star,
.quick-view-dialog .yotpo .empty-star,
.quick-view-dialog .yotpo .half-star {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.quick-view-dialog .yotpo .yotpo-icon-half-star {
  position: relative;
  bottom: 8px;
}
.quick-view-dialog .yotpo .star-count .fill-star,
.quick-view-dialog .yotpo .star-count .empty-star,
.quick-view-dialog .yotpo .star-count .half-star {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.quick-view-dialog .yotpo .yotpo-score {
  padding-left: 10px;
}

@media (min-width: 768px) {
  [data-action*=Product-Show] .primary-images .product-carousel img {
    width: auto;
    margin: auto;
  }
}
.homepage-wrapper .slider-wrapper {
  margin: 0 auto;
  max-width: 1200px;
}

[id^=cq_recomm_slot] .slider-wrapper {
  position: relative;
  margin: 2.5rem auto;
}
[id^=cq_recomm_slot] .product-tile {
  height: 100%;
}
[id^=cq_recomm_slot] .product-carousel-arrows .slick-prev {
  left: -48px;
}
[id^=cq_recomm_slot] .product-carousel-arrows .slick-next {
  right: -48px;
}

@media (max-width: 991.98px) {
  [data-action*=Product-Show] .primary-images .product-carousel .slide-link {
    height: 100%;
  }
  [data-action*=Product-Show] .primary-images .product-carousel .slide-link img {
    width: auto;
    margin: auto;
    height: auto;
    max-height: 100%;
    max-width: 336px;
  }
}
.attributes .promotions {
  padding-bottom: 0 !important;
}
.attributes .promotions:empty {
  border-bottom: 0 !important;
}

@media (min-width: 768px) {
  .stock-notify-me .form-group {
    position: relative;
  }
  .stock-notify-me .invalid-feedback {
    position: absolute;
  }
}

.product-notification-message {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
}

.product-notification-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.product-notification-alert.show {
  display: block;
}

.expertvoice {
  margin-top: 3.125rem;
}

.cls-slide-pdp {
  min-width: "400px";
  min-height: "183px";
}

footer .footer-content-header {
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
  font-size: 0.875rem;
  letter-spacing: 1px;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  line-height: 1;
}

.order-status-btn {
  color: #fff !important;
  background-color: #85704d;
  text-align: center;
  padding: 5px 0.5rem;
  line-height: 1.5;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: #85704d;
  border-radius: 0;
  box-shadow: 0 0 0 transparent;
}

@media (max-width: 1199.98px) {
  nav.menu-container .suggestions ul {
    overflow-y: scroll;
  }
}

.navbar-header .header-utility-links .suggestions a span,
.navbar-header .header-utility-links .suggestions a {
  font-size: 0.875rem;
  color: #85704d;
}

.breadcrumb-container .breadcrumb-item a {
  color: #222;
}
.breadcrumb-container .breadcrumb-item:last-child a {
  color: #85704d;
}

@media (min-width: 1025px) {
  nav.menu-container .header.menu {
    padding: 24px 12px 29px;
  }
}
nav.menu-container .navbar-header .navbar-toggler {
  background-size: 22px 20px;
  background-repeat: no-repeat;
  height: 20px;
}
nav.menu-container .mobile-search {
  background: #222;
}
nav.menu-container .minicart-quantity {
  font-weight: 600;
  font-size: 16px;
  position: relative;
  top: 4px;
}
@media (max-width: 767.98px) {
  nav.menu-container .minicart-quantity {
    font-size: 14px;
  }
}
nav.menu-container .site-search {
  border-bottom: 0;
}
nav.menu-container .search-field::placeholder {
  font-weight: 400;
}
@media (max-width: 767.98px) {
  nav.menu-container .brand img {
    width: auto;
  }
}

.navbar-header .header-utility-links a {
  margin-right: 30px;
  color: #85704d;
}
.navbar-header .header-utility-links a:nth-of-type(2) {
  margin-right: 30px;
}
.navbar-header .header-utility-links .minicart-footer a {
  color: #fff;
}

.prices-add-to-cart-actions .preorder-msg .availability-label, .prices-add-to-cart-actions.preorder-msg .availability-label,
.line-item-availability .preorder-msg .availability-label,
.line-item-availability.preorder-msg .availability-label,
.account-global .preorder-msg .availability-label,
.account-global.preorder-msg .availability-label,
.checkout-global .preorder-msg .availability-label,
.checkout-global.preorder-msg .availability-label,
.quick-view-dialog .preorder-msg .availability-label,
.quick-view-dialog.preorder-msg .availability-label {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  line-height: 1.371;
  margin: 10px 0;
}
.prices-add-to-cart-actions .expected-instock-date,
.prices-add-to-cart-actions .line-item-instock-date,
.line-item-availability .expected-instock-date,
.line-item-availability .line-item-instock-date,
.account-global .expected-instock-date,
.account-global .line-item-instock-date,
.checkout-global .expected-instock-date,
.checkout-global .line-item-instock-date,
.quick-view-dialog .expected-instock-date,
.quick-view-dialog .line-item-instock-date {
  flex-wrap: wrap;
}
.prices-add-to-cart-actions .expected-instock-date p,
.prices-add-to-cart-actions .line-item-instock-date p,
.line-item-availability .expected-instock-date p,
.line-item-availability .line-item-instock-date p,
.account-global .expected-instock-date p,
.account-global .line-item-instock-date p,
.checkout-global .expected-instock-date p,
.checkout-global .line-item-instock-date p,
.quick-view-dialog .expected-instock-date p,
.quick-view-dialog .line-item-instock-date p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  line-height: 1.371;
}
.prices-add-to-cart-actions .expected-instock-date p.expected-availability,
.prices-add-to-cart-actions .line-item-instock-date p.expected-availability,
.line-item-availability .expected-instock-date p.expected-availability,
.line-item-availability .line-item-instock-date p.expected-availability,
.account-global .expected-instock-date p.expected-availability,
.account-global .line-item-instock-date p.expected-availability,
.checkout-global .expected-instock-date p.expected-availability,
.checkout-global .line-item-instock-date p.expected-availability,
.quick-view-dialog .expected-instock-date p.expected-availability,
.quick-view-dialog .line-item-instock-date p.expected-availability {
  margin-right: 3px;
}
.prices-add-to-cart-actions .cart-and-ipay .add-to-cart,
.line-item-availability .cart-and-ipay .add-to-cart,
.account-global .cart-and-ipay .add-to-cart,
.checkout-global .cart-and-ipay .add-to-cart,
.quick-view-dialog .cart-and-ipay .add-to-cart {
  font-size: 0.875rem;
  font-weight: 600;
}

.pre-order-description.cart-preorder-description {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .pre-order-description.cart-preorder-description {
    margin-left: 90px;
  }
}
.pre-order-description p {
  font-family: "FFDINWebProMedium", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #d2232a;
}

.line-item-availability .instock-expected-date {
  margin: 10px 0;
}

@media (min-width: 768px) {
  .quick-view-dialog .modal-footer .availability ul.preorder-msg .availability-label {
    text-align: left;
  }
}

.nav-link {
  color: #231f20 !important;
}

.main-menu.no-menu .continue-shopping {
  color: #fff !important;
}

.checkout-global .btn {
  height: 44px;
}
@media (min-width: 768px) {
  .checkout-global.container {
    max-width: 1170px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .checkout-global.container {
    padding: 0 30px;
  }
}
.checkout-global.container h1.page-title {
  color: #222;
  left: 0;
  font-size: 1.75rem;
  margin-top: 2.5rem;
  margin-bottom: 1.75rem;
}
@media (min-width: 992px) {
  .checkout-global.container h1.page-title {
    font-size: 2.25rem;
    margin-top: 3.125rem;
  }
}
.checkout-global.container .customer-summary {
  margin-bottom: 1.875rem;
}
@media (min-width: 992px) {
  .checkout-global.container .custom-login {
    max-width: 670px;
  }
}
.checkout-global.container .custom-login .shipping-section {
  margin-bottom: 0;
}
.checkout-global.container .custom-login .btn {
  font-size: 0.875rem;
  font-weight: 600;
}
.checkout-global.container .custom-login .form-control-label {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.checkout-global.container .custom-login .customer-summary .summary-section-label,
.checkout-global.container .custom-login .customer-summary .summary-details {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
@media (min-width: 992px) {
  .checkout-global.container .custom-login .customer-summary .summary-details {
    padding-left: 5px;
  }
}
.checkout-global.container .custom-login .customer-summary .summary-details .customer-summary-email,
.checkout-global.container .custom-login .customer-summary .summary-details .customer-summary-fullname {
  font-weight: 600;
  color: #85704d;
  font-size: 0.875rem;
}
.checkout-global.container .summary-wrapper {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (min-width: 992px) {
  .checkout-global.container .summary-wrapper {
    max-width: 500px;
    padding-left: 30px;
  }
}
@media (min-width: 1200px) {
  .checkout-global.container .summary-wrapper {
    max-width: 470px;
    padding-left: 0;
  }
}
.checkout-global.container .summary-wrapper .summary-block {
  border: 1px solid #a5a5a5;
}
.checkout-global.container .summary-wrapper .order-total-block {
  border: 1px solid #d7d3d1;
}
.checkout-global .card .card-header {
  padding-left: 0;
  padding-right: 0;
}
.checkout-global .card .card-header .order-receipt-header {
  line-height: 1.375rem;
}
.checkout-global .card .card-header .card-header-custom {
  font-size: 22px;
  color: #85704d;
}
.checkout-global .card .edit-button {
  font-family: "Open Sans", sans-serif;
  color: #85704d;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-weight: 600;
}
.checkout-global .card .card-body {
  padding: 20px 0 0;
}
.checkout-global .card .card-body .customer-information-block .submit-customer-login {
  margin-top: 6px;
}
.checkout-global .card .card-body .customer-information-block .forgot-password {
  margin-top: -5px;
}
.checkout-global .card.order-product-summary {
  margin-bottom: 0;
}
.checkout-global .card.order-product-summary .card-body {
  padding: 0;
}
.checkout-global .card.order-product-summary .card-body .leading-lines {
  padding: 16px;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .item-image {
  max-width: 100px;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .item-image img {
  width: 100px;
  max-width: unset;
  margin-right: 0;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .line-item-name {
  margin-bottom: 5px;
  overflow: unset;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .line-item-name a {
  white-space: normal;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .line-item-name span {
  overflow: unset;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .line-item-name.no-link span {
  white-space: normal;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #85704d;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .line-item-attributes {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .line-item-attributes span {
  text-transform: inherit;
  font-weight: 400;
  margin-left: 9px;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .line-item-attributes.line-item-sku {
  margin-bottom: 2px;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .item-attributes {
  padding-left: 12px;
  flex: auto;
}
.checkout-global .card.order-product-summary .card-body .product-line-item-details .item-attributes .price-wrapper {
  margin-top: 10px;
}
.checkout-global .card.order-product-summary .card-body h2 {
  color: #222;
  line-height: 1;
  font-size: 18px;
}
.checkout-global .card.order-product-summary .card-body .line-item-quantity {
  margin-top: 4px;
}
.checkout-global .card.order-product-summary .card-body .line-item-quantity .line-item-pricing-info span {
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
}
.checkout-global .card.order-product-summary .card-body .line-item-quantity .line-item-pricing-info .qty-card-quantity-count {
  font-weight: 400;
  margin-left: 9px;
}
.checkout-global .card.order-product-summary .card-body .line-item-availability {
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkout-global .card.order-product-summary .card-body .line-item-availability .line-item-attributes {
  line-height: 1.5;
}
.checkout-global .card.order-product-summary .card-body .line-item-availability .line-item-attributes.expected-availability, .checkout-global .card.order-product-summary .card-body .line-item-availability .line-item-attributes.line-item-instock-date, .checkout-global .card.order-product-summary .card-body .line-item-availability .line-item-attributes.availability-label {
  line-height: 1.371;
}
.checkout-global .card.order-product-summary .card-body .pc-promotion-message {
  font-weight: 400;
  color: #d2232a;
  line-height: 1.375;
}
.checkout-global .card.order-product-summary .card-body .edit-button {
  color: #222;
}
.checkout-global .card.order-total-summary-wrapper .card-header h2.card-header-custom {
  color: #222;
  font-size: 1.125rem;
}
.checkout-global .card.order-total-summary-wrapper .card-header .order-receipt-label {
  font-family: "Open Sans", sans-serif;
  margin-left: 10px;
  line-height: 1.371;
  padding-top: 3px;
}
.checkout-global .card.order-total-summary-wrapper .order-total-summary .leading-lines.order-summary-title div p span {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}
.checkout-global .card .product-summary-block {
  margin: 0;
}
.checkout-global .card .product-summary-block .groupedProducts {
  margin: 0;
  border: unset;
}
.checkout-global .card .product-summary-block .product-line-item {
  padding: 0 20px 20px;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price {
  display: flex;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .line-item-pricing-info,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .line-item-pricing-info {
  line-height: 1.5;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .line-item-pricing-info span,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .line-item-pricing-info span {
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .price,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .price {
  margin-left: 6px;
  margin-top: 2.7px;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .price del,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .price del {
  color: #656565;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .price del + span span,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .price del + span span {
  margin-top: 5px;
  font-weight: 600;
  color: #d5232a;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .price span,
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .price .line-item-total-price-amount,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .price span,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .price .line-item-total-price-amount {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.checkout-global .card .product-summary-block .product-line-item .line-item-unit-price .price .line-item-total-price-amount,
.checkout-global .card .product-summary-block .product-line-item .line-item-total-price .price .line-item-total-price-amount {
  font-weight: 600;
}
.checkout-global .card.customer-section .card-header {
  background: #fff;
}
.checkout-global .card.customer-section .card-header .guest-description,
.checkout-global .card.customer-section .card-header .registered-customer-message {
  margin: 0.875rem 0 1rem;
}
@media (min-width: 992px) {
  .checkout-global .card.customer-section .card-header .guest-description,
  .checkout-global .card.customer-section .card-header .registered-customer-message {
    margin-bottom: 1.25rem;
  }
}
.checkout-global .card.customer-section .customer-information-guest {
  border-bottom: 1px solid #979797;
}
.checkout-global .card.customer-section #registered-customer .remember-me-wrapper {
  margin-top: 2px;
}
.checkout-global .card.customer-section #registered-customer .remember-me {
  margin-bottom: 4px;
}
.checkout-global .card.customer-section #registered-customer .remember-me label {
  line-height: 1.371;
  margin-bottom: 0;
}
.checkout-global .card.customer-section #registered-customer .privacy-policy {
  line-height: 1.317;
}
.checkout-global .card.customer-section #registered-customer .privacy-policy p {
  margin-bottom: 0;
}
.checkout-global .card.customer-section #registered-customer .privacy-policy p a {
  font-family: "Open Sans", sans-serif;
}
.checkout-global .card.customer-section #registered-customer .forgot-password a {
  font-family: "Open Sans", sans-serif;
}
.checkout-global .card.order-total-summary-wrapper {
  margin-bottom: 0;
}
.checkout-global .card.order-total-summary-wrapper .card-header {
  padding: 14px 20px;
}
.checkout-global .card.order-total-summary-wrapper .order-total-summary {
  padding: 15px 20px 0;
}
.checkout-global .card.order-total-summary-wrapper .shipping-discount,
.checkout-global .card.order-total-summary-wrapper .order-discount {
  color: #d5232a;
}
.checkout-global .card.order-total-summary-wrapper .order-total-summary .grand-total {
  padding-bottom: 16px;
  line-height: 1.067;
}
.checkout-global .card.order-total-summary-wrapper .order-total-summary .grand-total.leading-lines {
  border-top: 1px solid #979797;
  padding-top: 13px;
  margin-top: 8px;
}
.checkout-global .card.order-total-summary-wrapper .order-total-summary .grand-total p {
  margin-bottom: 0;
}
.checkout-global .card.order-total-summary-wrapper .order-total-summary .grand-total span {
  font-family: "Oswald", sans-serif;
  font-size: 1.125rem;
  line-height: 1.067;
}
.checkout-global .card.order-total-summary-wrapper .order-total-summary .grand-total .grand-total-sum {
  font-size: 1rem;
}
.checkout-global .card.ghost {
  opacity: 1;
  background-color: #f6f6f6;
}
.checkout-global .card .shipping-content-wrapper {
  flex: 100%;
}
.checkout-global .card .shipping-content {
  padding-top: 1rem;
}
.checkout-global .card .shipping-content .phone-Ex {
  font-size: 0.75rem;
}
.checkout-global .card .shipping-content .shipping-form .restricted-products,
.checkout-global .card .shipping-content .shipping-form .restricted-shipping {
  margin-top: 1.313rem;
}
.checkout-global .card .shipping-content .shipping-form .restricted-products .alert,
.checkout-global .card .shipping-content .shipping-form .restricted-shipping .alert {
  margin-bottom: 0;
}
.checkout-global .card .shipping-content .custom-checkbox label {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
@media (min-width: 992px) {
  .checkout-global .card .shipping-content .form-field .col-lg-6:first-child {
    padding-right: 5px;
  }
  .checkout-global .card .shipping-content .form-field .col-lg-6:last-child {
    padding-left: 5px;
  }
}
.checkout-global .custom-select {
  background: url("../images/svg-icons/arrow-down-24.svg") no-repeat right 0.8125rem center/22px 22px;
}
.checkout-global .custom-control-input + .custom-control-label::before {
  content: url("../images/svg-icons/checkout-check-box-unchecked.svg");
}
.checkout-global .custom-control-input:checked + .custom-control-label::before {
  content: url("../images/svg-icons/checkout-check-box-checked.svg");
  width: 15px;
  height: 15px;
}
.checkout-global .custom-control-input:checked + .custom-control-label::after {
  content: "";
  background: none;
  top: 0;
  width: 15px;
  height: 15px;
}
.checkout-global.required label::after {
  content: "";
  display: none;
}
.checkout-global .custom-login .edit-button {
  font-family: "Nunito Sans", sans-serif;
  color: #755836;
  line-height: 1.5;
  font-weight: 400;
}
.checkout-global .custom-login .form-group:not(.custom-control) {
  margin-bottom: 21px;
}
.checkout-global .shipping .card-header .card-header-custom,
.checkout-global .payment .card-header .card-header-custom,
.checkout-global .shipping-summary .card-header .card-header-custom,
.checkout-global .payment-summary .card-header .card-header-custom,
.checkout-global .order-review .card-header .card-header-custom,
.checkout-global .payment-form .card-header .card-header-custom {
  color: #85704d;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
}
.checkout-global .payment-summary .content-asset .custom-control-label,
.checkout-global .payment-summary .content-asset ul > li {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.checkout-global .order-review-container {
  padding-top: 1.375rem;
}
.checkout-global .checkout-stage {
  width: 30px;
  height: 53px;
  text-align: center;
  background-color: #dfdfdf;
  margin-bottom: 0;
  padding: 0.75rem 0;
  color: #222;
  font-size: 1.375rem;
  font-weight: 600;
}
.checkout-global[data-checkout-stage=customer] .estimated-sales-tax, .checkout-global[data-checkout-stage=shipping] .estimated-sales-tax {
  display: block;
}
.checkout-global[data-checkout-stage=customer] .actual-sales-tax, .checkout-global[data-checkout-stage=shipping] .actual-sales-tax {
  display: none;
}
@media (max-width: 991.98px) {
  .checkout-global[data-checkout-stage=customer] .card-header, .checkout-global[data-checkout-stage=shipping] .card-header {
    align-items: center;
  }
}
.checkout-global:not([data-checkout-stage=customer], [data-checkout-stage=shipping]) .estimated-sales-tax {
  display: none;
}
.checkout-global:not([data-checkout-stage=customer], [data-checkout-stage=shipping]) .actual-sales-tax {
  display: block;
}
.checkout-global .form-header {
  background-color: #f6f6f6;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .card.ghost.payment, .checkout-global.data-checkout-stage[data-checkout-stage=shipping] .card.ghost.order-review, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .card.ghost.payment, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .card.ghost.order-review, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost.payment, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost.order-review {
  display: block;
}
@media (max-width: 991.98px) {
  .checkout-global.data-checkout-stage[data-checkout-stage=shipping] .card.ghost.payment .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=shipping] .card.ghost.order-review .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .card.ghost.payment .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .card.ghost.order-review .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost.payment .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost.order-review .card-header {
    align-items: center;
  }
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .card.ghost .checkout-stage, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .card.ghost .checkout-stage, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost .checkout-stage {
  color: #222;
  background-color: #dfdfdf;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .card.shipping-summary, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .card.shipping-summary, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.shipping-summary {
  margin-bottom: 0;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .payment-summary,
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .payment-form, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .payment-summary,
.checkout-global.data-checkout-stage[data-checkout-stage=payment] .payment-form, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .payment-summary,
.checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .payment-form {
  margin-bottom: 0;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .payment-summary .card-header,
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .payment-form .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .payment-summary .card-header,
.checkout-global.data-checkout-stage[data-checkout-stage=payment] .payment-form .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .payment-summary .card-header,
.checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .payment-form .card-header {
  margin-top: 2.375rem;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .payment-summary .content-asset ul,
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .payment-form .content-asset ul, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .payment-summary .content-asset ul,
.checkout-global.data-checkout-stage[data-checkout-stage=payment] .payment-form .content-asset ul, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .payment-summary .content-asset ul,
.checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .payment-form .content-asset ul {
  margin-bottom: 0;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .checkout-stage, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .checkout-stage, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .checkout-stage {
  background-color: #85704d;
  color: #fff;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .summary-details.shipping, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .summary-details.shipping, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .summary-details.shipping {
  margin-bottom: 0;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .address-summary, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .address-summary, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .address-summary {
  margin-bottom: 0;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .address-summary .billing-addr-label, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .address-summary .billing-addr-label, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .address-summary .billing-addr-label {
  display: none;
}
@media (min-width: 768px) {
  .checkout-global.data-checkout-stage[data-checkout-stage=shipping] .single-shipping, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .single-shipping, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .single-shipping {
    display: flex;
  }
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .single-shipping .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .single-shipping .card-header, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .single-shipping .card-header {
  background-color: #f6f6f6;
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .single-shipping .single-shipping-details, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .single-shipping .single-shipping-details, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .single-shipping .single-shipping-details {
  width: 50%;
}
@media (max-width: 991.98px) {
  .checkout-global.data-checkout-stage[data-checkout-stage=shipping] .single-shipping .single-shipping-details:first-child, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .single-shipping .single-shipping-details:first-child, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .single-shipping .single-shipping-details:first-child {
    margin-bottom: 1.375rem;
  }
}
.checkout-global.data-checkout-stage[data-checkout-stage=shipping] .single-shipping .single-shipping-details .start-lines, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .single-shipping .single-shipping-details .start-lines, .checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .single-shipping .single-shipping-details .start-lines {
  padding: 0;
}
.checkout-global.data-checkout-stage .checkout-stage-bottom h2.card-header-custom {
  color: #222;
}
.checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost.payment, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .card.ghost.payment {
  display: none;
}
.checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .payment-information .summary-details.billing, .checkout-global.data-checkout-stage[data-checkout-stage=payment] .payment-information .summary-details.billing {
  display: none;
}
.checkout-global.data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost.order-review {
  display: none;
}
.checkout-global .shipment-selector-block a {
  margin-top: 10px;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .checkout-global .shipment-selector-block a {
    margin-bottom: 6px;
  }
}
.checkout-global .shipping-method-block h5 {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.125rem;
}
.checkout-global .shipping-method-block .form-check,
.checkout-global .shipping-method-block .shipping-method-pricing {
  padding-left: 0;
}
.checkout-global .shipping-method-block .form-check label,
.checkout-global .shipping-method-block .form-check span,
.checkout-global .shipping-method-block .shipping-method-pricing label,
.checkout-global .shipping-method-block .shipping-method-pricing span {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #222;
}
.checkout-global .shipping-method-block .form-check label.arrival-time,
.checkout-global .shipping-method-block .form-check span.arrival-time,
.checkout-global .shipping-method-block .shipping-method-pricing label.arrival-time,
.checkout-global .shipping-method-block .shipping-method-pricing span.arrival-time {
  padding-left: 8px;
  color: #222 !important;
}
.checkout-global .shipping-method-block .form-check label.display-name,
.checkout-global .shipping-method-block .form-check span.display-name,
.checkout-global .shipping-method-block .shipping-method-pricing label.display-name,
.checkout-global .shipping-method-block .shipping-method-pricing span.display-name {
  font-weight: 600;
}
.checkout-global .shipping-method-block .form-check label.shipping-cost,
.checkout-global .shipping-method-block .form-check span.shipping-cost,
.checkout-global .shipping-method-block .shipping-method-pricing label.shipping-cost,
.checkout-global .shipping-method-block .shipping-method-pricing span.shipping-cost {
  font-size: 1rem;
}
.checkout-global .shipping-method-block .form-check .form-check-input,
.checkout-global .shipping-method-block .shipping-method-pricing .form-check-input {
  opacity: 0;
}
.checkout-global .shipping-method-block .form-check .form-check-input + .shipping-method-option,
.checkout-global .shipping-method-block .shipping-method-pricing .form-check-input + .shipping-method-option {
  display: flex;
  align-items: center;
}
.checkout-global .shipping-method-block .form-check .form-check-input + .shipping-method-option::before,
.checkout-global .shipping-method-block .shipping-method-pricing .form-check-input + .shipping-method-option::before {
  content: url("../images/svg-icons/checkout-radio-btn-unchecked.svg");
  border: none;
  line-height: 1;
  width: 14px;
  height: 14px;
}
.checkout-global .shipping-method-block .form-check .form-check-input + .shipping-method-option .display-name,
.checkout-global .shipping-method-block .shipping-method-pricing .form-check-input + .shipping-method-option .display-name {
  padding-left: 12px;
}
.checkout-global .shipping-method-block .form-check .form-check-input:checked + .shipping-method-option::before,
.checkout-global .shipping-method-block .shipping-method-pricing .form-check-input:checked + .shipping-method-option::before {
  content: url("../images/svg-icons/checkout-radio-btn-checked.svg");
  border: none;
  background: none;
  line-height: 1;
  width: 15px;
  height: 15px;
}
.checkout-global .shipping-method-block .form-check .form-check-input:checked + .shipping-method-option::after,
.checkout-global .shipping-method-block .shipping-method-pricing .form-check-input:checked + .shipping-method-option::after {
  content: "";
  background: none;
}
.checkout-global .shipping-method-block .form-check,
.checkout-global .shipping-method-block .shipping-method-pricing {
  padding: 0;
  margin-bottom: 0;
  line-height: 1.371;
}
.checkout-global .shipping-method-block .form-check .form-check-label,
.checkout-global .shipping-method-block .shipping-method-pricing .form-check-label {
  margin-bottom: 4px;
}
.checkout-global .shipping-method-block .form-check .shipping-description,
.checkout-global .shipping-method-block .shipping-method-pricing .shipping-description {
  padding-left: 26px;
}
.checkout-global .shipping-summary .single-shipping .summary-section-label,
.checkout-global .payment-information .single-shipping .summary-section-label {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .checkout-global .shipping-summary .single-shipping .summary-section-label,
  .checkout-global .payment-information .single-shipping .summary-section-label {
    font-size: 1.125rem;
  }
}
.checkout-global .shipping-summary .summary-details,
.checkout-global .shipping-summary .address-summary,
.checkout-global .shipping-summary .address-summary span,
.checkout-global .shipping-summary .payment-details span,
.checkout-global .shipping-summary .order-history-info span,
.checkout-global .payment-information .summary-details,
.checkout-global .payment-information .address-summary,
.checkout-global .payment-information .address-summary span,
.checkout-global .payment-information .payment-details span,
.checkout-global .payment-information .order-history-info span {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.checkout-global .payment-form-label p {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .checkout-global .payment-form-label p {
    font-size: 1.125rem;
  }
}
.checkout-global .payment-form {
  margin-bottom: 0;
}
.checkout-global .payment-form .payment-options-block .credit-card-form .row .col-6:first-child {
  padding-right: 5px;
}
.checkout-global .payment-form .payment-options-block .credit-card-form .row .col-6:last-child {
  padding-left: 5px;
}
.checkout-global .payment-form .card-number-wrapper::after {
  background-image: url("../images/svg-icons/checkout-cc-front.svg");
  transform: translateY(-50%);
  top: 50%;
}
.checkout-global .payment-form .card-number-wrapper[data-type=visa]::after {
  background-image: url("../../images/payment-types.png");
  background-size: auto;
  background-position: -162px -110px;
}
.checkout-global .payment-form .card-number-wrapper[data-type=mastercard]::after {
  background-image: url("../../images/payment-types.png");
  background-size: auto;
  background-position: -295px -110px;
}
.checkout-global .payment-form .card-number-wrapper[data-type=amex]::after {
  background-image: url("../../images/payment-types.png");
  background-size: auto;
  background-position: -230px -15px;
}
.checkout-global .payment-form .card-number-wrapper[data-type=discover]::after {
  background-image: url("../../images/payment-types.png");
  background-size: auto;
  background-position: -95px -110px;
}
.checkout-global .payment-form .securityCode-wrapper {
  position: relative;
}
.checkout-global .payment-form .securityCode-wrapper::after {
  content: "";
  position: absolute;
  right: 16px;
  background-repeat: no-repeat;
  background-image: url("../images/svg-icons/checkout-cc-back.svg");
  background-size: contain;
  width: 40px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.checkout-global .payment-form .apply-top-spacing {
  margin-top: 1rem;
}
.checkout-global .payment-form .address-selector-block .address-selector-ctas a {
  margin-top: 10px;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
}
.checkout-global .payment-form .address-selector-block .address-selector-label {
  font-size: 1.125rem;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
}
.checkout-global .payment-form .address-selector-block .addressSelector {
  margin-top: 14px;
}
.checkout-global .payment-form .address-selector-block .subscription-checkbox .custom-control-label::before {
  border: 0;
  background-color: unset;
}
.checkout-global .payment-form .address-selector-block .subscription-checkbox .custom-control-label::after {
  content: "";
}
.checkout-global .payment-form .address-selector-block .content-asset {
  margin-top: 0.875rem;
}
.checkout-global .payment-form .address-selector-block .content-asset ul {
  padding-inline-start: 1.125rem;
}
.checkout-global .payment-form .address-selector-block .content-asset ul li {
  font-family: "Open Sans", sans-serif;
  color: #000;
}
.checkout-global .payment-form .address-selector-block .content-asset ul li a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.checkout-global .card-body-label {
  font-size: 1rem;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}
.checkout-global .shipping-address-heading {
  font-family: "Oswald", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #222;
  letter-spacing: 0;
  line-height: 1.067;
}
.checkout-global .shipping-address-heading.login-customer-heading {
  margin-bottom: 0.875rem;
}
.checkout-global .next-step-button {
  border-bottom: 1px solid #979797;
  margin-top: 1.25rem;
}
.checkout-global[data-checkout-stage=placeOrder] .next-step-button {
  border-bottom: 0;
}
.checkout-global[data-checkout-stage=placeOrder] .next-step-button .place-order {
  margin-top: 10px;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content {
  padding: 38px 40px 40px;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content .modal-header {
  padding: 0 0 20px;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #767676;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content .modal-header .close {
  margin: 0;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content .modal-header .close::before {
  position: unset;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content .modal-header .request-password-title {
  font-size: 1.25rem;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content .modal-body {
  padding: 0;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content .modal-body .request-password-body p {
  font-family: "Open Sans", sans-serif;
}
.checkout-global #requestPasswordResetModal .modal-dialog .modal-content .modal-body .request-password-body .form-control-label {
  font-weight: 700;
}
.checkout-global .form-control {
  height: 44px;
}

.main-menu.no-menu .continue-shopping.nav-link {
  color: #fff !important;
  text-decoration: none;
}

.checkout-global.receipt {
  padding: 0 1.25rem;
}
@media (min-width: 992px) {
  .checkout-global.receipt {
    max-width: 970px;
  }
}
.checkout-global.receipt .order-product-summary .end-lines {
  display: none;
}
.checkout-global.receipt .order-product-summary .item-attributes .line-item-attributes span {
  font-size: 0.875rem;
}
.checkout-global.receipt .order-thank-you-msg {
  color: #222;
  margin-top: 1.25rem;
  letter-spacing: inherit;
  line-height: 1.167;
}
@media (min-width: 992px) {
  .checkout-global.receipt .order-thank-you-msg {
    margin-top: 3.125rem;
    margin-bottom: 1.125rem;
  }
}
.checkout-global.receipt .order-thank-you-email-msg,
.checkout-global.receipt span {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
}
.checkout-global.receipt .order-thank-you-email-msg span,
.checkout-global.receipt span span {
  color: #85704d;
}
.checkout-global.receipt .order-thank-you-email-msg span:hover {
  text-decoration: underline;
}
.checkout-global.receipt .account-confirmation-wrapper {
  background-color: #f9f9f9;
  border: 1px solid #d7d3d1;
}
.checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account {
  max-width: 770px;
  background: unset;
}
.checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-header {
  background-color: unset;
}
.checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-header .card-header-custom {
  font-size: 22px;
}
.checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-header .confirmation-desc {
  margin-top: 16px;
  font-family: "Open Sans", sans-serif;
}
@media (min-width: 992px) {
  .checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-header .confirmation-desc {
    padding: 0 9rem;
  }
}
.checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-body .col-md-6 .form-control-label {
  font-family: "Open Sans", sans-serif;
}
@media (min-width: 768px) {
  .checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-body .col-md-6:first-child {
    padding-right: 1rem;
  }
  .checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-body .col-md-6:last-child {
    padding-left: 1rem;
  }
}
.checkout-global.receipt .account-confirmation-wrapper .order-confirm-create-account .card-body .btn {
  font-size: 0.875rem;
}
.checkout-global.receipt .confirm-details {
  background: #e7e7e7;
  border: 1px solid #a5a5a5;
  padding: 2.188rem 0;
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary {
  padding: 0 1.875rem;
}
@media (min-width: 992px) {
  .checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary {
    flex: 0 0 33.33%;
    padding: 0 4.063rem;
  }
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary .single-shipping-details .billing-addr-label {
  display: none;
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary .summary-details {
  margin-bottom: 1.563rem;
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary .summary-details div,
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary .summary-details span {
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.371;
  flex: auto;
  max-width: inherit;
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary .summary-details .order-history-info {
  margin-bottom: 0;
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-shippingSummary .start-lines span {
  background: unset;
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .payment-form-label p,
.checkout-global.receipt .confirm-details .confirmation-page-summary .summary-section-label {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  margin-bottom: 0.813rem;
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .checkout-global.receipt .confirm-details .confirmation-page-summary .payment-form-label p,
  .checkout-global.receipt .confirm-details .confirmation-page-summary .summary-section-label {
    font-size: 1.125rem;
  }
}
@media (max-width: 1024.98px) {
  .checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary {
    padding: 0 1.875rem;
  }
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information {
    display: flex;
    height: 100%;
  }
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information .payment-details {
  margin-bottom: 1.563rem;
}
@media (min-width: 992px) {
  .checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information .summary-details {
    padding: 0 4.063rem;
    flex: 0 0 50%;
  }
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information .summary-details .summary-section-label {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  margin-bottom: 0.813rem;
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information .summary-details .summary-section-label {
    font-size: 1.125rem;
  }
}
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information .summary-details div,
.checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information .summary-details span {
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.371;
}
@media (min-width: 992px) {
  .checkout-global.receipt .confirm-details .confirmation-page-summary .confirmation-page-billingSummary .payment-information .summary-details.payment {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
  }
}
.checkout-global.receipt .order-product-summary,
.checkout-global.receipt .checkout-order-total-summary {
  border: 1px solid #979797;
}
.checkout-global.receipt .order-product-summary .order-total-summary,
.checkout-global.receipt .checkout-order-total-summary .order-total-summary {
  padding-bottom: 1rem;
}
.checkout-global.receipt .order-product-summary .order-total-summary .grand-total,
.checkout-global.receipt .checkout-order-total-summary .order-total-summary .grand-total {
  padding-bottom: 0;
}
.checkout-global.receipt .order-product-summary .card-header .card-header-custom,
.checkout-global.receipt .checkout-order-total-summary .card-header .card-header-custom {
  font-size: 1.125rem;
  font-family: "Oswald", sans-serif;
  color: #222;
}
.checkout-global.receipt .order-product-summary .pc-promotion-message,
.checkout-global.receipt .checkout-order-total-summary .pc-promotion-message {
  margin-top: 10px;
}
@media (min-width: 992px) {
  .checkout-global.receipt .order-product-summary .product-summary-block,
  .checkout-global.receipt .checkout-order-total-summary .product-summary-block {
    max-width: 430px;
  }
}
.checkout-global.receipt .order-product-summary .product-summary-block .line-item-pricing-info span,
.checkout-global.receipt .checkout-order-total-summary .product-summary-block .line-item-pricing-info span {
  font-family: "Oswald", sans-serif;
}
.checkout-global.receipt .order-product-summary .product-summary-block .price-wrapper .line-item-unit-price .price span,
.checkout-global.receipt .checkout-order-total-summary .product-summary-block .price-wrapper .line-item-unit-price .price span {
  color: #222;
  font-weight: 400;
}
.checkout-global.receipt .order-product-summary .product-summary-block .price-wrapper .line-item-unit-price .price del span,
.checkout-global.receipt .checkout-order-total-summary .product-summary-block .price-wrapper .line-item-unit-price .price del span {
  color: #656565;
}
.checkout-global.receipt .order-product-summary .product-summary-block .price-wrapper .line-item-unit-price .price del + span span,
.checkout-global.receipt .checkout-order-total-summary .product-summary-block .price-wrapper .line-item-unit-price .price del + span span {
  color: #d5232a;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .checkout-global .age-verification .custom-control-input {
    left: 2px;
    z-index: 0;
  }
}

@media (min-width: 992px) {
  #address-verification-modal .modal-dialog {
    max-width: 616px;
    width: 100%;
  }
}
#address-verification-modal .modal-content {
  padding: 38px 30px 30px;
}
@media (min-width: 992px) {
  #address-verification-modal .modal-content {
    padding: 38px 41px 47px;
  }
}
#address-verification-modal .modal-content .modal-header {
  padding: 0 0 20px;
  border-bottom: 1px solid #767676;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}
@media (min-width: 992px) {
  #address-verification-modal .modal-content .modal-header {
    margin-bottom: 12px;
  }
}
#address-verification-modal .modal-content .modal-header .modal-title {
  line-height: 1.067;
  font-weight: 600;
}
@media (min-width: 768px) {
  #address-verification-modal .modal-content .modal-header {
    margin: 0 0 12px;
  }
}
#address-verification-modal .modal-content .modal-header .close {
  margin: 0 0 0 auto;
  opacity: 1;
  padding: 0;
}
#address-verification-modal .modal-content .modal-header .close::before {
  height: 14px;
  width: 14px;
  right: unset;
  top: unset;
  position: relative;
  background-size: 14px;
  background-repeat: no-repeat;
  background-image: url("../images/svg-icons/icn-close.svg");
}
#address-verification-modal .modal-content .multiline-address span {
  font-size: 14px;
}
#address-verification-modal .modal-content .modal-body.small-font {
  display: flex;
  flex-flow: column wrap;
  padding: 0;
}
@media (min-width: 768px) {
  #address-verification-modal .modal-content .modal-body.small-font {
    padding: 0;
    flex-flow: row wrap;
  }
}
#address-verification-modal .modal-content .modal-body.small-font p {
  width: 100%;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
#address-verification-modal .modal-content .modal-body.small-font .form-check-label {
  margin-bottom: 10px;
}
#address-verification-modal .modal-content .modal-body.small-font .form-check-label h5 {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block {
  width: 100%;
  grid-template-rows: unset;
  margin-top: 24px;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  #address-verification-modal .modal-content .modal-body.small-font .address-block {
    margin-bottom: 18px;
  }
}
@media (min-width: 768px) {
  #address-verification-modal .modal-content .modal-body.small-font .address-block {
    width: 50%;
  }
}
@media (max-width: 1024.98px) {
  #address-verification-modal .modal-content .modal-body.small-font .address-block {
    padding-right: 0;
  }
}
#address-verification-modal .modal-content .modal-body.small-font .address-block.suggestion-column {
  border-left: none;
}
@media (max-width: 1024.98px) {
  #address-verification-modal .modal-content .modal-body.small-font .address-block.suggestion-column {
    margin-top: 30px;
  }
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check {
  padding-left: 0;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check .form-check-input {
  opacity: 0;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check .form-check-input label {
  font-family: "Open Sans", sans-serif;
  line-height: 1.371;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check .form-check-input + label {
  display: flex;
  align-items: center;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check .form-check-input + label::before {
  content: url("../images/svg-icons/checkout-radio-btn-unchecked.svg");
  border: none;
  line-height: 1;
  padding-right: 9px;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check .form-check-input + label .display-name {
  padding-left: 12px;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check .form-check-input:checked + label::before {
  content: url("../images/svg-icons/checkout-radio-btn-checked.svg");
  border: none;
  background: none;
  line-height: 1;
  padding-right: 9px;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block .form-check .form-check-input:checked + label::after {
  content: "";
  background: none;
}
#address-verification-modal .modal-content .modal-body.small-font .address-block.suggestion-column {
  padding: 0;
}
@media (min-width: 768px) {
  #address-verification-modal .modal-content .modal-body.small-font .address-block.suggestion-column {
    padding: 0;
  }
}
#address-verification-modal .modal-content .modal-body.small-font .address-verification-content {
  margin-top: 0;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 1.371;
}
#address-verification-modal .modal-content .modal-body.small-font .address-verification-content span {
  font-family: "Open Sans", sans-serif;
}
#address-verification-modal .modal-content .modal-body.small-font .vertexOffLine {
  margin-top: 10px;
}
#address-verification-modal .modal-content .modal-body.small-font .vertexOffLine,
#address-verification-modal .modal-content .modal-body.small-font .vertexOffLine a {
  width: 100%;
}
#address-verification-modal .alert-warning {
  display: none;
}
#address-verification-modal .modal-title {
  font-size: 18px;
  font-weight: 500;
}
#address-verification-modal .address-bypass-row {
  width: 100%;
  padding: 0;
  text-align: center;
}
#address-verification-modal .address-bypass-row button {
  margin-top: 0;
  min-width: 45%;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-beige.disabled {
  opacity: 0.2;
}

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative;
}
.swatch-filter-beige.disabled {
  opacity: 0.2;
}

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-black.disabled {
  opacity: 0.2;
}

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative;
}
.swatch-filter-black.disabled {
  opacity: 0.2;
}

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #007bff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-blue.disabled {
  opacity: 0.2;
}

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #007bff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #007bff;
  display: block;
  position: relative;
}
.swatch-filter-blue.disabled {
  opacity: 0.2;
}

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-brown.disabled {
  opacity: 0.2;
}

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative;
}
.swatch-filter-brown.disabled {
  opacity: 0.2;
}

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #056808;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-green.disabled {
  opacity: 0.2;
}

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #056808;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #056808;
  display: block;
  position: relative;
}
.swatch-filter-green.disabled {
  opacity: 0.2;
}

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-grey.disabled {
  opacity: 0.2;
}

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative;
}
.swatch-filter-grey.disabled {
  opacity: 0.2;
}

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-navy.disabled {
  opacity: 0.2;
}

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative;
}
.swatch-filter-navy.disabled {
  opacity: 0.2;
}

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-orange.disabled {
  opacity: 0.2;
}

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative;
}
.swatch-filter-orange.disabled {
  opacity: 0.2;
}

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-pink.disabled {
  opacity: 0.2;
}

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative;
}
.swatch-filter-pink.disabled {
  opacity: 0.2;
}

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-purple.disabled {
  opacity: 0.2;
}

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative;
}
.swatch-filter-purple.disabled {
  opacity: 0.2;
}

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-red.disabled {
  opacity: 0.2;
}

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative;
}
.swatch-filter-red.disabled {
  opacity: 0.2;
}

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-white.disabled {
  opacity: 0.2;
}

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative;
}
.swatch-filter-white.disabled {
  opacity: 0.2;
}

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative;
}
.swatch-circle-yellow.disabled {
  opacity: 0.2;
}

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative;
}
.swatch-filter-yellow.disabled {
  opacity: 0.2;
}

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em;
}
.swatch-circle-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-circle-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.swatch-square-miscellaneous {
  background: linear-gradient(0deg, rgb(130, 30, 145) 0, rgb(130, 30, 145) 25%, rgb(237, 209, 52) 25%, rgb(255, 255, 0) 50%, rgb(237, 209, 52) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgb(14, 92, 209) 0, rgb(14, 92, 209) 50%, rgb(226, 11, 11) 50%, rgb(226, 11, 11) 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  line-height: 1.38em;
  position: relative;
  width: 1.38em;
}
.swatch-square-miscellaneous.disabled {
  opacity: 0.2;
}
.swatch-square-miscellaneous.selected::after {
  transform: rotate(-35deg);
}

.color-attribute {
  border: none;
  padding: 0;
  background: none;
}
.color-attribute .unselectable {
  pointer-events: none;
}
.color-attribute[disabled] {
  background: none;
  position: relative;
}
.color-attribute[disabled]:hover {
  background: none;
}
.color-attribute[disabled] .swatch-circle {
  border: none;
  opacity: 0.7;
}
.color-attribute[disabled] .cross-out {
  height: 2px;
  width: 31px;
  background: #231f20;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(45deg);
  transform-origin: 0 0;
  box-sizing: content-box;
  border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.color-attribute.unselectable {
  background: none;
  position: relative;
}
.color-attribute.unselectable:hover {
  background: none;
}
.color-attribute.unselectable .swatch-circle {
  border: none;
  opacity: 0.7;
}
.color-attribute.unselectable .cross-out {
  height: 2px;
  width: 31px;
  background: #231f20;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(45deg);
  transform-origin: 0 0;
  box-sizing: content-box;
  border-top: 0.5px solid #fff;
  border-bottom: 0.5px solid #fff;
}

.product-notification-message {
  z-index: 9999;
}

.shipping-discount,
.order-discount {
  color: #d5232a;
}

.line-item-availability.preorder-msg p {
  white-space: nowrap;
}

@media (max-width: 991.98px) {
  .saved-products-list .line-item-price,
  .saved-products-list .line-item-total-price {
    padding: 0;
  }
}
.saved-products-list .page-title {
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  .saved-products-list .page-title {
    font-size: 1.75rem;
    margin-bottom: 1.75rem;
  }
}
.saved-products-list .saved-items-count {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .saved-products-list .saved-items-count {
    margin-bottom: 1rem;
  }
}
.saved-products-list .product-card-footer .product-line-item-options {
  display: none;
}

.product-line-item-options a {
  font-weight: 700;
}

.login-require-error {
  all: unset;
  font-size: 0.875rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e27252;
  cursor: default;
  padding: 1.25rem 0;
  height: auto !important;
}
@media (max-width: 991.98px) {
  .login-require-error {
    text-align: center;
  }
}
.login-require-error p {
  font-family: "Open Sans", sans-serif;
}
.login-require-error p a {
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .login-require-error p span::after {
    display: block;
    clear: both;
    content: "";
  }
}

.login-require-error {
  height: auto !important;
  background-color: #faedd6;
}
.login-require-error p a {
  color: #000;
}

.glide--swipeable {
  cursor: auto;
}

.primary-images .product-carousel {
  visibility: hidden;
}
.primary-images .product-carousel.glide--swipeable {
  visibility: visible;
}
.primary-images .product-carousel .glide__slide {
  display: flex;
  align-items: center;
}
.primary-images .product-carousel .glide__slide .slide-link {
  max-width: 400px;
}
.primary-images .bonusproduct-carousel .glide__arrows {
  height: auto;
}
.primary-images .bonusproduct-carousel .cls-product {
  width: 100%;
}
.primary-images .glide__slides .glide__slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.primary-images .glide__slides .glide__slide .slide-link {
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .primary-images .glide__slides .glide__slide .slide-link {
    display: block;
  }
}
.primary-images .glide__arrows .glide__arrow--left {
  box-shadow: none;
  height: 30px;
  width: 30px;
}
.primary-images .glide__arrows .glide__arrow--left::before {
  content: "";
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat center;
  height: 30px;
  width: 30px;
  background-size: 30px;
  display: block;
}
@media (max-width: 767.98px) {
  .primary-images .glide__arrows .glide__arrow--left::before {
    height: 20px;
    width: 20px;
    background-size: 20px;
  }
}
.primary-images .glide__arrows .glide__arrow--right {
  box-shadow: none;
  height: 30px;
  width: 30px;
}
.primary-images .glide__arrows .glide__arrow--right::before {
  content: "";
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat center;
  height: 30px;
  width: 30px;
  background-size: 30px;
  display: block;
}
@media (max-width: 767.98px) {
  .primary-images .glide__arrows .glide__arrow--right::before {
    height: 20px;
    width: 20px;
    background-size: 20px;
  }
}
.primary-images .glide__arrows .glide__arrow--right:active {
  background: none;
}

.product-quickview .primary-images .glide__arrow--left,
.product-quickview .primary-images .glide__arrow--right {
  top: 25%;
}

.glide__arrow {
  border: none;
  padding: 0;
}

.product-tile-caro {
  position: relative;
}
.product-tile-caro .glide__slide {
  width: auto;
  height: auto;
}
@media (min-width: 768px) {
  .product-tile-caro .glide__slide {
    border-left: 1px solid #d7d3d1;
  }
}
.product-tile-caro .glide__arrow--left {
  box-shadow: none;
  height: 48px;
  width: 48px;
  left: -35px;
}
@media (max-width: 575.98px) {
  .product-tile-caro .glide__arrow--left {
    display: none;
  }
}
.product-tile-caro .glide__arrow--left::before {
  content: "";
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat center;
  height: 48px;
  width: 48px;
  background-size: 48px;
  display: block;
}
.product-tile-caro .glide__arrow--right {
  box-shadow: none;
  height: 48px;
  width: 48px;
  right: -35px;
}
@media (max-width: 575.98px) {
  .product-tile-caro .glide__arrow--right {
    display: none;
  }
}
.product-tile-caro .glide__arrow--right::before {
  content: "";
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat center;
  height: 48px;
  width: 48px;
  background-size: 48px;
  display: block;
}
@media (max-width: 575.98px) {
  .product-tile-caro .product-tile {
    padding: 20px;
  }
}

.video-carousel {
  position: relative;
}
.video-carousel .glide__slide {
  display: flex;
}
@media (max-width: 767.98px) {
  .video-carousel .video {
    width: 100%;
  }
}
.video-carousel .video img {
  width: 100%;
}
.video-carousel .glide__arrows .glide__arrow {
  top: 50%;
  border-radius: 0;
  height: 50px;
  width: 50px;
  box-shadow: none;
}
.video-carousel .glide__arrows .glide__arrow--left {
  left: 0;
}
.video-carousel .glide__arrows .glide__arrow--left::before {
  content: "";
  background: url("../images/svg-icons/icon-arrow-left.svg") no-repeat center;
  height: 48px;
  width: 48px;
  background-size: 48px;
  display: block;
}
.video-carousel .glide__arrows .glide__arrow--right {
  right: 0;
}
.video-carousel .glide__arrows .glide__arrow--right::before {
  content: "";
  background: url("../images/svg-icons/icon-arrow-right.svg") no-repeat center;
  height: 48px;
  width: 48px;
  background-size: 48px;
  display: block;
}

.age-gate {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999999999 !important;
  width: 100%;
  height: 100vh;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.age-gate[data-theme=ammo--remington] {
  background-color: #fff;
  color: #4a4a4a;
}
.age-gate[data-theme=ammo--VistaFederal] {
  background-color: #231f20;
}
.age-gate[data-theme=ammo--hevishot] {
  background-color: #231f20;
}
.age-gate[data-theme=ammo--VistaCCI] {
  background-color: #355ba8;
  color: #fff;
}
.age-gate[data-theme=ammo--VistaSpeer] {
  background-color: #fff;
  color: #231f20;
}
.age-gate[data-theme=ammo--le] {
  background-color: #fff;
  color: #231f20;
}
.age-gate input[type=checkbox] {
  margin-right: 0.5rem;
}
.age-gate__form {
  width: 100%;
  padding: 5vh 5vw;
}
@media (min-width: 1025px) {
  .age-gate__form {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
  }
}
.age-gate__form .content-asset {
  max-width: 75ch;
}
.age-gate__image {
  position: relative;
  object-fit: cover;
  height: 66vh;
  width: 100%;
}
@media (min-width: 1025px) {
  .age-gate__image {
    position: absolute;
    left: unset;
    right: 0;
    top: 0;
    width: 50%;
    height: 100vh;
  }
}

body.age-gate-active {
  overflow-y: hidden;
  height: 100vh;
}

/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.category-tile {
  position: relative;
}
.category-tile h1,
.category-tile h2,
.category-tile h3 {
  font-size: 1.75rem;
  position: absolute;
  bottom: 1.875rem;
  left: 1.875rem;
  color: #fff;
}
.category-tile::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.category-tile {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
}
.category-tile:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  .category-tile.zoom .image-wrapper {
    will-change: transform;
    transition: transform 200ms ease-out;
  }
  .category-tile.zoom:hover .image-wrapper {
    transform: scale(1.2);
  }
}
.category-tile .image-cropper {
  border-radius: 0;
  overflow: hidden;
  margin: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.category-tile .image-wrapper {
  width: 100%;
  position: relative;
}
.category-tile .image-wrapper .background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.category-tile .image-wrapper-custom .background-image-tablet,
.category-tile .image-wrapper-custom .background-image-desktop {
  display: none;
}
@media (min-width: 768px) {
  .category-tile .image-wrapper-custom .background-image-mobile,
  .category-tile .image-wrapper-custom .background-image-desktop {
    display: none;
  }
  .category-tile .image-wrapper-custom .background-image-tablet {
    display: block;
  }
}
@media (min-width: 992px) {
  .category-tile .image-wrapper-custom .background-image-mobile,
  .category-tile .image-wrapper-custom .background-image-tablet {
    display: none;
  }
  .category-tile .image-wrapper-custom .background-image-desktop {
    display: block;
  }
}
.category-tile .image-wrapper .video-container .video-aspect-ratio {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding-top: 56.25%;
  padding-left: 177.7777777778vh;
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio .video-player {
  position: static;
  pointer-events: none;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
  position: absolute;
}
.category-tile .image-text-block-text .primary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-size: 16px;
}
.category-tile .image-text-block-text .secondary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  color: orange;
  font-size: 13px;
}
.category-tile.text-placement-below .category-tile-text {
  margin-top: 1rem;
}
.category-tile.text-placement-inside::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
}
.category-tile.text-placement-inside .category-tile-text {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: #fff;
  z-index: 1;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}
.category-tile.aspect-ratio-square .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-square .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.category-tile.aspect-ratio-landscape .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-landscape .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 66.6666666667%;
}
.category-tile.aspect-ratio-portrait .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-portrait .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 150%;
}

.compare-bar-wrapper {
  bottom: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: rgba(34, 34, 34, 0.65);
}
.compare-bar-wrapper .product-slots {
  height: 6.25em;
}
.compare-bar-wrapper .slot {
  background-color: #fff;
  height: 5em;
  margin: 0 auto;
  position: relative;
  width: 6.788em;
  border-radius: 3px;
}
.compare-bar-wrapper .slot:not([data-pid]) {
  border: 1px dashed #353535;
}
.compare-bar-wrapper .slot img {
  float: left;
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px 0 0 3px;
}
.compare-bar-wrapper .slot .close {
  width: 25%;
  border-radius: 0 3px 3px 0;
  opacity: 1;
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  left: 70%;
}
.compare-bar-wrapper .slot .close button::before {
  content: "";
  display: block;
  background: #f6f6f6 url("../images/svg-icons/icon-close.svg") no-repeat center center;
  background-size: 10px 10px;
  height: 100%;
  width: 16px;
  position: absolute;
  right: -1px;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.compare-bar-wrapper .slot .action {
  text-align: center;
}
.compare-bar-wrapper .clear-all {
  color: #fff;
}

.min-products-msg {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.min-products-msg.show {
  display: block;
}

.product-comparison .back-to-results {
  margin: 0.75rem 0;
}
.product-comparison .product-header > .col {
  padding: 0;
}

.table-striped-column td:nth-of-type(odd) {
  background-color: #eee;
}

.compare-table {
  margin-bottom: 2.5rem;
}
.compare-table thead tr:hover {
  background: none;
}

.performance__table td.label::before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-right: 0.75rem;
  position: relative;
  top: 2px;
}
.performance__table td.label.label--blue::before {
  background: #4a90e2;
}
.performance__table td.label.label--orange::before {
  background: #f5a623;
}
.performance__table td.label.label--purple::before {
  background: #6e3f98;
}
.performance__table td.label.label--light-green::before {
  background: #70a730;
}

.yotpo .yotpo-score {
  padding-left: 10px;
}
.yotpo .yotpo-score span {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.refinement-bar {
  overflow: auto;
}
.refinement-bar h4 {
  margin-bottom: 8px;
}
.refinement-bar ul {
  padding-left: 0;
}
@media (max-width: 767.98px) {
  .refinement-bar {
    background-color: #fff;
    box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
    display: none;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
@media (max-width: 575.98px) {
  .refinement-bar {
    position: fixed;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .refinement-bar {
    display: block !important;
    margin-top: -34px;
  }
}

.filter-header {
  margin-left: -12px;
  margin-right: -12px;
}

.header-bar button.close {
  font-size: 1rem;
  font-weight: normal;
  opacity: 1;
  width: 1rem;
  position: relative;
  right: 10px;
  top: 10px;
}
.header-bar button.close .icon {
  height: 16px;
  width: 16px;
  pointer-events: none;
}

.refinements .header,
.refinements .values {
  padding: 0.313em 0;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .refinements .header li,
  .refinements .values li {
    display: inline-block;
  }
}
.refinements .header {
  font-size: 1.125em;
  color: #888380;
}
.refinements ul li {
  padding-left: 1px;
}
.refinements ul li button {
  border: none;
  cursor: pointer;
  color: #222;
  background-color: transparent;
  text-align: left;
}
@media (min-width: 768px) {
  .refinements ul li button {
    width: 100%;
  }
}
.refinements li {
  list-style-type: none;
  padding-left: 0;
}
.refinements li.color-attribute {
  display: inline-block;
  padding-left: 0;
}
.refinements li.color-attribute button {
  padding: 0;
}
.refinements li.disabled {
  opacity: 0.5;
}
.refinements .card {
  min-width: 0;
}
.refinements .card .card-body ul li {
  border-top: none;
}
@media (max-width: 575.98px) {
  .refinements {
    padding: 0;
  }
}
.refinements .card-header h2 {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0;
}

.secondary-bar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1em;
  overflow: auto;
}
.secondary-bar button.reset {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .secondary-bar button.reset {
    width: 100%;
    margin-top: 0.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .secondary-bar {
    padding: 0.938em 2.813em;
  }
}
@media (max-width: 575.98px) {
  .secondary-bar {
    padding: 0.938em;
  }
}

.refinement-header {
  overflow: auto;
}

.disabled {
  pointer-events: none;
}

.grid-header,
.content-grid-header {
  margin-top: 1em;
  margin-bottom: 9px;
}
@media (max-width: 575.98px) {
  .grid-header .result-count,
  .content-grid-header .result-count {
    padding-bottom: 0.938em;
  }
  .grid-header .filter-results,
  .content-grid-header .filter-results {
    display: block;
    width: 100%;
  }
}
.grid-header select,
.content-grid-header select {
  width: 100%;
}

.result-count p {
  margin: 0;
}
.result-count .result-found {
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  .result-count {
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .sort-options {
    padding: 0 20px;
  }
}

.refinement {
  margin-bottom: 1em;
}
@media (max-width: 767.98px) {
  .refinement.card {
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    border-radius: 0;
    margin-bottom: 0;
  }
  .refinement .card-header {
    border-bottom: 0 none;
    padding: 0.25rem 1rem;
  }
  .refinement .card-body {
    padding: 0 1rem;
  }
}
.refinement a {
  text-decoration: none;
}
.refinement:first-of-type.refinement-category {
  padding: 17px 0;
}
.refinement:first-of-type.refinement-category > ul > li {
  margin-left: 0;
}
.refinement.refinement-category ul {
  margin-bottom: -5px;
}
.refinement.refinement-category li {
  display: block;
}
.refinement.refinement-category li:nth-of-type(n+2) a {
  margin-top: 18px;
  display: block;
}
.refinement.refinement-category li ul li {
  margin-top: 8px;
}
.refinement.refinement-color button img {
  border: 1px solid #353535;
  border-color: #fff;
}
.refinement.refinement-color button img.selected {
  border-color: #353535;
}
.refinement.refinement-color button img:hover {
  border-color: #353535;
}
.refinement.refinement-color button[data-attr-value=swatch-circle-white] {
  border: 1px solid #d7d3d1;
}

.search-keywords {
  font-weight: bold;
}

.filter-bar ul {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
}
.filter-bar li {
  list-style: none;
  float: left;
  margin-bottom: 0.3125em;
  margin-right: 0.3125em;
  margin-top: 0;
}
.filter-bar li:first-child {
  margin-left: 0;
}
.filter-bar li.filter-value {
  border: 1px solid #d7d3d1;
  padding: 0.3125em;
  border-radius: 0;
  position: relative;
}
.filter-bar li.filter-value button {
  color: #222;
  border: none;
  width: auto;
  padding: 0 2rem 0 0.5rem;
}
.filter-bar li.filter-value button::after {
  content: "";
  background: url("../images/svg-icons/icon-close.svg");
  background-size: 9px 9px;
  text-rendering: auto;
  position: absolute;
  right: 10px;
  top: 13px;
  height: 9px;
  width: 9px;
}
.filter-bar li.swatch-filter {
  font-size: 1.38em;
  position: relative;
  margin-top: 4px;
}
.filter-bar li.swatch-filter button {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}
.filter-bar li.swatch-filter button::before {
  content: "";
  background-color: white;
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.4em;
  position: absolute;
  left: 0.5em;
  top: 0.5em;
}
.filter-bar li.swatch-filter button::after {
  content: "\f057";
  font: normal normal normal 14px/1 FontAwesome;
  display: inline;
  text-rendering: auto;
  position: absolute;
  left: 0.18em;
  top: 0.12em;
  font-size: 1.5em;
}

@media (max-width: 575.98px) {
  .tab-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.product-grid {
  padding: 0 20px;
}
.product-grid > div {
  border-bottom: 1px solid #d7d3d1;
  padding: 20px 0;
}
.product-grid > div:first-of-type {
  border-top: 1px solid #d7d3d1;
}
.product-grid > div > div {
  height: 40%;
}
@media (min-width: 576px) {
  .product-grid > div:nth-of-type(2) {
    border-top: 1px solid #d7d3d1;
  }
  .product-grid > div:nth-of-type(odd) .product-tile {
    border-right: 1px solid #d7d3d1;
  }
}
@media (min-width: 992px) {
  .product-grid > div:nth-of-type(odd) .product-tile {
    border-right: none;
  }
  .product-grid > div:nth-of-type(3) {
    border-top: 1px solid #d7d3d1;
  }
  .product-grid > div:nth-of-type(3n + 1) .product-tile {
    border-right: 1px solid #d7d3d1;
  }
  .product-grid > div:nth-of-type(3n) .product-tile {
    border-left: 1px solid #d7d3d1;
  }
}

.search-tips {
  text-align: left;
  margin-top: 1.875rem;
}

.category-item {
  margin-bottom: 0.938rem;
}

.show-more,
.show-more-content {
  padding: 0.938em;
  clear: both;
}

.search-nav .nav-tabs-wrapper {
  padding: 0;
  margin: 1.25rem;
}
.search-nav .nav-tabs-wrapper .nav-tabs {
  border-bottom: 0 none;
}

.product-options .quantity {
  position: absolute;
  bottom: 0;
  right: 0;
}

.product-grid > div > .product {
  height: 100%;
}