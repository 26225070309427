@import "../utilities/imagePathUtils";

@mixin playButton($pathsDeep) {
    position: relative;

    &::before {
        content: "";
        background: image-path($svg-video, $pathsDeep);
        background-size: 80px 80px;
        opacity: 0.6;
        display: block;
        height: 80px;
        width: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover::before {
        transition: $btn-transition;
        opacity: 0.8;
        cursor: pointer;
    }
}

.play-button {
    @include playButton(1);
}
