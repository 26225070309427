.form-group {
    margin-bottom: 0;

    .form-control-feedback {
        display: none;
    }

    &.has-danger {
        .form-control-feedback {
            display: block;
        }
    }

    &.required {
        label {
            &::after {
                content: "*";
                color: $black;
            }

            &.is-invalid::after {
                content: "*";
                color: $danger;
            }
        }
    }

    label {
        margin-bottom: 6px;
        line-height: 1;

        &.is-invalid {
            color: $danger;
        }
    }

    .form-control ~ .form-text {
        color: $gray-600;
    }

    + .form-group,
    + form {
        margin-top: 18px;
    }

    > .form-control-feedback {
        display: none;
        font-size: 0.8125rem;
    }

    &.has-danger,
    &.has-warning {
        > .form-control-feedback {
            display: block;
        }
    }

    ~ button[type="submit"] {
        margin-top: 18px;
    }
}
