.modal-dialog:not(.modal-lg, .modal-sm) {
    width: 300px;
    margin: 1.75rem auto;

    @include media-breakpoint-up(md) {
        width: 400px;
    }

    @include media-breakpoint-up(lg) {
        width: 500px;
    }
}

.modal-content {
    background-clip: padding-box;
    border-radius: $border-radius;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    border-top: $modal-border-top;
    padding: 34px 30px 12px;
    position: relative;

    h6 {
        margin-bottom: 0;
    }

    h4 {
        font-size: $h4-font-size;
    }

    .close {
        &::before {
            content: "";
            display: block;
            background: $svg-close;
            background-size: 10px 10px;
            height: 10px;
            width: 10px;
            position: absolute;
            right: 21px;
            top: 21px;
        }

        @include media-breakpoint-up(sm) {
            padding: 0;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 44px 40px 12px;
    }
}

.modal-footer {
    background: $gray-100;
    padding: 20px 15px;
    justify-content: flex-start;

    button {
        max-width: 130px;
        font-size: 0.8rem;
    }

    @include media-breakpoint-up(md) {
        padding: 20px 40px;
    }
}

.modal-body {
    padding: 0 30px 30px;

    p {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        padding: 0 40px 26px;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 40px 36px;
    }

    &:last-child {
        @include media-breakpoint-up(md) {
            padding-bottom: 36px;
        }
    }

    h3 {
        font-size: 0.9rem;
        letter-spacing: 1px;
    }
}

#requestPasswordResetModal {
    .modal-header {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .request-password-body {
        > p {
            margin-bottom: 1.25rem;
        }
    }
}

#bonus-item-warning-modal {
    .modal-footer button {
        max-width: 100%;
    }

    .modal-header {
        padding-bottom: 5px;
    }
}
