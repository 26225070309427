@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.page-designer {

    .photoHotspot {
        position: relative;

        h4 {
            white-space: nowrap;
        }

        .card-body {
            padding: 0.625em;
        }

        .quantity-label {
            font-size: 0.813em;
        }

        .quantity {
            width: 100%;
        }

        .primary-text {
            font-weight: 600;
            font-size: 1.5rem;
        }

        .secondary-text {
            font-weight: 600;
            font-size: 1rem;
            font-style: italic;
        }

        .product-quickview {
            border-bottom: 1px solid #d7d3d1;

            .product-name {
                font-weight: 600;
                font-size: 1rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .photo-hotspot-product-image {
                height: auto;

                .product-carousel {
                    visibility: visible;
                    .slide {
                        margin-top: auto;
                        margin-bottom: auto;
                        .slide-img {
                            max-height: 200px;
                        }
                    }
                }
            }
        }

        .popover {
            top: 100%;
            left: auto;
            right: 0;
            min-width: 23.44rem;
            max-width: 23.44rem;
            min-height: 6.25rem;
            display: none;
            padding: 0.5rem;

            .modal-footer {
                background-color: transparent;
                padding: 10px 40px;

                .prices-availability {

                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;

                    .value {
                        font-size: 1.25rem;
                    }

                    .availability-label {
                        font-size: 1.25rem;
                    }
                }

                .cart-and-ipay {
                    padding: 0;
                    margin-top: 0.25rem;
                }
            }

            &::before {
                left: auto;
                right: 15px;
            }

            &::after {
                left: auto;
                right: 16px;
            }

            &.show {
                display: block;
            }
        }

        .line-item-divider {
            margin: 0.625em -0.625em;
            width: calc(100% + 0.625em + 0.625em);
        }

        .line-item-name {
            width: 90%;
        }

        .line-item-unit-price {
            .price .strike-through,
            .price del {
                margin-right: 0;
            }
        }

        .checkout-btn {
            margin: 0 auto;
        }

        .product-summary-info {
            border-top: 0.5px solid #fff;
            margin-top: 0.75rem;
            padding-top: 0.75rem;
            // keep border within card
            margin-right: -10px;
            margin-left: -10px;
        }
    }

    .d-hide-mobile p {
        display: none !important;

        @include media-breakpoint-up(lg) {
                display: initial !important;
        }
    }

    .video-modal {
        .modal-dialog {
            width:100%;
            max-width:900px;
            height:400px;

            .modal-header {
                padding: 0;
                height: 40px;
                .close {
                    &::before {
                        background-size: 20px 20px;
                        height: 20px;
                        width: 20px;
                        right: 1.33%;
                        margin-top: 10px;
                        margin-right: 10px;
                        right: 0;
                        top: 0;
                    }
                }
            }
            .modal-body {
                padding: 0;
            }
            .modal-footer {
                padding: 0;
                height: 20px;
            }
            @include media-breakpoint-up(md) {
                width:90%;

                .modal-header {
                    padding: 2% 4% 2%;
                    .close {
                        &::before {
                            background-size: 10px 10px;
                            height: 10px;
                            width: 10px;
                            margin-top: 1.5%;
                            margin-right: 1.5%;
                            right: 0;
                            top: 0;
                        }
                    }
                }
                .modal-body {
                    padding: 0 4% 0;
                }
                .modal-footer {
                    padding: 2% 4% 2%;
                }
            }
        }
    }

    .carousel {
        @include media-breakpoint-down(sm) {
            padding-left: 6%;
            padding-right: 6%;
        }
    }

    .carousel-container {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .carousel-title {
        font-size: 5rem;
    }
    .carousel-copy {
        font-size: 1.025rem;
        font-style: italic;
    }
    .remove-padding {
        margin-top: 0px !important;
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2385704d' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");

    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2385704d' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    .carousel-indicators {
        position: inherit;
    }
    .carousel-indicators li {
        background-color: #85704d;
        width: 50px;
        height: 5px;
    }
    .carousel-caption {
        position: initial;
        color: black;
    }
    .carousel-control-prev, .carousel-control-next {
        width: 5% !important;
    }
    .carousel-image {
        @include media-breakpoint-down(sm) {
            margin-bottom: 2%;
        }
    }

}
