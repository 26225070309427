@import 'typography';

// Font face setup

@font-face {
    font-family: 'Blender Pro Bold';
    src: url('../fonts/Blender Pro Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Blender Pro Book';
    src: url('../fonts/Blender Pro Book.ttf') format('truetype');
}

@font-face {
    font-family: 'Blender Pro Heavy';
    src: url('../fonts/Blender Pro Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Blender Pro Medium';
    src: url('../fonts/Blender Pro Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Blender Pro Thin';
    src: url('../fonts/Blender Pro Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Tungsten Compressed Semi';
    src: url('../fonts/TungstenCompressed-SemiBold.eot');
    src:
        url('../fonts/TungstenCompressed-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-SemiBold.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-SemiBold.woff') format('woff'),
        url('../fonts/TungstenCompressed-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Compressed';
    src: url('../fonts/TungstenCompressed-Light.eot');
    src:
        url('../fonts/TungstenCompressed-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-Light.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-Light.woff') format('woff'),
        url('../fonts/TungstenCompressed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Compressed';
    src: url('../fonts/TungstenCompressed-Thin.eot');
    src:
        url('../fonts/TungstenCompressed-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-Thin.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-Thin.woff') format('woff'),
        url('../fonts/TungstenCompressed-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow';
    src: url('../fonts/TungstenNarrow-SemiBold.eot');
    src:
        url('../fonts/TungstenNarrow-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-SemiBold.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-SemiBold.woff') format('woff'),
        url('../fonts/TungstenNarrow-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow';
    src: url('../fonts/TungstenNarrow-Light.eot');
    src:
        url('../fonts/TungstenNarrow-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-Light.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-Light.woff') format('woff'),
        url('../fonts/TungstenNarrow-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Compressed XLight';
    src: url('../fonts/TungstenCompressed-ExtraLight.eot');
    src:
        url('../fonts/TungstenCompressed-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-ExtraLight.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-ExtraLight.woff') format('woff'),
        url('../fonts/TungstenCompressed-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Compressed Book';
    src: url('../fonts/TungstenCompressed-Book.eot');
    src:
        url('../fonts/TungstenCompressed-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-Book.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-Book.woff') format('woff'),
        url('../fonts/TungstenCompressed-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed Book';
    src: url('../fonts/TungstenCondensed-Book.eot');
    src:
        url('../fonts/TungstenCondensed-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-Book.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-Book.woff') format('woff'),
        url('../fonts/TungstenCondensed-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow Book';
    src: url('../fonts/TungstenNarrow-Book.eot');
    src:
        url('../fonts/TungstenNarrow-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-Book.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-Book.woff') format('woff'),
        url('../fonts/TungstenNarrow-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('../fonts/Tungsten-Thin.eot');
    src:
        url('../fonts/Tungsten-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-Thin.woff2') format('woff2'),
        url('../fonts/Tungsten-Thin.woff') format('woff'),
        url('../fonts/Tungsten-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow';
    src: url('../fonts/TungstenNarrow-Thin.eot');
    src:
        url('../fonts/TungstenNarrow-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-Thin.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-Thin.woff') format('woff'),
        url('../fonts/TungstenNarrow-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten XLight';
    src: url('../fonts/Tungsten-ExtraLight.eot');
    src:
        url('../fonts/Tungsten-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-ExtraLight.woff2') format('woff2'),
        url('../fonts/Tungsten-ExtraLight.woff') format('woff'),
        url('../fonts/Tungsten-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed';
    src: url('../fonts/TungstenCondensed-Bold.eot');
    src:
        url('../fonts/TungstenCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-Bold.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-Bold.woff') format('woff'),
        url('../fonts/TungstenCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed';
    src: url('../fonts/TungstenCondensed-Black.eot');
    src:
        url('../fonts/TungstenCondensed-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-Black.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-Black.woff') format('woff'),
        url('../fonts/TungstenCondensed-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Compressed';
    src: url('../fonts/TungstenCompressed-Medium.eot');
    src:
        url('../fonts/TungstenCompressed-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-Medium.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-Medium.woff') format('woff'),
        url('../fonts/TungstenCompressed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow';
    src: url('../fonts/TungstenNarrow-Medium.eot');
    src:
        url('../fonts/TungstenNarrow-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-Medium.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-Medium.woff') format('woff'),
        url('../fonts/TungstenNarrow-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed XLight';
    src: url('../fonts/TungstenCondensed-ExtraLight.eot');
    src:
        url('../fonts/TungstenCondensed-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-ExtraLight.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-ExtraLight.woff') format('woff'),
        url('../fonts/TungstenCondensed-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed';
    src: url('../fonts/TungstenCondensed-Light.eot');
    src:
        url('../fonts/TungstenCondensed-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-Light.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-Light.woff') format('woff'),
        url('../fonts/TungstenCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('../fonts/Tungsten-Medium.eot');
    src:
        url('../fonts/Tungsten-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-Medium.woff2') format('woff2'),
        url('../fonts/Tungsten-Medium.woff') format('woff'),
        url('../fonts/Tungsten-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Book';
    src: url('../fonts/Tungsten-Book.eot');
    src:
        url('../fonts/Tungsten-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-Book.woff2') format('woff2'),
        url('../fonts/Tungsten-Book.woff') format('woff'),
        url('../fonts/Tungsten-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed';
    src: url('../fonts/TungstenCondensed-Medium.eot');
    src:
        url('../fonts/TungstenCondensed-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-Medium.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-Medium.woff') format('woff'),
        url('../fonts/TungstenCondensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('../fonts/Tungsten-Light.eot');
    src:
        url('../fonts/Tungsten-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-Light.woff2') format('woff2'),
        url('../fonts/Tungsten-Light.woff') format('woff'),
        url('../fonts/Tungsten-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow';
    src: url('../fonts/TungstenNarrow-Bold.eot');
    src:
        url('../fonts/TungstenNarrow-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-Bold.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-Bold.woff') format('woff'),
        url('../fonts/TungstenNarrow-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Compressed';
    src: url('../fonts/TungstenCompressed-Black.eot');
    src:
        url('../fonts/TungstenCompressed-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-Black.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-Black.woff') format('woff'),
        url('../fonts/TungstenCompressed-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed';
    src: url('../fonts/TungstenCondensed-SemiBold.eot');
    src:
        url('../fonts/TungstenCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-SemiBold.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-SemiBold.woff') format('woff'),
        url('../fonts/TungstenCondensed-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Compressed';
    src: url('../fonts/TungstenCompressed-Bold.eot');
    src:
        url('../fonts/TungstenCompressed-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCompressed-Bold.woff2') format('woff2'),
        url('../fonts/TungstenCompressed-Bold.woff') format('woff'),
        url('../fonts/TungstenCompressed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow';
    src: url('../fonts/TungstenNarrow-Black.eot');
    src:
        url('../fonts/TungstenNarrow-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-Black.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-Black.woff') format('woff'),
        url('../fonts/TungstenNarrow-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('../fonts/Tungsten-Black.eot');
    src:
        url('../fonts/Tungsten-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-Black.woff2') format('woff2'),
        url('../fonts/Tungsten-Black.woff') format('woff'),
        url('../fonts/Tungsten-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('../fonts/Tungsten-Bold.eot');
    src:
        url('../fonts/Tungsten-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-Bold.woff2') format('woff2'),
        url('../fonts/Tungsten-Bold.woff') format('woff'),
        url('../fonts/Tungsten-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('../fonts/Tungsten-SemiBold.eot');
    src:
        url('../fonts/Tungsten-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tungsten-SemiBold.woff2') format('woff2'),
        url('../fonts/Tungsten-SemiBold.woff') format('woff'),
        url('../fonts/Tungsten-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Condensed';
    src: url('../fonts/TungstenCondensed-Thin.eot');
    src:
        url('../fonts/TungstenCondensed-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenCondensed-Thin.woff2') format('woff2'),
        url('../fonts/TungstenCondensed-Thin.woff') format('woff'),
        url('../fonts/TungstenCondensed-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten Narrow XLight';
    src: url('../fonts/TungstenNarrow-ExtraLight.eot');
    src:
        url('../fonts/TungstenNarrow-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TungstenNarrow-ExtraLight.woff2') format('woff2'),
        url('../fonts/TungstenNarrow-ExtraLight.woff') format('woff'),
        url('../fonts/TungstenNarrow-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

// Class selectors for typography mixins

// Links
a,
.link-primary,
.link-primary span {
    @include link-primary();

    color: $link-color;
    font-size: $font-size-body;
    line-height: 21px;
    text-decoration: none;

    &:hover {
        text-decoration: $link-decoration-hover;
        color: $link-hover-color;
    }
}

p a,
label a,
.link-secondary,
.link-secondary span {
    @include link-secondary();

    color: $link-color-alt;
    font-size: $font-size-body;
    line-height: 21px;
    text-decoration: underline;

    &:hover {
        color: $link-color-hover-alt;
    }
}

// Labels
.label-1 {
    @include type-label-1();
}

.label-2 {
    @include type-label-2();
}

.label-3 {
    @include type-label-3();
}

// Body

p,
span,
.body-txt {
    @include type-body();
}

// Forms
.non-input-label,
label {
    @include type-form-label();
}

.custom-control-label {
    @include type-custom-input-label();
}

.form-control::placeholder {
    @include type-placeholder-text();
}

input,
textarea,
select {
    @include type-form-control-text();
}

.form-control {
    ~ .form-text {
        @include type-form-text();
    }

    ~ .invalid-feedback {
        @include type-form-error-text();
    }
}

// Alerts

.alert,
.alert span {
    @include type-alert-text();
}

// Misc..

.prop-65-msg {
    @include type-prop-65-msg();
}

.breadcrumb-item,
.breadcrumb-item a {
    @include type-breadcrumbs();
}

ol,
ul {
    > li {
        @include type-parent-list();
    }

    ul,
    ol {
        > li {
            @include type-child-list();
        }
    }
}

// Product Tile
.pdp-link,
.pop-link a {
    @include type-h6();
}

.tile-body .pid {
    @include type-pid();
}

.tile-body .price,
.tile-body .price .starting,
.tile-body .price .range .dash,
.tile-body .price .list,
.tile-body .price .sales,
.tile-body .price .value,
.tile-body .price .list .value,
.tile-body .price .sales .value {
    @include type-tile-price();
}

.tile-body .price .strike-through,
.tile-body .price .strike-through .value {
    @include type-tile-price-crossed-out();
}

.badge {
    @include type-badge();
}

// Price
.prices-add-to-cart-actions .price,
.prices-add-to-cart-actions .price .starting,
.prices-add-to-cart-actions .price .list,
.prices-add-to-cart-actions .price .range .dash,
.prices-add-to-cart-actions .price .sales,
.prices-add-to-cart-actions .price .value {
    @include type-product-price();
}

.prices-add-to-cart-actions .price td,
.prices-add-to-cart-actions .price td.value,
.prices-add-to-cart-actions .price td .sales,
.prices-add-to-cart-actions .price td .value {
    @include type-body();
}

.price .strike-through,
.price .strike-through .value {
    @include type-product-price-crossed-out();
}

.tile-body .price,
.tile-body .price .list,
.tile-body .price .sales,
.minicart .price .sales,
.minicart .price .sales span,
.minicart .price .pricing,
.minicart .sub-total {
    @include type-tile-price();
}

.tile-body .price .strike-through,
.minicart .price .strike-through {
    @include type-tile-price-crossed-out();
}

// Promotion
.pc-promotion-message {
    @include type-promotion();
}

// Footer
footer .footer-item a {
    @include type-footer-item-link();
}

footer .postscript a {
    @include footer-postscript-links();
}

footer .copyright {
    @include footer-copyright();
}

// Header
.minicart-quantity {
    @include type-minicart-qty();
}

.site-search .search-field::placeholder {
    @include type-search-placeholder();
}

.header-utility-links a,
.header-utility-links a span {
    @include type-header-utility-link();
}

.search-result-count,
.result-count span {
    @include type-search-result-count();
}

.sort-options {
    select {
        @include type-sort-options();
    }
}

// PDP

.part-id {
    @include type-part-number();
}
