/**
 * ==============================================
 * Chamber Spinner
 * ==============================================
 */

.veil {
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: $black;
    }
}

.choose-bonus-product-dialog .veil .underlay {
    background-color: transparent;
}

$spinner-size: 200px;

.spinner {
    width: $spinner-size;
    height: $spinner-size;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: $spinner-size / 2 * -1;
    margin-left: $spinner-size / 2 * -1;
}

/* Vendors */

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin animation($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}

@mixin rounded($rounded) {
    -webkit-border-radius: $rounded;
    -moz-border-radius: $rounded;
    border-radius: $rounded;
}

@mixin rotate($rotate) {
    -webkit-transform: rotate($rotate);
    -moz-transform: rotate($rotate);
    -o-transform: rotate($rotate);
    transform: rotate($rotate);
}

@mixin scale($scale) {
    -webkit-transform: scale($scale);
    -moz-transform: scale($scale);
    -o-transform: scale($scale);
    transform: scale($scale);
}

@mixin shadow($shadow) {
    -webkit-box-shadow: ($shadow);
    box-shadow: ($shadow);
}

/* Cylinder animation */

@mixin anim_cylinder() {
    0% { @include rotate(0deg); }
    06% { @include rotate(60deg); }
    08% { @include rotate(60deg); }

    16% { @include rotate(120deg); }
    18% { @include rotate(120deg); }

    26% { @include rotate(180deg); }
    28% { @include rotate(180deg); }

    36% { @include rotate(240deg); }
    38% { @include rotate(240deg); }

    46% { @include rotate(300deg); }
    48% { @include rotate(300deg); }

    56% { @include rotate(360deg); }
    58% { @include rotate(360deg); }

    66% { @include rotate(-420deg); }
    68% { @include rotate(-420deg); }
    72% { @include rotate(-360deg); }
    76% { @include rotate(-300deg); }
    80% { @include rotate(-240deg); }
    84% { @include rotate(-180deg); }
    88% { @include rotate(-120deg); }
    92% { @include rotate(-60deg); }

    100% { @include rotate(360deg); }
}

/* Bullets animation */

@mixin anim_bullet1() {
    04.5% {
        @include scale(1);

        opacity: 0;
    }

    06% {
        @include scale(0.8);

        opacity: 1;
    }

    70% {
        @include scale(0.8);

        opacity: 1;
    }

    71% {
        @include scale(0);

        opacity: 0;
    }
}

@mixin anim_bullet2() {
    14.5% {
        @include scale(1);

        opacity: 0;
    }

    16% {
        @include scale(0.8);

        opacity: 1;
    }

    74% {
        @include scale(0.8);

        opacity: 1;
    }

    75% {
        @include scale(0);

        opacity: 0;
    }
}

@mixin anim_bullet3() {
    24.5% {
        @include scale(1);

        opacity: 0;
    }

    26% {
        @include scale(0.8);

        opacity: 1;
    }

    78% {
        @include scale(0.8);

        opacity: 1;
    }

    79% {
        @include scale(0);

        opacity: 0;
    }
}

@mixin anim_bullet4() {
    34.5% {
        @include scale(1);

        opacity: 0;
    }

    36% {
        @include scale(0.8);

        opacity: 1;
    }

    82% {
        @include scale(0.8);

        opacity: 1;
    }

    83% {
        @include scale(0);

        opacity: 0;
    }
}

@mixin anim_bullet5() {
    44.5% {
        @include scale(1);

        opacity: 0;
    }

    46% {
        @include scale(0.8);

        opacity: 1;
    }

    86% {
        @include scale(0.8);

        opacity: 1;
    }

    87% {
        @include scale(0);

        opacity: 0;
    }
}

@mixin anim_bullet6() {
    54.5% {
        @include scale(1);

        opacity: 0;
    }

    56% {
        @include scale(0.8);

        opacity: 1;
    }

    90% {
        @include scale(0.8);

        opacity: 1;
    }

    91% {
        @include scale(0);

        opacity: 0;
    }
}

/* Animation */

@keyframes anim_cylinder {
    @include anim_cylinder;
}

@keyframes anim_bullet1 {
    @include anim_bullet1;
}

@keyframes anim_bullet2 {
    @include anim_bullet2;
}

@keyframes anim_bullet3 {
    @include anim_bullet3;
}

@keyframes anim_bullet4 {
    @include anim_bullet4;
}

@keyframes anim_bullet5 {
    @include anim_bullet5;
}

@keyframes anim_bullet6 {
    @include anim_bullet6;
}

/* Rules */

$back_color: $pewter;

@mixin taille_au_carre($taille) {
    width: $taille;
    height: $taille;
}

/* General */

#cylinder {
    margin: 50px auto;
    background: $night-rider;
    position: relative;

    @include taille_au_carre(100px);

    @include rounded(100%);

    @include animation(anim_cylinder 8000ms ease 800ms infinite);
}

.sculpt {
    display: none; // TODO - remove the sculpts or keep working on it
    position: absolute;
    background-color: $back_color;

    @include taille_au_carre(27px);

    @include rounded(100%);
}

.sculpt.one {
    top: -12px;
    left: 65px;
}

.sculpt.two {
    top: -12px;
    left: 9px;
}

.sculpt.three {
    top: 37px;
    left: -20px;
}

.sculpt.four {
    top: 86px;
    left: 9px;
}

.sculpt.five {
    top: 86px;
    left: 65px;
}

.sculpt.six {
    top: 37px;
    left: 94px;
}

.ring.center {
    background-color: $nero;
    position: absolute;
    left: 38px;
    top: 38px;

    @include taille_au_carre(23px);

    @include rounded(100%);

    @include shadow(0 0 0 2px $charcoal);
}

.chamber {
    position: relative;
    overflow: hidden;
    background-color: $charcoal;

    @include taille_au_carre(23px);

    @include rounded(100%);
}

.chamber.one {
    top: 7px;
    left: 38px;
}

.chamber.two {
    top: -1px;
    left: 11px;
}

.chamber.three {
    top: 7px;
    left: 11px;
}

.chamber.four {
    top: 1px;
    left: 38px;
}

.chamber.five {
    top: -71px;
    left: 66px;
}

.chamber.six {
    top: -62px;
    left: 66px;
}

.bullet {
    background: $tapa;
    opacity: 0;

    @include taille_au_carre(23px);

    @include rounded(100%);
}

.one .bullet {
    @include animation(anim_bullet1 8000ms ease 800ms infinite);
}

.two .bullet {
    @include animation(anim_bullet2 8000ms ease 800ms infinite);
}

.three .bullet {
    @include animation(anim_bullet3 8000ms ease 800ms infinite);
}

.four .bullet {
    @include animation(anim_bullet4 8000ms ease 800ms infinite);
}

.five .bullet {
    @include animation(anim_bullet6 8000ms ease 800ms infinite);
}

.six .bullet {
    @include animation(anim_bullet5 8000ms ease 800ms infinite);
}

.bullet .ring {
    background-color: $barley-corn;
    position: absolute;
    left: 5px;
    top: 5px;

    @include taille_au_carre(13px);

    @include rounded(100%);
}
