@include media-breakpoint-up('md') {
    #quickViewModal {
        .quick-view-dialog {
            .primary-images,
            .slick-list {
                max-height: none;

                .slide {
                    display: table;
                    width: 100%;
                    table-layout: fixed;

                    a {
                        display: table-cell;
                        position: relative;
                        vertical-align: top;
                        text-align: center;
                        width: 205px;

                        img {
                            height: auto;
                            width: auto;
                            max-height: 344px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}

.quick-view-dialog {
    .modal-body {
        overflow-y: visible;
        max-height: 550px;
    }

    .product-carousel:not(.slick-initialized) {
        .slide.glide__slide {
            display: block;
        }
    }
}

.quick-view-dialog {
    .yotpo {
        .rating-star.fill-star {
            background-image: url("../../images/svg-icons/icon-star-filled.svg");
            display: inline-block;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .rating-star.empty-star {
            background-image: url("../../images/svg-icons/icon-star-empty.svg");
            display: inline-block;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .rating-star.half-star {
            background-image: url("../../images/svg-icons/icon-half-star.svg");
            display: inline-block;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .fill-star,
        .empty-star,
        .half-star {
            width: 20px;
            height: 20px;
            margin-right: 3px;
        }

        .yotpo-icon-half-star {
            position: relative;
            bottom: 8px;
        }

        .star-count {
            .fill-star,
            .empty-star,
            .half-star {
                width: 20px;
                height: 20px;
                margin-right: 3px;
            }
        }

        .yotpo-score {
            padding-left: 10px;
        }
    }
}
