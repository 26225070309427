@import "~org/utilities/swatch";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.color-attribute {
    &.unselectable {
        background: none;
        position: relative;

        &:hover {
            background: none;
        }

        .swatch-circle {
            border: none;
            opacity: 0.7;
        }

        .cross-out {
            height: 2px;
            width: 31px;
            background: $secondary;
            position: absolute;
            top: 0;
            left: 2px;
            transform: rotate(45deg);
            transform-origin: 0 0;
            box-sizing: content-box;
            border-top: $crossed-out-border;
            border-bottom: $crossed-out-border;
        }
    }
}

.product-notification-message {
    z-index: 9999;
}
