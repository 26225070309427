@import "../variables";

.price {
    color: $body-color;

    .strike-through,
    del {
        text-decoration: line-through;

        + .sales {
            color: $accent-2;
        }
    }

    .strike-through {
        margin-right: 10px;
    }

    .tiered {
        padding: 0 map-get($spacers, 1);
        background: $gray-200;
        margin-bottom: map-get($spacers, 1);

        table {
            margin: 0 auto;
        }
    }
}
