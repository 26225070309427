@import "toastMessage";
@import "./badges";

.attribute {
    margin-top: 0.938em;

    label {
        display: block;
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {
    height: 290px;
    padding-bottom: 10px;

    .slick-prev,
    .slick-next {
        height: 20px;
        width: 20px;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
        }

        @include media-breakpoint-up(md) {
            height: 30px;
            width: 30px;

            &::before {
                content: '';
                height: 30px;
                width: 30px;
            }
        }
    }

    .slick-prev {
        left: 25px;

        &::before {
            background: $svg-arrow-left no-repeat;
            background-size: 20px 20px;
        }

        @include media-breakpoint-up(sm) {
            left: -10px;
        }

        @include media-breakpoint-up(lg) {
            left: 10px;

            &::before {
                background-size: 30px 30px;
            }
        }
    }

    .slick-next {
        right: 25px;

        &::before {
            background: $svg-arrow-right no-repeat;
            background-size: 20px 20px;
        }

        @include media-breakpoint-up(sm) {
            right: -10px;
        }

        @include media-breakpoint-up(lg) {
            right: 10px;

            &::before {
                background-size: 30px 30px;
            }
        }
    }

    .slick-list {
        height: 290px;

        @include media-breakpoint-up(md) {
            height: 600px;
        }
    }

    .slick-initialized .slick-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    div:not(.badge) {
        height: 100%;
    }

    .product-carousel img {
        max-height: 100%;
        height: auto;
        max-width: 290px;

        @include media-breakpoint-down(md) {
            max-width: 211px;
            max-height: 90%;
        }

        @include media-breakpoint-up(md) {
            max-width: 400px;
        }
    }

    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }

    @include media-breakpoint-up(md) {
        height: 600px;
    }
}

.prices-add-to-cart-actions {
    margin-top: map-get($spacers, 1);

    .prices {
        text-align: left;
        width: 100%;
    }

    .price {
        position: relative;
        top: 2px;
    }
}

.cart-and-ipay {
    text-align: center;
    padding-top: 10px;
    padding-bottom: map-get($spacers, 2);

    .btn {
        width: 100%;
        display: block;
    }

    .hidden {
        display: none;
    }

    @include media-breakpoint-up(md) {
        padding: 0 12px map-get($spacers, 2);
        margin-top: map-get($spacers, 2);
    }
}

.add-to-cart-messages {
    @include toast-message();
}

.add-to-wish-list {
    margin-top: 1em;
}

.add-to-basket-alert {
    @include toast-alert();
}

.qty-alert {
    @include toast-message();
}

.simple-quantity {
    margin-top: map-get($spacers, 2);
}

.main-attributes {
    margin-top: 1em;
}

.size-chart {
    margin-top: 1.071em;
}

.bundle-item {
    padding-bottom: 1em;
    border-bottom: 1px solid $hr-border-color;

    &:last-child {
        border-bottom: none;
    }
}

.container.product-detail {
    margin-top: 0;
    margin-bottom: 0;

    @include media-breakpoint-only(xs) {
        margin-top: map-get($spacers, 5);
        margin-bottom: map-get($spacers, 5);
    }
}

//TODO look into options
.product-option:not(:first-child) {
    margin-top: map-get($spacers, 1);
}

.slide-link {
    display: block;
}

.customContact {
    flex: 0 0 100%;

    a {
        float: right;
        margin-right: 12px;
    }
}
